/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
// Componentes
import {
    Grid, 
    Box, 
    Paper, 
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
    makeStyles,
    Slide,
    Dialog,
    AppBar,
    IconButton,
    Typography,
    Toolbar,
    Button,
    Tooltip} from '@material-ui/core';
import ReloadWithFilter from "../shared/ReloadWithFilter";
import {getPuestosMunicipio} from "../../core/apiEventos";
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";
import CustomTableHead from "../shared/Tablas/CustomTableHead";

// Validaciones
import CloseIcon from '@material-ui/icons/Close';
import Paginacion from "../shared/Tablas/Paginacion";

import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";
import moment from "moment";

import DialogPuestoConfirmacion from './DialogPuestoConfirmacion';
import HistoryIcon from '@mui/icons-material/History';
import DialogHistorialMarcacion from './DialogHistorialMarcacion';
import { isAuthenticated } from '../../core/sessionAPI';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPuestosMarcacion({open, close, departamento, municipio, producto, estado, evento, tipo, titleCity=""}){
    const classes = useStyles();

    const [canMark, setCanMark] = useState(false);
    const [puestos, setPuestos] = useState([]); 
    const [puestosTable, setPuestosTable] = useState([]);    
    const [puesto, setPuesto] = useState();
    const [tipoZona, setTipoZona] = useState();

    const [openMarcarPuesto, setOpenMarcarPuesto] = useState(false);
    const [tipoMarcacion, setTipoMarcacion] = useState();

    const [openHistorial,setOpenHistorial] = useState(false);

    const [reloadFilter, setReloadFilter] = useState(false);

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    const loadPuestos = async () => {
        showLoader();
        
        const res = await getPuestosMunicipio(tipo, departamento, municipio, producto, estado, evento);

        if (res.error){
            setSnackbarProps("e" + res.error);
            setPuestos([]);
            setPuestosTable([]);
        }else{
            setPuestos(res.data);
            setPuestosTable(res.data);
        }
        setReloadFilter(!reloadFilter);
        hideLoader();
    }

    const loadPuestosFilter = async () => {
        showLoader();
        const res = await getPuestosMunicipio(tipo, departamento, municipio, producto, estado, evento);
        if (res.error) {
            setSnackbarProps("e" + res.error);
            setPuestos([]);
            setPuestosTable([]);
        } else {
            const filterPuestos = res.data.filter(item2 => puestosTable.some(item1 => item1._id === item2._id));
             setPuestos(res.data);
             setPuestosTable(filterPuestos);
        }

        hideLoader();
    }

    const actualizarValores = async () => {
        if(puestos.length===puestosTable.length || puestosTable.length===0){
            setReloadFilter(!reloadFilter);
            await loadPuestos(); 
        }else{
            await loadPuestosFilter();
        } 
    }

    useEffect(() => {
        async function inicializar() {
            if(departamento && municipio){
                await actualizarValores()
            }
        }
        if(open){
            inicializar();
        }
    }, [open]);


    useEffect(() => {
        const {user} = isAuthenticated()
        if(user.role === 2) setCanMark(true)
        else if(user.role === 3) setCanMark(user[estado])
        else setCanMark(false)
    }, [open])

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            PUESTOS DE { municipio?.name || '' } + {tipo} + {estado.toUpperCase()}
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box p={1}>
                                <Paper elevation={1}>
                                    <Box p={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={10} md={10}>
                                                <ReloadWithFilter
                                                    source={puestos}
                                                    searchBy="puestos"
                                                    setTableData={setPuestosTable}
                                                    tableReload={reloadFilter}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2}  md={2}>   
                                                    <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={actualizarValores}
                                                    >ACTUALIZAR
                                                    </Button>       
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>     
                            <Box p={1}>
                                <Paper elevation={1}>
                                    <TablaPuestos
                                        canMark={canMark}
                                        confirmarPuesto={(puesto, zona) => {
                                            setPuesto(puesto);
                                            setTipoZona(zona);
                                            setTipoMarcacion("marcar");
                                            setOpenMarcarPuesto(true);
                                        }}
                                        cancelarPuesto={(puesto, zona) => {
                                            setPuesto(puesto);
                                            setTipoZona(zona);
                                            setTipoMarcacion("desmarcar");
                                            setOpenMarcarPuesto(true);
                                        }}
                                        consultarHistoricoEventos={(puesto) => {
                                            setPuesto(puesto);
                                            setOpenHistorial(true);
                                        }}
                                        puestos={puestosTable}                                        
                                    />
                                </Paper>
                            </Box> 
                        </Grid>
                    </Grid>
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>               
            </Dialog>

            <DialogPuestoConfirmacion 
                tipoMarcacion={tipoMarcacion}
                setSnackbarProps={setSnackbarProps}
                open={openMarcarPuesto}
                close={()=>{
                    setOpenMarcarPuesto(false);
                }}
                tipo={tipoZona}
                estado={estado}
                departamento={departamento}
                municipio={municipio}
                puesto={puesto}
                evento={evento}
                producto={producto}
                reloadAll={actualizarValores}
            />

            <DialogHistorialMarcacion 
                open={openHistorial}
                close={()=>{
                    setOpenHistorial(false);
                }}
                tipo={tipo}
                estado={estado}
                puesto={puesto}
                evento={evento}
                producto={producto}
            />
        </>
    );
}

const headCells = 
    [
        { id: 'options', label: 'Opciones', align: 'left', sortable: false },
        { id: 'item', align: "left", label: '#', sortable: true },
        { id: 'fechaMarcacion', label: 'Fecha Marcación', align: 'left', sortable: true },
        { id: 'codigoDivipol', label: 'Código Divipol', align: 'left', sortable: true },
        { id: 'nombreDelPuesto', label: 'Puesto', align: 'left', sortable: true },
        { id: 'codigoComuna', label: 'Código Comuna', align: 'left', sortable: true },
        { id: 'nombreComuna', label: 'Comuna', align: 'left', sortable: true },
        { id: 'mesas', label: '# Mesas', align: 'left', sortable: true },
        { id: 'responsable', label: 'Responsable', align: 'left', sortable: true },
        { id: 'tipoZona', label: 'tipoZona', align: 'left', sortable: true },
        { id: 'estado', label: 'Estado', align: 'left', sortable: true }
    ];

export function TablaPuestos ({canMark, puestos, confirmarPuesto, cancelarPuesto, consultarHistoricoEventos})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();
    const [isWebview, setIsWebView] = useState(false);
    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 50;
	const rowsPerPageOptionsDefault = [50, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    useEffect(() => {
        //Validar si el origen es un webview, para remover el accceso a la interfaz de eventos y marcación
        const variables = window.location.href.split("/");
        if(variables.length === 8){
            //Contiene variable de webviwe
            if(variables[7] === "webView"){
                //Esconder acceso a controles
                setIsWebView(true);
            }
        };
    }, []); 

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() => {
        setRows(Array.from(puestos, (puesto, index) =>{

            var estado = "";
            if(puesto.valor){
                estado = "MARCADO";
            }else{
                estado = "PENDIENTE";
            }

            return {
                item: index + 1,
                puesto: puesto,
                zona:puesto.zona,
                codigoPuesto:puesto.codigoPuesto,
                nombreDelPuesto:puesto.nombreDelPuesto,
                codigoComuna:puesto.codigoComuna,
                nombreComuna:puesto.nombreComuna,
                mesas:puesto.mesas,
                responsable:puesto.responsable,
                kits:puesto.kits,
                codigoDivipol: puesto.codigoDivipol,
				estado: estado,
                tipoZona: puesto.tipoZona,
                valor: puesto.valor, 
                fechaMarcacion: puesto.fechaMarcacion

            };
        }));
    }, [puestos]);
    console.log({isWebview, canMark})
    return (
		<>
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}			
					/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron registros
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className={tableClasses.options}>
									<Box display="flex" justifyContent="left" alignItems="left">
                                        {!isWebview && !row.valor && canMark && <Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                confirmarPuesto(row.puesto, row.tipoZona)
                                            }}>
											<Tooltip title="Marcar puesto">
												<LibraryAddCheckIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>}
										{!isWebview && row.valor && canMark && <Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                cancelarPuesto(row.puesto, row.tipoZona)
                                            }}>
											<Tooltip title="Desmarcar puesto">
												<CancelPresentationIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>}
                                        {row.fechaMarcacion && <Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                consultarHistoricoEventos(row.puesto)
                                            }}>
											<Tooltip title="Historial de marcaciones del puesto">
												<HistoryIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>}
									</Box>
								</TableCell>
								<TableCell align="left">{row.item}</TableCell>
                                <TableCell align="left">{row.fechaMarcacion?moment(row.fechaMarcacion).format("DD/MM/YYYY HH:mm:ss"):"Pendiente"}</TableCell>
                                <TableCell align="left">{row.codigoDivipol}</TableCell>
								<TableCell align="left">
                                    <div className={row.valor? "blockBase blockGreen":"blockBase blockRed"}>
                                        <b>{row.nombreDelPuesto}</b>
                                    </div>
                                </TableCell>								
								<TableCell align="left">{row.codigoComuna}</TableCell>
								<TableCell align="left">{row.nombreComuna}</TableCell>
                                <TableCell align="left">
                                    <div className={row.valor? "blockBase blockGreen":"blockBase blockRed"}>
                                        {row.mesas}
                                    </div>
                                </TableCell>
                                <TableCell align="left">{row.responsable}</TableCell>
                                <TableCell align="left">{row.tipoZona}</TableCell>
                                <TableCell align="left">
                                    {row.valor ? <div className={row.valor? "blockBase blockGreen":"blockBase blockRed"}>MARCADO</div>:"PENDIENTE"}
                                </TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
    	</>
	);
}