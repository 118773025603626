const { makeStyles } = require("@material-ui/core")
const { blueGrey } = require("@material-ui/core/colors")

exports.useWrapperStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "100vh",
        flexFlow: "column"
    },
    offset: theme.mixins.toolbar,
    content: {
        flex: "1 1 auto",
        background: blueGrey[50],
        // border: "2px solid red",
        // padding: theme.spacing(3), // Pading
    }
}))