var urlApp = '';
if(process.env.REACT_APP_PORT_API){
    //Ambiente de desarrollo local
    urlApp = "http://localhost:" + process.env.REACT_APP_PORT_API;
}

exports.locationAPI                 = urlApp+"/api/location";
exports.empresasOperadorasAPI       = urlApp+"/api/empresaOperadora";
exports.sedesAPI                    = urlApp+"/api/sedes";
exports.tipoPagoAPI                 = urlApp+"/api/tipoPago";
exports.sessionAPI                  = urlApp+"/api/session";
exports.usersAPI                    = urlApp+"/api/users";
exports.empresasAPI                 = urlApp+"/api/companies";
exports.sellersAPI                  = urlApp+"/api/sellers";
exports.clientsAPI                  = urlApp+"/api/clients";
exports.supervisorsAPI              = urlApp+"/api/supervisors";
exports.productsAPI                 = urlApp+"/api/products";
exports.salesAPI                    = urlApp+"/api/sales";
exports.settingsAPI                 = urlApp+"/api/settings";
exports.movementsAPI                = urlApp+"/api/movements";
exports.controlsAPI                 = urlApp+"/api/controls";
exports.dashboardAPI                = urlApp+"/api/dashboard";
exports.eventosAPI                  = urlApp+"/api/eventos";
exports.gerentesAPI                 = urlApp+"/api/gerentes";
exports.productosAPI                = urlApp+"/api/productosEvento";
exports.rolesAPI                    = urlApp+"/api/rolesEvento";
exports.divipolAPI                  = urlApp+"/api/divipol";
exports.proyectoAPI                 = urlApp+"/api/proyecto";
exports.servicioAPI                 = urlApp+"/api/servicio";
exports.sucursalAPI                 = urlApp+"/api/sucursal";
exports.registroRetencionAPI        = urlApp+"/api/registroRetencion";
exports.reteicaAPI                  = urlApp+"/api/reteica";
exports.proveedorPagosAPI           = urlApp+"/api/proveedorPagos";
exports.reglaComisionProveedorAPI   = urlApp+"/api/reglaComisionProveedor";
exports.resolucionFacturacionAPI    = urlApp+"/api/resolucionFacturacion";
exports.conceptoAPI                 = urlApp+"/api/concepto";
exports.conceptoCiudadAPI           = urlApp+"/api/conceptoCiudad";
exports.pagoSupervisorAPI           = urlApp+"/api/pagoSupervisor";
exports.reportesAPI                 = urlApp+"/api/reportes";
exports.ciudadSucursalAPI           = urlApp+"/api/ciudadSucursal";
