import { conceptoCiudadAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getConceptoCiudadAll = async (concepto) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getConceptoCiudadAll`,{concepto}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getConceptoCiudad = async (ciudadSucursal, isZonificado, evento, departamento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getConceptoCiudad`,{ciudadSucursal, isZonificado, evento, departamento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getConceptoCiudadesTotal = async (sucursal) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getConceptoCiudadesTotal`,{ sucursal }, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getConceptoDepartamentoTotal = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getConceptoDepartamentoTotal`, {evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createConceptoCiudad = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createConceptoCiudad`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updateConceptoCiudad = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateConceptoCiudad`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}