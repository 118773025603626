import { supervisorsAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getSupervisores = async (idEvento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSupervisores`,{evento: idEvento, delete: false}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getCorreoExitente = async (email, evento) =>
{
        const res = await axios.post(`${API}/getCorreoExitente`,{evento : evento, email: email, delete: false});
        return handleResponse(res);
}

export const confirmActivateEmail = async (email, userName, idConcepto) =>
{
        const res = await axios.post(`${API}/confirmActivateEmail`, {email, userName, idConcepto});

        return handleResponse(res);
}

export const activarCuenta = async (codigo) =>
{
        const res = await axios.post(`${API}/activarCuenta`, {codigo});

        return handleResponse(res);
}

export const getSupervisoresByGerente = async (idEvento, id) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSupervisoresByGerente`,{evento: idEvento, idGerente: id, delete: false}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getSupervisoresAndTotalPago = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSupervisoresAndTotalPago`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

// Obtiene el listado de Clientes según la empresa
export const getSupervisoresAndTotalPagoPaginated = async (idEvento, page, perPage, buscarField, puesto, municipio, sucursal, estadoConcepto, empresaOperadora, proyecto) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/getSupervisoresAndTotalPagoPaginated`,{idEvento, puesto, municipio, sucursal, estadoConcepto, empresaOperadora, proyecto}, {
            headers: { 
                token: isAuthenticated().token,
                page: page, 
                perpage: perPage,
                search: buscarField
            }
        });
        
        return handleResponse(res);
    }
    return { error: "No puedes realizar esta acción" }
}

export const getSupervisoresByGerenteAndTotalPago = async (evento, gerente) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSupervisoresByGerenteAndTotalPago`,{evento, gerente}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

// Obtiene el listado de Clientes según la empresa
export const getSupervisoresByGerenteAndTotalPagoPaginated = async (idEvento, idGerente, page, perPage, buscarField, puesto, municipio, sucursal, estadoConcepto, empresaOperadora, proyecto) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/getSupervisoresByGerenteAndTotalPagoPaginated`,{idEvento, idGerente, puesto, municipio, sucursal, estadoConcepto, empresaOperadora, proyecto}, {
            headers: { 
                token: isAuthenticated().token,
                page: page, 
                perpage: perPage,
                search: buscarField
            }
        });
        
        return handleResponse(res);
    }
    return { error: "No puedes realizar esta acción" }
}

export const createSupervisor = async (datos, role) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createSupervisor`, {datos, role}, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const createSupervisorRegistro = async (datos, role) =>
{
        const res = await axios.post(`${API}/createSupervisor`, {datos, role},);
        console.log(res)
        return handleResponse(res);
}

export const updateSupervisor = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateSupervisor`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const deleteSupervisor = async (id) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/deleteSupervisor`, {id}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const handleChangeRol = async ({supervisorId, rol, departamentos, ciudades, puestos, gerente, oldRol}) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/change-rol`, {supervisorId, rol, departamentos, ciudades, puestos, gerente, oldRol}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}