//Import react
import React, { useEffect, useState } from 'react';

import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken, isAuthenticated } from "../../core/sessionAPI";
import NavContainer from '../shared/NavContainer'

import { getTerminosCondiciones, setTerminosCondiciones } from '../../core/settingsAPI' 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//Controles generales
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';

// Import de componentes necesarios 
import { 
    Paper,
    Box,
    TextField,
    Button,	
    Dialog,
    Slide,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

export default function TerminosCondiciones() {
    const wrapper = useWrapperStyles();

    return (
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />
                <div className={wrapper.offset}></div>

                {/* IMPORTANTE: Ver definición del componente View */}
                <View />
            </div>
        </div>
    )
}

const View = () => {
    useEffect(() => {
        async function init() {
            // Realizar el servicio que refresca el token
            await refreshToken();
            // Reiniciar timer de inactividad
            setTokenInterval();
        }
        init();
    }, [])

    return <LandingPage />
}

export function LandingPage() {
    //Control de mensajes de error 
    const [valueTerminosCondiciones, setValueTerminosCondiciones] = useState('');
    const [versionTC, serVersionTC] = useState('');

    const [snackbarProps, setSnackbarProps] = useState();    

    const [openConfirmar, setOpenConfirmar] = useState(false);

    const handleCloseConfirmacion = () => {
        setOpenConfirmar(false);
    }

    //Servicio de consulta de empresas
    const loadAll = async () =>
    {
        showLoader();
        
        var res = await getTerminosCondiciones();

        if (res.error){
            setSnackbarProps("e" + res.error)
            setValueTerminosCondiciones("Error de conexión");
        }else{
            setValueTerminosCondiciones(res.data.contenido);
            serVersionTC(res.data.version);
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar(){
            loadAll();
        }
        
        inicializar();
    },[]);    

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
      
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
      
        ['clean']                                         // remove formatting button
    ];

    const hanldleStoreTerminosCondiciones = () => e => {
        setOpenConfirmar(true);
    }

    const ConfirmarTerminoCondiciones = ({open, handleClose}) => {

        const Transition = React.forwardRef(function Transition(props, ref) {
            return <Slide direction="up" ref={ref} {...props} />;
        });

        const notificarCambioTerminosCondiciones = async () => {
            //Send terminos y condiciones            
            showLoader();
        
            var res = await setTerminosCondiciones(valueTerminosCondiciones);

            if (res.error){
                setSnackbarProps("e" + res.error)
            }else{
                setValueTerminosCondiciones(res.data.contenido);
                serVersionTC(res.data.version);
                setSnackbarProps("s" + res.data.message)                
            }

            hideLoader();

            handleClose();
        }

        return(
            <Dialog
                open={open}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Guardar una nueva versión de terminos y condiciones</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta seguro que desea almacenar esta nueva versión de los terminos y condiciones, se notificará a todos los usuarios sobre el cambio realizado en su siguiente inicio de sesión.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={notificarCambioTerminosCondiciones} color="primary" autoFocus>
                        Guardar y notificar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return(    
        <>
            <Box p={2}>
                <Paper elevation={1}>
                    <Box p={2}>
                        <h1>
                            Gestión de terminos y condiciones de uso de la plataforma
                        </h1>
                    </Box>
                    <Box p={2}>
                        <Box>
                            <TextField fullWidth value={versionTC} id="outlined-basic" label="Versión actual, terminos y condiciones" variant="outlined" disabled={true}/>
                        </Box>   
                        <br></br>                     
                        <Box>
                            <ReactQuill 
                                theme="snow" value={valueTerminosCondiciones} 
                                onChange={setValueTerminosCondiciones}
                                modules={{toolbar:toolbarOptions}}/>
                        </Box>
                        <br></br>
                        <Box>
                            <Button 
                                variant="contained"
                                color="primary"
                                onClick={hanldleStoreTerminosCondiciones()}
                                fullWidth>
                                Guardar nueva versión terminos y condiciones
                            </Button>
                        </Box>   
                    </Box>                    
                </Paper>
            </Box>


            <ConfirmarTerminoCondiciones            
                open={openConfirmar}
                handleClose={handleCloseConfirmacion}
            />

            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}