import React, { useState} from 'react';
// Componentes
import { 
    Button,
    Dialog,
    Grid, 
    Slide,
    Box,
    Hidden,
    Collapse
} from '@material-ui/core';



import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";
import { signout } from "../../core/sessionAPI";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmacionRegistro ({open})
{
    const [snackbarProps, setSnackbarProps] = useState();
    const [restorePass, setRestorePass] = useState(false);

    const salirBoton = () => {
        signout(); 
    }

    const registerButton = () =>(
        <Box className="form-container">
        <Grid container> 
            <Hidden xsDown >          
                <Grid item sm={2} md={4}> 
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={8} md={4}> 
                <Collapse 
                    in={!restorePass}>
                    <Box 
                        className="form"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column">                                
                        <img className="imageLogo" src="/img/disproelLogo.png"/>  
                        <h4>¡Registro Exitoso! Revisa tu correo electrónico para activar tu cuenta.</h4>
                            <Box> 
                                    <><form> 
                                    <Box className="fullWidth">    
                                        <div style={{height: 20}}></div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            {/* <Button 
                                                onClick={salirBoton}
                                                variant="contained"
                                                color="primary"
                                                >
                                                Continuar
                                            </Button> */}
                                        </div>                                    
                                    </Box>
                                </form></>                                
                            </Box>
                    </Box>
                </Collapse>
                
            </Grid>
            <Hidden xsDown >  
                <Grid item sm={2} md={4}> 
                </Grid>
            </Hidden>
        </Grid>
    </Box>
)



    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                    {registerButton()}
            </Dialog>
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}