import { Box, Button, Grid, DialogActions, DialogTitle, Dialog, DialogContent, DialogContentText } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useRef, useState, useEffect } from 'react'
import { MyInput } from '../formikComponents/MyInput'
import SignatureCanvas from 'react-signature-canvas';
import * as yup from 'yup'
import './signatureCanvas.css'
import { Delete } from '@material-ui/icons';
import ShowAlert from '../shared/Snackbar';
import { useSignatureRequest } from '../../hooks/useSignatureRequest';
import { pdf } from '@react-pdf/renderer';
import { showLoader, hideLoader } from '../shared/Loader';
import { PDFModel } from '../../helpers/ExportPDF/generatePDFPagos';
import { validarReportePDFWithoutToken } from '../../core/apiPagoSupervisor'

export const ElectronicSignatureFormMovil = ({values, setValues, concept, document, reloadPage}) => {
  const [snackbarProps, setSnackbarProps] = useState()
  const {handlerSaveSignature} = useSignatureRequest()
  const signatureRef = useRef();
  const [open, setOpen] = useState(false);
  const [pesoFirma, setPesoFirma] = useState(0);

  const handleSaveSignature = () => {
  };

  useEffect(() => {
    showLoader();
    const canvas = signatureRef.current.getCanvas();
    const signatureUrl = canvas.toDataURL('image/png');
    const base64String = signatureUrl.split(',')[1];
    const byteArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    // Obtener el tamaño en bytes
    const fileSizeInBytes = byteArray.length;
    // Calcular el tamaño en kilobytes
    const fileSizeInKB = fileSizeInBytes / 1024;
    //Guardar el tamaño inicial del recuadro donde se firma
    setPesoFirma(fileSizeInKB);
    hideLoader();
}, [])

  const onSubmit = async ({documento, fullName}) => {
    showLoader();
    const canvas = signatureRef.current.getCanvas();
    const signatureUrl = canvas.toDataURL('image/png');

    const base64String = signatureUrl.split(',')[1];
    const byteArray = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));

    // Obtener el tamaño en bytes
    const fileSizeInBytes = byteArray.length;

    // Calcular el tamaño en kilobytes
    const fileSizeInKB = fileSizeInBytes / 1024;
    const diferenciaFirma = fileSizeInKB - pesoFirma;

    if(signatureRef.current.isEmpty()){
      setSnackbarProps('eDebe firmar el documento')
      hideLoader();
      return
    }

    else if(diferenciaFirma<2){
      setSnackbarProps('eLa firma no cumple con el tamaño minimo para continuar el proceso')
      hideLoader();
      return
    }

    const signatureDate = new Date()
    concept.signatureUrl=signatureUrl;
    concept.signatureDate=signatureDate;
      hideLoader();
      setOpen(true);
  }

  const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    const blob = new Blob([u8arr], { type: mime });
    const file = new File([blob], fileName, { type: 'image/png' });
    return file;
  };

  const handleCloseDialog = () => {
    showLoader();
    signatureRef.current.clear()
    setOpen(false);
    hideLoader();
  };

  const handleConfirm = async () => {
    showLoader();
    // Aquí puedes realizar la acción que desees cuando el usuario confirme.
    const parsedData = {...concept, restSupervisor: concept?.supervisor}
    //concept.restSupervisor=concept;
    const completeConceptData = await validarReportePDFWithoutToken(
      parsedData?.restSupervisor?.evento,
      parsedData.consecutivo,
      parsedData?.restSupervisor,
      parsedData?.rol,
      parsedData
    )
    const pagoSupervisorCompleto= { ...parsedData, ...completeConceptData.data }
    const pdfFirmado = <PDFModel values={pagoSupervisorCompleto} />
    const blobPdf = await pdf(pdfFirmado).toBlob()
    const filePdf = new File([blobPdf], `documento-equivalente-${concept?.consecutivoFacturacion}.pdf`, { type: 'application/pdf' });
    const signature = dataURLtoFile(concept.signatureUrl, `signature-${concept._id}.png`)
    const response = await handlerSaveSignature(
      signature,
      filePdf,
      concept.supervisor,
      concept._id,
      concept.signatureDate,
      concept.consecutivoFacturacion
    )
    setSnackbarProps(response ? 'sDocumento firmado correctamente' : 'eError al firmar el documento');
    hideLoader();
    await reloadPage()
    setOpen(false);
  };

  const handleCancel = () => {
    handleCloseDialog();
  };

  return (
    <>
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
    { () => 
      <Form>
        <Grid container spacing={2}>
         <Grid item xs={12} sm={12}>
            <Box border='1px solid black' position='relative'>
              <SignatureCanvas 
                ref={signatureRef} 
                onEnd={handleSaveSignature}
                canvasProps={{ 
                  className: 'signature-canvas'
                }} 
              />
              <Button
                endIcon={<Delete />}
                style={{position: 'absolute', bottom: '5px', right: '5px'}}
                variant='contained'
                color='secondary'
                onClick={() => {
                  signatureRef.current.clear()
                }}       
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button 
              type='submit' 
              variant='contained'
              color='primary'
              fullWidth
              style={{textAlign: 'center'}}
            >
              Firmar
            </Button>
          </Grid>
        </Grid>
      </Form>
    }
    </Formik>

    <div>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Confirmación de Firma</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            No
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    {ShowAlert(snackbarProps, setSnackbarProps)}  
    </>
  )
}

const schema = yup.object().shape({
});