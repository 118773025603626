import React, {useEffect} from "react";
import { useWrapperStyles } from '../../../assets/js/wrapperStyles';
import { onUserNavigate, setTokenInterval } from "../../../helpers/CheckSession";
import { refreshToken, isAuthenticated } from "../../../core/sessionAPI";
import NavContainer from '../../shared/NavContainer'

import MainUsuarios from "../../controls/Usuarios/Main";
import MainEmpresas from "../../controls/Empresas/Main";

const View = () => {
    useEffect(() => {
        async function init() {
            // Realizar el servicio que refresca el token
            await refreshToken();
            // Reiniciar timer de inactividad
            setTokenInterval();
        }
        init();
    }, [])

    return <LandingPage />
}

export default function EmpresasAdmin() {
    const wrapper = useWrapperStyles();

    return (
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />
                <div className={wrapper.offset}></div>

                {/* IMPORTANTE: Ver definición del componente View */}
                <View />
            </div>
        </div>
    )
}

export function LandingPage() {
    return(
        <>
            <MainEmpresas />
            <MainUsuarios />            
        </>
    )
}