//Import react
import React, { useEffect, useState } from 'react';

//Import estructura de navegación, y renderizado de la landing page
import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import NavContainer from '../shared/NavContainer';
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken, isAuthenticated } from "../../core/sessionAPI";

//Controles generales
import MapContainer from './MapContainer';

import '../../App.css';

export default function Map(props) {
    const wrapper = useWrapperStyles();

    const View = () => {
        useEffect(() => {
            async function init() {
                // Realizar el servicio que refresca el token
                await refreshToken();
                // Reiniciar timer de inactividad
                setTokenInterval();
            }
            init();
        }, [])
    
        return <LandingPage evento={props.match.params.evento} />
    }    

    return(
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />
                <div className={wrapper.offset}></div>

                {/* IMPORTANTE: Ver definición del componente View */}
                <View />
            </div>
        </div>
    )
}

export function LandingPage({evento}) {
    return(
        <>
            <MapContainer evento={evento}/>
        </>
    )
};