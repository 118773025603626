import React, { useLayoutEffect, useState, useEffect } from 'react';
import * as yup from "yup";
import { regex } from "../../../helpers/Validator";

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery, 
    useTheme,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Slide,
    Box
} from '@material-ui/core';

// Validaciones
import { 
    letrasNumerosCaracteresEspacios,
    corregirEspaciado
} from "../../../helpers/Validator";
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Servicios
import { crearEmpresa, actualizarEmpresa } from '../../../core/superAdminServices/empresaAPI';
import timeZonesSrc from "../../../core/timeZones.json";

// Custom components
import { showLoader, hideLoader } from '../../shared/Loader';
import ShowAlert from '../../shared/Snackbar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Formulario ({open, close, mode, countries, data, refreshTable})
{
    const classes = useStyles();
    /* State para saber si la pantalla esta en tamaño small */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    const [submitButtonText, setSubmitButtonText] = useState("");

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>
    {
        formik.handleReset();
        close();
    }

    /* Manejador evento submit del formulario */
    const onSubmit = async (values) =>
    {
        showLoader();
        let res = {};

        values.name = corregirEspaciado(values.name);

        if (mode === "crear")
        {
            // Obtener el nombre del país según el código del país
            const country = countries.filter(c => c.code === values.countryCode)[0].name;
            values.country = country;
            
            res = await crearEmpresa(values);
        }
        else if (mode === "editar")
        {
            values.idEmpresa = data._id;
            res = await actualizarEmpresa(values);
        }        

        // Validar respuesta del servicio
        if (res.error)
        {
            setSnackbarProps("e" + res.error);
        }
        else
        {
            setSnackbarProps("s" + res.data.message);
            
            resetForm();
            refreshTable();
        }
        hideLoader();
    }

    /* Formik */
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            countryCode: "",
            timezone: ""
        },
        validationSchema: yup.object().shape({
            name: yup
                .string()
                .matches(regex.letrasNumerosCaracteresEspacios, "Este campo admite letras, números y los caracteres - _ * + . , ; : # $ % & @ ( ) ¡ ! ¿ ?")
                .required("Debes llenar este campo"),
            email: yup
                .string()
                .email("Ingresa un email válido")
                .required("Debes llenar este campo"),
            countryCode: yup.string().required("Selecciona un país"),
            timezone: yup.string().required("Selecciona una zona horaria")
        }),
        onSubmit
    });
    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;

    const nameProps = getFieldProps("name");
    const emailProps = getFieldProps("email");
    const countryCodeProps = getFieldProps("countryCode");
    const timezoneProps = getFieldProps("timezone");

    /* Almacena as zonas horarias */
    const [timeZones, setTimeZones] = useState([]);

    /* Cada vez que cambie el valor del país, cargar la(s) zona(s) horaria(s) correspondiente(s) */
    useEffect(() =>
    {
        const zonas = timeZonesSrc.filter(tz => tz.country_code === countryCodeProps.value);
        setTimeZones(zonas);

        if (mode === "crear")
        {
            formik.setFieldValue("timezone", "");
        }
        else if (mode === "editar")
        {
            if (countryCodeProps.value !== "")
            {
                formik.setFieldValue("timezone", data.timezone);
            }
        }
    }, [countryCodeProps.value])

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    useEffect(() =>
    {
        async function init ()
        {
            if (mode === "crear")
            {
                formik.handleReset();
                setFormTitle("Registrar nueva empresa");
                setSubmitButtonText("Registrar");
            }
            else if (mode === "editar")
            {
                setFormTitle("Actualizar datos de la empresa");
                setSubmitButtonText("Actualizar");
                
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("email", data.email);
                formik.setFieldValue("countryCode", data.countryCode);
            }
        }
        init();
    }, [open]);

    /* Campos del formulario */
    const fields = () => (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined" 
                        label="Nombre"
                        name="name"
                        color="primary"
                        fullWidth
                        required
                        inputProps={{ maxLength: 100 }}
                        helperText={touched.name ? errors.name : ""}
                        error={touched.name && Boolean(errors.name)}
                        onKeyPress={letrasNumerosCaracteresEspacios(100)}
                        {...nameProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined" 
                        label="Email"
                        name="email"
                        fullWidth
                        required
                        inputProps={{ maxLength: 50 }}
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        {...emailProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        label="País"
                        name="countryCode"
                        disabled={mode === "editar"}
                        variant="outlined" 
                        fullWidth
                        required
                        helperText={touched.countryCode ? errors.countryCode : ""}
                        error={touched.countryCode && Boolean(errors.countryCode)}
                        {...countryCodeProps}
                    >
                        {countries.map((c, i) => (
                            <MenuItem key={i} value={c.code}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        label="Zona horaria"
                        name="timezone"
                        disabled={mode === "editar"}
                        variant="outlined" 
                        fullWidth
                        required
                        helperText={touched.timezone ? errors.timezone : ""}
                        error={touched.timezone && Boolean(errors.timezone)}
                        {...timezoneProps}
                    >
                        {timeZones.map((tz, i) => (
                            <MenuItem key={i} value={tz.time_zone}>
                                {tz.time_zone}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Box>  
    )

    return (
        <>
            <Dialog                 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                            <Hidden xsDown>
                                <Button type="submit" color="inherit" disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </Hidden>                            
                            <Hidden smUp>
                                <IconButton type="submit" color="inherit" disabled={isSubmitting}>
                                    <SaveIcon />
                                </IconButton>
                            </Hidden>
                        </Toolbar>
                    </AppBar>

                    <DialogContent>
                        {fields()}
                    </DialogContent>                    
                </form>
            </Dialog>

            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}