/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";
import "../../App.css"

const ReloadWithFilter = ({source, searchBy, setTableData, tableReload}) =>
{
    const [criterio, setCriterio] = useState("");
    const [textValue, setTextValue] = useState("");
    
    useEffect(() => {
        return () => {
            console.log("Limpiar criterio de busqueda");
            setCriterio("");
            setTextValue("");
            setTableData(source);
        }
    }, [])

    useEffect(() => {
         setCriterio("")
         setTextValue("")
    }, [tableReload])

    useEffect(() => {        
        
        if(criterio != ""){
            console.log("se han filtrado los datos");
            filtrarDatos();
        }else{ 
            console.log("cargar nuevamente datos a la lista que se va a presentar: " + source.length);
            setTableData(source);         
        }

    }, [criterio])

    const filtrarDatos = () =>
    {
        let data;

        console.log(searchBy);

        // Buscar por clientes, vendedores, productos, etc...
        switch (searchBy)
        {            
            case "sedes":
                data = source.filter(sede =>{
                    return (
                        sede.name.toLowerCase().indexOf(criterio) > -1 ||
                        sede.descripcion.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;   
            case "eventos":
                data = source.filter(sede =>{
                    return (
                        sede.name.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break; 
            case "gerentes":
                data = source.filter(gerente =>{
                    return (
                        gerente.firstLastname.toLowerCase().indexOf(criterio) > -1 ||
                        gerente.documento.toLowerCase().indexOf(criterio) > -1 ||
                        gerente.name.toLowerCase().indexOf(criterio) > -1 ||
                        gerente.secondLastname.toLowerCase().indexOf(criterio) > -1 ||
                        gerente.telefono.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;
            case "supervisores":
                data = source.filter(supervisor =>{
                    return (
                        supervisor.firstLastname.toLowerCase().indexOf(criterio) > -1 ||
                        supervisor.documento.toLowerCase().indexOf(criterio) > -1 ||
                        supervisor.firstName.toLowerCase().indexOf(criterio) > -1 ||
                        supervisor.secondLastname.toLowerCase().indexOf(criterio) > -1 ||
                        supervisor.telefono.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;  
            case "puestos":
                data = source.filter(puesto =>{

                    var valorComparacion = "";
                    if(puesto.valor){                        
                        valorComparacion = "MARCADO";
                    }else{
                        valorComparacion = "PENDIENTE";
                    }

                    return (
                        puesto.codigoDivipol.toLowerCase().indexOf(criterio) > -1 ||
                        puesto.nombreDelPuesto.toLowerCase().indexOf(criterio) > -1 ||
                        puesto.nombreComuna.toLowerCase().indexOf(criterio) > -1 ||
                        puesto.tipoZona.toLowerCase().indexOf(criterio) > -1 ||
                        valorComparacion.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;
            case "ciudades":
                data = source.filter(ciudad =>{
                    return (
                        ciudad.ciudad?.codigo.toLowerCase().indexOf(criterio) > -1 ||
                        ciudad.ciudad?.name.toLowerCase().indexOf(criterio) > -1 ||
                        ciudad.tipo.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;
            case "departamentos":
                data = source.filter(departamento =>{
                    return (
                        departamento.departamento?.codigo.toLowerCase().indexOf(criterio) > -1 ||
                        departamento.departamento?.name.toLowerCase().indexOf(criterio) > -1 ||
                        departamento.tipo.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;   
            case "resolucionFacturacion":
                data = source.filter(resolucion =>{
                    return (
                        resolucion.codigo?.toLowerCase().indexOf(criterio) > -1 ||
                        resolucion.evento?.name.toLowerCase().indexOf(criterio) > -1 ||
                        String(resolucion.inicioRango).toLowerCase().indexOf(criterio) > -1 ||
                        String(resolucion.finRango).toLowerCase().indexOf(criterio) > -1 ||
                        resolucion.descripcion?.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;  
            case "proveedorPagos":
                data = source.filter(proveedor =>{
                    return (
                        String(proveedor.nit).toLowerCase().indexOf(criterio) > -1 ||
                        proveedor.name?.toLowerCase().indexOf(criterio) > -1 ||
                        proveedor.email.toLowerCase().indexOf(criterio) > -1 ||
                        proveedor.telefono.toLowerCase().indexOf(criterio) > -1 ||
                        proveedor.nameContacto?.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;   
            case "reglaComisionProveedor":
                data = source.filter(regla =>{
                    return (
                        String(regla.topeComision)?.toLowerCase().indexOf(criterio) > -1 ||
                        String(regla.porcentajeComision)?.toLowerCase().indexOf(criterio) > -1
                    )
                })
            break;    
            case "reteica":
                data = source.filter(reteica =>{
                    return (
                        String(reteica.valor)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;   
            case "registroRetencion":
                data = source.filter(reteica =>{
                    return (
                        String(reteica.tarifa)?.toLowerCase().indexOf(criterio) > -1 ||
                        reteica.servicio.name?.toLowerCase().indexOf(criterio) > -1 ||
                        reteica.regimen?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break; 
            case "servicio":
                data = source.filter(reteica =>{
                    return (
                        reteica.name?.toLowerCase().indexOf(criterio) > -1 ||
                        reteica.sigla?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;
            case "sucursal":
                data = source.filter(reteica =>{
                    return (
                        reteica.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(reteica.codigo)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break; 
            case "empresaOperadora":
                data = source.filter(reteica =>{
                    return (
                        reteica.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(reteica.codigo)?.toLowerCase().indexOf(criterio) > -1 ||
                        String(reteica.nit)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;   
            case "concepto":
                data = source.filter(concepto =>{
                    return (
                        concepto.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(concepto.valorMaximo)?.toLowerCase().indexOf(criterio) > -1 ||
                        concepto.descripcion?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;     
            case "presupuestoDepartamento":
                data = source.filter(dept =>{
                    return (
                        dept.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(dept.valorTotal)?.toLowerCase().indexOf(criterio) > -1 ||
                        String(dept.codigo)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;      
            case "presupuestoCiudad":
                data = source.filter(mun =>{
                    return (
                        mun.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(mun.valorTotal)?.toLowerCase().indexOf(criterio) > -1 ||
                        String(mun.codigo)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;        
            case "departamento":
                data = source.filter(dept =>{
                    return (
                        dept.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(dept.codigo)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;          
            case "municipio":
                data = source.filter(mun =>{
                    return (
                        mun.name?.toLowerCase().indexOf(criterio) > -1 ||
                        String(mun.codigo)?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;   
            case "rol":
                data = source.filter(rol =>{
                    return (
                        rol.name?.toLowerCase().indexOf(criterio) > -1 
                    )
                })
            break;           
            default:
                data = source;
            break;
        }

        // Datos a mostrar en la tabla
        setTableData(data)
    }

    return (
        <TextField
            id={"input" + Math.floor(Math.random() * 10000)}
            variant="outlined"
            className="smallClass"
            placeholder="Buscar..."
            value={textValue}
            onChange={(e) => {        
                setTextValue(e.target.value);
                setCriterio(e.target.value.toLowerCase().trim());
            }}
            fullWidth
            inputProps={{
                maxLength: 100
            }}
        />
    )
}

export default ReloadWithFilter;