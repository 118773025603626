import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ShowAlert from '../../shared/Snackbar';
import { hideLoader, showLoader } from '../../shared/Loader';
import { confirmActivateEmail } from '../../../core/apiSupervisores';

export const ResendEmailVerificationModal = ({open, onClose, supervisor = null}) => {
  const [snackbarProps, setSnackbarProps] = useState()
  
  const onSubmit = async () => {
    showLoader()
    const response = await confirmActivateEmail(supervisor.email, `${supervisor.firstName} ${supervisor.firstLastname}`, supervisor._id );
    hideLoader()

    if(response.error) setSnackbarProps('e' + response.error)
    else setSnackbarProps('s' + response.data.message)
    onClose()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle >Reenviar correo de verificación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se reenviará el correo de verificación al usuario <strong>({supervisor?.documento}) {supervisor?.firstName} {supervisor?.firstLastname}</strong>, con correo <strong>{supervisor?.email}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant='outlined'>
            Cancelar
          </Button>
          <Button 
            onClick={onSubmit} 
            color="primary" 
            variant='contained'
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      {ShowAlert(snackbarProps, setSnackbarProps)}
    </>
  )
}
