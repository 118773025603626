import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';

// Componentes 
import { 
    Paper,
    Box,
    Avatar,
    Tooltip,
	Button,
	TextField
} from '@material-ui/core';

// Tabla
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';

// Estilos
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";
import SaveIcon from '@material-ui/icons/Save';

// Custom components
import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import CustomTableHead from "../shared/Tablas/CustomTableHead";
import Paginacion from "../shared/Tablas/Paginacion";

import "../../App.css"
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import { createConceptoCiudad } from '../../core/apiConceptoCiudad';

const headCells = 
[
    { id: 'item', align: "left", label: '#', sortable: true },
	{ id: 'documento', label: 'Concepto', align: 'left', sortable: true },
    { id: 'dias', label: 'Cantidad de personas, día operación', align: 'left', sortable: true },
    { id: 'valorDia', label: 'Valor del día electoral', align: 'left', sortable: true },
    { id: 'diaAdicional', label: 'Dias adicionales de soporte', align: 'left', sortable: true },
    { id: 'valordiaAdicional', label: 'Valor días de soporte', align: 'left', sortable: true },
    { id: 'total', label: 'Valor Total', align: 'left', sortable: true },
];

export default function TablaConceptoCiudad ({departamentos, municipio, setSnackbarProps, close})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();
    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');
	const [totalPago, setTotalPago] = useState(0)

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);
	const [reloadTable, setRoloadTable] = useState(0)

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 30;
	const rowsPerPageOptionsDefault = [30, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */

    useEffect(() => {
		let auxTotalPago = 0
        setRows(Array.from(departamentos, (dept, index) =>{
            auxTotalPago += dept.total
			return {
                item: index + 1,
				rol: dept.rol,
				dias: dept.dias,
				totalDia: dept.totalDia,
				diasAdicionales: dept.diasAdicionales,
				totalDiasAdicionales: dept.totalDiasAdicionales,
                total: dept.total,
				rest: dept,
				ciudadSucursal: municipio?._id,
				departamento: municipio?.departamento,
				evento: dept.rol?.evento
            };
        }));
		setTotalPago(auxTotalPago)
    }, [departamentos]);

	const handleFormChange = (e, i) => {
		let auxRows = rows
		auxRows[i] = {
			...auxRows[i],
			[e.target.name]: e.target.value
		}
		auxRows[i] = {
			...auxRows[i],
			totalDia: parseInt(auxRows[i].rol?.valorDia || 0) * parseInt(auxRows[i].dias || 0),
			totalDiasAdicionales: parseInt(auxRows[i].rol?.valorDiaAdicional || 0) * parseInt(auxRows[i].diasAdicionales || 0) * parseInt(auxRows[i].dias || 0),
			total: 
				(parseInt(auxRows[i].rol?.valorDia || 0) * parseInt(auxRows[i].dias || 0)) +
				parseInt(auxRows[i].rol?.valorDiaAdicional || 0) * parseInt(auxRows[i].diasAdicionales || 0) * parseInt(auxRows[i].dias || 0)
		}
		setRows(auxRows)
		setRoloadTable(reloadTable + 1)
		recountTotal()
	}

	const recountTotal = () => {
		let auxTotal = 0
		rows.map(e => {
			auxTotal += e.total
		})
		setTotalPago(auxTotal)
	}

	const saveChanges = async () => {
		showLoader();
		const res = await createConceptoCiudad(rows)
		if(res.error){
			setSnackbarProps("e" + res.error);
		}else{
			setSnackbarProps("s" + res.data.message);
			close()
		}
		hideLoader();
	}

    return (
		<>
			<Box p={1} className="greyBackground">
				<h3 style={{textAlign: 'right', margin: 0}}>
					<CurrencyFormat value={totalPago} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>PRESUPUESTO TOTAL DEL MUNICIPIO: {value}</div>}/>
				</h3>
			</Box>
			{
				<ReloadTable
					reload={reloadTable}
					rows={rows}
					order={order}
					orderBy={orderBy}
					setOrder={setOrder}
					calculoPaginacion={calculoPaginacion}
					handleFormChange={handleFormChange}
					setOrderBy={setOrderBy}
					tableClasses={tableClasses}
				/>
			}
			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
			<Box p={1}>
				<Button 
					fullWidth 
					color="primary" 
					variant='contained'
					startIcon={<SaveIcon />}
					onClick={saveChanges}
				>
					Guardar Cambios
				</Button> 
			</Box>
    	</>
	);
}

const ReloadTable = ({reload, rows, order, orderBy, calculoPaginacion, handleFormChange, setOrderBy, setOrder, tableClasses}) => {
	useEffect(() => {}, [reload])
	return (
		<TableContainer className="borderTable">
			<Table
				stickyHeader
				aria-labelledby="tableTitle"
				aria-label="sticky table"
			>
				<CustomTableHead
					order={order}
					setOrder={setOrder}
					orderBy={orderBy}
					setOrderBy={setOrderBy}
					headCells={headCells}			
				/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron conceptos o no se han registrado.
								</TableCell>
							</TableRow>
						)}
						{
							stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
								<TableRow
									hover
									tabIndex={-1}
									key={row.item}>
									<TableCell align="left" className='topPaddingRow'>{row.item}</TableCell>
									<TableCell align="left" className="col20 topPaddingRow">{row.rol?.name}</TableCell>															
									<TableCell align="left" className='topPaddingRow'>
										<TextField
											key={index}
											variant="outlined" 
											name="dias"
											type='number' 
											label="Número de personas"
											value={row?.dias}
											size="small"
											onChange={(e) => handleFormChange(e, index)}
										/>
									</TableCell>				
									<TableCell align="left" className='topPaddingRow'>
										<CurrencyFormat value={row.totalDia} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</TableCell>								
									<TableCell align="left" className='topPaddingRow'>{
										row.rol?.aplicaValorDiaAdicional ?
											<TextField
												key={index}
												variant="outlined" 
												name="diasAdicionales"
												type='number' 
												label="Días de soporte"
												size="small"
												value={row.diasAdicionales}
												onChange={(e) => handleFormChange(e, index)}
											/> : 'No aplica'
									}</TableCell>								
									<TableCell align="left" className='topPaddingRow'>
										<CurrencyFormat value={row.totalDiasAdicionales} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</TableCell>
									<TableCell align="left" className='topPaddingRow'>
										<CurrencyFormat value={row.total} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
				</Table>
			</TableContainer>
	)
}