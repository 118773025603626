import React, {useState, useEffect} from 'react';
import {
        Grid, 
        Box, 
        Paper, 
        TableContainer,
        Table,
        TableRow,
        TableCell,
        TableBody,
        Avatar, 
        FormControl,
        InputLabel,
        Select,
        MenuItem,
        Tooltip,
        Button} from '@material-ui/core';

import Loader from '../shared/Loader';
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";
import ShowAlert from '../../../src/components/shared/Snackbar';

import "../../App.css"

import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";

import NavContainer from '../shared/NavContainer';
import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken } from "../../core/sessionAPI";
import ReloadWithFilter from '../shared//ReloadWithFilter';
import { isAuthenticated } from '../../core/sessionAPI';
import CustomTableHead from "../shared/Tablas/CustomTableHead";
import Paginacion from "../shared/Tablas/Paginacion";
import { getDepartamentosEstado, getProductosEventosClear } from '../../core/apiEventos';
import AutocompleteFromEntity from "../../components/controls/AutocompleteFromEntity";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import DialogMunicipiosMarcacion from "../municipios/DialogMunicipiosMarcacion";
import DialogConfirmarDepartamento from "./DialogConfirmarDepartamento";

export default function MainDepartamentos(props) {
    const wrapper = useWrapperStyles();
    const [title, setTitle] = useState("DEPARTAMENTOS");

    const View = () => {
        useEffect(() => {
            async function init() {
                // Realizar el servicio que refresca el token
                await refreshToken();
                // Reiniciar timer de inactividad
                setTokenInterval();
            }
            init();
        }, []);
        
        return (
            <>
                <LandingPage estado={props.match.params.estado} evento={props.match.params.evento} title={title} setTitle={setTitle}/>
            </>)
    }

    return (
        <>
            <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
                <div className={wrapper.content}>
                    <NavContainer nameEvento={title}/>
                    <div className={wrapper.offset}></div>

                    {/* IMPORTANTE: Ver definición del componente View */}
                    <View/>
                </div>
            </div>
        </>
    )
}

export function LandingPage({estado, evento}){
    const [snackbarProps, setSnackbarProps] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [departamentos, setDepartamentos] = useState([]);
    const [departamentosTable, setDepartamentosTable] = useState([]);

    const [openMarcacionDepartamento, setOpenMarcacionDepartamento] = useState(false);
    const [openMunicipios, setOpenMunicipios]=useState(false);
    const [departamentoSelected, setDepartamentoSelected]=useState(null);
    const [tipoMarcacion, setTipoMarcacion] = useState();
    const [canMark, setCanMark] = useState(false);

    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState();
    const [tipo, setTipo] = useState("ZO");
    const [reloadFilter, setReloadFilter] = useState(false);

    const loadDepartamentos = async () => {
        setShowLoader(true);
        const user = isAuthenticated().user;
        const res = await getDepartamentosEstado(tipo, producto, estado, evento);
        if (res.error) {
            setSnackbarProps("e" + res.error);
            setDepartamentos([]);
            setDepartamentosTable([]);
        } else {
            setDepartamentos(res.data);
            setDepartamentosTable(res.data);
        }
        setReloadFilter(!reloadFilter);

        setShowLoader(false);
    }

    const loadDepartamentosFilter = async () => {
        setShowLoader(true);
        const user = isAuthenticated().user;
        const res = await getDepartamentosEstado(tipo, producto, estado, evento);
        if (res.error) {
            setSnackbarProps("e" + res.error);
            setDepartamentos([]);
            setDepartamentosTable([]);
        } else {
            //setDepartamentos(res.data);
            const filterDepartamentos = res.data.filter(item2 => departamentosTable.some(item1 => item1._id === item2._id));
             setDepartamentos(res.data);
             setDepartamentosTable(filterDepartamentos);
        }

        setShowLoader(false);
    }
    
    const loadProductos = async () => {
        const res = await getProductosEventosClear(evento);
        if (res.error) {
            setSnackbarProps("e" + res.error);
            setProductos([]);
        } else {
            setProductos(res.data);    
            setProducto(res.data[0]?._id);                
        }
    } 
    
    const actualizarValores = async () => {
        if(departamentos.length===departamentosTable.length || departamentosTable.length===0){
            setReloadFilter(!reloadFilter);
            await loadDepartamentos(); 
        }else{
            await loadDepartamentosFilter();
        } 
    } 

    useEffect(() => {
        async function effect() {                           
            await actualizarValores();           
        } 

        if(producto && tipo){
            effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [producto, tipo]);    

    useEffect(() => {
        async function effect() {                           
            await loadProductos();           
        }
            
        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estado, evento]); 

    useEffect(() => {
        const {user} = isAuthenticated()
        if(user.role === 2) setCanMark(true)
        else if(user.role === 3) setCanMark(user[estado])
        else setCanMark(false)
    }, [estado])

    return (
        <>
            <Box p={1}>
                <Paper elevation={1}>
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <Box p={1}>
                                <Select
                                    className='smallClassSelect'
                                    variant="outlined" 
                                    fullWidth
                                    key={producto}
                                    defaultValue={producto}
                                    onChange={async (e)=>{
                                        setProducto(e.target.value);
                                    }}>
                                    {productos.map((producto, index)=>{
                                        if(index == 0){
                                            return(<MenuItem value={producto._id} key={index} selected>{producto.name}</MenuItem>)                                                
                                        }else{
                                            return(<MenuItem value={producto._id} key={index}>{producto.name}</MenuItem>)
                                        }                                            
                                    })}
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box p={1}>
                                <Select
                                    className='smallClassSelect'
                                    variant="outlined" 
                                    fullWidth
                                    key={tipo}
                                    defaultValue={tipo}
                                    onChange={async (e)=>{
                                        setTipo(e.target.value);
                                    }}>
                                    <MenuItem value="ZO" selected>ZO (Zonificado)</MenuItem>
                                    <MenuItem value="NZ" selected>NZ (No Zonificado)</MenuItem>
                                    <MenuItem value="EXT" selected>EXT (Exterior)</MenuItem>
                                    
                                </Select>
                            </Box>
                        </Grid>  
                        <Grid item xs={12} sm={4}>
                            <Box p={1}>
                                <ReloadWithFilter
                                    source={departamentos}
                                    searchBy="departamentos"
                                    setTableData={setDepartamentosTable}
                                    tableReload={reloadFilter}
                                />
                            </Box>
                        </Grid>  
                        <Grid item xs={12} sm={2}>
                            <Box p={1} justifyItems="center">
                                <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={actualizarValores}
                                >ACTUALIZAR
                                </Button> 
                            </Box>
                        </Grid>                                          
                    </Grid>
                </Paper>
            </Box>
            <Box p={1}>
                <Paper elevation={1}>
                    <TablaDepartamentos
                        canMark={canMark}
                        onSelectDepartamento={(departamento)=>{
                            setDepartamentoSelected(departamento);
                            setOpenMunicipios(true);
                        }}
                        confirmarDepartamento={(departamento)=>{                            
                            setDepartamentoSelected(departamento);
                            setTipoMarcacion("marcar");
                            setOpenMarcacionDepartamento(true);
                        }}
                        cancelarDepartamento={(departamento)=>{                            
                            setDepartamentoSelected(departamento);
                            setTipoMarcacion("desmarcar");
                            setOpenMarcacionDepartamento(true);
                        }}
                        departamentos={departamentosTable}/>
                </Paper>
            </Box>

            <DialogMunicipiosMarcacion 
                open={openMunicipios}
                close={()=>{
                    setOpenMunicipios(false);
                    actualizarValores();
                }}
                departamento={departamentoSelected}
                producto={producto}
                estado={estado}
                evento={evento}
                tipo={tipo}
                reloadAll={()=>{
                    actualizarValores();
                }}
            />

            <DialogConfirmarDepartamento
                open={openMarcacionDepartamento}
                close={()=>{
                    setOpenMarcacionDepartamento(false);
                }}
                departamento={departamentoSelected}
                producto={producto}
                estado={estado}
                evento={evento}
                tipo={tipo}
                setSnackbarProps={setSnackbarProps}
                tipoMarcacion={tipoMarcacion}
                reloadAll={()=>{
                    actualizarValores();
                }}
            />

            <Loader show={showLoader} />
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}

export function TablaDepartamentos ({canMark, departamentos, onSelectDepartamento, confirmarDepartamento, cancelarDepartamento})
{
    const headCells = 
    [
        { id: 'options', label: 'Opciones', align: 'left', sortable: false },
        { id: 'item', align: "left", label: '#', sortable: true },
        { id: 'codigoDepartamento', label: 'Código Departamento', align: 'left', sortable: true },
        { id: 'name', label: 'Departamento', align: 'left', sortable: true },
        { id: 'estado', label: 'Estado', align: 'left', sortable: true },
        { id: 'estadoPorcentual', label: 'Estado Porcentual (%)', align: 'left', sortable: true },
        { id: 'estadoMesas', label: 'Estado Mesas', align: 'left', sortable: true },
        { id: 'estadoPorcentualMesas', label: 'Estado Porcentual Mesas (%)', align: 'left', sortable: true },        
        { id: 'tipo', label: 'Tipo', align: 'left', sortable: true }
    ];

    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 50;
	const rowsPerPageOptionsDefault = [50, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() => {
        setRows(Array.from(departamentos, (departamento, index) =>{
            return {
                item: index + 1,
                departamento: departamento.departamento, 
                codigo: departamento.departamento.codigo,
                name: departamento.departamento.name,
				estado: departamento.valor + " / " + departamento.meta + " Puestos",
                estadoPorcentual: parseFloat((departamento.valor/departamento.meta) * 100).toFixed(2),
                estadoMesas: departamento.valorMesas + " / " + departamento.metaMesas + " Mesas",
                estadoPorcentualMesas: parseFloat((departamento.valorMesas/departamento.metaMesas) * 100).toFixed(2),
                tipo: departamento.tipo
            };
        }));
    }, [departamentos]);

    return (
		<>
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}			
					/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron registros
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className={tableClasses.options}>
									<Box display="flex" justifyContent="left" alignItems="left">
										{ canMark && 
                                            <>
                                                <Avatar
                                                    className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
                                                    variant="rounded"
                                                    onClick={() => {
                                                        confirmarDepartamento(row.departamento)
                                                    }}
                                                >
                                                    <Tooltip title="Marcar todos los puestos relacionados al departamento">
                                                        <LibraryAddCheckIcon fontSize="inherit"/>
                                                    </Tooltip>
                                                </Avatar>
                                                <Avatar
                                                    className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
                                                    variant="rounded"
                                                    onClick={() => {
                                                        cancelarDepartamento(row.departamento)
                                                    }}>
                                                    <Tooltip title="Desmarcar todos los puestos relacionados al departamento">
                                                        <CancelPresentationIcon fontSize="inherit"/>
                                                    </Tooltip>
                                                </Avatar>                                        	
                                            </>
                                        }
										<Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                onSelectDepartamento(row.departamento)
                                            }}>
											<Tooltip title="Listar municipios">
												<PlaylistAddCheckIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>	                                        										
									</Box>
								</TableCell>
								<TableCell align="left">{row.item}</TableCell>
								<TableCell align="left">{row.codigo}</TableCell>								
								<TableCell align="left"><b>{row.name}</b></TableCell>
								<TableCell align="left">{row.estado}</TableCell>
                                <TableCell align="left">
                                    <div  className={"blockBase " + (row.estadoPorcentual < 50 ? "blockRed":row.estadoPorcentual < 99 ? "blockYellow":"blockGreen")}>
                                        {row.estadoPorcentual}
                                    </div>
                                </TableCell>    
								<TableCell align="left">{row.estadoMesas}</TableCell>
                                <TableCell align="left">
                                    <div  className={"blockBase " + (row.estadoPorcentualMesas < 50 ? "blockRed":row.estadoPorcentualMesas < 99 ? "blockYellow":"blockGreen")}>
                                        {row.estadoPorcentualMesas}
                                    </div>
                                </TableCell>                                                                
                                <TableCell align="left">{row.tipo}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
    	</>
	);
}