import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core"
import React from 'react'

export const ModalTwoButtons = ({open, handleClose, title, content, handleAcept, nameButtonAcept}) => {
	return (
		<Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{textAlign: 'right'}}>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    Cancelar
                </Button>
                <Button onClick={handleAcept} color="primary">
                    {nameButtonAcept}
                </Button>
            </DialogActions>
        </Dialog>
	)
}
