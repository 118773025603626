import { useCallback } from 'react'
import { hideLoader, showLoader } from '../components/shared/Loader'
import { getPagoSupervisorById, signPaymentConfirmation } from '../core/apiPagoSupervisor'
import { uploadFile } from '../core/apiControls'

export const useSignatureRequest = () => {
  const getPagoById = useCallback( async id => {
    showLoader()
    const response = await  getPagoSupervisorById(id)
    hideLoader()
    return response?.data
  }, [])
  
  const handlerSaveSignature = async (signatureImage, document, supervisor, idConcepto, signatureDate, consecutivoFacturacion) => {
    showLoader()
    const uploads = await Promise.all([
      uploadFile(signatureImage),
      uploadFile(document)
    ])
    const [signatureUrl, documentUrl] = uploads
    const response = await signPaymentConfirmation(supervisor, idConcepto, signatureUrl, documentUrl, signatureDate, consecutivoFacturacion)
    hideLoader()
    return response?.data?.message
  }

  return {
    getPagoById,
    handlerSaveSignature
  }
}
