import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Dialog,
    Grid, 
    Slide,
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ValidacionDocumentoFoto from "./ValidacionDocumentoFoto";
import FormularioRegistroSupervisores from "../eventos/FormularioRegistroSupervisores";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

const useStyles = makeStyles((theme) => ({
  appBar: {
      position: 'relative',
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function SeleccionarCedula ({open, cedulaDigital, close, idEvento , eventoName, modo, tiposPago, datos, closeFormulario }){
  const classes = useStyles();
  const [snackbarProps, setSnackbarProps] = useState();
  const [infoImagen, setInfoImagen] = useState("/img/iconoCedula.PNG");
  const [openFormVerificacion, setOpenFormVerificacion] = useState(false);
  const [isCedulaDigital, setIsCedulaDigital] = useState(false);
  const [openCleanForm, setOpenCleanForm] = useState(false)
  const [documentType, setDocumentType] = useState('');
  //No Tocar
  useEffect(() => {
      //Carga los tipos de pagos
     if(cedulaDigital===true){
      setInfoImagen("/img/cedulaDigital.PNG")
     }else if(cedulaDigital===false){
      setInfoImagen("/img/iconoCedula.PNG")
     } 
  }, [cedulaDigital]);

  const closeHandlerProducto = async (estado) => {
    close();
  }

  const handleSeleccionarCedula = (cedula) => {
    navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      if(cedula==="digital") setIsCedulaDigital(true);
      else setIsCedulaDigital(false);
      setOpenFormVerificacion(true);
    })
    .catch(error => {
      setSnackbarProps("e" + "Para continuar, por favor otorga acceso a la cámara.");
    });
  };

  const handleCleanForm = documentType => {
    setOpenCleanForm(true);
    setDocumentType(documentType);
  }
   
  const registerButton = () =>(
    <Grid 
      container 
      spacing={2} 
      justify="center"
      alignItems="center"
      style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '42px'
      }}
    >
      <Grid item xs={12} md={5} style={{ padding: 10}}>
        <Paper style={{ padding: '5px',  height: '415px' }}>
          <img src="/img/ccdigital.png" onClick={() => handleSeleccionarCedula('digital')} alt="digital" style={{ width: '100%', height: '350px',  objectFit: "contain", cursor:"pointer" }}/>
          <Typography variant="h6"  style={{color: '#3f51b5'}} align="center"><b>Cédula Digital</b></Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={5} style={{ padding: 10}}>
        <Paper style={{ padding: '5px',  height: '415px' }}>
          <img src="/img/ccamarilla.jpeg" onClick={() => handleSeleccionarCedula('amarilla')}  alt="amarilla" style={{ width: '100%', height: '350px',  objectFit: "contain", cursor:"pointer" }}/>
          <Typography variant="h6" style={{color: '#3f51b5'}} align="center"><b>Cédula Amarilla</b></Typography>
        </Paper>
      </Grid>
      {/* <Grid item xs={12} md={6} lg={6} xl={6} style={{ padding: 10, maxWidth: 'fit-content' }}>
        <Paper>
          <img src="/img/CI-VNZ.jpg"  onClick={() => handleCleanForm('C.I')} alt="Venezuela" style={{  padding: '5px', height: 250, width: 350, objectFit: "contain", cursor:"pointer" }} />
          <Typography variant="h6" style={{color: '#3f51b5'}} align="center"><b>Cédula de Identidad (Venezuela)</b></Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6} style={{ padding: 10, maxWidth: 'fit-content' }}>
        <Paper>
          <img src="/img/pasaporte.png" onClick={() => handleCleanForm('P')} alt="pasaporte" style={{ padding: '5px',  height: 250, width: 350, objectFit: "contain", cursor:"pointer" }} />
          <Typography variant="h6" style={{color: '#3f51b5'}} align="center"><b>Pasaporte (Internacional)</b></Typography>
        </Paper>
      </Grid> */}
      {openFormVerificacion ? 
        <ValidacionDocumentoFoto
            open = {openFormVerificacion}
            onCancel={() => setOpenFormVerificacion(false)}
            close = {closeHandlerProducto}
            idEvento={idEvento}
            eventoName={eventoName}
            modo={"crear"}
            tiposPago={tiposPago}
            datos={datos}
            cedulaDigital = {isCedulaDigital}
            closeFormulario = {closeFormulario}
        />: null} 
        { openCleanForm ?
          <FormularioRegistroSupervisores
            open = {openCleanForm}
            onCancel={() => setOpenCleanForm(false)}
            close = {closeFormulario}
            idEvento={idEvento}
            eventoName={eventoName}
            modo={"crear"}
            tiposPago={tiposPago}
            datos={{documentType, ...datos}}
          /> : null
        }
    </Grid>
  )

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
                PaperProps={{
                  style: {
                    backgroundImage: "url(/img/background-login.jpg)",
                  }
                }}
              >
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={closeHandlerProducto} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                      Elige el tipo de cédula para la verificación
                    </Typography>
                  </Toolbar>
                </AppBar>
                {registerButton()}
            </Dialog>
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}