import { sucursalAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getSucursales = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSucursales`,{}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getSucursalById = async (id) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSucursalById`,{id}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}
export const createSucursal = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createSucursal`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updateSucursal = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateSucursal`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}