import { eventosAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const setStateEvento = async (evento, estado, tipoEstado) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/setStateEvento`,{evento, estado, tipoEstado}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getEvento = async (evento) => {
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getEvento`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const isEventConfigured = async evento =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/isEventConfigured`,{ evento }, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}


export const deleteEvento = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/deleteEvento`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getEstadoEvento = async (producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getEstadoEvento`,{producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getHistorialPuesto = async (puesto, producto, estado, evento, tipo) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getHistorialPuesto`,{puesto, producto, estado, evento, tipo}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getEventos = async (idEmpresa) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getEventos`,{empresa:idEmpresa}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

//Eventos para Registro
export const getEventosRegistro = async () =>
{
        const res = await axios.post(`${API}/getEventosRegistro`,{empresa:"Registro"},);

        return handleResponse(res);  
}

export const createEvento = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createEvento`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const updateEvento = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateEvento`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getDepartamentosEstado = async (tipo, producto, estado, evento) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/getDepartamentosEstado`, {tipo, producto, estado, evento}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getMunicipiosEstado = async (tipo, departamento, producto, estado, evento) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/getMunicipiosEstado`, {tipo, departamento, producto, estado, evento}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getProductosEventosClear = async (evento) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/getProductosEventosClear`, {evento}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const confirmarMarcacionDepartamentoWS = async (tipo, estado , departamento, producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/confirmarMarcacionDepartamentoWS`,{tipo, estado , departamento, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const cancelarMarcacionDepartamentoWS = async (tipo, estado , departamento, producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/cancelarMarcacionDepartamentoWS`,{tipo, estado , departamento, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const confirmarMarcacionMunicipioWS = async (tipo, estado, departamento, municipio, producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/confirmarMarcacionMunicipioWS`,{tipo, estado, departamento, municipio, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const cancelarMarcacionMunicipioWS = async (tipo, estado, departamento, municipio, producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/cancelarMarcacionMunicipioWS`,{tipo, estado, departamento, municipio, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getPuestosMunicipio = async (tipo, departamento, municipio, producto, estado, evento) =>                                          
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getPuestosMunicipio`,{tipo, estado, departamento, municipio, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}


export const getPuestosMunicipioDivipol = async (municipio, evento) =>                                          
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getPuestosMunicipioDivipol`,{municipio, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const confirmarMarcacionPuestoWS = async (tipo, estado, departamento, municipio, puesto, producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/confirmarMarcacionPuestoWS`,{tipo, estado, departamento, municipio, puesto, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const cancelarMarcacionPuestoWS = async (tipo, estado, departamento, municipio, puesto, producto, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/cancelarMarcacionPuestoWS`,{tipo, estado, departamento, municipio, puesto, producto, evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getStandsAndSupervisores = async ({
    evento, 
    page,
    limit,
    sucursal,
    departamento,
    municipio,
    search,
    standStatus
}) => {
    if (isAuthenticated()) { 
        const res = await axios.get(`${API}/getStandsAndSupervisores/${evento}/${page}/${limit}`, {
            headers: {
                token: isAuthenticated().token                
            },
            params: {
                sucursal,
                departamento,
                municipio,
                search,
                standStatus
            }
        });

        return handleResponse(res);
    }
}

export const getSupervisorsByStand = async idStand => {
    if (isAuthenticated()) { 
        const res = await axios.get(`${API}/getSupervisorsByStand/${idStand}`, {
            headers: {
                token: isAuthenticated().token                
            }
        });

        return handleResponse(res);
    }
}

export const downloadStandsTrackingReport = async ({
    evento, 
    search,
    sucursal,
    municipio,
    standStatus,
}) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/downloadStandsTrackingReport`,{
            evento, 
            search,
            sucursal,
            municipio,
            standStatus,
        }, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}
