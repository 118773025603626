import React, { useState, useEffect, useRef} from 'react';
import { procesarCopiaDatos } from '../../core/apiDivipol';

// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    Paper,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    DialogTitle,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
// Custom components
import ShowAlert from '../../../src/components/shared/Snackbar';
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import "../../../src/App.css";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogMigrarDatos({open, close, idEvento, eventoName, reloadTarjetas, eventosSeleccionados})
{
    const classes = useStyles();    

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [eventoSeleccionado, setEventoSeleccionado] = useState("");
    const [openConfirmation, setOpenConfirmation] = useState(false);    
    
    /* Textos del título/botón del formulario */

    const solicitarMigracionDatos = async () => {
        if(eventoSeleccionado){
            showLoader();

            const res = await procesarCopiaDatos(eventoSeleccionado._id, idEvento);
            if (res.data.error){
                setSnackbarProps("e" + res.data.error);   
                hideLoader();
            }else{      
                setSnackbarProps("s" + res.data.message);                
                hideLoader();
                reloadTarjetas();
                setOpenConfirmation(false);        
                close();                        
            }                 
        }else{
            setSnackbarProps("e" + "Se requiere seleccionar un evento de origen.");
        }
    }

    const closeClear = () => {
        close();
    }

    useEffect(() => {
    }, []);

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>                
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={closeClear} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Migración de datos para el evento: {eventoName}
                        </Typography>                           
                        <Hidden smUp>
                            <IconButton color="inherit">
                                <SaveIcon />
                            </IconButton>
                        </Hidden>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box p={2}>
                                <Paper elevation={2}>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Migrar datos desde evento</Typography>
                                    </Box>
                                    <Box display="flex" p={2}>
                                        <div>
                                        <Typography>1. Seleccione el evento origen.</Typography>
                                        <Typography>2. Se eliminarán los datos actuales del evento de destino de forma permanente.</Typography>
                                        <Typography style={{marginLeft:"30px"}}> a. Roles</Typography>
                                        <Typography style={{marginLeft:"30px"}}> b. Productos</Typography>
                                        <Typography style={{marginLeft:"30px"}}> c. Empresas operadoras del evento</Typography>
                                        <Typography style={{marginLeft:"30px"}}> d. Proyectos</Typography>
                                        <Typography style={{marginLeft:"30px"}}> e. Supervisores</Typography>
                                        <Typography style={{marginLeft:"30px"}}> f. Pagos</Typography>
                                        <Typography>3. Se copiaran los siguientes datos desde el evento de origen, no se efectuará ningún cambio sobre los datos de origen:</Typography>
                                        <Typography style={{marginLeft:"30px"}}> a. Roles</Typography>
                                        <Typography style={{marginLeft:"30px"}}> b. Productos</Typography>
                                        <Typography style={{marginLeft:"30px"}}> c. Empresas operadoras del evento</Typography>
                                        <Typography style={{marginLeft:"30px"}}> d. Proyectos</Typography>
                                        <Typography style={{marginLeft:"30px"}}> e. Supervisores</Typography>
                                        <Typography style={{marginLeft:"30px"}}> f. Pagos</Typography>
                                        <Typography>4. Cualquier avance realizado en el proceso de marcación será eliminado.</Typography>
                                        <Typography>5. La operación es irreversible, y no cuenta con sistema de respaldo.</Typography>
                                        <Typography>6. Seleccione el evento de origen.</Typography>
                                        <Typography>7. Confirme la ejecución del procedimiento.</Typography>
                                        </div>
                                    </Box>
                                    <Box p={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                className='smallClassSelect'
                                                label='Evento origen'
                                                value={eventoSeleccionado}
                                                onChange={(val)=>{
                                                    setEventoSeleccionado(val.target.value)
                                                }}>
                                                {eventosSeleccionados.map((e, i) => {
                                                    if(e._id != idEvento){
                                                        return(
                                                            <MenuItem key={i} value={e}>
                                                                {e.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Paper>
                            </Box>  
                        </Grid>                
                        <Grid item xs={12}>
                            <Box p={2}>
                                <Paper elevation={2}>
                                    <Grid item xs={12}>
                                        <Box className="panelUploadFiles">    
                                            <Box p={1}>
                                                <h3>Evento seleccionado: {eventoSeleccionado?.name}</h3>
                                            </Box>                    
                                            <Box p={1}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    color="primary"
                                                    startIcon={<PublishedWithChangesIcon />}
                                                    onClick={()=> {
                                                        if(eventoSeleccionado){
                                                            setOpenConfirmation(true)
                                                        }else{
                                                            setSnackbarProps("e" +"Se requeire seleccionar un evento origen para la migración");
                                                        }
                                                    }}>
                                                    Migrar datos desde evento
                                                </Button>                    
                                            </Box>      
                                        </Box>                      
                                    </Grid>
                                </Paper>
                            </Box>    
                        </Grid>
                    </Grid>                    
                </DialogContent>               
            </Dialog>
            
            <Dialog
                style={{zIndex:1301}}
                open={openConfirmation}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>{
                    setOpenConfirmation(false)
                }}>
                <DialogTitle>Migración de datos</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Confirma que desea copiar los datos del evento <b>{eventoSeleccionado?.name}</b> al evento <b>{eventoName}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={()=>{
                            setOpenConfirmation(false);
                        }}>
                        Cancelar
                    </Button>
                    <Button 
                        color="secondary" 
                        onClick={()=>{
                            solicitarMigracionDatos()
                        }}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}