// Expresiones regulares
export const regex = 
{
    oneNumber: /^[0-1]$/,
    tenNumbers: /^[0-9]$/,
    numeros: /^\d+(\.\d+)?$/,
    dinero: /^\d+$/i,
    letras: /^[ñäëïöüáéíóúàèìòùa-z]+$/i,
    letrasEspacios: /^[ñäëïöüáéíóúàèìòùa-z ]+$/i,
    letrasNumerosGuionBajo: /^[a-z0-9_]+$/i,
    letrasNumerosCaracteres: /^[a-zñ0-9\-_*+.#$%&@¡!¿?]+$/i,
    letrasNumerosCaracteresEspacios: /^[ñäëïöüáéíóúàèìòùa-z0-9\-_*+.,;:()#$%&@¡!¿? ]+$/i,
}

export const numeros = (longitud) => e =>
{
    validarExpresion(regex.numeros, longitud, e);
}

export const dinero = (longitud) => e =>
{
    validarExpresion(regex.dinero, longitud, e);
}

export const letrasEspacios = (longitud) => e =>
{
    validarEspaciado(e);
    validarExpresion(regex.letrasEspacios, longitud, e);
}

export const letrasNumerosGuionBajo = (longitud) => e =>
{
    validarExpresion(regex.letrasNumerosGuionBajo, longitud, e);
}

export const letrasNumerosCaracteres = (longitud) => e =>
{
    validarExpresion(regex.letrasNumerosCaracteres, longitud, e);
}

export const letrasNumerosCaracteresEspacios = (longitud) => e =>
{
    validarEspaciado(e);
    validarExpresion(regex.letrasNumerosCaracteresEspacios, longitud, e);
}

// Valida los caracteres ingresados por teclado con la expresión regular entregada
const validarExpresion = (pattern, longitud, e) =>
{   
    // Caracter ingresado por teclado
    const char = String.fromCharCode(e.charCode);

    // El caracter no es válido para la expresión regular
    if (! pattern.test(char))
    {
        return e.preventDefault();
    }

    // Validar longitud
    if (e.target.value.length >= longitud)
    {
        return e.preventDefault();
    }
}

// Valida si se ingresan 1 o más espacios seguidos, o al inicio de la cadena
const validarEspaciado = (e) =>
{
    if (e.target.value.length === 0)
    {
        // El input está vacío, validar si el primer caracter ingresado es un espacio
        if (e.charCode === 32) // Tecla espacio
        {
            return e.preventDefault();
        }
    }
    else
    {
        // Obtener último caracter ingresado
        const last = e.target.value.substr(e.target.value.length - 1);

        // Validar si el último caracter es un espacio y el nuevo caracter ingresado también
        if (last === " " && e.charCode === 32)
        {
            return e.preventDefault();
        }
    }
}

// Devuelve la cadena recibida sin espacios de más
export const corregirEspaciado = (str) =>
{
    return str.toString().replace(/\s+/g, ' ');
}