import React, { createContext, useEffect, useState } from "react"
import { getEvento } from "../core/apiEventos"

export const EventContext = createContext()

export const EventProvider = (props) => {
  const [currentEvent, setCurrentEvent] = useState(null)

  useEffect(() => {
    let eventoId = null
    if(window.location.pathname.split("/").length == 5) // No WebView
      eventoId = window.location.pathname.split("/")[4]
    else if(window.location.pathname.split("/").length == 6) // WebView
      eventoId = window.location.pathname.split("/")[4];
    else if(window.location.pathname.indexOf("/mapa/") != -1) // Mapa
      eventoId = window.location.pathname.split("/")[2];
    
    async function fetchEvent() {
      console.log('DEBO CONSULTAR EVENTO: ', eventoId)
      const res = await getEvento(eventoId)
      if(res?.data) setCurrentEvent(res.data)
    }
    eventoId && void fetchEvent()
  }, [])


  const values = {
    currentEvent
  }

  return (
    <EventContext.Provider value={values}>
      {props.children}
    </EventContext.Provider>
  )
} 

export const useEventContext = () => React.useContext(EventContext)