import React from 'react';
import './App.css';
import "../src/assets/css/loader.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from './helpers/PrivateRoute';
import GenericNotFound from './components/shared/GenericNotFound';

import Login from './components/Login';

import DashboardPage from './components/dashboards/DashboardPage';
import RestorePass from './components/RestorePass';
import EmpresasAdmin from './components/User/SupAdmin/EmpresasAdmin';
import TerminosCondiciones from './components/controls/TerminosCondiciones';

import NoWorkingDays from "./components/Settings/NoWorkingDays/Main";
import MainDepartamentos from './components/departamentos/MainDepartamentos';
import MainReporteDepartamentos from "./components/departamentos/MainReporteDepartamento";
import Map from './components/mapa/Map';

import Eventos from "./components/eventos/Eventos";
import Gerentes from "./components/User/Gerentes/Gerentes";
import Maestros from './components/User/Admin/Maestros/Maestros';
import Info from './components/infoPages/info';
import LoginWebView from './components/LoginWebView';
import InicioRegistro from "./components/Registro/InicioRegistro";
import { ElectronicSignaturePage } from './components/electronicSignature/ElectronicSignaturePage';
import ActivarCuenta from "./components/Registro/ActivarCuenta";
import RedirPageUrl from "./components/RedirPageUrl";


function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Login} />
				<Route exact path="/signin" component={Login} />
				<Route exact path="/privacy" component={Info} />
				<Route exact path="/info" component={Info} />
				<Route exact path='/restaurarPass/:code' component={RestorePass} />
				<Route exact path='/movil/:evento/:idUser' component={LoginWebView} />
				<Route exact path='/registro' component={InicioRegistro} />	
				<Route exact path='/electronic-signature/:concepto' component={ElectronicSignaturePage} />	
				<Route exact path='/activacion-cuenta/:code' component={ActivarCuenta} />
				<Route exact path='/redir/:code' component={RedirPageUrl} />	

				<PrivateRoute exact path='/masters' component={Maestros} />					
				<PrivateRoute exact path='/empresasAdmin' component={EmpresasAdmin} />
				<PrivateRoute exact path='/adminTerminosCondiciones' component={TerminosCondiciones} />	
				<PrivateRoute exact path='/gerentes' component={Gerentes} />								
				<PrivateRoute exact path='/eventos' component={Eventos} />
				<PrivateRoute exact path='/eventos/:evento' component={DashboardPage} />	
				<PrivateRoute exact path='/eventos/confirmacion-manual/:estado/:evento' component={MainDepartamentos} />	

				<PrivateRoute exact path='/eventos/:tipo/:estado/:evento' component={MainReporteDepartamentos} />
				<PrivateRoute exact path='/eventos/:tipo/:estado/:evento/:webView' component={MainReporteDepartamentos} />

				<PrivateRoute exact path='/mapa/:evento' component={Map} />	

				{/* Settings */}
				<PrivateRoute exact path='/settings/no-working-days' component={NoWorkingDays} />
				<Route component={GenericNotFound} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
