import { useEffect } from 'react';
import { useSnackbar } from "notistack";

const ShowAlert = (props, setProps) =>
{
    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(() =>
    {
        if (props)
        {
            const _message = props.slice(1);
            const variant = (
                props[0] === "e" ? "error" : 
                props[0] === "s" ? "success" :
                props[0] === "w" ? "warning" : "info"
            );

            if(props[0] === "e"){
                enqueueSnackbar(_message, { variant });
            }else{
                enqueueSnackbar(_message, { variant });
            }
                        
            setProps();
        }
    }, [enqueueSnackbar, props, setProps]);
}

export default ShowAlert;