/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';

// MUI Components
import {
	AppBar,
	Toolbar,
	Typography,
	makeStyles,
	Tooltip,
	IconButton, 
	Modal
} from "@material-ui/core";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/HelpOutline';

// Custom components
import { customStyles } from "../../assets/js/themeConfig";
import Loader from "./Loader";

// Servicios
import { signout, isAuthenticated } from "../../core/sessionAPI";
import VideoSelector from './VideoSelector';

// Estilos
const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	title: {
	  flexGrow: 1,
	},
	offset: theme.mixins.toolbar,
	bgColor: customStyles.gradientBackground,
}));

const VideoModal = ({ open, onClose, selectedVideo }) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle><h5>Manual de Registro</h5></DialogTitle>
        <DialogContent>
				<div>
					{selectedVideo && (
						<ReactPlayer url={selectedVideo} controls width="100%" height="500px" />
					)}
				</div>
		</DialogContent>
        <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Salir
                </Button>
        </DialogActions>
      </Dialog>
    );
};

const Navbar = (props) =>
{
	const classes = useStyles();
	const [pageTitle, setPageTitle] = useState("");
	const [showLoader, setShowLoader] = useState(false);
	const [isWebView,setIsWebView] = useState(false);


	//Ayuda Modal
	const [anchorEl, setAnchorEl] = useState(null);
  	const [selectedVideo, setSelectedVideo] = useState(null);

	// Datos del usuario logeado
	const user = isAuthenticated().user;

	//Funciones necesito ayuda
	const handleOpenSelector = (event) => {
		setAnchorEl(event.currentTarget);
	  };
	
	  const handleCloseSelector = () => {
		setAnchorEl(null);
	  };
	
	  const handleVideoSelect = (videoUrl) => {
		setSelectedVideo(videoUrl);
		handleCloseSelector();
	  };

	  const handleOpenModal = () => {
		// Lógica para abrir el modal con el video seleccionado
		// En este ejemplo, utilizamos un simple Modal con ReactPlayer para mostrar el video
		// Asegúrate de importar ReactPlayer en el componente si aún no lo has hecho
	  };
	
	  const handleCloseModal = () => {
		setSelectedVideo(null);
	  };

	// Se ejecuta cada vez que el usuario cambia de url
	useEffect(() =>
	{
		const path = window.location.pathname;
		if(props.nameEvento == ""){
			if (path === "/empresasAdmin") setPageTitle("Administrador de Empresas");
			if (path === "/mapa") setPageTitle("Mapa");
			if (path === "/eventos") setPageTitle("Administración de Eventos");
			if (path === "/gerentes") setPageTitle("Administración de Gerentes");
			if (path === "/masters") setPageTitle("Datos Maestros");			
		}else{
			setPageTitle(props.nameEvento);
		}

		props.cerrarSidebar();
	}, [window.location.pathname, props.nameEvento]);

	useEffect(() => {
        //Validar si el origen es un webview, para remover el accceso a la interfaz de eventos y marcación
        var variables = window.location.href.split("/");
        if(variables.length == 8){
            //Contiene variable de webviwe
            if(variables[7] == "webView"){
                //Esconder acceso a controles
                setIsWebView(true);
            }
        };
    }, []); 

	const cerrarSesion = () =>
	{
		setShowLoader(true)
		signout();
	}

	const handleOpenAyuda = () =>
	{
		console.log("Holaa")
	}

	return (
		<div>
			<AppBar className={`${classes.appBar} ${classes.bgColor} ${classes.bgShadow}`}>
				<Toolbar>
					<IconButton 
						className={classes.menuButton} 
						color="inherit" 
						aria-label="menu"
						onClick={() => props.accionAbrir()}>
						<MenuIcon />
					</IconButton>

					<Typography variant="h6" className={classes.title}>
						{pageTitle}
					</Typography>
					
					{user.role === 2 && !isWebView && (
						<Tooltip title="Necesito Ayuda">
								<IconButton onClick={handleOpenSelector} >
									<HelpIcon htmlColor="white"/>
								</IconButton>		
						</Tooltip>
					)}

					{!isWebView &&
						<Tooltip title="Cerrar sesión">
							<IconButton 
								onClick={cerrarSesion}>
								<ExitToAppIcon htmlColor="white"/>
							</IconButton>
						</Tooltip>
					}
				</Toolbar>
			</AppBar>

			<Loader show={showLoader} />

			<VideoSelector anchorEl={anchorEl} onClose={handleCloseSelector} onVideoSelect={handleVideoSelect} />

			{/* Modal para mostrar el video seleccionado */}
			
			<VideoModal selectedVideo={selectedVideo} open={Boolean(selectedVideo)} onClose={handleCloseModal} />
		</div>
	)
}

export default Navbar;