import React, { useState, useLayoutEffect } from 'react';

// Componentes 
import { 
    Paper,
    Box,
    Typography,
    Button,
    Hidden,
    Fab
} from '@material-ui/core';

// Iconos
import AddIcon from '@material-ui/icons/Add';

// Servicios
import { getEmpresasAdmin } from '../../../core/superAdminServices/empresaAPI';

// Custom components
import { showLoader, hideLoader } from '../../shared/Loader';
import Formulario from './Formulario';
import Tabla from './Tabla';
import ShowAlert from '../../shared/Snackbar';

import TablaSedes from '../Sedes/TablaSedes';

const MainEmpresas = () =>
{
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /**
     * formMode: Define si el formulario es para crear o editar empresas
     * editData: Almacena los datos de una empresa si formMode está en modo de edición
     * openForm: Define si el formulario está abierto o no
     */
    const [formMode, setFormMode] = useState();
    const [editData, setEditData] = useState();
    const [openForm, setOpenForm] = useState(false);
    
    /* Listado de empresas y países */
    const [empresas, setEmpresas] = useState([]);
    const [paises, setPaises] = useState([{code:"CO",name:"Colombia"}]);
    // Almacena los datos del pais de la empresa cuando se selecciona la opción "Editar"
    const [paisEmpresa, setPaisEmpresa] = useState([]);

    const [openSedes, setOpenSedes] = useState(false);
    const [empresaSeleccionada, setEmpresasSeleccionada] = useState(null);

    /**
     * Al dar click en el botón 'Nueva empresa' se abre el formulario de empresas en modo crear
     * Si se da click en la opción 'Editar' de la tabla, se abre en modo editar
     */
    const handleOpenForm = (mode, data = "") =>{
        if (mode === "editar")
        {
            setPaisEmpresa([{
                code: data.countryCode,
                name: data.country
            }])
        }

        setFormMode(mode);
        setEditData(data);
        setOpenForm(true);
    }

    const handleSedes = (empresaSeleccionada) => {
        setEmpresasSeleccionada(empresaSeleccionada);
        setOpenSedes(true);
    }

    /* Cierra el formulario de Creación/Edición de empresas */
    const close = () => 
    {
        setOpenForm(false);
        setOpenSedes(false);
    }

    /* Carga el listado de empresas */
    const loadCompanies = async () =>
    {
        showLoader();
        const res = await getEmpresasAdmin();

        if (res.error)
        {
            setSnackbarProps("e" + res.error)
            setEmpresas([]);
        }
        else
        {
            setEmpresas(res.data);
        }
        hideLoader();
    }

    /* Carga el listado de empresas y países una vez cargue la página */
    useLayoutEffect(() =>
    {
        async function init(){
            loadCompanies();
        }

        init();
    }, []);

    return (
        <>
            <Box p={2}>
                <Paper elevation={2}>
                    <Box display="flex" p={2} justifyContent="space-between">
                        <Typography variant="h6" align="left">
                            Empresas
                        </Typography>
                        {/* Botón crear para pantallas normales */}
                        <Hidden xsDown>
                            <Button 
                                variant="contained" 
                                color="primary"
                                startIcon={<AddIcon/>}
                                size="small"
                                onClick={() => handleOpenForm("crear")}
                            >
                                Nueva empresa
                            </Button>
                        </Hidden>
                        {/* Botón crear para pantallas pequeñas */}
                        <Hidden smUp>
                            <Fab
                                color="primary"
                                aria-label="add"
                                size="small"
                                title="Nueva empresa"
                                onClick={() => handleOpenForm("crear")}
                            >
                                <AddIcon />
                            </Fab>
                        </Hidden>
                    </Box>
                </Paper>
            </Box>

            <Tabla 
                empresas={empresas}
                handleEditClick={handleOpenForm}
                handleSedes={handleSedes}
                setSnackbarProps={setSnackbarProps}
                reloadTable={loadCompanies}
            />

            <TablaSedes
                 open={openSedes}
                 close={close} 
                 empresaSeleccionada={empresaSeleccionada} 
            />

            {/* Modal con el formulario para crear empresas */}
            <Formulario 
                open={openForm}
                close={close}
                mode={formMode}
                data={editData}
                countries={formMode === "crear" ? paises : paisEmpresa}
                refreshTable={loadCompanies}
            />            

            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}

export default MainEmpresas;