import React, {useState, useRef} from "react";
import { controlsAPI as API } from "../../core/apiUrls";
import { 
    Button,
    TextField,
    Grid, 
    Box,
    Paper,
    LinearProgress
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import "../../App.css";

import axios from 'axios';

export default function UploadFiles({title, label, setSnackbarProps, documents, setDocuments, disableDelete, maxNumberFiles}){

    const [selectedFile, setSelectedFile] = useState(null);
    const [disableUpload, setDisableUpload] = useState(false);
    const [progressUpload, setProgressUpload] = useState(0);
    const inputFileRef = useRef();

    const deleteFile = (url) => {
        var arrayDocumentsTemp = new Array();
        for(var i = 0; i < documents.length; i++){    
            if(documents[i].url != url){
                arrayDocumentsTemp.push(documents[i]);                
            }
        }
        
        setDocuments(arrayDocumentsTemp);
        setSnackbarProps("s" + "Imagen removida");

        console.log(arrayDocumentsTemp);
        if(arrayDocumentsTemp.length < maxNumberFiles){
            setDisableUpload(false);
        }else{
            setDisableUpload(true);
        }
    }

    const onChangeHandler = (event) =>{
        setDisableUpload(true);

        if(event.target.files.length > 0){
            setSelectedFile({selectedFile: event.target.files[0], loaded:0});

            //Se inicia la subida directamente del archivo
            const data = new FormData() 
            data.append('file', event.target.files[0]);

            axios.post(API + "/uploadFile", data, {
                onUploadProgress: ProgressEvent => {
                    setProgressUpload(ProgressEvent.loaded / ProgressEvent.total*100);
                },
            }).then(res => {
                //Si el archivo se sube correctamente, se registra en el array de elementos su existencia
                inputFileRef.current.value = null;
                
                if (res.error){
                    setSnackbarProps("e" + res.error);
                }else{            
                    var arrayDocumentsTemp = new Array();
                    for(var i = 0; i < documents.length; i++){    
                        arrayDocumentsTemp.push(documents[i]);                
                    }

                    arrayDocumentsTemp.push(res.data.file);
                    setDocuments(arrayDocumentsTemp);
                    setSnackbarProps("s" + res.data.message);
                }

                setTimeout(() => {
                    setProgressUpload(0);
                    if(arrayDocumentsTemp.length < maxNumberFiles){
                        setDisableUpload(false);
                    }else{
                        setDisableUpload(true);
                    }
                },5000)   
            })
        }
    }

    return(
        <> 
            <Box className="panelUploadFiles">    
                <LinearProgress 
                    className="sizeProgressBar"
                    variant="determinate" 
                    value={progressUpload} />
                <Box p={1}>
                    <h3>{title}</h3>
                </Box>                   
                <Box p={1}>
                    <Button 
                            fullWidth
                            variant="contained" 
                            color="primary"
                            disabled={disableUpload}
                            startIcon={<CloudUploadIcon />}
                            onClick={()=> {
                                //Call triger in input file
                                inputFileRef.current.click();
                            }}>
                            {label}
                        </Button>

                        <input type="file" name="file" ref={inputFileRef} onChange={onChangeHandler} className="hidenPanel"/>                         
                </Box>                                           
                <Box p={1}>
                    {documents.map((document, index) => (<Box p={1} key={index}>
                                                            <Grid container>
                                                                <Grid item  xs={6} sm={8} className="titleFile">
                                                                    <a href={document.url} target="_blank">{document.name}</a>
                                                                </Grid>
                                                                <Grid item  xs={6} sm={4}>
                                                                    <Button 
                                                                        disabled={disableDelete}
                                                                        fullWidth
                                                                        variant="outlined" 
                                                                        color="secondary"
                                                                        onClick={()=>{
                                                                            deleteFile(document.url);
                                                                        }}
                                                                        startIcon={<DeleteForeverIcon />}>
                                                                        Eliminar
                                                                    </Button>                            
                                                                </Grid>                            
                                                            </Grid>
                                                        </Box>))}                                        
                </Box>                          
            </Box>
        </>
    )
}