import React, { useState, useEffect, useRef } from "react";
import ShowAlert from '../shared/Snackbar';
import { Box, Grid, Collapse, Hidden, Checkbox, FormControlLabel, Link, TextField, Button, MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import "../../App.css";
import { getTiposPagosRegistro } from '../../core/apiTipoPago';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { getCorreoExitente } from '../../core/apiSupervisores';
import { getEventosRegistro } from '../../core/apiEventos';
import ConfirmacionRegistro from "./RegistroConfimado";
import SeleccionarCedula from "./SeleccionarCedula";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ReactPlayer from 'react-player';
import "../../assets/css/loader.css";
import Loader, { hideLoader, showLoader } from "../shared/Loader";
import { confirmActivateEmail } from '../../core/apiSupervisores';
import ReCAPTCHA from 'react-google-recaptcha';
//import FormularioRegistroSupervisores from "../eventos/FormularioRegistroSupervisores"; //Borrar
import PlayArrowIcon from '@mui/icons-material/YouTube';

const validationSchema = Yup.object().shape({
    email: Yup.string().matches(/^[a-z@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/, 'Solo se permiten minúsculas').email('Ingrese una dirección de correo electrónico válida').required('El correo electrónico es requerido'),
    confirmEmail: Yup.string().oneOf([Yup.ref('email')], 'Los correos electrónicos deben coincidir').required('La confirmación de correo electrónico es requerida'),
  });

const VideoModal = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle><div>Manual de Registro</div></DialogTitle>
        <DialogContent>
          <ReactPlayer url="https://www.youtube.com/watch?v=bF_xaDNswPE" controls width="100%" height="500px" />
        </DialogContent>
        <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Salir
                </Button>
        </DialogActions>
      </Dialog>
    );
  };

const InicioRegistro = () =>
{  
    //Abrir Formulario Supervisores
    const [openTerminos, setOpenTerminos] = useState(false);
    const [openFormProducto, setOpenFormProducto] = useState(false);
    const [openFormVerificacion, setOpenFormVerificacion] = useState(false);

    const [tiposPago, setTiposPagos] = useState([]);
    const [correoValidado, setCorreoValidado] = useState(false);
    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [eventoSeleccionado, setEventoSeleccionado] = useState("");
    //const [eventoSeleccionado, setEventoSeleccionado] = useState("6499ea3fb8aeaa0014761b53");
     const [eventoSeleccionadoNombre, setEventoSeleccionadoNombre] = useState("");
    //const [eventoSeleccionadoNombre, setEventoSeleccionadoNombre] = useState("PRUEBA 2 QA");
    const [enviarCorreo, setEnviarCorreo] = useState(false);
    const [datosCorreo, setDatosCorreo] = useState(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    const [eventos, setEventos] = useState([]);

    //datos para formulario de registro
    const [dataForm, setDataForm] = useState([]);
    
    const [restorePass, setRestorePass] = useState(false);
   
    const [snackbarProps, setSnackbarProps] = useState();

    //Terminos y Condiciones
    const [isChecked, setIsChecked] = useState(false);

    //Video Tutorial
    const [modalOpen, setModalOpen] = useState(false);

    //Cedula Gigital
    const [isCedulaDigital, setIsCedulaDigital] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const handleCaptchaChange = (value) => {
        // Si el valor de reCAPTCHA es válido, establece isCaptchaValid en true
        setIsCaptchaValid(true);
      };

    //Modal para video tutorial
    const handleOpenModal = () => {
        setModalOpen(true);
      };  
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };
    
    const handleOpenCorreo = async () => {
        setEnviarCorreo(false);
        const res= await confirmActivateEmail(datosCorreo.email, datosCorreo.name,  datosCorreo._id);
        if(res.data.message){
            setSnackbarProps("s" + "Correo de verificación enviado con exito.");
        }else{
            setSnackbarProps("e" + res.error);
        }
      };
    
    //Validación de correo(que sean iguales)
    const formik = useFormik({
        initialValues: {
          email: '',
          confirmEmail: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          //  console.log(isCaptchaValid)
            if (isCaptchaValid) {
                // Aquí puedes enviar los datos del formulario al backend para el registro
                // Si el reCAPTCHA es válido
                isEmail(values);
              } else {
                setSnackbarProps("e" + "Debes validar y completar el reCAPTCHA");
              }
        },
      });

    const {  touched, errors } = formik;

    const closeHandlerProducto = async (estado) => {
        console.log(estado)
        if(estado){
            setOpenConfirmacion(true);
        }else{
            window.location.reload();
            setOpenFormProducto(false);
           // window.open('/');  
        }    
    }

    const closeIdentificacion = async (estado) => {
        window.location.reload();
    }

    //Cargar tipos de pagos para formulario de registro
    const loadDataTipoPagos = async () => {
        let res = await getTiposPagosRegistro()
        
        if (res.error){
            setSnackbarProps("e" + res.error);
            setTiposPagos([]);
        }else{
            setTiposPagos(res.data);
        }
    }

    //Verifica correo En la BD
    const isEmail = async (values) => {
        setEnviarCorreo(false);
        if(eventoSeleccionado==0){
            setSnackbarProps("e" + "Debes seleccionar un evento para continuar el registro"); 
        }else{
        const correo = values.email;
        const res = await getCorreoExitente(values.email, eventoSeleccionado);
        const nombreEvento = eventos.find(item => item.value === eventoSeleccionado);
        

        setEventoSeleccionadoNombre(nombreEvento.label);
        const exist = res.data.exist;
        if(!exist){
            let values={};
            values.email= correo;
            setDataForm(values); 
           setOpenFormVerificacion(true);      
        }else{
            if(res.data?.estadoActivacion==="VERIFICADO"){
                setSnackbarProps("e" + "Ingresa un correo diferente, el correo que estas ingresando ya fue registrado.");
            }else if(res.data?.estadoActivacion==="GERENTE"){
                setSnackbarProps("e" + "EL correo esta registrado para un gerente. No lo puedes utilizar para un supervisor.");
            }else{
                setSnackbarProps("e" + "Ingresa un correo diferente o revisa tu correo electronico, para confirmar tu registro.");
                setEnviarCorreo(true);
                setDatosCorreo(res.data);
            }      
          }
        }
    }

    //Consultar Eventos
    const consultarEventos= async () =>{
        setIsLoading(true)
        showLoader()
        let tempo=[];
        const res = await getEventosRegistro();
        for (let index = 0; index < res.data.length; index++) {
           tempo.push({id:(index+1), label: res.data[index].name, value: res.data[index]._id});
        }
        setEventos(tempo);
        setIsLoading(false)
        hideLoader()
    }


    /**
     * Se ejecuta cada vez que se entra a la página
     */
    useEffect(() => {
        //Carga los tipos de pagos
        loadDataTipoPagos();

        consultarEventos(); 
    }, []);

    

    const registerButton = () =>(
        <>
            <Box className="form-containerRegistro">
                <Grid container> 
                    <Hidden xsDown >          
                        <Grid item sm={2} md={4}> 
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={8} md={4}> 
                        <Collapse 
                            in={!restorePass}>
                            <Box 
                                className="formRegistro"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column">                                
                                <img className="imageLogo" src="/img/disproelLogo.png" alt="logo"
                                />
                                
                                <form onSubmit={formik.handleSubmit} style={{maxWidth: '100%'}}> 
                                    <Grid container spacing={1}>
                                        
                                        {
                                            eventos.length === 0 ?
                                            <Grid item xs={12} sm={12}>
                                                <h3 style={{ color: '#3f51b5', textAlign: 'center' }}>No hay procesos electorales activos</h3>
                                            </Grid>
                                            : 
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <h4 style={{textAlign: 'center'}}>¡Ingresa tu correo electrónico y selecciona tu evento para continuar con el registro!</h4>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Link style={{textAlign: 'left'}} to="#" onClick={handleOpenModal}>
                                                        <h5 style={{ color: '#3f51b5', textAlign: 'center', cursor:"pointer" }}>
                                                            <PlayArrowIcon style={{ verticalAlign: 'middle', height: 18, color: "#FE2E2E" }} /> {/* Ícono antes del texto */}
                                                            Ver manual para registrarse
                                                        </h5>
                                                    </Link>  
                                                </Grid> 
                                                <Grid item xs={12} sm={12}>
                                                    <FormControl fullWidth size="small" style={{paddingTop:10}}>
                                                        <InputLabel style={{paddingLeft:15}}  id="encabezadoEvento">Evento</InputLabel>
                                                        <Select
                                                            labelId="encabezadoEvento"
                                                            variant="outlined" 
                                                            fullWidth
                                                            label="Evento"
                                                            value={eventoSeleccionado}
                                                            onChange={(e) => {setEventoSeleccionado(e.target.value)}}
                                                        >
                                                            <MenuItem value={0} name=" Seleccione un evento" key={0} disabled selected>
                                                                Seleccione un evento
                                                            </MenuItem>
                                                            {   
                                                                eventos.length !== 0 ?
                                                                    eventos.map( item => 
                                                                        <MenuItem
                                                                            value={item.value}
                                                                            name={item.label}
                                                                            key={item.id}
                                                                            selected
                                                                        >
                                                                            {item.label}
                                                                        </MenuItem>
                                                                    )
                                                                : <MenuItem disabled key={1}>No hay Eventos</MenuItem>
                                                            }
                                                        </Select>
                                                    </FormControl >
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField 
                                                        name="email"
                                                        label="Correo electrónico"
                                                        fullWidth
                                                        inputProps={{ maxLength: 90 }}
                                                        variant="outlined"
                                                        size="small"
                                                        helperText={touched.email ? errors.email : ""}
                                                        error={touched.email && Boolean(errors.email)}
                                                        {...formik.getFieldProps('email')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        style={{paddingTop:3, paddingBlock:1}}
                                                        name="confirmEmail"
                                                        label="Confirmar correo electrónico"
                                                        fullWidth
                                                        inputProps={{ maxLength: 90 }}
                                                        variant="outlined"
                                                        size="small"
                                                        helperText={touched.confirmEmail ? errors.confirmEmail : ""}
                                                        error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                                                        {...formik.getFieldProps('confirmEmail')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        style={{ height: 25}}
                                                        control={
                                                        <Checkbox
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                            color="primary"
                                                        />
                                                        }
                                                        label={
                                                            <p style={{fontSize: "0.8rem", lineHeight: 1, color:"grey"}}>
                                                                He leído y acepto los{' '}
                                                                <Link 
                                                                    onClick={()=>setOpenTerminos(true)}
                                                                    target="_blank" rel="noopener">
                                                                    términos y condiciones.
                                                                </Link>
                                                            </p>
                                                            }
                                                    />
                                                </Grid>
                                                {enviarCorreo? 
                                                    <Grid item xs={12} sm={12}>
                                                        <Link style={{textAlign: 'left'}} to="#" onClick={handleOpenCorreo}>
                                                        <h6 style={{color: '#3f51b5', textAlign: 'center'}} >Volver a enviar correo de verficación</h6>
                                                        </Link>  
                                                    </Grid>
                                                : null}
                                                <Grid item xs={12} sm={12}>
                                                    <ReCAPTCHA
                                                        sitekey="6Lc2O3cnAAAAAHyTnIMnhrkWTjOVrvolesgz_GGQ"
                                                        onChange={handleCaptchaChange}
                                                        /> 
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button 
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!isChecked}
                                                            >
                                                            Validar Correo Electrónico
                                                        </Button>
                                                    </div>                                               
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </form>                          
                            </Box>
                        </Collapse>
                        
                    </Grid>
                    <Hidden xsDown >  
                        <Grid item sm={2} md={4}> 
                        </Grid>
                    </Hidden>
                </Grid>
                
                <ConfirmacionRegistro
                open = {openConfirmacion}
                /> 

                {openFormVerificacion ? 
                <SeleccionarCedula
                    open = {openFormVerificacion}
                //  open = {true}
                    close = {closeIdentificacion}
                    idEvento={eventoSeleccionado}
                    eventoName={eventoSeleccionadoNombre}
                    modo={"crear"}
                    tiposPago={tiposPago}
                    datos={dataForm}
                    cedulaDigital = {isCedulaDigital}
                    closeFormulario = {closeHandlerProducto}
                />: null}
                <VideoModal open={modalOpen} onClose={handleCloseModal} />
            </Box>
            <Dialog open={openTerminos} onClose={()=>setOpenTerminos(false)} maxWidth="lg" fullWidth={true}>
                <DialogTitle><div>Terminos y condiciones</div></DialogTitle>
                <DialogContent>                    
                    <p style={{textAlign: "justify", textJustify: "inter-word"}}>
                        <br/>
                        <br/>
                        <h1>Thomas Greg & Sons</h1>
                        <br/>
                        <b>CONFIDENCIALIDAD:</b>
                        <br/>
                        <br/>
                        Conoceré información entregada por parte de la UT DISPROEL 2023 y/o por parte de mi contratante directo , en virtud del Contrato 052 de 2023, la cual tendrá el carácter de confidencial. Por lo tanto, me comprometo a no revelar, ni utilizar dicha información para el ejercicio de mi propia actividad, ni la duplicaré o compartiré con terceras personas. Adicionalmente, me obligo a adoptar todas las medidas necesarias o convenientes para garantizar la reserva de la información a la que tenga acceso con ocasión del presente Contrato, comprometiéndome a que tales medidas o precauciones no serán en ningún caso menores que aquellas adoptadas por la UT DISPROEL 2023 y/o mi contratante directo, para mantener la propia información en reserva. La información que se llegue a conocer en virtud del Contrato 052 de 2023, se utilizará exclusivamente para los fines establecidos por la UT DISPROEL 2023 y/o su contratante. Dicha información no será divulgada a terceros sin el consentimiento previo y escrito de la UT DISPROEL 2023, aún con posterioridad a la terminación del Contrato 052 de 2023. Cualquier violación a esta disposición, facultará a la UT DISPROEL 2023 y/o a mi contratante directo para ejercer las acciones a que haya lugar.
                        <br/>
                        <br/>
                        <b>INDEPENDENCIA POLITICA:</b>
                        <br/>
                        <br/>
                        Por otra parte, declaro que:
                        <br/>
                        <br/>
                        -NO me encuentro afiliado, no pertenezco, no trabajo, ni he trabajado en los últimos cuatro (4) años en ningún partido, movimiento, gremio político o grupo significativo de ciudadanos.
                        <br/>
                        <br/>
                        -NO trabajo, ni he trabajado en los últimos cuatro (4) años con ninguno de los posibles candidatos y/o candidatos inscritos para el proceso electoral a realizarse en el año 2023 en el país, ni con personas cuyo vínculo de parentesco con algún candidato se extienda hasta el segundo grado de consanguinidad, segundo de afinidad o primero civil.
                        <br/>
                        <br/>
                        -NO tengo relación directa o indirecta con ninguno de los posibles candidatos y/o candidatos inscritos para el proceso electoral a realizarse en el año 2023 (Alcaldes, Gobernadores, Diputados Departamentales, Concejales Municipales o Distritales, Ediles) en el país, o con algún partido, movimiento, gremio político o grupo significativo de ciudadanos, ni con personas cuyo vínculo de parentesco con algún candidato o directivo, revisor fiscal o asesor de algún partido, movimiento, gremio político o grupo significativo de ciudadanos, se extienda hasta el segundo grado de consanguinidad, segundo de afinidad o primero civil.
                        <br/>
                        <br/>
                        -NO tengo relación de parentesco hasta el segundo grado de consanguinidad (padres, hermanos, hijos, abuelos, nietos), segundo de afinidad (suegros, yernos/nueras, cuñados e hijos y nietos de mi cónyuge o compañero permanente) o primero civil (hijos o padres adoptivos) y cónyuge, o compañero permanente, con ninguno de los posibles candidatos y/o candidatos inscritos para el proceso electoral a realizarse en el año 2023 en el país, ni con ninguna persona vinculada a un partido, movimiento, gremio político o grupo significativo de ciudadanos en Colombia.
                        <br/>
                        <br/>
                        -NO he sido declarado responsable judicialmente por la comisión de delitos contra la Administración Pública cuya pena sea privativa de la libertad o que afecten el patrimonio del Estado.
                        <br/>
                        <br/>
                        -NO he sido condenado por delitos relacionados con la pertenencia, promoción o financiación de grupos ilegales, delitos de lesa humanidad, narcotráfico en Colombia o en el exterior, o soborno transnacional, con excepción de delitos culposos.
                        <br/>
                        <br/>
                        -NO he financiado campañas políticas a la Presidencia de la República, Congreso de la República, Autoridades Territoriales o cualquier campaña política en los últimos cuatro (4) años. Esta causal también operará para las personas que se encuentren dentro del segundo grado de consanguinidad, segundo de afinidad, o primero civil de la persona que haya o esté financiando campaña política en los últimos cuatro (4) años.
                        <br/>
                        <br/>
                        <b>PREVENCION DE LAVADO DE ACTIVOS, FINANCIACION DEL TERRORISMO Y PROLIFERACIÓN DE ARMAS DE DESTRUCCIÓN MASIVA</b>
                        <br/>
                        <br/>
                        Declaro que:
                        <br/>
                        <br/>
                        -Tanto mis actividades, mi profesión u oficio son lícitas, las ejerzo dentro del marco legal y los recursos que poseo no provienen de actividades ilícitas, no se destinarán para actividades terroristas, a la financiación del terrorismo ni a la proliferación de armas de destrucción masiva, contempladas en el Código Penal o en cualquier norma que lo sustituya, adicione o modifique.
                        <br/>
                        <br/>
                        -Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado no provienen de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.
                        <br/>
                        <br/>
                        -Qué NO me encuentro en las listas internacionales vinculantes para Colombia de conformidad con el derecho internacional (listas de Naciones Unidas- ONU), en listas de la OFAC, Clinton y/o similares. Si se demuestra que esta declaración jurada es falsa, el vínculo contractual terminará automáticamente y será un incumplimiento gravísimo de las obligaciones a mi cargo. 
                        <br/>
                        <br/>
                        -Que en el evento que tenga conocimiento de alguna de las circunstancias descritas en el párrafo anterior o la inclusión en las listas en mención, me comprometo a comunicarlo de inmediato a la UT DISPROEL 2023, y/o a las sociedades que la conforman, y/o a mi contratante directo.
                        <br/>
                        <br/>
                        -Que, con la firma del presente documento, se entiende que conozco, declaro y acepto que la UT DISPROEL 2023, y/o las sociedades que la conforman, y/o mi contratante directo, están en la obligación legal de solicitar las aclaraciones que estimen pertinentes en el evento en que se presenten circunstancias con base en las cuales ésta(s), pueda(n) tener dudas razonables sobre mis operaciones, así como del origen de mis activos, evento en el cual suministraré las aclaraciones que sean del caso, si estas no son satisfactorias, a juicio de ella(s), autorizo a dar por terminada cualquier relación contractual o negocio jurídico, sin lugar al pago de ninguna indemnización. Autorizo a la UT DISPROEL 2023, y/o las sociedades que la conforman, y/o a mi contratante directo, para que con fines estadísticos y de información entre compañías y entre estas y las autoridades competentes, consulte, almacene, administre, verifique, transfiera o reporte a las Centrales de Datos que considere necesario o a cualquier otra entidad autorizada, la información suministrada en cualquier momento y la del presente documento en los términos y durante el tiempo que los sistemas de bases de datos, las normas, leyes y autorizados establezcan.
                        <br/>
                        <br/>
                        <b>AUTORIZACIÓN TRATAMIENTO DATOS PERSONALES</b>
                        <br/>
                        <br/>
                        Autorizo a la UT DISPROEL 2023, y/o las sociedades que la conforman, y/o a mi contratante directo a consultar, obtener, almacenar, usar, circular, suprimir y, en general, realizar el tratamiento de datos personales de mi información financiera, datos personales, comerciales, con el fin de cumplir con las normas legales, establecer y mantener la relación contractual, actualizar la información y prevenir el lavado de activos, financiación del terrorismo y la proliferación de armas de destrucción masiva, garantizar la ejecución del contrato, para la celebración de contratos futuros u otros fines comerciales. Las políticas de tratamiento de datos personales aplicables serán las establecidas por cada uno de los integrantes de la UT DISPROEL 2023 y/o mi contratante directo, las cuales podrán ser consultadas a través de los medios establecidos por cada uno de ellos.
                        <br/>
                        <br/>
                        <b>EXTENSIÓN DE COMPROMISOS</b>
                        <br/>
                        <br/>
                        Me obligo a que cada uno de mis contratistas y subcontratistas suscriban el presente documento, y remitiré a la UT DISPROEL 2023, y/o las sociedades que la conforman y/o a mi contratante directo, copia de los mismos.
                        <br/>
                        <br/>
                        <b>HECHOS SOBREVINIENTES</b>
                        <br/>
                        <br/>
                        Con la firma del presente documento y en el caso de que sobrevenga con posterioridad algún hecho que haga que una de las anteriores declaraciones o compromisos cambie o no se ajuste a la realidad, me comprometo a informarlo de forma inmediata a la UT DISPROEL 2023, y/o las sociedades que la conforman, y/o a mi contratante directo, a quienes faculto expresamente a tomar las medidas que sea necesarias sin lugar al pago de ninguna indemnización de su parte. Firmo en señal de aceptación y de garantía de que las presentes declaraciones son verídicas.
                    </p>
                    <p style={{textAlign: "justify", textJustify: "inter-word"}}>
                        <br/>
                        <br/>
                        <h1>Política de Privacidad Grupo Prosegur</h1>
                        <br/>
                        <br/>
                        <b>1.GRUPO PROSEGUR</b>
                        <br/>
                        <br/>
                        El Grupo PROSEGUR (en adelante, “PROSEGUR") es un referente global del sector de la seguridad privada. Estamos presentes en los 5 continentes, en los que nuestro principal reto consiste en ofrecer a nuestros clientes servicios de alto valor añadido y ocupar una posición destacada a nivel mundial en el sector de la seguridad privada y externalización de servicios en cada mercado. 
                        <br/>
                        Para ello, buscamos una implantación geográfica sólida y asentada sobre un modelo de negocio de probada eficacia. Además de nuestra concepción global, también actuamos de forma local, y para ello actuamos atendiendo a las particularidades de cada mercado, y respetando en todo momento el marco normativo y regulatorio del país en el que operamos, ya que nuestro sector está altamente regulado, cuya regulación varía en función de la legislación de cada país.
                        <br/>
                        A través de nuestras cinco líneas de negocio en PROSEGUR proporcionamos a empresas y particulares servicios de Alarmas, Seguridad, Ciberseguridad, Gestión de Efectivo “CASH”, Externalización de Servicios “Business Process Outsourcing o BPO”, ofreciéndoles a sus clientes una seguridad de confianza basada en las soluciones más avanzadas del mercado. 
                        <br/>
                        -Servicio de Alarmas: PROSEGUR cuenta con una amplia gama de productos y servicios que contribuyen a mejorar la seguridad y tranquilidad de familias y comercios. En PROSEGUR ofrecemos servicios se instalación y mantenimiento de sistemas de alarmas personalizados a las necesidades del cliente y disponemos de los sistemas de alarmas más avanzados, que incluyen desde alarmas con video-verificación hasta la automatización de espacios, lo que nos convierte en un referente en el mundo.
                        <br/>
                        -Servicio de Seguridad: PROSEGUR presta servicios integrales de seguridad de alto valor añadido mediante la combinación de las últimas tecnologías con los mejores profesionales. La compañía mantiene una permanente orientación hacia la innovación tecnológica que le permite integrarse en la cadena de valor de cada segmento de negocio. Dentro del negocio de Seguridad, se encuentran incluidos tanto los servicios de vigilancia tradicional, como los relacionados con servicios auxiliares.
                        <br/>
                        -Servicio de Gestión de Efectivo: "CASH" PROSEGUR cubre el ciclo completo del efectivo y procesa más de 450.000 millones de euros cada año. Opera en más de 500 centros de quince países y gestiona más de 100.000 cajeros automáticos. PROSEGUR es una compañía líder a nivel mundial que provee servicios de logística y gestión de efectivo y externalización de servicios a instituciones financieras, comercios, organismos gubernamentales y bancos centrales, fábricas de moneda, joyerías, así como a otras actividades comerciales alrededor del mundo. Fundamentalmente a los sectores bancario y de distribución.
                        <br/>
                        -Servicio de Ciberseguridad: PROSEGUR, a través de su marca Cipher, es una empresa altamente reconocida en el sector de la Ciberseguridad. Presta una amplia variedad de servicios: Detección y Respuesta Gestionada (MDR), Servicios de Seguridad Gestionada (MSS), Servicios de Ciberinteligencia (CIS), Servicios de Red Team (RTS), Gestión, Riesgo y Cumplimiento (GRC) e integración de Tecnología de Ciberseguridad (CTI). Estos servicios cuentan con el soporte de Cipher Labs, un laboratorio de élite en desarrollo e investigación en Ciberinteligencia y amenazas, y con cinco Centros de Operaciones de Seguridad (SOC) en 24×7. Entre sus clientes se encuentran desde empresas de tamaño medio hasta empresas de fama internacional y agencias gubernamentales, con innumerables casos de éxito.
                        <br/>
                        -Servicio BPO "Business Process Outsourcing": PROSEGUR, a través de su línea de negocio BPO ofrece a sus clientes soluciones de negocio que permiten la externalización de servicios, aportando soluciones innovadoras y apostando por las nuevas capacidades tecnológicas. En PROSEGUR ayudamos a nuestros socios a mejorar sus operativas y a adelantarse al mercado, afrontando los procesos más complejos y mejorando la experiencia de cliente.
                        <br/>
                        Por ello, hemos diseñado una propuesta de valor diferencial que tiene como objetivo principal apalancar el conocimiento adquirido a lo largo de los años y adaptarlo a las nuevas tendencias tecnológicas y de digitalización. En PROSEGUR pretendemos dotar a nuestros clientes de la máxima agilidad, trazabilidad y visibilidad en todas las tareas tramitadas en los centros de trabajo.
                        <br/>
                        PROSEGUR, además de ser un referente a nivel mundial en la prestación de servicios de seguridad, se encuentra firmemente comprometido con la sociedad y con los más desfavorecidos, y es por ello que contamos con una Fundación sin ánimo de lucro, FUNDACIÓN PROSEGUR, a través de la cual se materializa el compromiso de PROSEGUR por contribuir al progreso de las regiones más desprotegidas en las que opera, apoyando la educación como motor indiscutible de cambio, fomentando la inclusión de personas con discapacidad intelectual, e impulsando acciones de voluntariado que canalizan la solidaridad de los profesionales de nuestra compañía.
                        <br/>
                        <br/>
                        <b>2. OBJETO</b>
                        <br/>
                        <br/>
                        PROSEGUR es una compañía multinacional que se encuentra firmemente comprometida con la protección de los datos de carácter personal y en concreto con el cumplimiento de las normativas que resultan de aplicación en materia de privacidad, con el objetivo principal de proteger los derechos y libertades fundamentales de las personas físicas que intervienen en el ejercicio de su actividad.
                        <br/>
                        PROSEGUR con el fin de cumplir con este compromiso y de dar cumplimiento a sus obligaciones en materia de Protección de Datos que resulten exigibles atendiendo a la regulación de los distintos ámbitos geográficos en los que opera, ha redactado la presente Política de Privacidad del Grupo PROSEGUR, que tiene por objeto informarle acerca de cómo tratamos sus datos personales y como protegemos la información cuando interactuamos con usted.
                        <br/>
                        <br/>
                        <b>3. ALCANCE</b>
                        <br/>
                        <br/>
                        La presente Política de Privacidad proporciona información sobre el tratamiento de datos personales llevado a cabo por parte de las Entidades que conforman el Grupo Prosegur, dedicadas a las cinco líneas de negocio descritas en el apartado anterior, en relación con el uso que haga usted de los sitios web y aplicaciones corporativas, así como en el contexto de la relación comercial, precontractual, contractual, accionarial y/o laboral existente entre usted y PROSEGUR. 
                        <br/>
                        Por tanto, la presente Política de Privacidad refleja la información relativa al tratamiento de datos personales con respecto a las siguientes categorías de interesados:
                        <br/>
                        <br/>
                        Visitantes y usuarios de los distintos sitios webs corporativos de PROSEGUR, así como de aplicaciones informáticas o software móvil.
                        <br/>
                        <br/>
                        Clientes y potenciales clientes.
                        <br/>
                        <br/>
                        Proveedores y potenciales proveedores.
                        <br/>
                        <br/>
                        Empleados.
                        <br/>
                        <br/>
                        Candidatos.
                        <br/>
                        <br/>
                        Accionistas.
                        <br/>
                        <br/>
                        Tal y como se utiliza en esta Política de privacidad, "datos personales" significa toda información sobre una persona física identificada o identificable. Esto podría incluir, entre otros, su nombre y apellidos, número de teléfono, dirección, dirección de correo electrónico, o información recopilada a través de sus interacciones con nosotros mediante nuestros sitios web.
                        <br/>
                        <br/>
                        4. <b>RESPONSABLE DEL TRATAMIENTO</b>
                        <br/>
                        <br/>
                        Las Entidades que conforman el Grupo PROSEGUR son las Responsables del Tratamiento de los datos personales que nos facilite. Puede consultar los datos identificativos, así como los datos de contacto de la Entidad Responsable del Tratamiento de sus datos personales, seleccionando el País y Servicio correspondiente del Grupo PROSEGUR, a través del siguiente link: https://www.prosegur.com/politica-privacidad o https://www.prosegurcash.com/politica-privacidad.
                        <br/>
                        <br/>
                        5. <b>FINALIDADES Y LEGITIMACIÓN</b>
                        <br/>
                        <br/>
                        Usted queda informado de que todos los datos que PROSEGUR le solicite o le pueda solicitar son necesarios para las finalidades del tratamiento de sus datos y el hecho de no facilitarlos supondría la imposibilidad de poder contactar con usted o gestionar la solicitud que realice a PROSEGUR.
                        <br/>
                        Asimismo, PROSEGUR se reserva la facultad de no responder o tramitar solicitudes que no incluyan los datos que le sean solicitados.
                        <br/>
                        Usted garantiza la veracidad de los datos de carácter personal proporcionados a PROSEGUR. PROSEGUR puede solicitarle periódicamente la revisión y actualización de los datos personales que sobre usted conserve.
                        <br/>
                        PROSEGUR tratará sus datos personales, ya sea por su condición cliente o potencial cliente, proveedor o potencial proveedor, empleado, candidato, o accionista, sobre la base de:
                        <br/>
                        La obtención de su consentimiento.
                        <br/>
                        La relación precontractual o contractual existente entre usted y PROSEGUR.
                        <br/>
                        El cumplimiento de una obligación legal.
                        <br/>
                        El interés legítimo.
                        <br/>
                        Puede consultar las diferentes finalidades con las que se tratan sus datos personales y las bases que legitiman el tratamiento de los mismos, accediendo a la Política de Privacidad correspondiente, seleccionando el País y Servicio del Grupo PROSEGUR, a través del siguiente link: https://www.prosegur.com/politica-privacidad o https://www.prosegurcash.com/politica-privacidad.
                        <br/>
                        <br/>
                        6. <b>POTENCIALES DESTINATARIOS DE SUS DATOS</b>
                        <br/>
                        <br/>
                        Los datos personales que usted proporcione a PROSEGUR podrán ser comunicados a las siguientes categorías de destinatarios:
                        <br/>
                        Terceras partes a las que PROSEGUR está obligada a transmitir información, como autoridades públicas, fuerzas y cuerpos de seguridad, jueces y tribunales, con la finalidad de dar cumplimiento con los requerimientos de dichas autoridades y la normativa aplicable, en su caso.
                        <br/>
                        Entidades que forman parte del Grupo PROSEGUR, como consecuencia de la centralización de procesos administrativos e informáticos existente dentro del Grupo PROSEGUR, sobre la base del interés legítimo de PROSEGUR.
                        <br/>
                        Entidades que forman parte del Grupo PROSEGUR y terceras entidades con las que PROSEGUR haya suscrito acuerdos de colaboración, con la finalidad de remitirle comunicaciones comerciales, sobre la base de su consentimiento.
                        <br/>
                        Auditores, sobre la base legal del cumplimiento de una obligación legal.
                        <br/>
                        Potenciales compradores de las acciones/activos/negocios de PROSEGUR, para que puedan revisar el objeto de su potencial compra y con sujeción a obligaciones de confidencialidad, sobre la base legal del interés legítimo de PROSEGUR.
                        <br/>
                        PROSEGUR garantiza la adopción de las medidas contractuales, técnicas y organizativas adecuadas, en la realización de comunicaciones de datos a terceros, destinadas a garantizar la protección de sus datos personales, de acuerdo con la presente Política de Privacidad, y de conformidad con lo dispuesto en la normativa de privacidad que resulta de aplicación.
                        <br/>
                        Asimismo, le informamos que el Grupo PROSEGUR puede contar con Entidades situadas fuera del Espacio Económico Europeo. En esos casos, PROSEGUR exige que dichas sociedades cumplan con las medidas diseñadas para proteger los datos personales establecidas en un contrato vinculante, tales como las Cláusulas Contractuales Tipo adoptadas por la Comisión Europea, o instrumento jurídico equivalente, salvo en los casos en que la Comisión Europea haya determinado que el país donde se encuentra ubicado el importador proporciona un nivel adecuado de protección de datos personales.
                        <br/>
                        <br/>
                        7. <b>CONSERVACIÓN DE LOS DATOS</b>
                        <br/>
                        <br/>
                        PROSEGUR tratará sus datos personales durante el tiempo estrictamente necesario para cumplir con la finalidad correspondiente, conservándose posteriormente debidamente bloqueados, durante el tiempo en que pudieran surgir responsabilidades derivadas del tratamiento.
                        <br/>
                        Los criterios que PROSEGUR utiliza para fijar los plazos de conservación de sus datos han sido determinados de acuerdo con los requisitos establecidos en la legislación, reglamentos y directrices normativas locales que resultan de aplicación, atendiendo a la tipología de datos de carácter personal que resultan objeto de tratamiento, así como los requisitos operacionales de PROSEGUR, relacionados con la correcta gestión de la relación existente con las diferentes categorías de interesados.
                        <br/>
                        No obstante lo anterior, en el caso de que existiera un procedimiento procesal en curso en relación con usted, sus datos podrán ser conservados durante el tiempo adicional necesario hasta que se obtenga una resolución judicial firme. Una vez terminado dicho periodo, sus datos serán eliminados.
                        <br/>
                        Usted podrá consultar el periodo de conservación de sus datos personales en la sección “Conservación de los Datos” de la Política de Privacidad correspondiente, seleccionando el País y Servicio del Grupo PROSEGUR, a través del siguiente link: https://www.prosegur.com/politica-privacidad o https://www.prosegurcash.com/politica-privacidad.
                        <br/>
                        <br/>
                        8. <b>SUS DERECHOS DE PROTECCIÓN DE DATOS</b>
                        <br/>
                        <br/>
                        Usted puede revocar en cualquier momento el consentimiento otorgado para las actividades de tratamiento de datos personales por parte de PROSEGUR, así como ejercitar, si está interesado en ello, sus derechos de protección de datos. A este respecto, podrá ponerse en contacto cuando así lo estime con PROSEGUR solicitando:
                        <br/>
                        Confirmación de si se están tratando o no datos personales que le conciernen y, en tal caso, derecho de acceso a los datos personales.
                        <br/>
                        Rectificación de los datos personales inexactos que le conciernan o que se completen los datos personales que sean incompletos.
                        <br/>
                        Supresión de los datos personales que le conciernan.
                        <br/>
                        Limitación del tratamiento de sus datos personales.
                        <br/>
                        Recepción de los datos personales que le incumban, que haya facilitado a PROSEGUR, en un formato estructurado, de uso común y lectura mecánica, y su transmisión a otro Responsable del Tratamiento.
                        <br/>
                        Oposición, en cualquier momento, a que datos personales que le conciernan sean objeto de un tratamiento.
                        <br/>
                        No ser objeto de decisiones automatizadas, incluida la elaboración de perfiles.
                        <br/>
                        Puede hacerlo mediante solicitud dirigida a PROSEGUR en la dirección física o electrónica establecida en la sección “Sus derechos de protección de datos” de la Política de Privacidad correspondiente, seleccionando el País y Servicio del Grupo PROSEGUR, a través del siguiente link:https://www.prosegur.com/politica-privacidad o https://www.prosegurcash.com/politica-privacidad.
                        <br/>
                        Por último, en el caso que usted desee más información acerca de sus derechos en materia de protección de datos o precise presentar una reclamación, podrá dirigirse a la Autoridad de Control de protección de datos competente, con el fin de salvaguardar sus derechos.
                        <br/>
                        <br/>
                        9. <b>DATOS DE MENORES</b>
                        <br/>
                        <br/>
                        Los servicios de PROSEGUR no están dirigidos a menores de edad y, en consecuencia, PROSEGUR no lleva a cabo tratamientos de datos personales relativos a menores de 16 años, excepto en circunstancias muy limitadas, tales como las acciones de voluntariado de la FUNDACIÓN PROSEGUR.
                        <br/>
                        En estos casos, PROSEGUR garantiza la obtención del consentimiento del titular de la patria potestad o tutela sobre el menor, y el tratamiento de datos solo se llevará a cabo en la medida en que se dio o autorizó.
                        <br/>
                        Si usted cree que hemos recogido por error o no intencionalmente datos personales de un menor de 16 años, a través de nuestros formularios, sin el consentimiento apropiado, por favor, notifíquelo a PROSEGUR para que podamos proceder, de forma inmediata, a la eliminación de la información de nuestros servidores y realizar cualquier otro tipo de acción correctiva que resulte adecuada.
                        <br/>
                        <br/>
                        10. <b>SEGURIDAD DE LOS DATOS</b>
                        <br/>
                        <br/>
                        PROSEGUR cuenta con políticas apropiadas y medidas técnicas y organizativas para salvaguardar y proteger sus datos personales contra el acceso ilegal o no autorizado, pérdida o destrucción accidental, daños, uso y divulgación ilegal o no autorizada.
                        <br/>
                        Asimismo, tomaremos todas las precauciones razonables para garantizar que nuestro personal y los empleados que tienen acceso a sus datos personales hayan recibido la capacitación adecuada.
                        <br/>
                        En todo caso, se informa al usuario que cualquier transmisión de datos a través de Internet no es completamente segura y, como tal, se realiza bajo su propio riesgo. Aunque haremos nuestro mejor esfuerzo para proteger sus datos personales, PROSEGUR no puede garantizar la seguridad de los datos personales transmitidos a través de nuestro Sitio Web.
                        <br/>
                        Usted podrá obtener una copia de las medidas exigidas por PROSEGUR poniéndose en contacto con el Delegado de Protección de Datos.
                        <br/>
                        <br/>
                        11. <b>INFORMACIÓN CORPORATIVA</b>
                        <br/>
                        <br/>
                        Sede corporativa de PROSEGUR: Calle Pajaritos, 24. 28007 Madrid (España).
                        <br/>
                        Contacto Delegado de Protección de Datos: dpo@prosegur.com.
                        <br/>
                        <br/>
                        12. <b>ACTUALIZACIÓN DE LA POLÍTICA DE PRIVACIDAD</b>
                        <br/>
                        <br/>
                        Usted podrá encontrar disponible la versión más actualizada de la Política de Privacidad del Grupo PROSEGUR, de forma permanente en la página web de PROSEGUR, seleccionando el País y Servicio del Grupo PROSEGUR, con el fin de que pueda consultarla cuando lo estime oportuno.
                        <br/>
                        No obstante lo anterior, le informamos de que su contenido puede ser objeto de modificación, por ejemplo, para cumplir con los requisitos legales o para satisfacer las cambiantes necesidades del negocio. En caso de que tengan lugar modificaciones relevantes, se lo notificaremos a través del canal que resulte más apropiado.
                    </p>
                </DialogContent>
                <DialogActions>
                        <Button onClick={()=>setOpenTerminos(false)} color="secondary">
                            Salir
                        </Button>
                </DialogActions>
            </Dialog>
        </>
    )
    
    return (
        <>
            {registerButton()}
            {ShowAlert(snackbarProps, setSnackbarProps)}
            <Loader/>
        </>
    )
}

export default InicioRegistro;