import { makeStyles } from '@material-ui/core/styles';
// Colors
import { green, yellow, blue, red, orange, purple, blueGrey } from '@material-ui/core/colors';
// Icons
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import ListIcon from '@material-ui/icons/List';
import FaceIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

export const Icons = 
{
    TransferWithinAStation  : TransferWithinAStationIcon,
    DescriptionTwoTone      : DescriptionTwoToneIcon,
    VisibilityOff           : VisibilityOffIcon,
    AttachMoney             : AttachMoneyIcon,
    Visibility              : VisibilityIcon,
    Assignment              : AssignmentIcon,
    SwapHoriz               : SwapHorizIcon,
    Launch                  : LaunchIcon,
    money                   : MonetizationOnIcon,
    Edit                    : EditIcon,
    Info                    : InfoIcon,
    List                    : ListIcon,
    Persona                 : FaceIcon,
    People                  : PeopleIcon
}

export const tableStyles = makeStyles((theme) => ({
    root: {
        padding: "0 1em",
        marginBottom: "2em"
    },
    collapsibleRow: {
        border: "none",
        padding: 0,
        borderTop: "1px solid #eee",
        boxShadow: "inset 0px 0px 10px 0px rgba(130, 130, 130, 0.3)"
    },
    maxHeight: {
        maxHeight: 440
    },
    // Tabla vacía o sin resultados
    emptyTable: {
        textAlign: "center",
    },
    // Oculta el texto de accesibilidad al ordenar la tabla
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    // Columna de opciones de la tabla
    options: {
        width: "10%"
    },
    // Iconos de las opciones de la tabla
    icons: {
        cursor: 'pointer',
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: theme.spacing(1)
    },
    // Colores para los iconos de las opciones de la tabla
    yellow: {
        color: theme.palette.getContrastText(yellow[500]),
        backgroundColor: yellow[500],
    },
    green: {
        color:"#fff",
        backgroundColor: green[500],
    },
    blue: {
        color:"#fff",
        backgroundColor: blue[500],
    },
    red: {
        color:"#fff",
        backgroundColor: red[500],
    },
    orange: {
        color:"#fff",
        backgroundColor: orange[500],
    },
    purple: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
    },
    blueGrey: {
        color: theme.palette.getContrastText(blueGrey[500]),
        backgroundColor: blueGrey[500],
    },
    // Estilos de los timestamps
    timeStamps: {
        fontSize: "0.7rem"
    }
}));