import { productosAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getProductosEvento = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getProductosEvento`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getProductosByEventos = async (eventos) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getProductosByEventos`,{eventos}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createProductosEvento = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createProductosEvento`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const updateProductosEvento = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateProductosEvento`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}