import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import "../../../src/App.css";

import TablaReteica from './TablaReteica';
import FormularioReteica from './FormularioReteica';
import { getReteica } from '../../core/apiReteica';
import { getEventos } from '../../core/apiEventos';
import { FilterEventoDept } from '../empresasOperadoras/FilterEvento';
import { isAuthenticated } from '../../core/sessionAPI';
import axios from 'axios';
import { reteicaAll } from '../../core/apiReportes';
import fileDownload from 'react-file-download';
import ShowAlert from '../../../src/components/shared/Snackbar';

import CargueReteica from '../User/Admin/Maestros/CargueReteica';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogReteica({open, close, idEvento, eventoName}){
    const {user} = isAuthenticated()
    const classes = useStyles();
    const theme = useTheme();

    const [departamentos, setDepartamentos] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState(false);
    const [departamentoSeleccioando, setDepartamentoSeleccionado] = useState(null)
    const [eventos, setEventos] = useState([])
    const [ultimoEvento, setUltimoEvento] = useState([])
    const [dataFilter, setDataFilter] = useState([]);
    const [deptFilter, setDeptFilter] = useState([]);
    const [eventoSelected, setEventoSelected] = useState(null);
    const [deptSelected, setDeptSelected] = useState(null);

    const [openDialogCargue, setOpenDialogCargue] = useState(false);

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadEventos = async () => {
        const res = await getEventos()
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEventos([]);
            setUltimoEvento([])
        }else{
            setEventos(res.data);
            setUltimoEvento(res.data[0]._id)
        }
    }

    const loadDeptFilter = async () => {
        showLoader();
        const res = await axios.post(API + "/getDataFromEntity", {entity: 'Departamento', condition: {delete:false}})

        if (res.error){
            setSnackbarProps("e" + res.error);
            setDeptFilter([]);
        }else{
            setDeptFilter(res.data);
        }
        
        hideLoader();;
    }

    const loadDataDepartamentos = async () => {
        showLoader();
        const res = await getReteica()

        if (res.error){
            setSnackbarProps("e" + res.error);
            setDepartamentos([]);
            setDataFilter([])
        }else{
            setDepartamentos(res.data);
            setDataFilter(res.data)
        }
        
        hideLoader();;
    }

    useEffect(() => {
        async function inicializar() {
            await loadDataDepartamentos()
            await loadEventos()
            await loadDeptFilter()
        }
        inicializar();
    }, []);

    const handleCrearEditarDepartamento= (tipo, deptSeleccionado) => {
        setDepartamentoSeleccionado(deptSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = () => {
        setOpenForm(false)
        setOpenDialogCargue(false);
    }

    const reporteReteica = async () => {
        showLoader();
        let res
        if( eventoSelected !== eventos.length + 2)
            res = await reteicaAll(eventoSelected || eventos[0]?._id);
        else
            setSnackbarProps("e" + 'Debes seleccionar un evento');
        if (res?.error){
            setSnackbarProps("e" + res.error);
        }else if(res?.data){
            fileDownload(res.data, `REPORTE_PAGO_SUPERVISORES_${eventoName}.xlsx`)
        }
        hideLoader();
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar RETEICA
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={2}>
                                        <Filter
                                            source={departamentos}
                                            searchBy="reteica"
                                            setTableData={setDataFilter}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FilterEventoDept
                                            data={departamentos}
                                            eventos={eventos}
                                            dept={deptFilter}
                                            setData={setDataFilter}
                                            defaultIdEventos={ultimoEvento}
                                            defaultIdDept={deptFilter[0]?._id}
                                            setDepartamento={setDeptSelected}
                                            setEvento={setEventoSelected}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Grid item>
                                            <Button 
                                                className='littleTextButton'
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => handleCrearEditarDepartamento("crear", null)}>
                                                Configurar RETEICA Municipio
                                            </Button>    
                                        </Grid>   
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Button 
                                            className='littleTextButton'
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                            startIcon={<DownloadIcon />}
                                            onClick={() => reporteReteica()}>
                                            Descargar reporte
                                        </Button>  
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Button 
                                            className='littleTextButton'
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                            startIcon={<FileOpenIcon />}
                                            onClick={() => {
                                                setOpenDialogCargue(true);
                                            }}>
                                            Cargar configuración RETEICA
                                        </Button>  
                                    </Grid>                                    
                                </Grid>
                            </Box>     
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>   
                            <TablaReteica
                                departamentos = {dataFilter}
                                handleEdit = {handleCrearEditarDepartamento}
                                reloadTable = {loadDataDepartamentos}
                                setSnackbarProps ={setSnackbarProps}
                            />
                        </Paper>
                    </Box>    
                    {ShowAlert(snackbarProps, setSnackbarProps)}             
                </Box>               
            </Dialog>

            <FormularioReteica
                eventos={eventos}
                open = {openForm}
                close = {closeForm}
                mode={modo}
                data={departamentoSeleccioando}
                eventoSelected={eventoSelected}
                deptSelected={deptSelected}
                refreshTable={loadDataDepartamentos}
            />

            <CargueReteica 
                open={openDialogCargue}
                close={closeForm}
                refreshTable={loadDataDepartamentos}
            />
        </>
    );
}