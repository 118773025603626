import { settingsAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios"

const noWorkingDaysAPI = API + "/noWorkingDays";

// Deshabilita el día especificado
export const disableDay = async (date) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${noWorkingDaysAPI}/disable/`, {date}, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const enableDay = async (date) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${noWorkingDaysAPI}/enable`, {date}, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

// Obtiene el listado de días deshabilitados
export const getDisabledDays = async () =>
{
    if (isAuthenticated())
    {
        const res = await axios.get(`${noWorkingDaysAPI}/getDays/`, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

// Obtiene el listado de días deshabilitados
export const getTerminosCondiciones = async () =>
{
    if (isAuthenticated())
    {
        const res = await axios.get(`${API}/getTerminosCondiciones`, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getTerminosCondicionesUsers = async () =>
{
    if (isAuthenticated())
    {
        const res = await axios.get(`${API}/getTerminosCondicionesUsers`, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getTerminosPublicCondicionesUsers = async () =>
{
    var token = null;
    if (isAuthenticated()){
        token = isAuthenticated().token;        
    }

    const res = await axios.get(`${API}/getPublicTerminosCondiciones`, {
        headers: {
            token: token
        }
    });

    return handleResponse(res);
}

// Obtiene el listado de días deshabilitados
export const setTerminosCondiciones = async (terminosCondiciones) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/setTerminosCondiciones`,{terminosCondiciones:terminosCondiciones}, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

// Obtiene el listado de días deshabilitados
export const acceptTerminosCondicionesUser = async (versionTC) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/acceptTerminosCondicionesUser`, {versionTC:versionTC}, {
            headers: {
                "token": isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}
