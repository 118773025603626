import { ciudadSucursalAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getCiudadSucursales = async (sucursal) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getCiudadSucursales`,{sucursal}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createCiudadSucursal = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createCiudadSucursal`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}