import { divipolAPI as API } from "./apiUrls";
import { controlsAPI } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const procesarArchivoSupervisores = async(fileSelect, idEvento) =>
{
    if (isAuthenticated()){ 
        // 1. Subir archivo a AWS y esperar la URL
        const fileData = new FormData() 
        fileData.append('file', fileSelect);

        const uploadedFile = await axios.post(controlsAPI + "/uploadFile", fileData)
        const { url } = uploadedFile.data.file

        // 2. Ejecutar el worker
        const data = { idEvento, url }
        const res = await axios.post(API + "/procesarArchivoSupervisores", data, {
            headers: {
                token: isAuthenticated().token,
            },
        });
        return res;              
    }
}

export const procesarArchivoDivipol = async(fileSelect, idEvento, setProgressUpload) =>
{
    if (isAuthenticated()){ 
            //Se inicia la subida directamente del archivo
        const data = new FormData() 
        data.append('file', fileSelect);
        data.append('idevento', idEvento);

        var res = await axios.post(API + "/procesarArchivoDivipol", data, {
            headers: {
                token: isAuthenticated().token,
                idEvento: idEvento       
            },
            onUploadProgress: ProgressEvent => {
                setProgressUpload(ProgressEvent.loaded / ProgressEvent.total*100);
            }
        });
        
        return handleResponse(res);              

    }
}

export const getDivipol = async (idEmpresa) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getDivipol`,{empresa:idEmpresa}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createDivipol = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createDivipol`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const updateDivipol = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateDivipol`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const procesarCopiaDatos = async (origen, destino) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/procesarCopiaDatos`, {origen, destino}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}