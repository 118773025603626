import React, { useState, useEffect} from 'react';
// Componentes
import {
    Grid, 
    Box, 
    Paper, 
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
    makeStyles,
    Slide,
    Dialog,
    DialogContent,
    AppBar,
    IconButton,
    Typography,
    Toolbar,
    Tooltip} from '@material-ui/core';
import Filter from "../shared/Filters";
import {getHistorialPuesto} from "../../core/apiEventos";
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";
import CustomTableHead from "../shared/Tablas/CustomTableHead";

// Validaciones
import CloseIcon from '@material-ui/icons/Close';
import Paginacion from "../shared/Tablas/Paginacion";

import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";
import moment from "moment";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogHistorialMarcacion({open, close, puesto, producto, estado, evento, tipo}){
    const classes = useStyles();

    const [eventos, setEventos] = useState([]);        
    const [snackbarProps, setSnackbarProps] = useState();

    const [openIm, setOpenIm] = useState(false);
    const [image, setImage] = useState("/img/image.jpg");
    const [fechaReporte, setFechaReporte] = useState();
    
    const loadHistoricoEventos = async () => {
        showLoader();
        
        const res = await getHistorialPuesto(puesto, producto, estado, evento, tipo);

        if (res.error){
            setSnackbarProps("e" + res.error);
            setEventos([]);
        }else{
            setEventos(res.data);
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar() {
            await loadHistoricoEventos()
        }
        if(open){
            inicializar();
        }
    }, [open]);

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Historial de Eventos { puesto ? puesto.nombreDelPuesto:'' } ({ puesto ? puesto.codigoDivipol:'' })
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box p={1}>
                                <Paper elevation={1}>
                                    <Box p={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Filter
                                                    source={eventos}
                                                    searchBy="eventosHistoricos"
                                                    setTableData={setEventos}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>     
                            <Box p={1}>
                                <Paper elevation={1}>
                                    <TablaEventos
                                        eventos={eventos} 
                                        estado={estado}   
                                        selectIm={(im, fecha)=>{
                                            setImage(im);
                                            setFechaReporte(fecha);
                                            setOpenIm(true);
                                        }}
                                    />
                                </Paper>
                            </Box> 
                        </Grid>
                    </Grid>
                </Box>               
            </Dialog>
            {ShowAlert(snackbarProps, setSnackbarProps)}
            <ModalImage 
                open={openIm}
                close={()=>{
                    setOpenIm(false);
                }}
                image={image}
                fecha={fechaReporte}
            />
        </>
    );
}

export function TablaEventos ({eventos, estado, selectIm})
{
    const headCells = 
    [
        { id: 'options', label: 'Opciones', align: 'left', sortable: false },
        { id: 'item', align: "left", label: '#', sortable: true },
        { id: 'puesto', label: 'Puesto', align: 'left', sortable: true },
        { id: 'puestoName', label: 'Nombre Puesto', align: 'left', sortable: true },
        { id: 'fechaEvento', label: 'Fecha', align: 'left', sortable: true },
        { id: 'tipo', label: 'Tipo Evento', align: 'left', sortable: true },
        { id: 'responsable', label: 'Usuario Responsable', align: 'left', sortable: true }
    ];

    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 50;
	const rowsPerPageOptionsDefault = [50, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() => {
        setRows(Array.from(eventos, (evento, index) =>{

            var responsableMarcacion = "";

            if(evento.gerente){
                responsableMarcacion = evento.gerente.name + " "  + evento.gerente.firstLastname + " (" + evento.gerente.telefono + ")"
            }else if(evento.supervisor){
                console.log(evento.supervisor);
                responsableMarcacion = evento.supervisor.firstName + " "  + evento.supervisor.firstLastname + " (" + evento.supervisor.telefono + ")"
            }else if(evento.createBy){
                responsableMarcacion = evento.createBy.name + " "  + evento.createBy.lastname + " (" + evento.createBy.telefono + ")"                
            }else{
                responsableMarcacion = "N/A"
            }

            return {
                item: index + 1,
                puesto: evento.divipolRegistro.codigoDivipol,
                puestoName: evento.divipolRegistro.nombreDelPuesto,
                fechaEvento: evento.createdAt,
                valor: evento.valor,
                responsable: responsableMarcacion,
                url: evento.url ? evento.url:""
            };
        }));
    }, [eventos]);

    return (
		<>
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}			
					/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron registros
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className={tableClasses.options}>
									{row.url && row.url != "" && <Box display="flex" justifyContent="left" alignItems="left">
                                        <Avatar
											className={`${tableClasses.icons} ${tableClasses.yellow}`}
											variant="rounded"
											onClick={() => {
                                                selectIm(row.url ,moment(row.fechaEvento).format("DD/MM/YYYY HH:mm:ss"));
                                            }}>
											<Tooltip title="Ver Evidencia">
												<PhotoCameraBackIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>
									</Box>}
								</TableCell>
								<TableCell align="left">{row.item}</TableCell>
                                <TableCell align="left">{row.puesto}</TableCell>
                                <TableCell align="left"><b>{row.puestoName}</b></TableCell>
                                <TableCell align="left">{row.fechaEvento?moment(row.fechaEvento).format("DD/MM/YYYY HH:mm:ss"):"--"}</TableCell>
                                <TableCell align="left">
                                    <div className={row.valor ? "productoEntregado":"entregaAnulada"}>
                                        {row.valor ? (estado == "entrega" ? "Entregado":"Recolectado"): ((estado == "entrega" ? "Entregada":"Recolección") + " Anulada")}
                                    </div>
                                </TableCell>
                                <TableCell align="left">{row.responsable}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
    	</>
	);
}

export function ModalImage ({open, close, image, fecha}){
    const classes = useStyles();
    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Fecha del Reporte { fecha }
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <img className="styleImage" src={image} />
                </Box>               
            </Dialog>
        </>
    );
}