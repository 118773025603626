import React, { useState, useEffect } from 'react'

//Import estructura de navegación, y renderizado de la landing page
import { useWrapperStyles } from '../../../../assets/js/wrapperStyles';
import NavContainer from '../../../shared/NavContainer'
import { onUserNavigate, setTokenInterval } from "../../../../helpers/CheckSession";
import { refreshToken, isAuthenticated } from "../../../../core/sessionAPI";

// MUI Components
import { 
    Button,
    Paper,
    Box,
    Grid,
    Divider,
} from '@material-ui/core'

// Mis Componentes
import ShowAlert from '../../../shared/Snackbar';
import Loader from '../../../shared/Loader';
import DialogDepartamento from '../../../departamentos/DialogDepartamentos';
import { Stack } from '@mui/material';
import DialogEmpresasOperadoras from '../../../empresasOperadoras/DialogEmpresaOperadora';
import DialogServicio from '../../../servicios/DialogServicio';
import DialogSucursal from '../../../sucursal/DialogSucursal';
import DialogRegistroRetencion from '../../../registroRetencion/DialogRegistroRetencion';
import DialogReteica from '../../../reteica/DialogReteica';
import DialogProveedorPagos from '../../../proveedorPagos/DialogProveedorPagos';
import { getEventos } from '../../../../core/apiEventos';
import DialogResolucionFacturacion from '../../../resolucionFacturacion/DialogResolucionFacturacion';
import DialogPresupuestoDepartamento from '../../../presupuesto/DialogPresupuestoDepartamentos';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DialogProducto from '../../../productos/DialogProducto';
import DialogRoles from '../../../roles/DialogRoles';
import "../../../../App.css";

export default function Maestros() {
    const wrapper = useWrapperStyles();

    return (
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />
                <div className={wrapper.offset}></div>

                {/* IMPORTANTE: Ver definición del componente View */}
                <View />
            </div>
        </div>
    )
}

const View = () => {
    useEffect(() => {
        async function init() {
            // Realizar el servicio que refresca el token
            await refreshToken();

            // Reiniciar timer de inactividad
            setTokenInterval();
        }
        init();
    }, [])

    return <LandingMaestros />
}

export const LandingMaestros = () =>
{
    const [openFormDepartamentos, setOpenFormDepartamentos] = useState(false);
    const [openFormEmpresasOperadoras, setOpenFormEmpresasOperadoras] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [snackbarProps, setSnackbarProps] = useState();
    const [showServicio, setShowServicio] = useState(false)
    const [showSucursal, setShowSucursal] = useState(false)
    const [showRetenciones, setShowRetenciones] = useState(false)
    const [showReteica, setReteica] = useState(false)
    const [showProveedorPago, setProveedorPago] = useState(false)
    const [showResolucion, setResolucion] = useState(false)
    const [showPresupuesto, setShowPresupuesto] = useState(false)
    const [showProductos, setShowProductos] = useState(false)
    const [showRoles, setShowRoles] = useState(false)
    const [eventos, setEventos] = useState([])

    const loadEventos = async () => {
        setShowLoader(true)
        const res = await getEventos()    
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEventos([]);
        }else{
            setEventos(res.data);
        }
        setShowLoader(false)
    }
    
    useEffect(() => {
        async function init(){
            await loadEventos()
        }
        init()
    }, [])

    const openDialogEmpresasOperadoras = () => {
        setOpenFormEmpresasOperadoras(true)
    }

    const closeDialogEmpresasOperadoras = () => {
        setOpenFormEmpresasOperadoras(false)
    }

    const openDialogDepartementos = () => {
        setOpenFormDepartamentos(true)
    }

    const closeDialogDepartamentos = () => {
        setOpenFormDepartamentos(false)
    }

    const openServicio = () => {
        setShowServicio(true)
    }

    const closeServicio = () => {
        setShowServicio(false)
    }

    const openSucursal = () => {
        setShowSucursal(true)
    }

    const closeSucursal = () => {
        setShowSucursal(false)
    }

    const openRetencion = () => {
        setShowRetenciones(true)
    }

    const closeRetencion = () => {
        setShowRetenciones(false)
    }

    const openReteica = () => {
        setReteica(true)
    }

    const closeReteica = () => {
        setReteica(false)
    }

    const openProveedorPago = () => {
        setProveedorPago(true)
    }

    const closeProveedorPago = () => {
        setProveedorPago(false)
    }

    const openResolucion = () => {
        setResolucion(true)
    }

    const closeResolucion = () => {
        setResolucion(false)
    }

    const openPresupuesto = () => {
        setShowPresupuesto(true)
    }

    const closePresupuesto = () => {
        setShowPresupuesto(false)
    }

    const openDialogProductos = () => {
        setShowProductos(true)
    }

    const closeDialogProductos = () => {
        setShowProductos(false)
    }

    const openDialogRoles = () => {
        setShowRoles(true)
    }

    const closeDialogRoles = () => {
        setShowRoles(false)
    }

    return (
        <>
            <Box p={1}>
                <Paper elevation={2}>
                    <Box p={1} >
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12}>
                                <h1>Empresas Operadoras y Proyectos</h1>
                                <Grid container spacing={1}>  
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Adminitrador de empresas operadoras, entidades utilizadas para la caracterización de las empresas involucradas en la operación de cada evento.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openDialogEmpresasOperadoras()}>
                                            Administrar empresas operadoras
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Administrador de roles por evento, entidades usadas para ser asociadas a los usuarios.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openDialogRoles()}>
                                            Administrar roles
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Sección destinada para administrar productos, los cuales son usados en el evento seleccionado.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openDialogProductos()}>
                                            Administrar productos
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h1>Administración Departamentos</h1>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>   
                                        <div className='smallTextDescription heighBlock'>Sección para la administración maestra de departamentos, disponibles para todos los eventos.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openDialogDepartementos()}>
                                            Administrar departamentos
                                        </Button>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Sección para la agrupación de municipios en torno a cada una de las sucursales de operación.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openSucursal()}>
                                            Administrar sucursales
                                        </Button>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4}>    
                                        <div className='smallTextDescription heighBlock'>Definición de conceptos presupuestales y visualización para el administrador, de los presupuestos configurados por evento, para cada uno de los municipios y departamentos.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openPresupuesto()}>
                                            Configuración de presupuesto
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <h1>Tributario</h1>
                                <Grid container spacing={1}>
                                    {/*
                                    <Grid item xs={12} sm={3}>
                                        <p className='smallTextDescription'>Administrador de servicios, disponibles para la generación de conceptos de pago para los supervisores.</p>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openServicio()}>
                                            Administrar servicios
                                        </Button>
                                    </Grid>
                                    */}
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Administrador de retenciones, por evento, para cada uno de los servicios disponibles, que se asociarán a los supervisores y sus conceptos de pago.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openRetencion()}>
                                            Administrar retenciones
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Administración de RETEICA, para cada uno de los municipios y departamentos por evento.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openReteica()}>
                                            Administrar RETEICA
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Sección destinada, para la configuración de proveedores de pago, y las comisiones que aplican a las transacciones por monto.</div>
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openProveedorPago()}>
                                            Proveedores de pago y configuración
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='smallTextDescription heighBlock'>Sección para la administración de los consecutivos utilizados en la generación de documentos equivalentes y la información de la resolución de facturación utilizada por evento.</div>
                                        <Button 
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            startIcon={<EngineeringIcon />}
                                            onClick={() => openResolucion()}>
                                            Resolución de facturación por evento
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>               

            <Loader show={showLoader} />
            <DialogDepartamento
                open={openFormDepartamentos}
                close={closeDialogDepartamentos}
            />
            <DialogEmpresasOperadoras
                open={openFormEmpresasOperadoras}
                close={closeDialogEmpresasOperadoras}
            />
            <DialogServicio
                open={showServicio}
                close={closeServicio}
            />
            <DialogSucursal
                open={showSucursal}
                close={closeSucursal}
                eventos={eventos}
            />
            <DialogRegistroRetencion
                open={showRetenciones}
                eventos={eventos}
                close={closeRetencion}
            />
            <DialogReteica
                open={showReteica}
                close={closeReteica}
            />
            <DialogProveedorPagos
                open={showProveedorPago}
                eventos={eventos}
                close={closeProveedorPago}
            />

            <DialogResolucionFacturacion
                eventos={eventos}
                open={showResolucion}
                close={closeResolucion}
            />

            <DialogPresupuestoDepartamento
                eventos={eventos}
                open={showPresupuesto}
                close={closePresupuesto}
            />

            <DialogProducto
                open={showProductos}
                close={closeDialogProductos}
                eventos={eventos}
            />

            <DialogRoles    
                eventos={eventos}
                open={showRoles}
                recargarEvento={loadEventos}
                close={closeDialogRoles}
            />
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}