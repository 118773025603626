import React, { useState, useEffect} from 'react';
import { getRolesEvento } from '../../core/apiRolesEvento';
import TablaSupervisores from './TablaSupervisores';

// Componentes
import { 
    Button,
    Dialog,
    Typography,
    Grid, 
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,
    TextField,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, 
    MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";
import AutocompleteFromEntity from '../controls/AutocompleteFromEntity';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

// Servicios
import FormularioSupervisores from './FormularioSupervisores';
import { getSupervisoresAndTotalPagoPaginated, getSupervisoresByGerenteAndTotalPagoPaginated } from '../../core/apiSupervisores';
import { getPuestosMunicipioDivipol, isEventConfigured } from '../../core/apiEventos';
import { isAuthenticated } from '../../core/sessionAPI';
import { getTiposPagos } from '../../core/apiTipoPago';
import { getCiudadSucursales } from '../../core/apiCiudadSucursal';
import { conceptosSupervisores, supervisoresAll, conceptosProcesadosSupervisores, downloadFile, createTxtByProveedor, createTxtByProveedorDate, reportConceptoByState } from '../../core/apiReportes';
import { getProveedoresPagos } from '../../core/apiProveedorPagos';
import fileDownload from 'react-file-download';
import { cambiarEstadoBySupervisores, changeProveedorPagos, confirmarDescargaDocumentoById, exportarDocumentosEquivalentes, pagarTodo, resetDownloadDocsEquivalentes, getPagoSupervisorByEventoFirmaMasiva, signatureRequestMasivo } from '../../core/apiPagoSupervisor';
import { DialogValidarAllPagos } from '../pagoSupervisor/DialogValidarAllPagos';
import { DialogValidarFirmaMasiva } from '../pagoSupervisor/DialogValidarFirmaMasiva';
import { PDFModel } from '../../helpers/ExportPDF/generatePDFPagos';
import { ButtonMenu } from '../shared/ButtonMenu';
import { ButtonSelect } from '../shared/ButtonSelect';
import { CustomPlainDocsDialog } from './CustomPlainDocsDialog';
import { getUploadFileStatus } from '../../core/apiControls';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const listStateConceptos = [
    {name: 'NO VALIDADOS', value: 'NO VALIDADO'},
    {name: 'VALIDADOS', value: 'VALIDADO'},
    {name: 'RECHAZADOS', value: 'RECHAZADO'},
    {name: 'PROCESADOS', value: 'PROCESADO'},
    {name: 'ANULADOS', value: 'ANULADO'}
]

const listFilterConcepto = [
    {name: 'TODOS', value: 'TODOS'},
    {name: 'NO VALIDADOS', value: 'NO VALIDADO'},
    {name: 'VALIDADOS', value: 'VALIDADO'},
    {name: 'RECHAZADOS', value: 'RECHAZADO'},
    {name: 'PROCESADOS (SIN ENVIAR PARA FIRMA)', value: 'PROCESADO'},
    {name: 'ANULADOS', value: 'ANULADO'},
    {name: 'ENVIADOS PARA FIRMA (PROCESADOS)', value: 'ENVIADOS PARA FIRMA'},
    {name: 'FIRMADOS (PROCESADOS)', value: 'FIRMADO'}
]

export default function DialogSupervisor ({open, close, idEvento, eventoName, edicionEvento})
{
    const classes = useStyles();
    const {user} = isAuthenticated()

    const [rolesEvento, setRolesEvento ] = useState([]);
    const [productosSeleccionado, setProductosSeleccionado] = useState(null);
    const [openFormProducto, setOpenFormProducto] = useState(false);
    const [supervisoresTable, setSupervisoresTable ] = useState([]);
    const [modo, setModo] = useState("crear");
    const [tiposPago, setTiposPagos] = useState([]);
    const [modalValidarAll, setModalValidarAll] = useState(false);
    const [proveedores, setProveedores] = useState([])
    const [modalValidarFirmaM, setModalValidarFirmaM] = useState(false);
    const [documentosPorFimar, setDocumentosPorFimar] = useState([]);
    const [numeroDocPorFimar, setNumeroDocPorFimar] = useState(0);

    //Variables paginador
    const [ perPage, setPerPage ] = useState(100);
    const [ page, setPage ] = useState(1);
    const [ numberTotalRows, setNumberTotalRows ] = useState(0);    
    const [ buscarField, setBuscarField ] = useState("");
    const [totalPago, setTotalPago] = useState(0);
    
    const [municipiosDisponibles, setMunicipiosDisponibles] = useState([]);
    const [puestosDisponibles, setPuestosDisponibles] = useState([]);

    const [sucursal, setSucursal] = useState(null);
    const [municipio, setMunicipio] = useState(null);
    const [puesto, setPuesto] = useState(null);
    const [stateConcepto, setStateConcepto] = useState('TODOS');
    const [showModalProveedor, setShowModalProveedor] = useState(false);
    const [showModalDescarga, setShowModalDescarga] = useState(false);

    const [openTXTPersonalizado, setOpenTXTPersonalizado] = useState(false);

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [empresaOperadora, setEmpresaOperadora] = useState(null);
    const [proyecto, setProyecto] = useState(null);

	const handleIsEventConfigured = async () => {
		showLoader()
		const resp = await isEventConfigured(idEvento)
		hideLoader()
		return resp.data
	}

    const openModalDescarga = () => {
        setShowModalDescarga(true)
    }

    const closeModalDescarga = () => {
        setShowModalDescarga(false)
    }

    const openConfirmarFirmaMasivo = async () => {
        let res = await getPagoSupervisorByEventoFirmaMasiva(idEvento, supervisoresTable);
        
        if (res.error){
            setSnackbarProps("e" + res.error);
            // setTiposPagos([]);
        }else{
            console.log(res.data.result);
            const data = res.data.result;
            if(data.length>0){
                setDocumentosPorFimar(data);
                setNumeroDocPorFimar(data.length);
                setModalValidarFirmaM(true);
            }else{
                setSnackbarProps("i" + "No hay documentos procesados y pagados pendientes por firmar."); 
            }
         //   setTiposPagos(res.data);
        }
    }

    const closeConfirmarFirmaMasivo = () => {
        setModalValidarFirmaM(false)
    }

    const handleValidarFimaMasiva = async () => {
        showLoader();
        if(documentosPorFimar.length>0){
           let res = await signatureRequestMasivo(documentosPorFimar);

            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                setSnackbarProps("s" + res.data.message);
            }
        }else{
            setSnackbarProps("i" + "No hay documentos procesados y pagados pendientes por firmar."); 
        }
        closeConfirmarFirmaMasivo();
        hideLoader();
    }

    const loadDataProveedores = async () => {
        //showLoader();
        const res = await getProveedoresPagos();
        if (res.error){
            setSnackbarProps("e" + res.error);
            setProveedores([])
        }else{
            setProveedores(res.data)
        }
        //hideLoader();
    }

    const reporteSupervisorAdmin = async () => {
        showLoader()
        const res = await supervisoresAll(idEvento);
        hideLoader()
        showLoader()
        const intervalId =  setInterval(async function(){
            const fileStatus = await getUploadFileStatus(res.data.fileId);
            if (fileStatus.data.status === "ERROR"){
                setSnackbarProps("eError al generar el archivo, contacte un administrador");              
                hideLoader();
                clearInterval(intervalId);
            }else if(fileStatus.data.status === 'SUCCESS'){      
                const timestamp = new Date().getTime();
                const fileUrl = fileStatus.data.url
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', `supervisores-${timestamp}.xlsx`); 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSnackbarProps("s¡Archivo generado con éxito!");
                hideLoader();
                clearInterval(intervalId);
            }             
        },5000);
    }

    const reportePagos = async () => {
        showLoader();
        const res = await conceptosSupervisores(supervisoresTable, idEvento);
        console.log(res)
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            fileDownload(res.data, `Conceptos de pago de ${eventoName}.xlsx`)
            setSnackbarProps('s' + 'Archivo descargado con éxito')
        }
        hideLoader();
    }

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataRol = async () => {
        //showLoader();
        const res = await getRolesEvento(idEvento);
        if (res.error){
            setSnackbarProps("e" + res.error);
            setRolesEvento([]);
        }else{
            setRolesEvento(res.data);
        }
        
        //hideLoader();
    }

    const loadDataSupervisores = async () => {        
        showLoader();
        if(idEvento){
            const {user} = isAuthenticated()
            let res
            if(user.role !== 2){
                res = await getSupervisoresByGerenteAndTotalPagoPaginated(
                    idEvento, 
                    user._id, 
                    page, 
                    perPage, 
                    buscarField, 
                    puesto?._id, 
                    municipio?._id, 
                    user?.sucursal ?? sucursal, 
                    stateConcepto,
                    empresaOperadora,
                    proyecto
                );                
                user?.sucursal && setSucursal(user?.sucursal)
            } else 
                res = await getSupervisoresAndTotalPagoPaginated(
                    idEvento, 
                    page, 
                    perPage, 
                    buscarField, 
                    puesto?._id, 
                    municipio?._id, 
                    sucursal??null, 
                    stateConcepto,
                    empresaOperadora,
                    proyecto
                );
            if (res.error){
                hideLoader();
                setSnackbarProps("e" + res.error);
                setNumberTotalRows(0);
                setSupervisoresTable([]);
            }else{
                hideLoader();
                setSupervisoresTable(res.data.data);
                setTotalPago(res.data.total ? res.data.total:0);
                setNumberTotalRows(res.data.length);
            }
        }        
    }

    const handleValidarAllEvento = async () => {
        showLoader();
        const res = await cambiarEstadoBySupervisores(supervisoresTable, 'VALIDADO');
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
        }
        closeModalValidarAll()
        hideLoader();
    }

    const loadDataTipoPagos = async () => {
        //showLoader();
        let res = await getTiposPagos()
        
        if (res.error){
            setSnackbarProps("e" + res.error);
            setTiposPagos([]);
        }else{
            setTiposPagos(res.data);
        }
        //hideLoader();
    }

    const closeHandlerProducto = async () => {
        setOpenFormProducto(false);
        await loadDataSupervisores();
    }

    const loadMunicipiosSucursal = async (sucursal) => {
        let res = await getCiudadSucursales(sucursal)
        
        if (res.error){
            setSnackbarProps("e" + res.error);
            setMunicipiosDisponibles([]);
            setMunicipio(null);
        }else{

            if (user?.role === 3){
                const allowedMunicipios = []
                const idsMunicipiosGerente = user.ciudades?.map(({_id}) => _id)
                for(let i=0; i < res.data.length; i++){
                    if(idsMunicipiosGerente?.includes(res.data[i].ciudad._id)){
                        allowedMunicipios.push({_id: res.data[i].ciudad._id, codigo: res.data[i].ciudad.codigo, name:res.data[i].ciudad.name});
                    }
                }
                setMunicipiosDisponibles(allowedMunicipios);
            }else{
                const tempCiudades = [];
                for(let i=0; i < res.data.length; i++){
                    tempCiudades.push({_id: res.data[i].ciudad._id, codigo: res.data[i].ciudad.codigo, name:res.data[i].ciudad.name});
                }
    
                setMunicipiosDisponibles(tempCiudades);
            }
        }
    }

    const loadPuestos = async (municipioSeleccionado) => {
        let res = await getPuestosMunicipioDivipol(municipioSeleccionado, idEvento);
        
        if (res.error){
            setSnackbarProps("e" + res.error);
            setPuestosDisponibles([]);
        }else{
            setPuestosDisponibles(res.data);
        }
    }    

    useEffect(() => {
        async function inicializar() {
            await loadDataTipoPagos();
            await loadDataRol();
            await loadDataProveedores()
            await loadDataSupervisores();            
        }

        if(idEvento){
            inicializar();
        }

        return () => {
            setRolesEvento([]);
            setSupervisoresTable([]);
            setStateConcepto('TODOS')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idEvento, page, perPage]);

    useEffect(() => {        
        async function inicializar() {
            console.log("COSULTA PUESTOS");
            await loadDataSupervisores()
        }
        if(puesto){
            console.log("5");
            inicializar();
        }else{
            console.log("6");
            loadDataSupervisores();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puesto]);   
    
    useEffect(() => {        
        async function inicializar() {
            console.log("COSULTA MUNICIPIOS");
            await loadPuestos(municipio);
            await loadDataSupervisores();
        }

        if(municipio){
            inicializar();
        }else{
            if(puesto){
                console.log("3");
                setPuesto(null);
            }else{
                console.log("4");
                loadDataSupervisores();                  
            }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [municipio]);
    
    useEffect(() => {        
        async function inicializar() {
            console.log("COSULTA SUCURSAL");
            await loadMunicipiosSucursal(sucursal);
            await loadDataSupervisores()        
        }

        if(sucursal){
            inicializar();
        }else{
            if(municipio){
                setMunicipio(null);
            }else{
                if(!puesto){
                    setMunicipio(null);
                }

                loadDataSupervisores();
            }                    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucursal]);

    useEffect(() => {
        async function init(){
            await loadDataSupervisores()
        }
        if(idEvento) init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateConcepto, empresaOperadora, proyecto])

    const handleCrearEditarProductos = (tipo, productoSeleccionado) => {
        //VALIDA QUE SI EL ROL ES GERENTE, DEBE TENER EL ACCESO HABILIATDO A EDITAR O CREAR
        let canEnter = false
        if(tipo == 'crear' && user?.role == 3) canEnter = user?.canCreateUsers 
        else if(tipo == 'editar' && user?.role == 3) canEnter = user?.canUpdateUsers 

        if(
            (user?.role == 3 && canEnter) ||
            user?.role == 2
        ){
            setProductosSeleccionado(productoSeleccionado);
            setModo(tipo);
            setOpenFormProducto(true);
        }else{
            setSnackbarProps('w' + 'No tiene permiso para ' + tipo + ' usuarios, informe a un administrador para habilitar esta opción')
        }
    }

    const openModalValidarAll = () => {
        setModalValidarAll(true)
    }

    const closeModalValidarAll = () => {
        setModalValidarAll(false)
    }

    const generateAllDocumentos = async () => {
        setSnackbarProps("s" + 'Los documentos empezarán a descargarse, este proceso puede tardar');
        showLoader()
        const validar = await exportarDocumentosEquivalentes(supervisoresTable, idEvento)        
        if (validar.error){
            setSnackbarProps("e" + validar.error);
            setTiposPagos([]);
        }else{
            const {data} = validar
            for(let i = 0; i < data.length; i++){
                const blob = await pdf((
                    <PDFModel values={data[i]} />
                )).toBlob()
                const changeFlagDescargado = await confirmarDescargaDocumentoById(data[i]._id)
                if(!changeFlagDescargado){
                    setSnackbarProps("e" + 'Ha ocurrido un error en la descarga con el usuario de cédula ' + data[i].restSupervisor.documento);
                    break
                }else{
                    saveAs(blob, data[i].nameDoc + '.pdf')   
                }
            }
            await loadDataSupervisores();
        }
        closeModalDescarga()
        hideLoader()
    }

    const procesarAllPagos = async () => {
        showLoader();
        const res = await pagarTodo(supervisoresTable, idEvento);
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
        }
        
        hideLoader();
    }

    const clearDescargaDocumentos = async () => {
        showLoader();
        const res = await resetDownloadDocsEquivalentes(supervisoresTable);
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{        
            setSnackbarProps("s" + 'Los documentos descargados se pueden descargar nuevamente');
            await loadDataSupervisores()
        }
        closeModalDescarga()
        hideLoader();
    }

    const openModalChangeProveedor = () => {
        setShowModalProveedor(true)
    }

    const closeModalChangeProveedor = () => {
        setShowModalProveedor(false)
    }

    const changeProveedores = async(proveedor) => {
        showLoader();
        const res = await changeProveedorPagos(supervisoresTable, proveedor);
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{        
            setSnackbarProps("s" + 'El proveedor de pagos fue cambiado masivamente');
            await loadDataSupervisores()
        }
        closeModalChangeProveedor()
        hideLoader();
    }

    const generarDocsPlanos = async (proveedor) => {        
        if(proveedor && proveedor.name != "Personalizado"){
            const res = await createTxtByProveedor(idEvento, proveedor._id, proveedor.name);
            console.log(res);
            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                //fileDownload(res.data, `REPORTE_SUPERVISORES_${eventoName}.xlsx`)
                setSnackbarProps("s" + res.data.message);

                //Se registra la solicitud de generación del archivo
                //Ahora se valida con un timeout la existencia del archivo
                downloadFile(showLoader, hideLoader, setSnackbarProps, res.data.nameFile);
            }
        }else if(proveedor && proveedor.name == "Personalizado"){
            //show modal, con selector de fecha   
            console.log("open modal de personalizado", proveedor);         
            setOpenTXTPersonalizado(true);      
        }
    }

    const generateCustomPlainDocs = async (date, provider, sucursal, municipio, puesto) => {    
        if(date && provider){    
            console.log({date, provider, sucursal, municipio, puesto});

            //date
            const dateFixed = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const res = await createTxtByProveedorDate(dateFixed, idEvento, provider._id, provider.name, sucursal, municipio, puesto);
            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                //fileDownload(res.data, `REPORTE_SUPERVISORES_${eventoName}.xlsx`)
                setSnackbarProps("s" + res.data.message);

                //Se registra la solicitud de generación del archivo
                //Ahora se valida con un timeout la existencia del archivo
                downloadFile(showLoader, hideLoader, setSnackbarProps, res.data.nameFile);
            }
        }else{
            setSnackbarProps("eEl proveedor de pagos y la fecha son requeridos.");
        }
    }

    const reporteConceptos = async (stateConcepto) => {
        const res = await reportConceptoByState(idEvento, stateConcepto.value);
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            //fileDownload(res.data, `REPORTE_SUPERVISORES_${eventoName}.xlsx`)
            setSnackbarProps("s" + res.data.message);

            //Se registra la solicitud de generación del archivo
            //Ahora se valida con un timeout la existencia del archivo
            downloadFile(showLoader, hideLoader, setSnackbarProps, res.data.nameFile);
        }
    }

    const filterTableByConcepto = async (stateConcepto) => {
        setStateConcepto(stateConcepto)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Usuarios de evento <b>{eventoName}</b>
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1} style={{background: "#fafafa"}}>
                            <Box p={1}>                                
                                <Box> 
                                    <Grid container spacing={1} style={{paddingBottom: 10}}>
                                        <Grid item  xs={12} sm={3}>                                        
                                            <TextField 
                                                size="small"
                                                value={buscarField}
                                                variant="outlined"                                                 
                                                placeholder="Buscar..."
                                                label="Buscar..."
                                                fullWidth
                                                onChange={(e)=>{
                                                    setPage(1);
                                                    setBuscarField(e.target.value);
                                                }}
                                                onKeyPress={event => {
                                                    if (event.key === 'Enter') {
                                                        loadDataSupervisores();
                                                    }
                                                }}
                                                onKeyUp={event => {                                                
                                                    if (event.key === 'Backspace' && buscarField == "") {
                                                        //Consulta utilizando el parametro de busqueda
                                                        loadDataSupervisores();
                                                    }
                                                }}/>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <AutocompleteFromEntity
                                                size="small"
                                                value={sucursal}
                                                enableCreation={false}
                                                label="Sucursales"
                                                placeholder="Sucursales"
                                                disabled={user?.role === 3}
                                                entity="Sucursal"
                                                field="name"
                                                setObject={(obj) => {
                                                    if(obj){
                                                        setSucursal(obj._id);
                                                        loadMunicipiosSucursal(obj._id)
                                                    }else{
                                                        setSucursal(null);
                                                        setMunicipio(null);
                                                        loadMunicipiosSucursal(null);                                                        
                                                    }
                                                }}
                                                condition={{delete:false}}
                                                multiple={false}
                                                selectAll={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Autocomplete
                                                key={sucursal}
                                                fullWidth         
                                                size="small"    
                                                options={municipiosDisponibles}      
                                                getOptionLabel={(option) => {
                                                    return option.codigo + " - " + option.name;
                                                }} 
                                                onChange={(e, value)=>{
                                                    if(value){
                                                        setMunicipio(value);                                                        
                                                    }else{
                                                        setMunicipio(null);
                                                    }
                                                }}             
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}                                                    
                                                    variant="outlined"
                                                    label="Municipio"
                                                    placeholder="Municipio"
                                                    />
                                                )}
                                            />
                                        </Grid> 
                                        <Grid item xs={12} sm={3}>
                                            <Autocomplete
                                                key={municipio}
                                                fullWidth         
                                                size="small"
                                                options={puestosDisponibles}     
                                                getOptionLabel={(option) => {
                                                    return option.codigoDivipol + " - " + option.nombreDelPuesto + " - " + option.tipoZona;
                                                }}
                                                onChange={(e, value)=>{
                                                    if(value){                                                        
                                                        setPuesto(value);    
                                                    }else{
                                                        setPuesto(null);
                                                    }
                                                }}   
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}                                                    
                                                    variant="outlined"
                                                    label="Puesto"
                                                    placeholder="Puesto"
                                                    />
                                                )}
                                            />
                                        </Grid>    
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3}>
                                            <ButtonSelect
                                                defaultValue={stateConcepto}
                                                handleSelect={filterTableByConcepto}
                                                list={listFilterConcepto}                                                        
                                            />                     
                                        </Grid>  
                                        <Grid item xs={12} sm={3}>
                                            <AutocompleteFromEntity
                                                size="small"
                                                value={empresaOperadora}
                                                enableCreation={false}
                                                label="Empresa operadora"
                                                placeholder="Empresa operadora"
                                                entity="EmpresaOperadora"
                                                field="name"
                                                setObject={(obj) => {
                                                    if(obj) setEmpresaOperadora(obj._id)
                                                    else setEmpresaOperadora(null);                                                     
                                                    setProyecto(null)
                                                }}
                                                condition={{delete:false, evento: idEvento}}
                                                multiple={false}
                                                selectAll={false}
                                            />                     
                                        </Grid>  
                                        <Grid item xs={12} sm={3}>
                                            <AutocompleteFromEntity
                                                size="small"
                                                value={proyecto}
                                                enableCreation={false}
                                                label="Proyectos"
                                                placeholder="Proyectos"
                                                entity="Proyecto"
                                                disabled={empresaOperadora === null}
                                                field="name"
                                                setObject={(obj) => {
                                                    if(obj){
                                                        setProyecto(obj._id)
                                                    }else{
                                                        setProyecto(null);                                                     
                                                    }
                                                }}
                                                condition={{delete:false, empresaOperadora}}
                                                multiple={false}
                                                selectAll={false}
                                            />                          
                                        </Grid>  
                                        <Grid item xs={12} sm={3}>
                                            {(user?.role === 2 || edicionEvento)&&
                                                <Grid item>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                        className='customUsuariosTable'
                                                        startIcon={<AddIcon />}
                                                        onClick={() => {
                                                            handleCrearEditarProductos("crear", null)
                                                        }}>
                                                        Agregar Usuario
                                                    </Button>  
                                                </Grid>   
                                            }                     
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Box p={1}>
                                        <h3>Operaciones sobre la consulta de usuarios realizada (datos mostrados en la tabla)</h3>
                                    </Box>
                                    <Grid container spacing={1} justify="flex-end">
                                        {user?.role === 3 &&
                                            <>
                                                <Grid item xs={12} sm={2}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                        startIcon={<DownloadIcon />}
                                                        onClick={async () => {
                                                            const resp = await handleIsEventConfigured()
                                                            if(!resp?.configured) {
                                                                setSnackbarProps('e' + resp.message)
                                                            }else {
                                                                reportePagos()
                                                            }
                                                        }}>
                                                        Descargar reporte de pagos
                                                    </Button>  
                                                </Grid>
                                            </>
                                        }
                                        {user?.role === 2 &&
                                            <>
                                                <Grid item xs={12} sm={3}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                        startIcon={<DownloadIcon />}
                                                        onClick={() => reportePagos()}>
                                                        Descargar reporte de pagos
                                                    </Button>  
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                        startIcon={<CheckCircleOutlineRoundedIcon />}
                                                        onClick={async () => {
                                                            const resp = await handleIsEventConfigured()
                                                            if(!resp?.configured) {
                                                                setSnackbarProps('e' + resp.message)
                                                            }else{
                                                                openModalChangeProveedor()
                                                            }
                                                        }}>
                                                        Cambiar proveedores de pagos
                                                    </Button>  
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                        startIcon={<CheckCircleOutlineRoundedIcon />}
                                                        onClick={async () => {
                                                            const resp = await handleIsEventConfigured()
                                                            if(!resp?.configured) {
                                                                setSnackbarProps('e' + resp.message)
                                                            }else{
                                                                openModalValidarAll()
                                                            }
                                                        }}>
                                                        Validar Todo
                                                    </Button>  
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary"
                                                        fullWidth
                                                        startIcon={<CheckCircleOutlineRoundedIcon />}
                                                        onClick={async () => {
                                                            const resp = await handleIsEventConfigured()
                                                            if(!resp?.configured) {
                                                                setSnackbarProps('e' + resp.message)
                                                            }else{
                                                                procesarAllPagos()
                                                            }
                                                        }}>
                                                        Procesar pagos
                                                    </Button>  
                                                </Grid>
                                                
                                            </>
                                        }
                                        <Grid item xs={12} sm={2}>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<DownloadIcon />}
                                                onClick={async () => {
                                                    const resp = await handleIsEventConfigured()
                                                    if(!resp?.configured) {
                                                        setSnackbarProps('e' + resp.message)
                                                    }else{
                                                        openModalDescarga()
                                                    }
                                                }}>
                                                Descargar docs. equ.
                                            </Button>  
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<SendIcon />}
                                                onClick={async () => {
                                                    const resp = await handleIsEventConfigured()
                                                    if(!resp?.configured) {
                                                        setSnackbarProps('e' + resp.message)
                                                    }else {
                                                        openConfirmarFirmaMasivo()
                                                    }
                                                }}>
                                                Enviar para firma documentos procesados y pagados
                                            </Button>  
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Box p={1}>
                                        <h3>Operaciones sobre toda la base de usuarios</h3>
                                    </Box>
                                    <Grid container spacing={1} justify="flex-end">
                                        {user?.role === 2 &&
                                            <>
                                                <Grid item xs={12} sm={3} className="fullWidthContent">
                                                    <ButtonMenu
                                                        list={listStateConceptos}
                                                        title='Reporte de conceptos de pago'
                                                        handleSelect={reporteConceptos}
                                                    />
                                                </Grid>  
                                                <Grid item xs={12} sm={3} className="fullWidthContent">
                                                    <ButtonMenu
                                                        list={[...proveedores,{name: "Personalizado"}]}
                                                        title='Descarga de documentos txt'
                                                        handleSelect={(e)=>{
                                                            generarDocsPlanos(e)
                                                        }}
                                                    />
                                                </Grid> 
                                            </>
                                        } 
                                        <Grid item xs={12} sm={3}>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                size='medium'
                                                fullWidth
                                                startIcon={<DownloadIcon />}
                                                onClick={() => reporteSupervisorAdmin()}>
                                                Descargar usuarios
                                            </Button>  
                                        </Grid>       
                                    </Grid>
                                </Box>                          
                            </Box>
                        </Paper>
                    </Box>    
                    <Box p={1}>
                        <Paper elevation={1} style={{background: "#fafafa"}}>             
                            <TablaSupervisores
                                registros = {supervisoresTable}
                                handleEditClick = {handleCrearEditarProductos}
                                reloadTable = {loadDataSupervisores}
                                setSnackbarProps ={setSnackbarProps}
                                roles={rolesEvento}
                                totalPago={totalPago}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                page={page}
                                setPage={setPage}
                                numberTotalRows={numberTotalRows}
                                edicionEvento={edicionEvento}
                            />
                        </Paper>
                    </Box>
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>    
            </Dialog>

            {openFormProducto ?
            <FormularioSupervisores
                open = {openFormProducto}
                close = {closeHandlerProducto}
                idEvento={idEvento}
                eventoName={eventoName}
                modo={modo}
                data={productosSeleccionado}
                tiposPago={tiposPago}
                proveedores={proveedores}
            /> : null}

            <DialogValidarAllPagos
                open={modalValidarAll}
                handleClose={closeModalValidarAll}
                validarPago={handleValidarAllEvento}
                title='Validación masiva de conceptos de pago'
                content='¿Deseas validar todos los conceptos de pago de estos supervisores?'
            />

            <DialogValidarFirmaMasiva
                open={modalValidarFirmaM}
                handleClose={closeConfirmarFirmaMasivo}
                validarPago={handleValidarFimaMasiva}
                title='Confirmación envió para firma de documentos'
                content={`Aún no se han firmado ${numeroDocPorFimar} documentos que ya fueron procesados y pagados ¿Estás seguro de enviarlos todos para firmar?`}
            />

            <ModalDownloadAllPagos
                open={showModalDescarga}
                download={generateAllDocumentos}
                handleClose={closeModalDescarga}
                clear={clearDescargaDocumentos}
            />

            <ModalProveedores
                open={showModalProveedor}
                handleClose={closeModalChangeProveedor}
                handleAcept={changeProveedores}
                proveedores={proveedores}
            />

            <CustomPlainDocsDialog
                isOpen={openTXTPersonalizado}
                onClose={() => setOpenTXTPersonalizado(false)}
                onSubmit={generateCustomPlainDocs}
                proveedores={proveedores}
                idEvento={idEvento}
            />
        </>
    );
}

const ModalDownloadAllPagos = ({open, handleClose, download, clear}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Descarga masiva de documentos equivalentes</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>LIMPIAR</b>: Se podrán descargar los documentos equivalentes previamente descargados.
                    <br/>
                    <br/>
                    <b>DESCARGA MASIVA</b>: Se descargan todos los documentos equivalentes de los usuarios de la tabla que aún no hayan sido descargados..
                </DialogContentText>
            </DialogContent>                
            <DialogActions style={{textAlign: 'right'}}>
                <Button onClick={handleClose} color="secondary">
                    Cerrar
                </Button>
                <Button onClick={clear} color="secondary">
                    LIMPIAR
                </Button>
                <Button onClick={download} color="primary">
                    DESCARGA MASIVA
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const ModalProveedores = ({open, handleClose, handleAcept, proveedores = []}) => {
    const [proveedorSelected, setProveedorSelected] = useState('')
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Cambio masivo de proveedores de pago</DialogTitle>
            <DialogContent>
                    <TextField
                        select
                        variant="outlined" 
                        name="proveedor" 
                        label="Proveedor de pago"
                        fullWidth
                        onChange={(e) => setProveedorSelected(e.target.value) }
                    >   
                    {
                        proveedores.map((e, i) => (
                            <MenuItem key={i} value={e._id}>
                                {e.name}
                            </MenuItem>
                        ))
                    }
                    </TextField>
            </DialogContent>                
            <DialogActions style={{textAlign: 'right'}}>
                <Button onClick={handleClose} color="secondary">
                    Cerrar
                </Button>
                <Button onClick={() => handleAcept(proveedorSelected)} color="primary">
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
