import React, { useState, useEffect, useRef} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import { useFormik } from "formik";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    DialogTitle,
    DialogActions,
    Paper,
    LinearProgress
} from '@material-ui/core';
import AutocompleteFromEntityRegistro from '../controls/AutocompleteRegistroFromEntity';
import AutocompleteFromEntityRegistroStalls from '../controls/AutocompleteRegistroFromEntityStalls';
import { 
    corregirEspaciado
} from "../../helpers/Validator";

import { getEmpresasAdminRegistro } from '../../core/superAdminServices/empresaAPI'
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import { createSupervisorRegistro, confirmActivateEmail } from '../../core/apiSupervisores';
import { getRolesEventoRegistro, getPuestosEventoRegistro } from '../../core/apiRolesEvento';
import { getDepartamentosMunicipiosGerenteRegistro, getGerenteCiudad  } from '../../core/apiGerentes';
import { getEmpresasOperadorasByEventoRegistro } from '../../core/apiEmpresaOperadora';
import { uploadFile } from '../../core/apiControls';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioSupervisores ({open, close, onCancel, idEvento, eventoName, modo, datos})
{
    const classes = useStyles();
    /* Snackbar */
    const [rolesEvento, setRolesEvento ] = useState([]);
    const [documentoToValidate, setDocuementoToValidate] = useState('')
    const [empresaSelected, setEmpresaSelected] = useState();
    const [EOselected, setEOselected] = useState('');
    const [botonCrear, setBotonCrear] = useState(true);
    const [documentType, setDocumentType] = useState('C.C');

    const [roleSelected, setRoleSelected] = useState();
    const [unPuestoRol, setUnPuestoRol] = useState();
    const [entregaCheck, setEntregaCheck] = useState(false);
    const [CADCheck, setCADCheck] = useState(false);
    const [recolectaCheck, setRecolectaCheck] = useState(false);
    const [tipoContribuidorSelected, setTipoContribuidorSelected] = useState(true);
    const [empresas, setEmpresas ] = useState([]);
    const [puestosOcupados, setPuestosOcupados ] = useState([]);
    const [snackbarProps, setSnackbarProps] = useState();

    const [departamentos, setDepartamentos] = useState([]);
    const [departamentoFacturacion, setDepartamentoFacturacion] = useState();

    const [ciudades, setCiudades] = useState([]);
    const [ciudadFacturacion, setCiudadFacturacion] = useState();
    
    const [puestos, setPuestos] = useState([]);
    const [gerente, setGerente] = useState(null);
    const [empresasOperadoras, setEmpresasOperadoras] = useState([]);    
    const [proyectos, setProyectos] = useState(null);
    const [serviciosSelected, setServiciosSelected] = useState([])    
   // const [isAdmin, setIsAdmin] = useState(false);
    const [gerenteSelected, setGerenteSelected] = useState();
    const [tipoPagoSelected, setTipoPagoSelected] = useState();
    const [rolActual, setRolActual] = useState(null);
    const [rolActualVista, setRolActualVista] = useState(false);
    /* Textos del título/botón del formulario */

    const [reloadCities, setReloadCities] = useState(0);
    const [reloadStation, setReloadStation] = useState(0);

    //Cargar RUT
    const [selectedFile, setSelectedFile] = useState(null);
    const inputFileRef = useRef();

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if(!file.name.endsWith('.pdf')) return setSnackbarProps("e" + "Solo se permiten archivos PDF");

        const fileSizeInBytes = file.size;
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

        if(fileSizeInMB.toFixed(2) < 5){
            setSelectedFile(file);
        }else{
            setSnackbarProps("e" + "El RUT no puede exceder un tamaño maximo de 5MB, reduzca el tamaño del archivo.");
        }
      };

    const resetForm = () =>{
        close(true); 
    }

    const loadEmpresaOperadora = async () => {
        showLoader();
        const res = await getEmpresasOperadorasByEventoRegistro(idEvento);
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEmpresasOperadoras([]);
        }else{
            setEmpresasOperadoras(res.data);
            setEOselected(res.data && res.data.length > 0 ?  res.data[0]._id:null);
        }
        hideLoader();
    }

    const getDptoMunGerente = async () => {
        const resp = await getDepartamentosMunicipiosGerenteRegistro()
        if (resp.error){
            setSnackbarProps("e" + resp.error);
            setGerente([]);
        }else{
            setGerente(resp.data[0]);            
        }
    }

    const closeClear = () => {
        setReloadStation(0)
        setReloadCities(0)
        setServiciosSelected([])
        setProyectos(null)
        onCancel()
    }

    const loadDataRol = async () => {
        showLoader();
        const res = await getRolesEventoRegistro(idEvento);
        if (res.error){
            setSnackbarProps("e" + res.error);
            setRolesEvento([]);
        }else{
            setRoleSelected(res.data && res.data.length === 0 ?  res.data[0]._id:null);
            setRolesEvento(res.data);
        }
        hideLoader();
    }

    const loadCompanies = async () =>
    {
        showLoader();
        const res = await getEmpresasAdminRegistro();
        if (res.error){
            setSnackbarProps("e" + res.error)
            setEmpresas([]);
        }else{
            setEmpresas(res.data);
            setEmpresaSelected(res.data && res.data.length > 0 ?  res.data[0]._id:null);
        }
        hideLoader();
    }

    const loadPuestosRol = async (rol) =>
    {
        const res = await  getPuestosEventoRegistro(idEvento, rol);
        if (res.error){
            setSnackbarProps("e" + res.error)
            setPuestosOcupados([]);
        }else{
            setPuestosOcupados(res.data);
        }
    }

    // Actualizar ciudades cuando se borra un departamento
    useEffect(() => {
        const updatedCities = ciudades.filter( ({departamento}) => 
            departamentos.find( ({_id}) => _id === departamento )
        )
        setCiudades(updatedCities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departamentos]) 

    // Actualizar puestos cuando se borra una ciudad
    useEffect(() => {
        if(Array.isArray(puestos)){
            const updatedStands = puestos.filter( ({municipio}) => 
                ciudades.find( ({_id}) => _id === municipio )
            )
            setPuestos(updatedStands)
            setReloadStation(prev => prev * Math.random())
        }else {
            const updatedStands =  ciudades.find( ({_id}) => _id === puestos.municipio) 
            setPuestos(prev =>  updatedStands ? prev : [])
            setReloadStation(prev => prev * Math.random())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ciudades]) 

    useEffect(() => {
        async function init() {
            setPuestos([]);
            if(rolActual){
                await loadPuestosRol(rolActual?._id);
                if(rolActual?.puestoObligatorio){
                    setRolActualVista(true);
                }else{
                    setRolActualVista(false);
                }
    
                if(rolActual?.unPuesto===true){
                    setPuestos([]);
                    setUnPuestoRol(true);       
                }else if(rolActual?.unPuesto===false){
                    setPuestos([]);
                    setUnPuestoRol(false);       
                }
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolActual]);


    useEffect(() => {    
        if(idEvento!==""){
            setDocuementoToValidate('')
            setReloadStation(0)
            setReloadCities(0)
            setDepartamentos([])
            setServiciosSelected([])        
            setProyectos(null)
            setCiudades([])
            setPuestos([])
            formik.handleReset();
            setEntregaCheck(false);
            setCADCheck(false)
            setRecolectaCheck(false);
            setRoleSelected()
            setEmpresaSelected()
            setEOselected()
            setGerenteSelected()
            setTipoPagoSelected()
            setRolActual(null)
            setDepartamentoFacturacion([])
            setCiudadFacturacion([])
            setDocumentType('C.C')

            loadDataRol()
            loadCompanies()
            loadEmpresaOperadora()
            getDptoMunGerente()
        }
        
        if(modo == 'crear'){            
            setDocumentType(datos?.documentType ?? 'C.C')
            formik.setFieldValue("documento", datos.document);
            formik.setFieldValue("firstLastname", datos.firstLastname);
            formik.setFieldValue("secondLastname", datos.secondLastname);
            formik.setFieldValue("firstName", datos.firstName);
            formik.setFieldValue("secondName", datos?.secondName || '');
            formik.setFieldValue("nacimiento", datos.nacimiento);
            formik.setFieldValue("sexo", datos.sexo);
            formik.setFieldValue("tipoSangre", datos.sangre);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = async (values) => {
        showLoader();
        setBotonCrear(false);
        let estaPresente = false;
        //Validacion de la cedula en el modal
        // if(tipoPagoSelected){
       if(departamentos.length!==0){
        if(ciudades.length!==0){
            if(rolActual){
                const res = await getPuestosEventoRegistro(idEvento, rolActual._id);
                setPuestosOcupados(res.data);
                for(let i=0; i < puestos.length; i++){
                     estaPresente = res.data.includes(puestos[i]._id);
                    if(estaPresente){
                        estaPresente= puestos[i].nombreDelPuesto;
                    }
                }
                if(estaPresente===false){
                    if(proyectos){
                        if(selectedFile){
                            let canCreateUser = true          
                        if(canCreateUser){
    
                            //Guardar el documento en S3
                            const urlFileRut = await uploadFile(selectedFile);
    
                            //Generar Codigo para activar cuenta
                            const randomNumber = Math.floor(Math.random() * 10000000000);
                            const formattedNumber = randomNumber.toString().padStart(10, "0");
                           
                            //Valdiar Acaaa
                            values.estadoActivacion=(parseInt(values.documento)*80)+"C"+formattedNumber;
                            values.documento = documentType === 'P' ? values.documento : parseInt(values.documento);
                            values.password = values.documento;
                            values.evento = idEvento;
                            values.entrega = entregaCheck;
                            values.recolecta = recolectaCheck;
                            values.CAD = CADCheck;
                            values.tipo_contribuidor = tipoContribuidorSelected;
                            values.firstLastname = corregirEspaciado(values.firstLastname);   
                            values.secondLastname = corregirEspaciado(values?.secondLastname ?? ''); 
                            values.rol = roleSelected;
                            values.empresa = empresaSelected;
                            values.departamentos = departamentos;
                            values.ciudades = ciudades;
                            values.puestos = puestos;
                            values.proyectos = proyectos;
                            values.empresaOperadora = EOselected;        
                            values.servicios = serviciosSelected;
                            values.tipoPago = "620d5f32897f93ed5eaa8dd3";
                            values.rolCompleto = rolActual
                            values.documentType = documentType
                            values.departamentoFacturacion = departamentoFacturacion;
                            values.ciudadFacturacion = ciudadFacturacion;
                            values.rutUrl = urlFileRut;
                            values.email = datos.email;
                            values.urlCedulaFrontal = datos?.urlCedulaFrontal;
                            values.urlCedulaPosterior = datos?.urlCedulaPosterior;
                            values.urlSelfie = datos.urlSelfie;
                           const respuestaGerente= await getGerenteCiudad(idEvento, ciudades[0] )
    
                           if(respuestaGerente.error){
                             setSnackbarProps("e" + respuestaGerente.error);
                           }else if(!respuestaGerente.data.gerente){
                             setSnackbarProps("e" + "La sucursal no tiene un gerente asignado");
                           }else{
    
                            values.gerente = respuestaGerente.data.gerente._id;
                             
                             //Se extrae el nombre del rol seleccionado
                             var textRol = "";
                             for(var i = 0; i < rolesEvento.length; i++){
                                 if(roleSelected == rolesEvento[i]._id){
                                     textRol = rolesEvento[i].name;
                                 }
                             }
     
                             var procesarSolicitud = true;
                             if(textRol.toUpperCase().indexOf("MOTORIZADO") != -1 || textRol.toUpperCase().indexOf("VEHÍCULO") != -1){
                                 //Se valida que el campo de informacionAdicional no este vacío
                                 if(values.informacionAdicional == ""){
                                     procesarSolicitud = false;
                                 }else{
                                     procesarSolicitud = true;
                                 }
                             }
     
                             if(procesarSolicitud){
                                 let res = {}
                                 if(modo === 'crear'){
                                     res = await createSupervisorRegistro(values, 2);
                                 }
                             
                                 if (res.error){
                                     setSnackbarProps("e" + res.error);
                                 }else{

                                     //Enviar Correo Para Activación
                                    const resEmail= await confirmActivateEmail(datos.email, `${datos.firstName} ${datos.firstLastname}`, res.data._id );
                                     //borrar campos
                                    console.log(resEmail) 
                                     resetForm();
                                 }
                             }else{
                                 setSnackbarProps("e" + "Para los usuarios motorizados ó que tengan asociado un vehículo, el campo información adicional es obligatorío y debe incluir la placa del vehículo.");
                             }
                           }
    
                        }else{
                            setSnackbarProps("e" + "Los documentos no concuerdan");
                        }
                        hideLoader();
                        }else{
                            setSnackbarProps("e" + "Deber cargar el RUT");
                        }        
                    }else{
                        setSnackbarProps("e" + "Debe asignarse un proyecto valido");
                    } 
                 }else{
                     setSnackbarProps("e" + `El puesto: ${estaPresente} ya tiene un supervisor asignado`);
                 }
            }else{
                setSnackbarProps("e" + "Rol es requerido para el registro");
            }
        }else{
            setSnackbarProps("e" + "Debes asiganar almenos una ciudad");
        }
     }else{
        setSnackbarProps("e" + "Debes asiganar almenos un departamento");
     }
        // }else{
        //     setSnackbarProps("e" + "Debes seleccionar el tipo de pago");
        // }
        
        setBotonCrear(true);
        hideLoader();
    }

    const formik = useFormik({
        initialValues:{
            documento: "",
            firstLastname: "",
            secondLastname: "",
            firstName: "",
            secondName: "",
            tipo_contribuidor: false,
            direccion: "",
            telefono: "",
            departamentos: [],
            ciudades: [],
            puestos: [],
            nacimiento: "",
            rol: "",
            tipo_servicio: "",
            empresa: "",
            password: "",
            evento: "",
            entrega: false,
            recolecta: false, 
            informacionAdicional: "",
            tipoSangre : "",
        },
        validationSchema: yup.object().shape({
            firstName: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            firstLastname: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            documento: documentType === 'P' ?  yup.string().required("Debes llenar este campo") : yup.string().matches(regex.numeros, "Este campo solo admite números").required("Debes llenar este campo"),
            telefono: yup.string().length(10).matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            nacimiento: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
         }),
        onSubmit
    });

    const { handleSubmit, touched, errors, getFieldProps } = formik;
    const direccionProps = getFieldProps("direccion");
    const firstNameProps = getFieldProps("firstName");
    const secondNameProps = getFieldProps("secondName");
    const firstLastnameProps = getFieldProps("firstLastname");
    const secondLastnameProps = getFieldProps("secondLastname");
    const documentoProps = getFieldProps("documento");
    const telefonoProps = getFieldProps("telefono"); 
    const informacionAdicionalProps = getFieldProps("informacionAdicional"); 
    const nacimientoProps = getFieldProps("nacimiento"); 
    const tipoSangreProps = getFieldProps("tipoSangre");     
    const sexoProps = getFieldProps("sexo");

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2>Datos basicos</h2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Tipo de documento</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='documentType'
                            fullWidth
                            disabled
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            label="Tipo de documento"
                        >
                            <MenuItem value='C.C' selected>Cédula de Ciudadanía (Colombia)</MenuItem>
                            <MenuItem value='C.I'>Cédula de Identidad (Venezuela)</MenuItem>
                            <MenuItem value='P'>Pasaporte (Internacional)</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Documento</InputLabel>
                        <TextField
                            size="small"
                            autoFocus
                            variant="outlined" 
                            name="documento" 
                            placeholder="Documento"
                            fullWidth
                            required
                            disabled={documentType === 'C.C'}
                            inputProps={{ maxLength: 20 }}
                            helperText={touched.documento ? errors.documento : ""}
                            error={touched.documento && Boolean(errors.documento)}
                            {...documentoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Primer apellido</InputLabel>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="firstLastname" 
                            placeholder="Primer apellido"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.firstLastname ? errors.firstLastname : ""}
                            error={touched.firstLastname && Boolean(errors.firstLastname)}
                            {...firstLastnameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="secondLastname" 
                            label="Segundo apellido"          
                            fullWidth             
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.secondLastname ? errors.secondLastname : ""}
                            error={touched.secondLastname && Boolean(errors.secondLastname)}
                            {...secondLastnameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="firstName" 
                            label="Primer nombre"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.firstName ? errors.firstName : ""}
                            error={touched.firstName && Boolean(errors.firstName)}
                            {...firstNameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="secondName" 
                            label="Segundo nombre"
                            fullWidth
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.secondName ? errors.secondName : ""}
                            error={touched.secondName && Boolean(errors.secondName)}
                            {...secondNameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="sexo" 
                            label="Sexo (M/F) (Opcional)"
                            fullWidth    
                            inputProps={{ maxLength: 1 }}
                            helperText={touched.sexo ? errors.sexo : ""}
                            error={touched.sexo && Boolean(errors.sexo)}
                            {...sexoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="nacimiento" 
                            required
                            label="Fecha de nacimiento (DDMMAAAA)"
                            fullWidth    
                            type="number"                        
                            inputProps={{ maxLength: 8 }}
                            helperText={touched.nacimiento ? errors.nacimiento : ""}
                            error={touched.nacimiento && Boolean(errors.nacimiento)}
                            {...nacimientoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="tipoSangre" 
                            label="Tipo Sangre (Opcional)"
                            fullWidth
                            inputProps={{ maxLength: 2 }}
                            helperText={touched.tipoSangre ? errors.tipoSangre : ""}
                            error={touched.tipoSangre && Boolean(errors.tipoSangre)}
                            {...tipoSangreProps}
                        />
                    </Grid>     
                    <Grid item xs={12} sm={12}></Grid>               
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="direccion" 
                            label="Dirección"
                            fullWidth                            
                            inputProps={{ maxLength: 40 }}
                            helperText={touched.direccion ? errors.direccion : ""}
                            error={touched.direccion && Boolean(errors.direccion)}
                            {...direccionProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="telefono" 
                            label="Celular"
                            fullWidth
                            required
                            inputProps={{ maxLength: 24 }}
                            helperText={touched.telefono ? errors.telefono : ""}
                            error={touched.telefono && Boolean(errors.telefono)}
                            {...telefonoProps}
                        />
                    </Grid>                                 
                    <Grid item xs={12}>
                        <h2>Datos tributarios</h2>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel className='bottomPadding'>Tipo de contribuyente</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='tipo_contribuidor'
                            fullWidth
                            value={tipoContribuidorSelected}
                            onChange={(e) => setTipoContribuidorSelected(e.target.value)}
                            label="Tipo de contribuidor *">
                            <MenuItem value={true} selected>Natural</MenuItem>
                            {/*<MenuItem value={false}>Juridica</MenuItem>*/}
                        </Select>
                    </Grid>     
                    {/* <Grid item xs={12} sm={6}>
                        <InputLabel className='bottomPadding'>Tipo de pago *</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='tipoPago'
                            value={tipoPagoSelected ?? ""}
                            onChange={(e) => setTipoPagoSelected(e.target.value)}
                            fullWidth
                            label="Tipo de pago *">
                            {
                                tiposPago.length > 0 ?
                                tiposPago.map( i => 
                                        <MenuItem value={i._id} key={i._id + 1}>{i.name}</MenuItem>
                                    )
                                : <MenuItem disabled key={1}>No hay tipos de pago disponibles</MenuItem>
                            }
                        </Select>
                    </Grid>                */}
                    <Grid item xs={12} sm={6}>
                        <InputLabel className='bottomPadding'>{modo == 'crear' ? "Rol *" : "Rol (Los cambios de rol solo se pueden realizar en la edición de conceptos a pagar)"}</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='rol'
                            disabled={modo == 'editar'}
                            value={roleSelected??""}
                            defaultValue=''
                            onChange={(e) => {
                                setRoleSelected(e.target.value)
                            }}
                            fullWidth
                            label={"Rol *"}>
                            {   rolesEvento.length !== 0 ?
                                    rolesEvento.map( item => 
                                        <MenuItem
                                            value={item._id}
                                            name={item.name}
                                            key={item._id + 1}
                                            onClick={() => setRolActual(item)}
                                            style={{whiteSpace: 'break-spaces'}}
                                        >{ item.name }
                                        </MenuItem>
                                    )
                                : <MenuItem disabled key={1}>No hay roles</MenuItem>
                            }
                        </Select>
                    </Grid>       
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Empresa a la que pertenece el usuario *</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='empresa'
                            value={empresaSelected ?? ""}
                            onChange={(e) => setEmpresaSelected(e.target.value)}
                            fullWidth
                            label="Empresa *">
                            {
                                empresas.length > 0 ?
                                    empresas.map( (i) => <MenuItem value={i._id} key={i._id + 1}>{i.name}</MenuItem>)
                                    : 
                                    <MenuItem disabled key={1}>No hay empresas</MenuItem>
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Empresa operadora a la cual se asocia el pago *</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='empresaOperadora'
                            value={EOselected ?? ""}
                            onChange={(e) => setEOselected(e.target.value)}
                            fullWidth
                            label="Empresa Operadora *">
                            {
                                empresasOperadoras.length > 0 ?
                                    empresasOperadoras.map( (i) => <MenuItem value={i._id} key={i._id + 1}>{i.codigo} - {i.name}</MenuItem>)
                                    : 
                                    <MenuItem disabled key={1}>No hay empresas operadoras para este evento</MenuItem>
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Proyectos empresa operadora *</InputLabel>
                        <AutocompleteFromEntityRegistro
                            size="small"
                            value={proyectos}
                            enableCreation={false}
                            label="Proyectos de empresa operadora"
                            placeholder="Seleccione los proyectos asignados"
                            entity="Proyecto"
                            field="name"
                            setObject={(objs) => {
                                setProyectos(objs);
                            }}
                            condition={{delete:false, empresaOperadora: (EOselected == "" ? null : EOselected)}}
                            multiple={false}
                            reloadFlag={EOselected}
                            selectAll={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <h2>Asignación de puestos</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>El primer municipio seleccionado, determina el impuesto RETEICA que se aplicará.</h4>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutocompleteFromEntityRegistro
                            size="small"
                            value={departamentos}
                            enableCreation={false}
                            label="Departamentos"
                            placeholder="Seleccione los departamentos asignados"
                            entity="Departamento"
                            field="name"
                            setObject={(objs) => {
                                setDepartamentos(objs);
                                setReloadCities(objs.length);                                
                            }}
                            condition={
                                true ?
                                {delete: false} :
                                {_id:{$in:gerente?.departamentos},delete:false}
                            }
                            multiple={true}
                            selectAll={false}
                            showOtherField={{field: 'codigo', show: true}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={6}>
                        <AutocompleteFromEntityRegistro
                            size="small"
                            value={ciudades}
                            enableCreation={false}
                            label="Ciudades"
                            placeholder="Seleccione las ciudades asignadas"
                            entity="Ciudad"
                            field="name"
                            setObject={(objs) => {
                                setCiudades(objs);
                                setReloadStation(objs.length);
                            }}
                            condition={
                                true ?
                                {departamento:{$in:departamentos}, delete:false} : 
                                {_id:{$in:gerente?.ciudades},departamento:{$in:departamentos},delete:false}
                            }
                            multiple={true}
                            selectAll={false}
                            reloadFlag={reloadCities}      
                            showOtherField={{field: 'codigo', show: true}}                              
                        />
                    </Grid>
                    {rolActualVista?
                        <><Grid item xs={12}>
                            <h4>Si no conoce los puestos que le serán asignados no diligencie este paso, la sucursal le asignará sus puestos</h4>
                        </Grid>
                        
                        {unPuestoRol ?
                            <Grid item xs={12} sm={6}>
                                <AutocompleteFromEntityRegistroStalls
                                    size="small"
                                    value={puestos}
                                    enableCreation={false}
                                    label="Puestos"
                                    placeholder="Seleccione los puestos asignados"
                                    entity="DivipolRegistros"
                                    field="nombreDelPuesto"
                                    setObject={(objs) => {
                                        setPuestos(objs);
                                    }}
                                    condition={{municipio:{$in:ciudades},evento:idEvento, delete:false, _id:{$nin:puestosOcupados}}}
                                    reloadFlag={reloadStation}
                                    multiple={false}
                                    selectAll={false}
                                    showOtherField={{field: 'codigoDivipol', show: true}}                            
                                />
                            </Grid>
                        : 
                            <Grid item xs={12} sm={6}>
                                <AutocompleteFromEntityRegistro
                                    size="small"
                                    value={puestos}
                                    enableCreation={false}
                                    label="Puestos"
                                    placeholder="Seleccione los puestos asignados"
                                    entity="DivipolRegistros"
                                    field="nombreDelPuesto"
                                    setObject={(objs) => {
                                        setPuestos(objs);
                                    }}
                                    condition={{municipio:{$in:ciudades},evento:idEvento, delete:false, _id:{$nin:puestosOcupados}}}
                                    reloadFlag={reloadStation}
                                    multiple={true}
                                    selectAll={false}
                                    showOtherField={{field: 'codigoDivipol', show: true}}                            
                                />
                            </Grid>
                        }
                        
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel 
                                id="demo-simple-select-required-label"
                                style={{textAlign: 'left'}}
                                label="ENTREGA: El usuario tendrá acceso a la aplicación móvil y estará habilitado para ENTREGA de todos los productos disponibles en el evento."
                                control={
                                    <Checkbox
                                        checked={entregaCheck}
                                        onChange={() => {setEntregaCheck(!entregaCheck)}}
                                        size='medium'
                                        color='primary'
                                        aria-label='Entrega'
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                            />
                        </Grid> 
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel 
                                id="demo-simple-select-required-label"
                                style={{textAlign: 'left'}}
                                label="RECOLECCIÓN: El usuario tendrá acceso a la aplicación móvil y estará habilitado para RECOLECCIÓN de todos los productos disponibles en el evento."
                                control={
                                    <Checkbox
                                        checked={recolectaCheck}
                                        onChange={() => {setRecolectaCheck(!recolectaCheck)}}
                                        size='medium'
                                        color='primary'
                                        aria-label='Recolecta'
                                        inputProps={{ 'aria-label': 'controlled' }}/>                            
                                }
                            />
                        </Grid> 
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel 
                                id="demo-simple-select-required-label"
                                style={{textAlign: 'left'}}
                                label="ENTREGA E-14 A CAD/PD: El usuario tendrá acceso a la aplicación móvil y estará habilitado para ENTREGA E-14 A CAD/PD"
                                control={
                                    <Checkbox
                                        checked={CADCheck}
                                        onChange={() => {setCADCheck(!CADCheck)}}
                                        size='medium'
                                        color='primary'
                                        aria-label='CAD'
                                        inputProps={{ 'aria-label': 'controlled' }}/>                            
                                }
                            />
                        </Grid>
                        </>
                    : null}
                    
                    <Grid item xs={12}>
                        <h2>Información adicional (opcional)</h2>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel className='bottomPadding'>Información adicional, aquí se puede registrar datos como placas de vehículos asociados ó datos adicionales.</InputLabel>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="informacionAdicional" 
                            fullWidth
                            inputProps={{ min: 0, max: 10 }}
                            helperText={touched.informacionAdicional ? errors.informacionAdicional : ""}
                            error={touched.informacionAdicional && Boolean(errors.informacionAdicional)}
                            {...informacionAdicionalProps}
                        />
                    </Grid> 
                    <Grid item xs={12}>
                            <Box p={0}>
                                <Paper elevation={2}>
                                    <Grid item xs={12}>
                                        {/* <Box className="panelUploadFiles">    
                                            <Box p={1}>
                                                <h3>Subida de Archivo RUT (PDF)</h3>
                                            </Box>                    
                                            <div className="fileInputWrapper">
                                            <input type="file" onChange={handleFileChange} accept=".pdf" id="fileInput" className="hidenPanel" />
                                            <label color="primary" htmlFor="fileInput" className="customButton">Seleccionar archivo </label>
                                            {selectedFile && <span>{selectedFile.name}</span>}
                                            </div>     
                                        </Box>   */}

                                        <Box className="panelUploadFiles">    
                                            {/* <LinearProgress 
                                                className="sizeProgressBar"
                                                variant="determinate" 
                                                value={progressUpload} /> */}
                                            <Box p={1}>
                                                <h3>Subida de Archivo RUT (PDF)</h3>
                                            </Box>  
                                            <Box p={1}>
                                                <h4>{selectedFile ? selectedFile.name : ""}</h4>
                                            </Box>                   
                                            <Box p={1}>
                                            <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    color="primary"
                                                    startIcon={<CloudUploadIcon />}
                                                    onClick={()=> {
                                                        //Call triger in input file
                                                        inputFileRef.current.click();
                                                    }}>
                                                    Seleccione el archivo Rut
                                            </Button>

                                                <input ref={inputFileRef} type="file" onChange={handleFileChange} accept=".pdf" id="fileInput" className="hidenPanel" />                        
                                            </Box>      
                                        </Box>                    
                                    </Grid>
                                </Paper>
                            </Box>    
                        </Grid>                                                             
                </Grid>
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off" id='formulario-registro'>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeClear} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {
                                    modo === 'crear' ? 
                                    `Registra un supervisor para evento: ${eventoName}`
                                    : `Edita un supervisor para evento: ${eventoName}`
                                }
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()} 
                        
                        <Box p={1}>
                            {botonCrear?<Button 
                            fullWidth                        
                            color="primary" 
                            variant='contained'
                            type='submit'
                            startIcon={<SaveIcon />}>
                            Registrar Supervisor
                            </Button>  
                            : <></>
                            }
                        
                        </Box>                   
                    </DialogContent>                      

                </form>     
            </Dialog>

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}