import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    MenuItem
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import axios from 'axios';
import { createConceptoCiudad, updateConceptoCiudad } from '../../core/apiConceptoCiudad';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioConceptoCiudad ({municipio, roles, open, close, mode, data, refreshTable})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [valorDias, setValorDias] = useState(0);
    const [rolActual, setRolActual] = useState({})
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        values.departamento = municipio?.departamento
        values.evento = municipio?.sucursal?.evento
        values.ciudadSucursal = municipio?._id
        values.totalDia = parseInt(values.dias) * parseInt(rolActual?.valorDia)
        values.totalDiasAdicionales = parseInt(values.diasAdicionales || 0) * parseInt(rolActual?.valorDiaAdicional || 0)
        values.total = values.totalDia + values.totalDiasAdicionales

        let res = {};
        if (mode === "crear"){
            res = await createConceptoCiudad(values)
        }else if (mode === "editar"){
            values.id = data._id;
            res = await updateConceptoCiudad(values)
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            dias: "",
            rol: '',
            diasAdicionales: '',
        },
        validationSchema: yup.object().shape({
            dias:  yup.string().matches(regex.numeros, "Este campo solo admite numeros"),
            rol: yup.string().required("Debes llenar este campo"),
            diasAdicionales:  yup.string().matches(regex.numeros, "Este campo solo admite numeros")
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const diasProps = getFieldProps("dias");
    const rolProps = getFieldProps("rol");
    const diasAdicionalesProps = getFieldProps("diasAdicionales");

    
    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                setFormTitle("Añadir nuevo concepto al municipio");
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos del concepto");
                formik.setFieldValue("rol", data.rol._id);
                formik.setFieldValue("dias", data.dias);
                formik.setFieldValue("diasAdicionales", data?.diasAdicionales || 0);
                setRolActual(data.rol)
            }
        }

        init();
    }, [open]);

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos del concepto para el municipio</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="rol" 
                            label="Concepto presupuestal"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.rol ? errors.rol : ""}
                            error={touched.rol && Boolean(errors.rol)}
                            {...rolProps}
                        >   
                            {
                                roles.map((e, i) => 
                                    municipio?.ciudad?.isZonificado ?
                                        e.aplicaSoloMunicipioZonificado ? 
                                            <MenuItem key={i} value={e._id} 
                                                onClick={() => setRolActual(e)}
                                            >
                                                {e.name}
                                            </MenuItem>
                                        : <></>
                                    : !e.aplicaSoloMunicipioZonificado ? 
                                        <MenuItem key={i} value={e._id}
                                            onClick={() => setRolActual(e)}
                                        >
                                            {e.name}
                                        </MenuItem>
                                    : <></>
                                )
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="dias" 
                            label="Días"
                            fullWidth
                            disabled={rolProps.value == ''}
                            required
                            inputProps={{ maxLength: 200 }}
                            helperText={touched.dias ? errors.dias : ""}
                            error={touched.dias && Boolean(errors.dias)}
                            {...diasProps}
                        />
                    </Grid>  
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            disabled
                            label='Valor total por días'
                            fullWidth
                            value={`$ ${parseInt(diasProps.value || 0) * parseInt(rolActual?.valorDia || 0)}`}
                        />
                    </Grid>   
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="diasAdicionales" 
                            label="Días adicionales"
                            fullWidth
                            disabled={!rolActual?.aplicaValorDiaAdicional}
                            inputProps={{ maxLength: 200 }}
                            helperText={touched.diasAdicionales ? errors.diasAdicionales : ""}
                            error={touched.diasAdicionales && Boolean(errors.diasAdicionales)}
                            {...diasAdicionalesProps}
                        />
                    </Grid>  
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            disabled
                            label='Valor total por días adicionales'
                            fullWidth
                            value={`$ ${parseInt(diasAdicionalesProps.value || 0) * parseInt(rolActual?.valorDiaAdicional || 0)}`}
                        />
                    </Grid>  
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}