import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

import { getServicios } from '../../core/apiServicio';
import TablaPagoSupervisor from './TablaPagoSupervisor';
import FormularioPagoSupervisor from './FormularioPagoSupervisor';
import { getPagoSupervisores, pagoSupervisorPagoUnicoAll, cambiarEstadoBySupervisor } from '../../core/apiPagoSupervisor';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { ConfirmacionControlPago } from './ConfirmacionControlPago';
import { getProveedoresPagos } from '../../core/apiProveedorPagos';
import { isAuthenticated } from '../../core/sessionAPI';
import { DialogValidarAllPagos } from './DialogValidarAllPagos';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPagoSupervisor({edicionEvento, open, close, Empresa, roles = []}){
    const {user} = isAuthenticated()
    const classes = useStyles();
    const theme = useTheme();
    const [isAdmin, setIsAdmin] = useState();

    const [Proyectos, setProyectos] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState(false);
    const [conceptoSeleccionado, setConceptoSeleccionado] = useState(null)
    const [servicios, setServicios] = useState([])
    const [formPagos, setFormPagos] = useState(false)
    const [proveedorPagosList, setProveedorPagosList] = useState(false)
    const [modalValidarAll, setModalValidarAll] = useState(false)

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    const openFormPagos = () => {
        setFormPagos(true)
    }

    const closeFormPagos = () => {
        setFormPagos(false)
    }
    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataServicios = async () => {
        showLoader();
        const res = await getServicios()

        if (res.error){
            setSnackbarProps("e" + res.error);
            setServicios([]);
        }else{
            setServicios(res.data);
        }

        hideLoader();
    }

    const loadDataConceptos = async () => {
        showLoader();
        const res = await getPagoSupervisores(Empresa._id)

        if (res.error){
            setSnackbarProps("e" + res.error);
            setProyectos([]);
        }else{
            setProyectos(res.data); 
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar() {
            if(Empresa){
                await loadDataConceptos()
                await loadDataServicios()
            }
        }
        inicializar();

        if(user?.role == 2){
            setIsAdmin(true);
        }else{
            setIsAdmin(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Empresa]);

    const handleCrearEditarMunicipios = (tipo, conceptoPago) => {
        setConceptoSeleccionado(conceptoPago);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = async() => {
        await loadDataConceptos()
        setOpenForm(false)
    }

    const handlePagarAll = async () => {
        showLoader();
        const res = await pagoSupervisorPagoUnicoAll(Empresa._id, Empresa.proveedorPagos, Empresa?.evento, Empresa?.empresaOperadora)
        if (res.error){
            setSnackbarProps("e" + res.error);
            closeFormPagos()
        }else{
            setSnackbarProps("s" + 'Todos los conceptos fueron pagados por pago único');
            await loadDataConceptos()
            closeFormPagos()
        }
        hideLoader();
    }

    const openModalValidarAll = () => {
        setModalValidarAll(true)
    }

    const closeModalValidarAll = () => {
        setModalValidarAll(false)
    }

    const handleValidarAll = async () => {
        console.log(Proyectos)
        const canChangeState = Proyectos.some(item => item.rol.aplicaValorDiaAdicional && item.diasAdicionales === 0)
        if(canChangeState){
            setSnackbarProps("e" + 'Error, hay conceptos que deben tener días adicionales diferentes de 0')
            closeModalValidarAll()
            return
        }
        showLoader();
        const res = await cambiarEstadoBySupervisor(Empresa._id, 'VALIDADO')
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            await loadDataConceptos()
        }
        closeModalValidarAll()
        hideLoader();
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar conceptos de pago de {`${Empresa?.firstName || ''} ${Empresa?.firstLastname}`}
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} 
                                        sm={(edicionEvento || user?.role==2) ? ((Empresa?.tipoPago?.name === 'Pago único' ? 8 : 10) - (user?.role == 2 ? 2 : 0)):12}>
                                        <Filter
                                            source={Proyectos}
                                            searchBy="name"
                                            setTableData={setProyectos}
                                            setFiltering={setFiltering}
                                        />
                                    </Grid>
                                    {(edicionEvento || user?.role == 2) &&
                                        <Grid item xs={2}>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<MonetizationOnIcon />}
                                                onClick={() => {
                                                    setModo("crear");
                                                    setConceptoSeleccionado(null);
                                                    setOpenForm(true);
                                                }}>
                                                Adicionar concepto de pago
                                            </Button>                        
                                        </Grid>
                                    }
                                    {
                                        Empresa?.tipoPago?.name === 'Pago único' && (edicionEvento || user?.role == 2) &&
                                            <Grid item xs={2}>
                                                <Button 
                                                    variant="contained" 
                                                    color="primary"
                                                    fullWidth
                                                    startIcon={<MonetizationOnIcon />}
                                                    onClick={() => openFormPagos()}>
                                                    Pago único
                                                </Button>                        
                                            </Grid>
                                    }
                                    {
                                        user?.role == 2 ?
                                        <Grid item xs={12} sm={2}>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<CheckCircleOutlineRoundedIcon />}
                                                onClick={() => openModalValidarAll()}>
                                                Validar Todos
                                            </Button>                        
                                        </Grid> : <></>
                                    }
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>
                            <TablaPagoSupervisor
                                proyectos={Proyectos}
                                handleEdit={handleCrearEditarMunicipios}
                                reloadTable={loadDataConceptos}
                                setSnackbarProps={setSnackbarProps}
                                Supervisor={Empresa}
                                edicionEvento={edicionEvento}
                            />
                        </Paper>
                    </Box> 
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>               
            </Dialog>

            <FormularioPagoSupervisor
                open={openForm}
                close={closeForm}
                mode={modo}
                Empresa={Empresa}
                servicios={servicios}
                roles={roles}
                data={conceptoSeleccionado}
                pagosAsignados={Proyectos}
            />

            <ConfirmacionControlPago
                open={formPagos}
                Title='¿Deseas pagar todo en un pago único?'
                handleClose={closeFormPagos}
                handlePagar={handlePagarAll}
            />

            <DialogValidarAllPagos  
                title={`Validar todos los conceptos de ${Empresa?.firstName}  ${Empresa?.firstLastname} - ${Empresa?.documento}`}
                content='¿Deseas validar todos los conceptos de este usuario?'
                validarPago={handleValidarAll}
                open={modalValidarAll}
                handleClose={closeModalValidarAll}
            />
        </>
    );
}