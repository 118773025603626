import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Slide, TextField } from '@material-ui/core';
import React from 'react'
import { useState } from 'react';
import AutocompleteFromEntity from '../controls/AutocompleteFromEntity';
import { getCiudadSucursales } from '../../core/apiCiudadSucursal';
import { getPuestosMunicipioDivipol } from '../../core/apiEventos';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomPlainDocsDialog = ({
  isOpen, 
  onClose, 
  onSubmit,
  idEvento,
  proveedores
}) => {
  const [currentProvider, setCurrentProvider] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [puesto, setPuesto] = useState(null);
  const [municipiosDisponibles, setMunicipiosDisponibles] = useState([]);
  const [puestosDisponibles, setPuestosDisponibles] = useState([]);
  const [date, setDate] = useState(new Date());
  
  const loadMunicipiosSucursal = async (sucursal) => {
    let res = await getCiudadSucursales(sucursal)
    
    if (res.error){
        setMunicipiosDisponibles([]);
        setMunicipio(null);
    }else{

        console.log(res.data);
        const tempCiudades = [];
        for(let i=0; i < res.data.length; i++){
            tempCiudades.push({_id: res.data[i].ciudad._id, codigo: res.data[i].ciudad.codigo, name:res.data[i].ciudad.name});
        }

        setMunicipiosDisponibles(tempCiudades);
    }
  }

  const loadPuestos = async (municipioSeleccionado) => {
    let res = await getPuestosMunicipioDivipol(municipioSeleccionado, idEvento);
    
    if (res.error){
        setPuestosDisponibles([]);
    }else{
        setPuestosDisponibles(res.data);
    }
} 

  const handleSubmit = async () => {
    await onSubmit( date, currentProvider, sucursal, municipio, puesto);
  }

  const handleClose = () => {
    setCurrentProvider(null);
    setSucursal(null);
    setMunicipio(null);
    setPuesto(null);
    setMunicipiosDisponibles([]);
    setPuestosDisponibles([]);
    setDate(new Date());
    onClose();
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle>Reporte TXT</DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
          <Box>
              <Box>
                  Generación del reporte de pagos personalizado
              </Box>
              <br/>
              <Box>
                  <TextField
                      select
                      value={currentProvider}
                      variant="outlined" 
                      name="proveedor" 
                      label="Proveedor de pago"
                      fullWidth
                      onChange={(e) => {
                        setCurrentProvider(e.target.value);
                      }}>   
                      {
                        proveedores.map((e, i) => (
                          <MenuItem key={i} value={e}>
                              {e.name}
                          </MenuItem>
                        ))
                      }
                  </TextField>
              </Box>
              <br/>
              <Box>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                          inputVariant="outlined"
                          label="Fecha de reporte"
                          fullWidth
                          format='dd/MM/yyyy'
                          required
                          value={date}
                          onChange={setDate}
                      />
                  </MuiPickersUtilsProvider>
              </Box>
              <br />
              <Box>
                <AutocompleteFromEntity
                  value={sucursal}
                  enableCreation={false}
                  label="Sucursales"
                  placeholder="Sucursales"
                  entity="Sucursal"
                  field="name"
                  setObject={(obj) => {
                      if(obj){
                          setSucursal(obj._id);
                          loadMunicipiosSucursal(obj._id)
                      }else{
                          setSucursal(null);
                          setMunicipio(null);
                          loadMunicipiosSucursal(null);                                                        
                      }
                  }}
                  condition={{delete:false}}
                  multiple={false}
                  selectAll={false}
                />
              </Box>
              <br />
              <Box>
                <Autocomplete
                  key={sucursal}
                  fullWidth       
                  options={municipiosDisponibles}      
                  getOptionLabel={(option) => {
                      return option.codigo + " - " + option.name;
                  }} 
                  onChange={(e, value)=>{
                      if(value){
                          setMunicipio(value);       
                          loadPuestos(value._id)                                                 
                      }else{
                          setMunicipio(null);
                      }
                  }}             
                  renderInput={(params) => (
                  <TextField
                      {...params}                                                    
                      variant="outlined"
                      label="Municipio"
                      placeholder="Municipio"
                      />
                  )}
                />
              </Box>
              <br />
              <Box>
                <Autocomplete
                  key={municipio}
                  fullWidth       
                  options={puestosDisponibles}     
                  getOptionLabel={(option) => {
                      return option.codigoDivipol + " - " + option.nombreDelPuesto + " - " + option.tipoZona;
                  }}
                  onChange={(e, value)=>{
                      if(value){                                                        
                          setPuesto(value);    
                      }else{
                          setPuesto(null);
                      }
                  }}   
                  renderInput={(params) => (
                  <TextField
                      {...params}                                                    
                      variant="outlined"
                      label="Puesto"
                      placeholder="Puesto"
                      />
                  )}
                />
              </Box>
          </Box>
      </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>Cancelar</Button>
        <Button color="primary" onClick={handleSubmit}>Generar</Button>
      </DialogActions>
  </Dialog>
  )
}
