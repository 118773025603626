import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core"

export const SignatureRequest = ({open, handleClose, onSubmit}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"><b>Solicitar firma de documento equivalente</b></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Se enviará un correo al supervisor para que firme el documento equivalente.
                </DialogContentText>
            </DialogContent>                
            <DialogActions style={{textAlign: 'right'}}>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={onSubmit} color="primary">
                    Solicitar
                </Button>
            </DialogActions>
        </Dialog>
    )
}