import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import { handleResponse, isAuthenticated } from "../../../src/core/sessionAPI";


// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';


// Validaciones
import { 
    letrasEspacios,
    letrasNumerosCaracteres,
    corregirEspaciado
} from "../../helpers/Validator";
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../App.css";

// Servicios
import { createProductosEvento, updateProductosEvento } from '../../../src/core/apiProductosEvento';
import { getSedes } from '../../../src/core/apiSedes';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioProductos ({eventoDefault, open, close, mode, data, refreshTable, eventos = []})
{
    const classes = useStyles();
    const theme = useTheme();

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    const [switchEntrega, setSwitchEntrega] = useState(false);
    const [switchRecoleccion, setSwitchRecoleccion] = useState(false);

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        // Corregir espaciado de algunos campos
        values.name = corregirEspaciado(values.name);      

        //Se adiciona el campo empresa al formulario de registro
        var user = isAuthenticated().user;
        values.empresa = user.empresa;
        //Registro de funcionarios de la plataforma
        values.entrega = switchEntrega;
        values.recoleccion = switchRecoleccion;

        let res = {};
        if (mode === "crear"){
            res = await createProductosEvento(values);
        }else if (mode === "editar"){
            values.id = data._id;
            res = await updateProductosEvento(values);
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            resetForm();
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */
    const formik = useFormik({
        initialValues:{
            name: "",
            entrega: "",
            recoleccion: "",
            evento: ''
        },
        validationSchema: yup.object().shape({
            name: yup.string().required("Debes llenar este campo"),
            evento: yup.string().required("Debes llenar este campo"),
            entrega: yup.string(),
            recoleccion: yup.string()
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const entregaProps = getFieldProps("entrega");
    const recoleccionProps = getFieldProps("recoleccion");
    const eventoProps = getFieldProps("evento");

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                setSwitchEntrega(false);
                setSwitchRecoleccion(false);
                formik.setFieldValue("evento", eventoDefault);
                setFormTitle("Registrar nuevo Producto");
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos del Producto");
                formik.setFieldValue("name", data.name);
                setSwitchEntrega(data.entrega);
                setSwitchRecoleccion(data.recoleccion);
                formik.setFieldValue("evento", data?.evento?._id);
            }
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [open]);

    //Switch
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 55,
        height: 34,
        padding: 5,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(28px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 6,
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 20,
          height: 20,
          borderRadius: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 32 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
    }));

    const formFields = () => (
        <>
            <Box p={5}>
                <Grid container spacing={8}>
                    <Grid item xs={12} className="smallPadding">
                        <TextField
                            select
                            variant="outlined" 
                            name="evento" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.evento ? errors.evento : ""}
                            error={touched.evento && Boolean(errors.evento)}
                            {...eventoProps}
                        >   
                            {
                                eventos.map((e, i) => (
                                    <MenuItem key={i} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>    
                    <Grid item xs={12} className="smallPadding">
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombre"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={6} ms={6} className="smallPadding centerContent">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">Entrega</div>
                            <Stack 
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center"
                                name="entrega"
                                {...entregaProps}>
                                <Typography>No</Typography>
                                <AntSwitch 
                                    checked = {switchEntrega}
                                    onChange = {() => setSwitchEntrega(!switchEntrega)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    
                                />
                                <Typography>Si</Typography>
                            </Stack>
                        </Box>                        
                    </Grid>   
                    <Grid item xs={6} ms={6} className="smallPadding centerContent">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">Recolección</div>
                            <Stack 
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center"
                                {...recoleccionProps}
                                name="recoleccion">
                                <Typography>No</Typography>
                                <AntSwitch 
                                    checked = {switchRecoleccion}
                                    onChange = {() => setSwitchRecoleccion(!switchRecoleccion)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                                <Typography>Si</Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>    
            </Box>  
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}