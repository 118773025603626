import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../../helpers/Validator";
import { handleResponse, isAuthenticated } from "../../../core/sessionAPI";

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery,
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    InputLabel,
    Checkbox
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../shared/Loader';
import ShowAlert from '../../shared/Snackbar';
import "../../../../src/App.css";

// Servicios
import { createGerente, updateGerente } from '../../../core/apiGerentes';
import AutocompleteFromEntity from '../../controls/AutocompleteFromEntity';

import { 
    letrasEspacios,
    letrasNumerosCaracteres,
    corregirEspaciado
} from "../../../helpers/Validator";



const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Formulario ({open, close, mode, data, refreshTable})
{
    const classes = useStyles();
    const theme = useTheme();
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [reloadCities, setReloadCities] = useState(0);
    const [entregaCheck, setEntregaCheck] = useState(false);
    const [recolectaCheck, setRecolectaCheck] = useState(false);
    const [CADCheck, setCADCheck] = useState(false);
    const [canUpdateUsersCheck, setCanUpdateUsersCheck] = useState(false);
    const [canCreateUsersCheck, setCanCreateUsersCheck] = useState(false);
    const [sucursal, setSucursal] = useState(null);
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        setReloadCities(0)
        setDepartamentos([])
        setCiudades([])
        setEntregaCheck(false);
        setRecolectaCheck(false);
        setCADCheck(false);
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader(); 
        console.log(sucursal);
        if(sucursal===null || sucursal===undefined){
            setSnackbarProps("e" + "Debes ingresar la sucursal a la que perteneces");
        }else{
            values.name = corregirEspaciado(values.name);
            values.firstLastname = corregirEspaciado(values.firstLastname);   
            values.secondLastname = corregirEspaciado(values.secondLastname);      
            values.ciudades = ciudades;
            values.departamentos = departamentos;    
            values.entrega = entregaCheck;
            values.recolecta = recolectaCheck;
            values.CAD = CADCheck;
            values.canUpdateUsers = canUpdateUsersCheck
            values.canCreateUsers = canCreateUsersCheck
            //Se adiciona el campo empresa al formulario de registro
            var user = isAuthenticated().user;
            //Registro de funcionarios de la plataforma
            //Se asocia el gerente a la empresa del administrador que lo crea 
            values.empresa = user.empresa;
            if(sucursal){
                values.sucursal = sucursal._id;
            }else{
                values.sucursal = undefined;
            }

            let res = {};
            if (mode === "crear"){
                res = await createGerente(values);
            }else if (mode === "editar"){
                if(sucursal._id){
                    values.sucursal = sucursal._id;   
                }else if (sucursal){
                    values.sucursal = sucursal;
                }
                values.id = data._id;
                res = await updateGerente(values);
            }

            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                setSnackbarProps("s" + res.data.message);
                
                //borrar campos
                resetForm();
                //reiniciar tabla de vehiculos
                refreshTable();
            }
        }
        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            name: "",
            firstLastname: "",
            secondLastname: "",
            documento: "",
            telefono: "",
            email: "",
            password: "",
            confirmPassword: "",
            entrega: false,
            recolecta: false,
            tipoUsuario: '',
            canUpdateUsers: false,
            canCreateUsers: false,
        },
        validationSchema: yup.object().shape({
            name: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            firstLastname: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            documento: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            telefono: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            email: yup.string().email("Ingresa un email valido").required("Debes llenar este campo"),
            password: yup.string().matches(regex.letrasNumerosCaracteres, "La contraseña se puede conformar por números, letras y caracteres como - _ * + . # $ % & @ ¡ ! ¿ ?").required("Debes llenar este campo"),
            confirmPassword: yup.string().required("Debes llenar este campo").oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
            tipoUsuario: yup.string().required("Debes llenar este campo")
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const firstLastnameProps = getFieldProps("firstLastname");
    const secondLastnameProps = getFieldProps("secondLastname");
    const documentoProps = getFieldProps("documento");
    const telefonoProps = getFieldProps("telefono");
    const emailProps = getFieldProps("email");
    const passwordProps = getFieldProps("password");
    const confirmPasswordProps = getFieldProps("confirmPassword");    
    const tipoUsuarioProps = getFieldProps("tipoUsuario");    
    

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            setReloadCities(0)
            var user = isAuthenticated().user;
            console.log(user);

            if (mode === "crear"){
                formik.handleReset();
                setCiudades([])
                setDepartamentos([])
                setEntregaCheck(false);
                setRecolectaCheck(false);
                setCADCheck(false);
                setSucursal(null);
                setFormTitle("Registrar nuevo Gerente");
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos del Gerente");
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("firstLastname", data.firstLastname);
                formik.setFieldValue("secondLastname", data.secondLastname);
                formik.setFieldValue("documento", data.documento);
                formik.setFieldValue("telefono", data.telefono);
                formik.setFieldValue("email", data.email);
                formik.setFieldValue("tipoUsuario", data?.tipoUsuario || '');
                setEntregaCheck(data?.entrega || false)
                setCADCheck(data?.CAD || false);
                setRecolectaCheck(data?.recolecta || false)
                // Se pone cualquier valor para que formik los tome como válidos
                formik.setFieldValue("password", "xxxxxxxxxxxxxxx");
                formik.setFieldValue("confirmPassword", "xxxxxxxxxxxxxxx"); 
                setDepartamentos(data.departamentos)
                setCiudades(data.ciudades)
                setSucursal(data?.sucursal)
                setCanCreateUsersCheck(data?.canCreateUsers)
                setCanUpdateUsersCheck(data?.canUpdateUsers)
            }
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos basicos de Gerentes</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombre"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="firstLastname" 
                            label="Primer apellido"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.firstLastname ? errors.firstLastname : ""}
                            error={touched.firstLastname && Boolean(errors.firstLastname)}
                            {...firstLastnameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="secondLastname" 
                            label="Segundo apellido"
                            fullWidth                            
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.secondLastname ? errors.secondLastname : ""}
                            error={touched.secondLastname && Boolean(errors.secondLastname)}
                            {...secondLastnameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="documento" 
                            label="Cédula"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.documento ? errors.documento : ""}
                            error={touched.documento && Boolean(errors.documento)}
                            {...documentoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="telefono" 
                            label="Celular"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.telefono ? errors.telefono : ""}
                            error={touched.telefono && Boolean(errors.telefono)}
                            {...telefonoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="email" 
                            label="Correo"
                            fullWidth
                            required
                            inputProps={{ maxLength: 80 }}
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            {...emailProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            variant="outlined" 
                            name="tipoUsuario" 
                            label="Tipo de usuario"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.tipoUsuario ? errors.tipoUsuario : ""}
                            error={touched.tipoUsuario && Boolean(errors.tipoUsuario)}
                            {...tipoUsuarioProps}
                        >   
                            <MenuItem key='Gerente' value='Gerente'>Gerente</MenuItem>
                            <MenuItem key='AsistenciaGerente' value='Asistente de gerencia'>Asistente de gerencia</MenuItem>
                        </TextField>
                    </Grid>
                    {   mode == 'editar' ?
                        <></> :
                        <>
                            <Grid item xs={12}>
                                <h3>Datos de acceso</h3>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined" 
                                    name="password" 
                                    label="Contraseña" 
                                    type="password"
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 30 }}
                                    helperText={touched.password ? errors.password : ""}
                                    error={touched.password && Boolean(errors.password)}
                                    onKeyPress={letrasNumerosCaracteres(30)}
                                    {...passwordProps}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined" 
                                    name="confirmPassword" 
                                    label="Confirmar contraseña" 
                                    type="password"
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 30 }}
                                    helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                    {...confirmPasswordProps}
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <h3>Asignación de departamentos y municipios</h3>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <AutocompleteFromEntity
                            value={departamentos}
                            enableCreation={false}
                            label="Departamentos"
                            placeholder="Seleccione los departamentos asignados"
                            entity="Departamento"
                            field="name"
                            setObject={(objs) => {
                                console.log("Departamentos seleccionados");
                                console.log(objs);
                                setDepartamentos(objs);
                                setReloadCities(objs.length)
                            }} 
                            condition={{delete:false}}
                            multiple={true}
                            selectAll={true}
                            showOtherField={{field: 'codigo', show: true}}
                        />
                    </Grid>                  
                    <Grid item xs={12} sm={6}>
                        <AutocompleteFromEntity
                            value={ciudades}
                            enableCreation={false}
                            label="Ciudades"
                            placeholder="Seleccione las ciudades asignadas"
                            entity="Ciudad"
                            field="name"
                            reloadFlag={reloadCities}
                            setObject={(objs) => {
                                console.log("Ciudades seleccionadas");
                                console.log(objs)
                                setCiudades(objs);
                            }}
                            condition={{departamento:{$in:departamentos}, delete:false}}
                            multiple={true}
                            selectAll={true}
                            showOtherField={{field: 'codigo', show: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel 
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'center'}}
                        >
                            Entrega
                            <Checkbox
                                checked={entregaCheck}
                                onChange={() => setEntregaCheck(!entregaCheck)}
                                size='medium'
                                color='primary'
                                aria-label='Entrega'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </InputLabel>
                    </Grid> 
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'center'}}
                        >
                            Recolecta
                            <Checkbox
                                checked={recolectaCheck}
                                onChange={() => setRecolectaCheck(!recolectaCheck)}
                                size='medium'
                                color='primary'
                                aria-label='Recolecta'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </InputLabel>
                    </Grid>   
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'center'}}
                        >
                            Entrega CAD
                            <Checkbox
                                checked={CADCheck}
                                onChange={() => setCADCheck(!CADCheck)}
                                size='medium'
                                color='primary'
                                aria-label='CAD'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </InputLabel>
                    </Grid>  
                    <Grid item xs={12}>
                        <h3>Controles de usuarios</h3>
                    </Grid>                      
                    <Grid item xs={6}>
                        <InputLabel 
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'center'}}
                        >
                            Puede crear usuarios
                            <Checkbox
                                checked={canCreateUsersCheck}
                                onChange={() => setCanCreateUsersCheck(!canCreateUsersCheck)}
                                size='medium'
                                color='primary'
                                aria-label='Puede crear usuarios'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </InputLabel>
                    </Grid> 
                    <Grid item xs={6}>
                        <InputLabel
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'center'}}
                        >
                            Puede editar usuarios
                            <Checkbox
                                checked={canUpdateUsersCheck}
                                onChange={() => setCanUpdateUsersCheck(!canUpdateUsersCheck)}
                                size='medium'
                                color='primary'
                                aria-label='Puede editar usuarios'
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </InputLabel>
                    </Grid> 

                    <Grid item xs={12}>
                        <h3>Asignación de Sucursal</h3>
                    </Grid>                      
                    <Grid item xs={6}>
                        <AutocompleteFromEntity
                            size="small"
                            value={sucursal}
                            enableCreation={false}
                            label="Sucursal"
                            placeholder="Seleccione la sucursal asignada"
                            entity="Sucursal"
                            field="name"
                            setObject={(objs) => {
                                setSucursal(objs);
                            }}
                            multiple={false}
                            selectAll={false}
                        />
                    </Grid> 

                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </Box>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}