import { Avatar, Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@material-ui/core';
import React, { useState } from 'react'
import CustomTableHead from '../shared/Tablas/CustomTableHead';
import { Icons, tableStyles } from '../../assets/js/GeneralStyles';
import ServerPagination from '../shared/Tablas/ServerPagination';

const headCells = 
[
	{ id: 'options', label: 'Personal', align: 'left', sortable: false },
  { id: 'codigoDivipol', label: 'Código Divipol', align: 'left', sortable: false },
  { id: 'nombreDelPuesto', label: 'Nombre del puesto', align: 'left', sortable: false },
  { id: 'departamento', label: 'Departamento', align: 'left', sortable: false },
  { id: 'municipio', label: 'Municipio', align: 'left', sortable: false },
];

const rowsPerPageOptions = [50, 100, 200, 500];	

export const StandsTrackingTable = ({data, page, perPage, setPage, setPerPage, openStand}) => {
  const { stands, count } = data
	const tableClasses = tableStyles();
  const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

  return (
    <Grid container spacing={2}>
     	<Grid item xs={12} sm={6} />
			<Grid item xs={12} sm={6}>
				<ServerPagination 
					numberTotalRows={count}
					page={page}
					perPage={perPage}
					setPage={setPage}
					setPerPage={setPerPage}
					rowsPerPageOptions={rowsPerPageOptions}
					className="greyBackground rightAlign"
				/>			
      </Grid>
      <TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table">
						<CustomTableHead
							order={order}
							setOrder={setOrder}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
							headCells={headCells}/>
						<TableBody>
							{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
							{stands.length === 0 && (
								<TableRow>
									<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
										No se encontraron Puestos o no se han registrado.
									</TableCell>
								</TableRow>
							)}
							{/* Sí hay filas para mostrar */}
							{stands.map((row, index) => (
								<TableRow
									hover
									tabIndex={-1}
									key={index}
									style={
										row.hasSupervisores ?
										{background: '#ded'} : {background: '#fecfcf'}
									}
					>
									<TableCell className="col20">
										<Box display="flex" justifyContent="left" alignItems="left">
						<Avatar
							className={`
							${tableClasses.icons}
							${tableClasses.blue}
							`}
							variant="rounded"
							onClick={() => openStand(row)}
						>
							<Tooltip title="Ver personal">
							<Icons.People fontSize="inherit"/>
							</Tooltip>
						</Avatar>
						</Box>
					</TableCell>
									<TableCell className="col50" align="left">{row.codigoDivipol}</TableCell>
									<TableCell className="col300" align="left">{row.nombreDelPuesto}</TableCell>
									<TableCell className="col100" align="left">{row.departamento.name}</TableCell>
									<TableCell className="col100" align="left">{row.municipio.name}</TableCell>
								</TableRow>
							))}
						</TableBody>
				</Table>
			</TableContainer>		
    </Grid>
  )
}
