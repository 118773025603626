import { reteicaAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const procesarArchivoReteica = async(fileSelect, idEvento, setProgressUpload) =>
{
    if (isAuthenticated()){ 
            //Se inicia la subida directamente del archivo
        const data = new FormData() 
        data.append('file', fileSelect);
        data.append('idevento', idEvento);

        var res = await axios.post(API + "/procesarArchivoReteica", data, {
            headers: {
                token: isAuthenticated().token,
                idEvento: idEvento       
            },
            onUploadProgress: ProgressEvent => {
                setProgressUpload(ProgressEvent.loaded / ProgressEvent.total*100);
            }
        });
        
        return handleResponse(res);              

    }
}

export const getReteica = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getReteica`,{}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createReteica = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createReteica`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updateReteica = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateReteica`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}