import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';

// Componentes 
import { 
    Box,
    Avatar,
    Tooltip,
} from '@material-ui/core';

// Tabla
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';

// Estilos
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";

// Custom components
import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import CustomTableHead from "../shared/Tablas/CustomTableHead";
import Paginacion from "../shared/Tablas/Paginacion";
import PriceCheckRoundedIcon from '@mui/icons-material/PriceCheckRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import "../../App.css"
import { ConfirmacionControlPago } from './ConfirmacionControlPago';
import { cambiarEstadoPago, pagoSupervisorPagoAdelantadoOne, signatureRequest, getPagoSupervisorById } from '../../core/apiPagoSupervisor';
import { hideLoader, showLoader } from '../shared/Loader';
import { isAuthenticated } from '../../core/sessionAPI';
import { DialogValidarPago } from './DialogValidarPago';
import { GeneratePDFPagos } from '../../helpers/ExportPDF/generatePDFPagos';
import { ModalTwoButtons } from '../shared/ModalTwoButtons';
import GestureIcon from '@material-ui/icons/Gesture';
import { SignatureRequest } from './SignatureRequest';
import RestoreIcon from '@mui/icons-material/Restore';

const headCells = 
[
	{ id: 'options', label: 'Opciones', align: 'left', sortable: false },
    { id: 'item', label: '#', align: 'left', sortable: true },
    { id: 'Estado', label: 'Estado', align: 'left', sortable: true },
    { id: 'consecutivo', label: 'Consecutivo', align: 'left', sortable: true },
    { id: 'proveedor', label: 'Proveedor de Pago', align: 'left', sortable: true },
    { id: 'servicio', label: 'Rol', align: 'left', sortable: true },
    { id: 'dias', label: 'Días', align: 'left', sortable: true },
    { id: 'totalDias', label: 'Total Días ', align: 'left', sortable: true },
    { id: 'diasAdicionales', label: 'Días Adicionales', align: 'left', sortable: true },
    { id: 'totaldiasAdicionales', label: 'Total Días Adicionales', align: 'left', sortable: true },

	{ id: 'diasViaticos', label: 'Días Víaticos', align: 'left', sortable: true },
    { id: 'totalViaticos', label: 'Total Víaticos', align: 'left', sortable: true },

    { id: 'valor', label: 'Total', align: 'left', sortable: true },
    { id: 'creador', label: 'Creador', align: 'left', sortable: true },
    { id: 'Pago', label: 'Pago', align: 'left', sortable: true },
    { id: 'Fechapagp', label: 'Fecha de pago', align: 'left', sortable: true },
    { id: 'valorComision', label: 'Valor de la comisión', align: 'left', sortable: true },
	{ id: 'updatedAt', label: 'Última modificación', sortable: true},
];

export default function TablaPagoSupervisor ({edicionEvento, proyectos, handleEdit, reloadTable, setSnackbarProps, Supervisor})
{
	const {user} = isAuthenticated()
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');
	const [totalPago, setTotalPago] = useState(0)
	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 30;
	const rowsPerPageOptionsDefault = [30, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
	const [openForm, setOpenForm] = useState(false);
	const [validarPago, setValidarPago] = useState(false);
	const [conceptoActual, setConceptoActual] = useState(null)
	const [showModalRechazarPago, setShowModalRechazarPago] = useState(false)
	const [showModalRecuperarPago, setShowModalRecuperarPago] = useState(false)
	const [showSignatureRequest, setShowSignatureRequest] = useState(false)
	const [currentConcept, setCurrentConcept] = useState(null)
	
    useEffect(() => {
		let auxTotal = 0
        setRows(Array.from(proyectos, (p, index) =>{
			if(p?.estado != 'RECHAZADO' && p?.estado != 'ANULADO')
				auxTotal += p?.total || p?.valorTotalPago || 0
            return {
                item: index + 1,
				rol: p.rol?.name || '',
                consecutivo: p.consecutivo,
				dias: p.valorTotalPago ? "No aplica":p.dias,
				totalDia: p.valorTotalPago ? 0:p.totalDia,
				diasAdicionales: p.diasAdicionales,
				totalDiasAdicionales: p.totalDiasAdicionales,
				diasViaticos: p.diasViaticos,
				totalViaticos: p.totalViaticos,
				total: p.valorTotalPago ? p.valorTotalPago:p.total,
				proveedorPagos: p.proveedorPagos?.name || 'Sin asignar',
				creador: p?.creador || '',
				isPagado: p?.pagado,
				isPagoAdelantado: p?.pagadoAdelantado,
				fechaPagado: p?.fechaPagado,
				fechaPagadoAdelantado: p?.fechaPagadoAdelantado,
				estado: p.estado,
				valorComision: p?.valorComision || 'X',
				rest: p
            };
        }));
		setTotalPago(auxTotal)
    }, [proyectos]);
    
	const openSignatureRequest = async (idConcepto) => {
		setCurrentConcept(idConcepto)
		const response = await  getPagoSupervisorById(idConcepto._id)

		if(response.data?.signatureStatus !== "SIGNED" ){
			//console.log(response);
			setShowSignatureRequest(true)
		}else{
			setSnackbarProps("eNo se puede enviar la solicitud ya que el documento fue firmado");
		}
	}

	const closeSignatureRequest = () => {
		setShowSignatureRequest(false)
		setCurrentConcept(null)
	}

	const openFormPago = (rest) => {
		setConceptoActual(rest)
		setOpenForm(true)
	}

	const closeFormPago = () => {
		setOpenForm(false)
	}

	const openDialogValidarPago = (concept) => {
		setConceptoActual(concept)
		setValidarPago(true)
	}

	const closeDialogValidarPago = () => {
		setValidarPago(false)
	}

	const handlePagarOne = async () => {
		showLoader();
        const res = await pagoSupervisorPagoAdelantadoOne(conceptoActual, Supervisor?.proveedorPagos, Supervisor?.evento, Supervisor?.empresaOperadora)
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + 'El concepto fue pagado por adelantado');
            await reloadTable()
        }
		closeFormPago()
        hideLoader();
	}

	const handleChangeStateConcepto = async (estado) => {
		const concept = proyectos.find( item => item._id === conceptoActual)
		if(concept.rol.aplicaValorDiaAdicional && concept.diasAdicionales === 0 && estado === 'VALIDADO'){
			setSnackbarProps('eEl concepto no puede ser validado porque este rol debe tener días adicionales');
			return
		}
		let newState = estado
		if(concept?.pagado && concept?.estado === 'ANULADO') newState = 'PROCESADO'
		showLoader();
		const res = await cambiarEstadoPago(conceptoActual, newState)
		hideLoader();
		if (res.error){
			setSnackbarProps("e" + res.error);
		}else{
			showLoader();
			await reloadTable()
			hideLoader()
			setSnackbarProps("s" + res.data.message);
		}
		closeDialogValidarPago()
	}

	const handleSignatureRequest = async () => {
		showLoader();
		const {evento, supervisor, rol, _id, consecutivoFacturacion} = currentConcept
		const resp = await signatureRequest(evento, supervisor, rol, _id, consecutivoFacturacion)
		console.log(resp)
		if(resp?.error)  setSnackbarProps("e" + resp.error);
		else  setSnackbarProps("s" + resp.data.message);
		closeSignatureRequest()
		hideLoader();
	}

	const handleCloseModalRechazarPago = () => {
		setShowModalRechazarPago(false)
	}

	const handleOpenModalRechazarPago = (idConcepto) => {
		setConceptoActual(idConcepto)
		setShowModalRechazarPago(true)
	}

	const handleCloseModalRecuperarPago = () => {
		setShowModalRecuperarPago(false)
	}

	const handleOpenModalRecuperarPago = (idConcepto) => {
		setConceptoActual(idConcepto)
		setShowModalRecuperarPago(true)
	}

    return (
		<>
			<Box p={1} className="greyBackground">
				<h3 style={{textAlign: 'right',margin: 0}}>
					<CurrencyFormat value={totalPago ?? 0} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>PAGO TOTAL USUARIO: {value}</div>}/>
				</h3>
			</Box>
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}			
					/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron conceptos de pago o no se han registrado.
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								style={
									row?.rest?.documentoDescargado ?
									{background: '#ded'} : {}
								}
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className="col100">
									<Box display="flex" justifyContent="left" alignItems="left">
										{/* Editar */}
										{
											!row?.isPagado && row?.estado !== 'RECHAZADO' && row?.estado !== 'ANULADO'  ?
											<>
												{(edicionEvento || user?.role == 2) && 
													<Avatar
														className={`
															${tableClasses.icons} ${tableClasses.yellow}
														`}
														variant="rounded"
														onClick={() => handleEdit("editar", row.rest)}>
														<Tooltip title="Editar">
															<Icons.Edit fontSize="inherit"/>
														</Tooltip>
													</Avatar>
												}
												{	
													user?.role == 2 ?
														row.estado == 'NO VALIDADO' ?
														<Avatar
															className={`
																${tableClasses.icons} ${tableClasses.green}
															`}
															variant="rounded"
															onClick={() => openDialogValidarPago(row.rest?._id)}
														>
															<Tooltip title="Validar pago">
																<PriceCheckRoundedIcon fontSize="inherit"/>
															</Tooltip>
														</Avatar> :
															Supervisor?.tipoPago?.name !== 'Pago único' ?
																<Avatar
																	className={`
																		${tableClasses.icons} ${tableClasses.green}
																	`}
																	variant="rounded"
																	onClick={() => openFormPago(row.rest?._id)}
																>
																	<Tooltip title="Pagar">
																		<Icons.money fontSize="inherit"/>
																	</Tooltip>
																</Avatar> : <></>
													: <></>
												}				
											</>
											: 
											row.estado === 'PROCESADO' ? 
											<>
												<Avatar
													className={`
														${tableClasses.icons} ${tableClasses.red}
													`}
													variant="rounded"
													onClick={() => handleOpenModalRechazarPago(row.rest?._id)}
												>
													<Tooltip title="Anular pago">
														<RemoveCircleIcon fontSize="inherit"/>
													</Tooltip>
												</Avatar> 
												<GeneratePDFPagos values={{...row?.rest, restSupervisor: Supervisor}} setSnackbarProps={setSnackbarProps}/>
												{
													row?.rest?.signatureStatus === 'PENDING' || row?.rest?.signatureStatus === 'REQUESTED' ?
													<Avatar
														className={`
															${tableClasses.icons} ${tableClasses.green}
														`}
														variant="rounded"
														onClick={() => openSignatureRequest({...row.rest,supervisor: Supervisor})}
													>
														<Tooltip title="Solicitar firma">
															<GestureIcon fontSize="inherit"/>
														</Tooltip>
													</Avatar>
													: null
												}
											</>
											: row.isPagado ? 
												<GeneratePDFPagos values={{...row?.rest, restSupervisor: Supervisor}} setSnackbarProps={setSnackbarProps}/>
											:  <></>
										}					
										{ row.estado !== 'ANULADO' && row.estado !== 'PROCESADO' && index !== 0 ?
												<Avatar
													className={`
														${tableClasses.icons} ${tableClasses.red}
													`}
													variant="rounded"
													onClick={() => handleOpenModalRechazarPago(row.rest?._id)}
												>
													<Tooltip title="Anular pago">
														<RemoveCircleIcon fontSize="inherit"/>
													</Tooltip>
												</Avatar>  : <></>
										}			
										{ row.estado === 'ANULADO' && user?.role === 2 ?
												<Avatar
													className={`
														${tableClasses.icons} ${tableClasses.orange}
													`}
													variant="rounded"
													onClick={() => handleOpenModalRecuperarPago(row.rest?._id)}
												>
													<Tooltip title="Recuperar pago">
														<RestoreIcon fontSize="inherit"/>
													</Tooltip>
												</Avatar>  : <></>
										}				
									</Box>
								</TableCell>
								<TableCell className="col20" align="left">{row.item}</TableCell>								
								<TableCell className="col150" align="left">
									{
										<div className={
											row.estado == 'NO VALIDADO' ?
											'blockBase blockYellow' : 
												row.estado == 'VALIDADO' ? 
												'blockBase blockGreen' :
												row.estado == 'PROCESADO' ? 
												'blockBase blockBlue' :
												'blockBase blockRed'
										}>
												{row.estado}
										</div>
									}
								</TableCell>								
								<TableCell className="col50" align="left">{row.consecutivo}</TableCell>
								<TableCell className="col150" align="left">{row.proveedorPagos}</TableCell>
								<TableCell className="col250" align="left">{row.rol}</TableCell>								
								<TableCell className="col100" align="left">{row.dias}</TableCell>								
								<TableCell className="col100" align="left">
									<div className="blockBase">
										<CurrencyFormat value={row.totalDia ?? 0} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</div>
								</TableCell>								
								<TableCell className="col50" align="left">{row.diasAdicionales || '--'}</TableCell>								
								<TableCell className="col100" align="left">
									<div className="blockBase">
										<CurrencyFormat value={row.totalDiasAdicionales ?? 0} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</div>
								</TableCell>

								<TableCell className="col50" align="left">{row.diasViaticos || '--'}</TableCell>								
								<TableCell className="col100" align="left">
									<div className="blockBase">
										<CurrencyFormat value={row.totalViaticos ?? 0} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</div>
								</TableCell>

								<TableCell className="col100" align="left">
									<div className="blockBase blockGreen">
										<CurrencyFormat value={row.total ?? 0} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									</div>
								</TableCell>
								<TableCell className="col150" align="left">{row.creador}</TableCell>								
								<TableCell className="col200" align="left">
									<div className={row.isPagado ? "blockBase blockGreen":"blockBase blockRed"}>
									{
										row.isPagado ? 
											row.isPagoAdelantado ? 
											'Pagado por adelantado' :
											'Pago único'
										: 'Sin pagar'
									}
									</div>
								</TableCell>			
								<TableCell className="col200" align="left">
									{
										row.isPagado ? 
											row.isPagoAdelantado ? 
											new Date(row.fechaPagadoAdelantado).toLocaleString('es-co') :
											new Date(row.fechaPagado).toLocaleString('es-co')
										: 'Sin pagar'
									}
								</TableCell>
								<TableCell className="col200" align="left">
									<CurrencyFormat value={row.valorComision} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
								</TableCell>		
								<TableCell className="col150" align="left">
									{
										user?.role == 2 ?
										new Date(row.rest.updatedAt).toLocaleString('es-co')
										: 'Solo para admin'
									}
								</TableCell>	
								{/* <TableCell align="left">{new Date(row.fechaAdicion).toLocaleString('es-co')}</TableCell>	 */}							
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>

			<ConfirmacionControlPago
				open={openForm}
				Title='Ejecutar pago de concepto'
				handleClose={closeFormPago}
				handlePagar={handlePagarOne}
			/>

			<DialogValidarPago
				open={validarPago}
				handleClose={closeDialogValidarPago}
				validarPago={handleChangeStateConcepto}
			/>

			{/* Modal para rechazar pago despues de haber pagado */}
			<ModalTwoButtons
				open={showModalRechazarPago}
				handleClose={handleCloseModalRechazarPago}
				title='Anular pago'
				content='Tenga en cuenta que una vez anulado, no podrá reversar esta acción. Este consecutivo de facturación no será reutilizado'
				handleAcept={() => {
					handleChangeStateConcepto('ANULADO')
					handleCloseModalRechazarPago()
				}}
				nameButtonAcept='Si, quiero ANULAR el pago'
			/>
			<ModalTwoButtons
				open={showModalRecuperarPago}
				handleClose={handleCloseModalRecuperarPago}
				title='Recuperar pago'
				content='Este consecutivo de facturación será reutilizado y estará disponible.'
				handleAcept={() => {
					handleChangeStateConcepto('NO VALIDADO')
					handleCloseModalRecuperarPago()
				}}
				nameButtonAcept='Si, quiero RESTAURAR el pago'
			/>
			<SignatureRequest
				handleClose={closeSignatureRequest}
				onSubmit={handleSignatureRequest}
				open={showSignatureRequest}
			/>
    	</>
	);
}