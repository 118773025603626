import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import "../../../src/App.css";

import { FilterEvento } from '../empresasOperadoras/FilterEvento';
import FormularioSucursal from './FormularioSucursal';
import TablaSucursal from './TablaSucursal';
import { getSucursales } from '../../core/apiSucursal';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSucursal({open, close, eventos = []}){
    const classes = useStyles();
    const theme = useTheme();

    const [sucursales, setSucursales] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState([]);
    const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null)

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataSucursal = async () => {
        showLoader();
        const res = await getSucursales()

        if (res.error){
            setSnackbarProps("e" + res.error);
            setSucursales([]);
            setFiltering(res.data)
        }else{
            setSucursales(res.data);
            setFiltering(res.data);
        }
        
        hideLoader();;
    }

    useEffect(() => {
        async function inicializar() {
            await loadDataSucursal()
        }
        inicializar();
    }, []);

    const handleCrearEditarDepartamento= (tipo, deptSeleccionado) => {
        setSucursalSeleccionada(deptSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = () => {
        setOpenForm(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar sucursales
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <Filter
                                            source={sucursales}
                                            searchBy="sucursal"
                                            setTableData={setFiltering}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => handleCrearEditarDepartamento("crear", null)}>
                                                Agregar sucursal
                                            </Button>    
                                        </Grid>   
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>   
                            <TablaSucursal
                                sucursales = {filtering}
                                handleEdit = {handleCrearEditarDepartamento}
                                reloadTable = {loadDataSucursal}
                                setSnackbarProps ={setSnackbarProps}
                            />
                        </Paper>
                    </Box>                  
                </Box>               
            </Dialog>

            <FormularioSucursal
                open = {openForm}
                close = {closeForm}
                mode={modo}
                data={sucursalSeleccionada}
                refreshTable={loadDataSucursal}
            />
        </>
    );
}