import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField, MenuItem } from "@material-ui/core"

export const DialogValidarFirmaMasiva = ({open, handleClose, validarPago, title, content}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                        {content}
                </DialogContentText>
            </DialogContent>  
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    NO
                </Button>
                <Button onClick={validarPago} color="primary">
                    SI
                </Button>
            </DialogActions>
        </Dialog>
    )
}
