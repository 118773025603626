import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { showLoader, hideLoader } from '../shared/Loader';
import { tableStyles, Icons } from '../../assets/js/GeneralStyles'

import { deleteEntity } from '../../core/apiControls'
import '../../App.css';

import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Avatar,
    Tooltip,
    MenuItem
} from '@material-ui/core';
import { getConceptoCiudadAll } from '../../core/apiConceptoCiudad';

export default function DeleteControlConcepto({ entidad, idEntidad, setSnackbarProps, nameShowDelete, refreshEvent, labelText = "", callback = null }) {
    const tableClasses = tableStyles();
    const [open, setOpen] = useState(false);

    const handleDeleteEntity = async () => {
        showLoader();
        const dataConceptosMun = await getConceptoCiudadAll(idEntidad)

        if (dataConceptosMun.error) {
            setSnackbarProps("e" + dataConceptosMun.error);
        }

        if(!dataConceptosMun.data.length){
            var res = await deleteEntity(entidad, idEntidad);
            if (res.error) {
                setOpen(false);
                setSnackbarProps("e" + res.error);
            } else {
                setOpen(false);
                refreshEvent();
                setSnackbarProps("s" + "Concepto eliminado.");
                //Si se eliminar el registro se ejecutan el callback, para la continuidad del evento
                //Se valida si el objecto existe
                if (callback) {
                    callback(idEntidad);
                }
            }
            hideLoader();
        }else{
            hideLoader();
            setSnackbarProps("e" + 'No se puede eliminar, hay municipios asociados a este concepto')
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleDelete = () => e => {
        setOpen(true)
    }

    if (labelText == "") {
        return (
            <>
                <Avatar
                    className={`${tableClasses.icons} ${tableClasses.red}`}
                    variant="rounded"
                    onClick={handleDelete(entidad)}>
                    <Tooltip title="Eliminar">
                        <DeleteForeverIcon fontSize="inherit" />
                    </Tooltip>
                </Avatar>

                <ModalConfirmacion
                    open={open}
                    handleDeleteEntity={handleDeleteEntity}
                    handleClose={handleClose}
                    nameShowDelete={nameShowDelete}
                />
            </>
        )
    } else {
        return (
            <>
                <MenuItem onClick={handleDelete(entidad)} className="redControl">
                    <DeleteForeverIcon className="paddingRight" />{labelText}
                </MenuItem>

                <ModalConfirmacion
                    open={open}
                    handleDeleteEntity={handleDeleteEntity}
                    handleClose={handleClose}
                    nameShowDelete={nameShowDelete}
                />
            </>
        )
    }
}

export function ModalConfirmacion({ open, handleClose, handleDeleteEntity, nameShowDelete }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Eliminación de registro {nameShowDelete}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Confirma que desea eliminar el registro seleccionado: {nameShowDelete}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleDeleteEntity} color="secondary" autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    )
}