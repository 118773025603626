import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField, MenuItem } from "@material-ui/core"
import React, { useEffect, useState } from 'react'


export const ConfirmacionControlPago = ({open, handleClose, Title, handlePagar, proveedorPagos}) => {
	return (
		<Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{Title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    El pago se realizará con el proveedor de pagos: {proveedorPagos}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{textAlign: 'right'}}>
                <Button onClick={handleClose} color="secondary" autoFocus>
                    Cancelar
                </Button>
                <Button onClick={() => handlePagar()} color="primary">
                    Pagar
                </Button>
            </DialogActions>
        </Dialog>
	)
}

