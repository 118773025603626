import { BlobProvider, PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { PDFModel } from '../../helpers/ExportPDF/generatePDFPagos'
import { validarReportePDFWithoutToken } from '../../core/apiPagoSupervisor'
import { Skeleton, Button } from '@mui/material'
import '../../assets/css/loader.css'
import useResponsive from '../../hooks/useResponsive'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { showLoader, hideLoader } from '../shared/Loader'

export const ElectronicSignaturePDF = ({concept, setPdf, Pdf, onMensajeError}) => {
  const {isMobile} = useResponsive();

  useEffect(() => {
    const resizeDiv = () => {
      const div = document.getElementById('pdfContainer');
      const width = div.offsetWidth;
      const height = (width * 595) / 842; // Relación de tamaño 842 x 595

      div.style.height = `${height}px`;
    };
    if( !isMobile) {
      window.addEventListener('resize', resizeDiv);
      resizeDiv();
    }
    return () => {
      window.removeEventListener('resize', resizeDiv);
    };
  }, [isMobile]);

  const onVerPdf = async () => {
    try {
      showLoader()
      const parsedData = {...concept, restSupervisor: concept?.supervisor}

      const completeConceptData = await validarReportePDFWithoutToken(
        parsedData?.restSupervisor?.evento,
        parsedData.consecutivo,
        parsedData?.restSupervisor,
        parsedData?.rol,
        parsedData
      );
      const pagoSupervisorCompleto= { ...parsedData, ...completeConceptData.data };
     const blob = await pdf((
      <PDFModel values={pagoSupervisorCompleto} />
      )).toBlob();
      saveAs(blob, `documento-equivalente-${concept?.consecutivoFacturacion}.pdf`); 
      hideLoader();
    } catch (error) {
      hideLoader();
      onMensajeError();
    }
  }

  useEffect(() => {
    async function init(){
      const parsedData = {...concept, restSupervisor: concept?.supervisor}
      const completeConceptData = await validarReportePDFWithoutToken(
        parsedData?.restSupervisor?.evento,
        parsedData.consecutivo,
        parsedData?.restSupervisor,
        parsedData?.rol,
        parsedData
      )
      const pagoSupervisorCompleto= { ...parsedData, ...completeConceptData.data }
      setPdf(<PDFModel values={pagoSupervisorCompleto} />)
    }
   init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!isMobile){
    return (
      <>
      { concept ?
        <PDFViewer id='pdfContainer' width='100%' height='100%' showToolbar={false}>
          { Pdf }
        </PDFViewer>
        : <Skeleton variant="rectangular" animation='wave' width="100%" height="550px" />
      }
      </>
    )
  }
  return (
    <>
      <div id='pdfContainer'>
            <div style={{paddingBlock: 80, paddingLeft:20, paddingRight: 20, border: '3px dashed #BDBDBD'}}>
              <Button 
                    variant="contained"
                    color="secondary"
                    fullWidth
                  // disabled={isSubmitting}
                    onClick={onVerPdf}
                    //style={{maxWidth: 'fit-content'}} 
                    size='large'
                    >
                    Ver Documento PDF
                </Button>  
            </div>
      </div>
    </>
  )
}
