/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
    TextField
} from "@material-ui/core";

import { controlsAPI as API } from "../../core/apiUrls";
import axios from 'axios';
import "../../App.css";
import CircularProgress from '@mui/material/CircularProgress';

/*
--Ejemplo de uso

<Grid item xs={12}>
    <AutocompleteFromEntity
        enableCreation={true}
        label="Marca"
        placeholder="Seleccione la marca del producto"
        entity="Marca"
        field="name"
        setObject={(obj) => {
            console.log(obj)
        }}
        condition={{delete:false}}
        multiple={false}
    />
</Grid> 
*/

export default function AutocompleteFromEntityRegistro({label, placeholder, setObject, entity, field, selectAll = false, enableCreation = false, condition, multiple, disabled = false, reloadFlag = 0, showOtherField = {field: '', show: false}, value, size="medium", fields = []}){
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadAllObjects = async () => {
        setLoading(true);
        await axios.post(API + "/getDataFromEntity", {entity, condition}).then(async res => {    
            setLoading(false);
            if(!res.data.error){
                setItems(res.data);
            }
        })
    }

    const searchDefaultValue = (option) => {
        for(let i = 0; i < items?.length; i++){
            if(option === items[i]?._id){
                return items[i];
            }
        }
        return option
    }
    
    useEffect(() =>{
        async function init(){
            await loadAllObjects();
        }

        init();
    },[])

    
    useEffect(() =>{        
        async function init(){
            await loadAllObjects();
        }

        init();
    },[reloadFlag])

    return(
        <>
            {items.length > 0 && !loading &&
                <Autocomplete
                    size={size}
                    fullWidth
                    defaultValue={(value && value.length) ? value : undefined}
                    disabled={disabled}
                    multiple={multiple}
                    freeSolo={enableCreation}
                    options={
                        (selectAll && items.length) > 0 ? [{_id: '0', [field]: 'SELECCIONAR TODOS', isSelectAll: true}, ...items] : items
                    }
                    getOptionLabel={(option) => {        
                    
                        if(fields.length > 0){
                            var stringView = ""

                            for(var i=0; i < fields.length; i++){
                                if(stringView == ""){
                                    if(fields[i] != "-"){
                                        stringView = option[fields[i]];
                                    }else{
                                        stringView = fields[i];
                                    }
                                }else{
                                    if(fields[i] != "-"){
                                        if(option[fields[i]])
                                            stringView = stringView + " " + option[fields[i]];
                                    }else{
                                        stringView = stringView + " " + fields[i];
                                    }
                                }
                            }

                            return stringView;
                        }else{
                            if(!multiple && option[0] && showOtherField.show){
                                return  `${option[0][showOtherField.field]} - ${option[0][field]}`
                            }

                            if(multiple &&  !showOtherField.show){
                                const item = searchDefaultValue(option);
                                return item[field]
                            }

                            if(option._id){
                                return (showOtherField.show && option?.[showOtherField.field] ? `${option[showOtherField.field]} - ${option[field]}`:  option[field])
                            }else if(value){
                                const item = searchDefaultValue(option);
                                if(item.codigo && item.name){
                                    return `${item?.codigo} - ${item?.name}`
                                }

                                return `${item?.codigoDivipol} - ${item?.nombreDelPuesto}`
                            }else{
                                return option
                            }
                        }
                    }}
                    onChange={async (e, value) => {
                        //En caso de que sea una selección        
                        //var user = isAuthenticated().user;
                        if(value){
                            if(value?.[0]?.isSelectAll){
                                setObject(items)
                            }else{
                                if(multiple){
                                    var completeArray = new Array();
                                    for(var i = 0; i < value.length; i++){
                                        const aux = searchDefaultValue(value[i])
                                        if(value[i]._id){
                                            completeArray.push(value[i]);
                                        }else if(aux?._id){
                                            completeArray.push(aux)
                                        }else{
                                            var res = await axios.post(API + "/createDataInEntity", {entity: entity, column: field, value:value[i], empresa: ""});
                                            completeArray.push(res.data);
                                        }
                                    }
                                    setObject(completeArray);
                                }else{
                                    if(value._id){
                                        setObject(value);
                                    }else{                                
                                        axios.post(API + "/createDataInEntity", {entity: entity, column: field, value:value, empresa: ""}).then(res => {     
                                            //Retorna todos los objetos de la lista, son cargados nuevamente
                                            //Y se retorna el objeto seleccionad   
                                            setObject(res.data);
                                            loadAllObjects();   
                                        })
                                    }
                                }
                            }
                        }else{
                            if(multiple){
                                setObject([]);
                            }else{
                                setObject("");
                            }
                        }
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        name={"field_"  + label}
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                        />
                    )}
                />
            }
            {items.length == 0 && !loading && 
                <Autocomplete
                    fullWidth         
                    size={size}           
                    options={[]}                    
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        name={"field_"  + label}
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                        />
                    )}
                />            
            }
            {loading &&
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder={label}
                    disabled
                    InputProps={{
                        endAdornment: <CircularProgress size={20} />
                    }}
                />                
            }
        </>
    )
}