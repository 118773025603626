import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { ElectronicSignatureForm } from './ElectronicSignatureForm'
import { ElectronicSignatureFormMovil } from './ElectronicSignatureFormMovil'
import { ElectronicSignaturePDF } from './ElectronicSignaturePDF'
import { Skeleton, Button } from '@mui/material'
import useResponsive from '../../hooks/useResponsive'
import { MyInput } from '../formikComponents/MyInput'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import ShowAlert from '../shared/Snackbar';

const INITIAL_VALUES = {
  documento: '',
  fullName: '',
  signatureUrl: null,
  signatureDate: null
}

export const ElectronicSignatureContainer = ({concept, reloadPage}) => {
  const [snackbarProps, setSnackbarProps] = useState()
  const [values, setValues] = useState(INITIAL_VALUES);
  const [Pdf, setPdf] = useState();
  const [continuar, setContinuar] = useState(false);

  const {isMobile} = useResponsive();

  const onMensajeError = () => {
    setSnackbarProps('eError al generar el Pdf')
  }

  const onSubmit = async ({documento, fullName}) => {
    if(documento !== concept?.supervisor?.documento){
      setSnackbarProps('eEl documento no coincide con el del usuario')
      return
    }

    setContinuar(true);
  }

  return (
    <>
    {isMobile? 
      <> 
        {continuar? <>
                      <>
                        <Grid item xs={12} md={4}>      
                        <ElectronicSignatureFormMovil 
                          reloadPage={reloadPage}
                          values={values} 
                          setValues={setValues} 
                          concept={concept} 
                          document={Pdf}
                        />
                        </Grid>
                      </> 
                  </> 
                  : 
                  <>
                  <Grid item xs={12} md={8}>
                          { concept ? 
                            <ElectronicSignaturePDF 
                              concept={{...concept, signatureUrl: values.signatureUrl, signatureDate: values.signatureDate}}
                              Pdf={Pdf}
                              setPdf={setPdf}
                              onMensajeError={onMensajeError}
                            />
                            : <Skeleton variant="rectangular" animation='wave' width="100%" height="550px" /> 
                          }
                  </Grid>
                  
                  <Formik
                    initialValues={values}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                  >
                  { () => 
                    <Form style={{paddingTop: 20}}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <MyInput 
                            label='Documento de identidad'
                            name='documento'
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Button 
                            type='submit' 
                            variant='contained'
                            color='primary'
                            fullWidth
                            style={{textAlign: 'center'}}
                          >
                            Continuar y Firmar
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  }
                  </Formik>
                  </> 
        } 
      </> 
      : 
      <>
      <Grid container spacing={2} style={{minHeight: 'inherit'}}>
          <Grid item xs={12} md={8}>
            { concept ? 
              <ElectronicSignaturePDF 
                concept={{...concept, signatureUrl: values.signatureUrl, signatureDate: values.signatureDate}}
                Pdf={Pdf}
                setPdf={setPdf}
              />
              : <Skeleton variant="rectangular" animation='wave' width="100%" height="550px" /> 
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <ElectronicSignatureForm 
              reloadPage={reloadPage}
              values={values} 
              setValues={setValues} 
              concept={concept} 
              document={Pdf}
            />
          </Grid>
        </Grid>
      </> 
      }
      {ShowAlert(snackbarProps, setSnackbarProps)}  
      </>
  )
}

const schema = yup.object().shape({
  documento: yup
    .number()
    .typeError('Debe ser un número')
    .required('El documento es requerido'),
});
