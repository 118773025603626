import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { numeros, regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    MenuItem
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import { createResolucionFacturacion, updateResolucionFacturacion } from '../../core/apiResolucionFacturacion';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioResolucionFacturacion ({eventos, open, close, mode, data, refreshTable})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    const [fecha, setFecha] = useState(new Date())
    
    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        //Se adiciona el campo empresa al formulario de registro
        //Existe por lo menos un archivo cargado
        if(parseInt(values.inicioRango) < parseInt(values.finRango)){
            values.fechaVencimiento = fecha
            let res = {};
            if (mode === "crear"){
                values.ultimoConsecutivo = values.inicioRango - 1

                res = await createResolucionFacturacion(values)
            }else if (mode === "editar"){
                values.id = data._id;
                res = await updateResolucionFacturacion(values)
            }

            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                setSnackbarProps("s" + res.data.message);
                
                //borrar campos
                resetForm();
                //reiniciar tabla de vehiculos
                refreshTable();
        }}else{
            setSnackbarProps('e' + 'El rango final debe ser mayor al rango incial')
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            evento: '',
            descripcion: '',
            inicioRango: 0,
            finRango: 0,
            codigo: ''
        },
        validationSchema: yup.object().shape({
            evento: yup.string().required("Debes llenar este campo"),
            codigo: yup.string().required("Debes llenar este campo"),
            descripcion: yup.string().required("Debes llenar este campo"),
            finRango: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            inicioRango: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const eventoProps = getFieldProps("evento");
    const descripcionProps = getFieldProps("descripcion");
    const finRangoProps = getFieldProps("finRango");
    const inicioRangoProps = getFieldProps("inicioRango");
    const codigoProps = getFieldProps("codigo");
    
    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                formik.setFieldValue("evento", eventos[0]?._id);
                setFormTitle("Registrar resolución de facturación");
                setFecha(new Date())
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos de la resolución de facturación");
                formik.setFieldValue("evento", data.evento._id);
                formik.setFieldValue("inicioRango", data.inicioRango);
                formik.setFieldValue("finRango", data.finRango);
                formik.setFieldValue("descripcion", data.descripcion);
                formik.setFieldValue("codigo", data.codigo);
                setFecha(data.fechaVencimiento)
            }
        }

        init();
        // eslint-disable-next-line
    }, [open]);


    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos de la resolución de facturación</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="evento" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.evento ? errors.evento : ""}
                            error={touched.evento && Boolean(errors.evento)}
                            {...eventoProps}
                        >   
                            {
                                eventos.map((e, i) => (
                                    <MenuItem key={i} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="codigo" 
                            label="Código"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.codigo ? errors.codigo : ""}
                            error={touched.codigo && Boolean(errors.codigo)}
                            {...codigoProps}
                        />  
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                name="fechaVencimiento" 
                                inputVariant="outlined"
                                label="Fecha de vencimiento"
                                fullWidth
                                format='dd/MM/yyyy'
                                required
                                value={fecha}
                                onChange={setFecha}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>  
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined" 
                            name="descripcion" 
                            label="Descripción"
                            fullWidth
                            required
                            helperText={touched.descripcion ? errors.descripcion : ""}
                            error={touched.descripcion && Boolean(errors.descripcion)}
                            {...descripcionProps}
                        />  
                    </Grid>   
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="inicioRango" 
                            label="Rango inicial"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.inicioRango ? errors.inicioRango : ""}
                            error={touched.inicioRango && Boolean(errors.inicioRango)}
                            {...inicioRangoProps}
                        />  
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="finRango" 
                            label="Rango final"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.finRango ? errors.finRango : ""}
                            error={touched.finRango && Boolean(errors.finRango)}
                            {...finRangoProps}
                        />  
                    </Grid>
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}