import { FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';

import "../../App.css"

export const FilterEvento = ({data = [], eventos = [], setData, defaultID, setEvento = null}) => {
    const [filter, setFilter] = useState(defaultID || eventos.length + 2)

    useEffect(() => {
        if(defaultID ){
            setData(data.filter( e => e.evento._id === defaultID))
            setFilter(defaultID)
            if(setEvento) setEvento(defaultID)
        }else
            setFilter(eventos.length + 2)    
    }, [data])

    const handleChange = (e) => {
        const {value} = e.target
        setFilter(value)
        if(setEvento)
            setEvento(value)
        if(value === eventos.length + 2) setData(data)
        else
            setData(
                data.filter( e => e.evento._id === value)
            )
        console.log(data)
    }

    return (
        <FormControl fullWidth variant="outlined">
            <Select
                variant="outlined"
                fullWidth
                className='smallClassSelect'
                defaultValue={'Todos'}
                label='Evento'
                value={filter}
                onChange={handleChange}
            >   
                <MenuItem 
                    key={eventos.length + 2}
                    value={eventos.length + 2}
                >
                    Todos
                </MenuItem>
                {
                    eventos.map((e, i) => (
                        <MenuItem key={i} value={e._id}>
                            {e.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        
    )
};

export const FilterDepartamento = ({data = [], dept = [], setData, defaultID = null}) => {
    const [filter, setFilter] = useState(defaultID || dept.length + 2)

    useEffect(() => {
        if(defaultID ){
            setData(data.filter( e => e.evento._id === defaultID))
            setFilter(defaultID)
        }else
            setFilter(dept.length + 2)    
    }, [data])

    const handleChange = (e) => {
        const {value} = e.target
        setFilter(value)
        if(value === dept.length + 2) setData(data)
        else
            setData(
                data.filter( e => e.departamento._id === value)
            )
        console.log(data)
    }

    return (
        <FormControl fullWidth variant="standard">
            <Select
                fullWidth
                defaultValue={'Todos'}
                label='Evento'
                value={filter}
                onChange={handleChange}
            >   
                <MenuItem 
                    key={dept.length + 2}
                    value={dept.length + 2}
                >
                    Todos
                </MenuItem>
                {
                    dept.map((e, i) => (
                        <MenuItem key={i} value={e._id}>
                            {e.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        
    )
};

export const FilterEventoDept= ({data = [], dept = [], setData, defaultIdDept = null, eventos, defaultIdEventos, setEvento, setDepartamento}) => {
    const [filter, setFilter] = useState({dept: defaultIdDept || dept.length + 2, evento: defaultIdEventos || eventos.length + 2})

    useEffect(() => {
        if(defaultIdDept || defaultIdEventos){
            setData(data.filter( e => e.evento._id === defaultIdEventos && e.departamento._id === defaultIdDept))
            setFilter({dept: defaultIdDept, evento: defaultIdEventos})
        }else
            setFilter({dept: defaultIdDept, evento: defaultIdEventos})    
    }, [data])

    const handleChange = (e, mode) => {
        const {value} = e.target
        let newFilter
        if(mode === 'evento'){ 
            setEvento(value)
            newFilter = {...filter, evento: value}
        }
        else{
            for(let i = 0; i < dept.length; i++)
                if(dept[i]._id == value){
                    setDepartamento(dept[i])
                    break;
                }
            
            newFilter = {...filter, dept: value}
        }

        if(newFilter.evento === eventos.length + 2 && newFilter.dept === dept.length + 2)
            setData(data)
        else    
            setData(data.filter( e => {
                if(newFilter.dept === dept.length + 2) return e.evento._id === newFilter.evento
                if(newFilter.evento === eventos.length + 2) return e.departamento._id === newFilter.dept
                return e.evento._id === newFilter.evento && e.departamento._id === newFilter.dept
            }))
        setFilter(newFilter)
    }

    return (
        <>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                    <Select
                        variant="outlined" 
                        className='smallClassSelect'
                        fullWidth
                        defaultValue={'Todos'}
                        label='Evento'
                        value={filter.evento}
                        onChange={(e) => handleChange(e, 'evento')}
                    >   
                        <MenuItem 
                            key={eventos.length + 2}
                            value={eventos.length + 2}
                        >
                            Todos
                        </MenuItem>
                        {
                            eventos.map((e, i) => (
                                <MenuItem key={i} value={e._id}>
                                    {e.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="standard">
                    <Select
                        variant="outlined" 
                        className='smallClassSelect'
                        defaultValue={'Todos'}
                        label='Departamento'
                        value={filter.dept}
                        onChange={(e) => handleChange(e, 'dept')}
                    >   
                        <MenuItem 
                            key={dept.length + 2}
                            value={dept.length + 2}
                        >
                            Todos
                        </MenuItem>
                        {
                            dept.map((e, i) => (
                                <MenuItem key={i} value={e._id}>
                                    {e.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        </>
    )
};

export const FilterEventoReturningEvento = ({data = [], eventos = [], setData, defaultID = null, setEventoSelected}) => {
    const [filter, setFilter] = useState(defaultID || eventos.length + 2)

    useEffect(() => {
        if(defaultID ){
            setData(data.filter( e => e.evento._id === defaultID))
            setFilter(defaultID)
        }else
            setFilter(eventos.length + 2)    
    }, [data])

    const handleChange = (e) => {
        const {value} = e.target
        setFilter(value)
        setEventoSelected(value)
        if(value === eventos.length + 2) setData(data)
        else
            setData(
                data.filter( e => e.evento._id === value)
            )
        console.log(data)
    }

    return (
        <FormControl fullWidth variant="standard">
            <Select
                fullWidth
                className='smallClassSelect'
                variant='outlined'
                defaultValue={'Todos'}
                label='Evento'
                value={filter}
                onChange={handleChange}
            >   
                <MenuItem 
                    key={eventos.length + 2}
                    value={eventos.length + 2}
                >
                    Todos
                </MenuItem>
                {
                    eventos.map((e, i) => (
                        <MenuItem key={i} value={e._id}>
                            {e.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
        
    )
};
