/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Loader from "../components/shared/Loader";
import ShowAlert from './shared/Snackbar';

import { 
    Box, 
    Grid,
    Collapse,
    Hidden 
} from "@material-ui/core";

// Validaciones
import { validarCodigoSms } from "../core/sessionAPI";
import "../App.css";

const RedirPageUrl = (props) =>
{
    const id = props.match.params?.code;
    const [restorePass, setRestorePass] = useState(false);
    const [redireccionar, setRedireccionar] = useState(false);
    const [textoUrl, setTextoUrl] = useState("Generando documento");

    const [snackbarProps, setSnackbarProps] = useState();

    const redirectUser = async () => {
        setRedireccionar(true);
        if(id){
          const res = await validarCodigoSms(id);
          if(res.data.length>0){
            setTextoUrl("En 5 segundos será generado tu documento.");
            setTimeout(() => {
              window.location.href = res.data[0].urlDestino;
              setTextoUrl("Documento Generado.");
            }, 5000);
          }else{
            setTextoUrl("URL no valida...")
          }
        }else{
            setTextoUrl("URL no valida...")
        }
    }

    useEffect(() => {
        redirectUser();
    }, []);

    const formRedir = () =>(
        <Box className="form-container">
            <Grid container> 
                <Hidden xsDown >          
                    <Grid item sm={2} md={4}> 
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={8} md={4}> 
                    <Collapse 
                        in={!restorePass}>
                        <Box 
                            className="form"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column">                                
                            <img className="imageLogo" src="/img/disproelLogo.png"/>
                            <form >
                                <Box className="fullWidth">
                                <h4 style={{ textAlign: 'center'}}>{textoUrl}</h4>                              
                                </Box>
                            </form>
                        </Box>
                    </Collapse>
                </Grid>
                <Hidden xsDown >  
                    <Grid item sm={2} md={4}> 
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    )
    
    return (
        <>
            {formRedir()}
            {ShowAlert(snackbarProps, setSnackbarProps)}
            <Loader/>
        </>
    )
}

export default RedirPageUrl;