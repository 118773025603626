import { servicioAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getServicios = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getServicios`,{}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createServicio = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createServicio`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updateServicio = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateServicio`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}