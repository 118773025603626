import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';

// Componentes 
import { 
    Paper,
    Box,
    Avatar,
    Tooltip,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody, 
    String
} from '@material-ui/core';

// Estilos
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";

// Custom components
import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import CustomTableHead from "../shared/Tablas/CustomTableHead";
import Paginacion from "../shared/Tablas/Paginacion";
import DeleteControl from "../controls/DeleteControl";


import "../../../src/App.css"

const headCells = 
[
	{ id: 'options', label: 'Opciones', align: 'left', sortable: false },
	{ id: 'item', align: "left", label: '#', sortable: true },
	{ id: 'name', label: 'Nombre', align: 'left', sortable: true },
	{ id: 'entrega', label: 'Entrega', align: 'left', sortable: true },
	{ id: 'recoleccion', label: 'Recolección', align: 'left', sortable: true }, 
	{ id: 'presupuestos', label: 'Valor del día', align: 'left', sortable: true },
	{ id: 'diaAdicional', label: '¿Aplica día adicional?', align: 'left', sortable: true },
	{ id: 'valorDiaAdicional', label: 'Valor del día adicional', align: 'left', sortable: true },
	{ id: 'aplicaViaticos', label: '¿Aplica día viáticos?', align: 'left', sortable: true },
	{ id: 'valorDiaViaticos', label: 'Valor del día viáticos', align: 'left', sortable: true },
	{ id: 'munNZ', label: '¿Aplica solo a municipios No Zonificados?', align: 'left', sortable: true },
	{ id: 'tipoRol', label: 'Tipo de rol', align: 'left', sortable: true },
	{ id: 'puestoObligatorio', label: 'Puesto obligatorio', align: 'left', sortable: true },
	{ id: 'evento', label: 'Evento', align: 'left', sortable: true },

];

export default function TablaRoles ({registros, recargarEventos, handleEditClick, reloadTable, setSnackbarProps})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 30;
	const rowsPerPageOptionsDefault = [30, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() =>
    {
        setRows(Array.from(registros, (reg, index) =>
        {
            return {
                item: index + 1,
				name: reg.name,
				entrega: reg.entrega ? "Si" : "No",
				recoleccion: reg.recoleccion ? "Si" : "No",
				valorDia: reg.isCustomValue ? 0:(reg?.valorDia || 0),
				aplicaValorDiaAdicional: reg.isCustomValue ? "--":(reg?.aplicaValorDiaAdicional ? 'Si' : 'No'),
				valorDiaAdicional: reg.isCustomValue ? 0:(reg?.aplicaValorDiaAdicional ? (reg?.valorDiaAdicional ||  0) : 0),
				aplicaViaticos: reg.isCustomValue ? "--":(reg?.aplicaViaticos ? 'Si' : 'No'),
				valorDiaViaticos: reg.isCustomValue ? 0:(reg?.aplicaViaticos ? (reg?.valorDiaViaticos ||  0) : 0),
				aplicaSoloMunicipioZonificado: reg.isCustomValue ? "--":(reg?.aplicaSoloMunicipioZonificado ? 'Si' : 'No'),
				evento: reg.evento ? reg.evento.name:"--",
				tipoRol: reg?.tipoRol || "--",
				puestoObligatorio: reg.puestoObligatorio ? "Si":"No",
				rest: reg
            };
        }));
    }, [registros]);

    return (
		<Paper>
			<TableContainer>
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron Roles o no se han registrado.
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className="col20">
									<Box display="flex" justifyContent="left" alignItems="left">
										{/* Editar */}
										<Avatar
											className={`
												${tableClasses.icons}
												${tableClasses.yellow}
											`}
											variant="rounded"
											onClick={() => handleEditClick("editar", row.rest)}
										>
											<Tooltip title="Editar">
												<Icons.Edit fontSize="inherit"/>
											</Tooltip>
										</Avatar>
										<DeleteControl
										    recargarEvento={recargarEventos}
											entidad="RolesEvento"
											idEntidad={row.rest._id}
											setSnackbarProps={setSnackbarProps}
											nameShowDelete={"Usuario, tenga en cuenta que se eliminará este Rol"}
											refreshEvent={reloadTable}/>											
									</Box>
								</TableCell>
								<TableCell className="col20" align="left">{row.item}</TableCell>
								<TableCell className="col250" align="left">{row.name}</TableCell>
								<TableCell className="col100" align="left">{row.entrega}</TableCell>
								<TableCell className="col100" align="left">{row.recoleccion}</TableCell>
								<TableCell className="col150" align="left">
									{!row.rest.isCustomValue &&
										<CurrencyFormat value={row.valorDia} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
									}
									{row.rest.isCustomValue &&
										<div className='eventoCell'>Valor personalizado</div>
									}
								</TableCell>
								<TableCell className="col100" align="left">{row.aplicaValorDiaAdicional}</TableCell>
								<TableCell className="col100" align="left">
									<CurrencyFormat value={row.valorDiaAdicional} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
								</TableCell>
								<TableCell className="col100" align="left">{row.aplicaViaticos}</TableCell>
								<TableCell className="col100" align="left">
									<CurrencyFormat value={row.valorDiaViaticos} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>}/>
								</TableCell>								
								<TableCell className="col100" align="left">{row.aplicaSoloMunicipioZonificado}</TableCell>
								<TableCell className="col100" align="left">{row.tipoRol}</TableCell>
								<TableCell className="col100" align="left">{row.puestoObligatorio}</TableCell>
								<TableCell align="left" className="col250">
									<div className='eventoCell'>
										{row.evento}
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
		</Paper>
	);
}