import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
//Componentes
import { 
    Card,
    CardHeader,
    CardMedia,
    CardActions,
    Grid,
    Box,
    Tooltip,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    makeStyles,
    Collapse,
    CardContent
} from '@material-ui/core';

import "../../App.css";
import DivipolEventos from './DivipolEventos';
import DialogCargueSupervisores from "./DialogCargueSupervisores";
import DialogMigrarDatos from './DialogMigrarDatos';

import { Book, PersonAdd } from '@material-ui/icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogSupervisor from './DialogSupervisor';
import { isAuthenticated } from "../../core/sessionAPI";
import CloseIcon from '@material-ui/icons/Close';
import { deleteEvento, isEventConfigured, setStateEvento } from "../../core/apiEventos";

import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import DialogPresupuestoDepartamento from '../presupuesto/DialogPresupuestoDepartamentos';
import { Chip } from '@mui/material';
import DialogStandsTracking from '../standsTracking/DialogStandsTracking';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Tarjetas({registros, reloadTarjetas, setSnackbarProps}) {
    const classes = useStyles();

    const handleConfirmarEliminacion = async () => {
        //Eliminar evento, eliminado logico, para evitar inconsistencias en los datos, por el momento.
        showLoader();

        const res = await deleteEvento(idEvento);
    
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            setEliminarEvento(false);
            reloadTarjetas();
        }
        
        hideLoader();
    }
    
    const setStateEventoHandle = async (idEvento, state, tipoEstado) => {
        //Eliminar evento, eliminado logico, para evitar inconsistencias en los datos, por el momento.
        //showLoader();

        const res = await setStateEvento(idEvento, state, tipoEstado);
    
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            reloadTarjetas();
        }
        
        //hideLoader();
    }

    const [eliminarEvento, setEliminarEvento] = useState(false);
    const [firstStepEliminacion, setFirstStepEliminacion] = useState(true);
    const[openFormDivipol, setOpenFormDivipol] = useState(false);
    const [openStandsTracking, setOpenStandsTracking] = useState(false);
    const [idEvento, setIdEvento] = useState(null);
    const [idEventoCargueSupervisores, setIdEventoCargueSupervisores] = useState(null);
    const [idEventoCargueDivipol, setIdEventoCargueDivipol] = useState(null);
    const [idEventoMigracion, setIdEventoMigracion] = useState(null);
    
    const [eventoName, setEventoName] = useState("");
    const [evento, setEvento] = useState(null);
    const [openFormSupervisores, setOpenFormSupervisores] = useState(false);    
    const [openCargueSupervisores, setOpenCargueSupervisores] = useState(false);    
    const [openMigrarDatos, setOpenMigrarDatos] = useState(false);

    const [openConfiguracionPresupuesto, setOpenConfiguracionPresupuesto] = useState(false);
    const [edicionEvento, setEdicionEvento] = useState(false);
    

    const [expandedId, setExpandedId] = React.useState(-1);

    const handleExpandClick = (i) => {
        setExpandedId(expandedId === i ? -1 : i);
    };

    const closeHandler = () => {
        setOpenConfiguracionPresupuesto(false);
        setOpenMigrarDatos(false);
    }

    const openDialogMigrarDatos = (idEvento, name) => {
        setIdEventoMigracion(idEvento);
        setEventoName(name);
        setOpenMigrarDatos(true);
    }

    const openDialogCargueSupervisores = (idEvento, name) => {
        setIdEventoCargueSupervisores(idEvento);
        setEventoName(name); 
        setOpenFormDivipol(false);                
        setOpenCargueSupervisores(true);
    }

    const openDialogDivipol = (idEvento, name) => {
        setIdEventoCargueDivipol(idEvento);
        setEventoName(name); 
        setOpenFormDivipol(true);        
        setOpenCargueSupervisores(false);        
    }

    const openDialogPresupuestoGerente = (evento, nameEvento, idGerente) => {
        setEvento(evento);
        setEventoName(nameEvento); 
        setOpenConfiguracionPresupuesto(true);        
    }

    const handleOpenStandsTracking = (idEvento, nameEvento) => {
        setIdEvento(idEvento);
        setEventoName(nameEvento); 
        setOpenStandsTracking(true);        
    }

    const closeHandlerCargueSupervisores= () => {
        setOpenCargueSupervisores(false);
    }

    const closeHandlerDivipol = () => {
        setOpenFormDivipol(false);
    }

    const closeHandlerSupervisores = () => {
        setOpenFormSupervisores(false);
    }

    const openDialogSupervisores = (idEvento, name, enableEdicionGerentes) => {
        setIdEvento(idEvento);
        setEventoName(name); 
        setOpenFormSupervisores(true);         
        setEdicionEvento(enableEdicionGerentes);
    }

    const redirectUser = async (e, id) => {
        e.stopPropagation();
        window.open('/eventos/zonificado/entrega/' + id, "_self");
    }

    const openSupervisoresDialog = async data => {

        if(data.productosEvento.length === 0){
            setSnackbarProps("eSe deben configurar productos en la sección de administración, para gestionar los usuarios de este evento");
        }else if(data.rolesEvento.length === 0){                                                        
            setSnackbarProps("eSe deben configurar los roles en la sección de administración, para gestionar los usuarios de este evento");
        }else if(data.numeroPuestos === 0){
            setSnackbarProps("eSe requiere el cargue de la divipol y configuración de puestos, para administrar los usuarios de este evento.");
        }else{
            openDialogSupervisores(data._id, data.name, data.enableEdicionGerentes);
        }
    }

    const user = isAuthenticated().user;
    const tarjetasE = registros.map(function(data, id){    
        return (  
            <Grid key={id} item xs={12} sm={12} md={6} lg={4} xl={3}>                 
                <Box p={1}>
                    <Card 
                        className="cursorEvento">
                        <CardHeader 
                            className='noPadding'
                            action={
                                <CardActions disableSpacing>
                                    {/*Acceso solo para administradores*/}
                                    {(data.productosEvento.length === 0 || data.rolesEvento.length === 0) && user.role === 2 && 
                                        <Chip
                                            variant='outlined'
                                            label={
                                                data.productosEvento.length === 0 && data.rolesEvento.length === 0 ?
                                                    "Faltan asignar productos y roles"
                                                : data.productosEvento.length === 0 ?
                                                    "Faltan asignar productos"
                                                : "Faltan asginar roles"
                                            }
                                            color='warning'
                                        />
                                    }
                                    {/* eslint-disable-next-line no-mixed-operators */}
                                    {(user.role === 3 || user.role === 2 && (data.productosEvento.length > 0 || data.rolesEvento.length > 0)) &&   
                                        <Tooltip title="Gestión de usuarios de evento">
                                            <IconButton aria-label="addSupervisores"
                                                onClick={async () => await openSupervisoresDialog(data)}>
                                                <PersonAdd/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {user.role === 2 &&<Tooltip title="Eliminar evento">
                                        <IconButton
                                            color="secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setFirstStepEliminacion(true);
                                                setIdEvento(data._id);
                                                setEventoName(data.name);
                                                setEliminarEvento(true);
                                            }}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </Tooltip>}
                                </CardActions>}
                        />
                        <CardHeader 
                            className='littlePadding'
                            onClick={(e)=>{
                                if(data.productosEvento.length !== 0 && data.rolesEvento.length !== 0)
                                    redirectUser(e, data._id)
                            }}
                            title={<h3>{data.name}</h3>}
                            subheader={<p className="subtituloTarjetas">{moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}</p>}
                        />
                        <CardMedia
                            onClick={(e)=>{
                                if(data.productosEvento.length !== 0 && data.rolesEvento.length !== 0)
                                    redirectUser(e, data._id)
                            }}
                            component="img"
                            alt="Evento"
                            height="140"
                            image= {data.urlImg}   
                        />
                        <Box p={1}>
                            <Box className="textDescription">
                                {data.descripcion}
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={4} md={4} >
                                    <Box className="contentindicador">
                                        <div className="titleIndicador">
                                            Productos: {data.productosEvento.length}
                                        </div>                                    
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} >
                                    <Box className="contentindicador">
                                        <div className="titleIndicador">
                                            Mesas: {data.numeroMesas}
                                        </div>                                    
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} >
                                    <Box className="contentindicador">
                                        <div className="titleIndicador">
                                            Puestos: {data.numeroPuestos}
                                        </div>                                    
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} >
                                    <Box className="contentindicador">
                                        <div className="titleIndicador">
                                            Ciudades: {data.numeroCiudades}
                                        </div>                                    
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} >
                                    <Box className="contentindicador">
                                        <div className="titleIndicador">
                                            Departamentos: {data.numeroDepartamentos}
                                        </div>                                    
                                    </Box>
                                </Grid>  
                                <Grid item xs={6} sm={4} md={4} >
                                    <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                        <Button 
                                            color="primary"
                                            variant="contained"
                                            size='small'
                                            style={{
                                                width: '100%',
                                                textTransform: 'capitalize'
                                            }}
                                            onClick={() => handleOpenStandsTracking(data?._id, data?.name)}
                                        >
                                            Personal: {data.supervisores}
                                        </Button>
                                    </div>
                                </Grid>                       
                            </Grid>
                        </Box>
                        {user.role === 2 && <>
                            <CardActions disableSpacing>
                                <Box className="rightText">
                                    Opciones avanzadas
                                </Box>
                                <ExpandMore
                                    expand={expandedId === id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleExpandClick(id)
                                    }}
                                    aria-label="Opciones avanzadas">
                                <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expandedId === id} timeout="auto" unmountOnExit>
                                <div>
                                    <Box p={1}>
                                        <h2>
                                            Control de operación evento
                                        </h2>
                                        {
                                            data.entrega && 
                                            <Box fullWidth>
                                                <FormControlLabel
                                                control={
                                                    <Switch checked={data.enableEntrega} onChange={(e) => {
                                                        setStateEventoHandle(data._id, !data.enableEntrega, "entrega");
                                                    }} name="gilad" />
                                                }
                                                label={<p className="rightText">Habilitar entrega</p>}/>
                                            </Box>
                                        }
                                        {
                                            data.recoleccion &&
                                            <Box fullWidth>
                                                <FormControlLabel
                                                    fullWidth
                                                    control={
                                                        <Switch checked={data.enableRecoleccion} onChange={(e) => {
                                                            setStateEventoHandle(data._id, !data.enableRecoleccion, "recoleccion");
                                                        }} name="gilad" />
                                                    }
                                                    label={<p className="rightText">Habilitar recolección</p>}/>
                                            </Box>
                                        }
                                        {
                                            data.CAD &&
                                            <Box fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Switch checked={data.enableCAD} onChange={(e) => {
                                                            setStateEventoHandle(data._id, !data.enableCAD, "CAD");
                                                        }} name="gilad" />
                                                    }
                                                    label={<p className="rightText">Habilitar entrega CAD</p>}/>
                                            </Box>
                                        }
                                        <Box fullWidth>
                                            <FormControlLabel
                                                fullWidth
                                                control={
                                                    <Switch checked={data.enableEdicionGerentes} onChange={(e) => {
                                                        setStateEventoHandle(data._id, !data.enableEdicionGerentes, "edicionGerentes");
                                                    }} name="gilad" />
                                                }
                                                label={<p className="rightText">Permitir la edición a gerentes de operaciones</p>}/>
                                        </Box>
                                        <Box fullWidth>
                                            <FormControlLabel
                                                fullWidth
                                                control={
                                                    <Switch checked={data.enablePublicRegister} onChange={(e) => {
                                                        setStateEventoHandle(data._id, !data.enablePublicRegister, "enablePublicRegister");
                                                    }} name="gilad" />
                                                }
                                                label={<p className="rightText">Permitir el registro público</p>}/>
                                        </Box>
                                    </Box>
                                    <Box p={1}>
                                        <h2>
                                            Cargue masivo de datos
                                        </h2>
                                    </Box>
                                    {/*Acceso para administradores*/}
                                    {(data.productosEvento.length !== 0 && data.rolesEvento.length !== 0) && user.role === 2 && 
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box p={1}>
                                                    <Button 
                                                        fullWidth
                                                        color="primary"
                                                        variant="contained"
                                                        startIcon={<Book/>}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openDialogDivipol(data._id, data.name)
                                                        }}>  
                                                        Divipol                                              
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box p={1}>
                                                    <Button 
                                                        fullWidth
                                                        color="primary"
                                                        variant="contained"
                                                        startIcon={<Book/>}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openDialogCargueSupervisores(data._id, data.name)
                                                        }}>  
                                                        Cargue masivo, supervisores adicionales con conceptos pago personalizados                                     
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid> 
                                    }
                                    <Box p={1}>
                                        <Button 
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            startIcon={<Book/>}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openDialogMigrarDatos(data._id, data.name);
                                            }}>  
                                            Importar datos desde evento                                              
                                        </Button>
                                    </Box>
                                </div>
                            </Collapse>
                        </>}
                        {user.role === 3 && <>
                            <CardActions disableSpacing>
                                <Box className="rightText">
                                    Opciones avanzadas
                                </Box>
                                <ExpandMore
                                    expand={expandedId === id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleExpandClick(id)
                                    }}
                                    aria-label="Opciones avanzadas">
                                <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expandedId === id} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <h2>
                                        Control de operación evento
                                    </h2>
                                    {/*Acceso para administradores*/}
                                    {(data.productosEvento.length !== 0 && data.rolesEvento.length !== 0) && user.role === 3 && 
                                        <Box p ={1}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        fullWidth
                                                        color="primary"
                                                        variant="contained"
                                                        startIcon={<Book/>}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openDialogPresupuestoGerente(data, data.name, user._id)
                                                        }}>  
                                                        Configuración de presupuesto                                              
                                                    </Button>
                                                </Grid>
                                            </Grid> 
                                        </Box>
                                    } 
                                </CardContent>
                            </Collapse>
                        </>}                        
                    </Card>    
                </Box>      
            </Grid>
        )
        
    })

    return (<>
                <Grid container>
                    {registros.length > 0 ? tarjetasE:<Box p={5}><h1>Cargando eventos...</h1></Box>}
                </Grid>

                <DivipolEventos
                    open={openFormDivipol}
                    close={closeHandlerDivipol}
                    idEvento={idEventoCargueDivipol}
                    eventoName={eventoName}
                    reloadTarjetas={reloadTarjetas}
                />          
                {openFormSupervisores? 
                    <DialogSupervisor
                    open={openFormSupervisores}
                    close={closeHandlerSupervisores}
                    idEvento={idEvento}
                    eventoName={eventoName}
                    edicionEvento={edicionEvento}
                    />: null 
                } 

                <DialogCargueSupervisores
                    open={openCargueSupervisores}
                    close={closeHandlerCargueSupervisores}
                    idEvento={idEventoCargueSupervisores}
                    eventoName={eventoName}
                    reloadTarjetas={reloadTarjetas}
                />

                <DialogMigrarDatos
                    open={openMigrarDatos}
                    close={closeHandler}
                    idEvento={idEventoMigracion}
                    eventoName={eventoName}
                    reloadTarjetas={reloadTarjetas}
                    eventosSeleccionados={registros}
                />

                <Dialog
                    open={eliminarEvento}
                    TransitionComponent={Transition}
                    aria-describedby="alert-dialog-slide-description">
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => setEliminarEvento(false)} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Eliminar evento: {eventoName}
                            </Typography>                            
                        </Toolbar>
                    </AppBar>                    
                        <DialogContent>
                            <Collapse in={firstStepEliminacion}>
                                <DialogContentText id="alert-dialog-slide-description">
                                    La eliminación de un evento es permanente y todos los datos relacionados serán eliminados.
                                </DialogContentText>
                            </Collapse>
                            <Collapse in={!firstStepEliminacion}>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Los datos serán eliminados de forma permanente, ¿seguro que desea continuar con esta operación?
                                </DialogContentText>
                            </Collapse>
                        </DialogContent>
                        <DialogActions>
                                <Button className={firstStepEliminacion ? "":"hiddenClass"} color="secondary" onClick={() => setEliminarEvento(false)}>Cancelar</Button>
                                <Button className={firstStepEliminacion ? "":"hiddenClass"}  color="primary" onClick={() => setFirstStepEliminacion(false)}>Continuar</Button>
                                <Button className={firstStepEliminacion ? "hiddenClass":""}  color="secondary" onClick={() => setEliminarEvento(false)}>Cancelar eliminación</Button>
                                <Button className={firstStepEliminacion ? "hiddenClass":""}  color="primary" onClick={handleConfirmarEliminacion}>Eliminar evento</Button>
                        </DialogActions>                                       
                </Dialog>

                <DialogPresupuestoDepartamento
                    eventos={[evento]}
                    open={openConfiguracionPresupuesto}
                    close={closeHandler}                
                />

                <DialogStandsTracking
                    eventName={eventoName}
                    idEvento={idEvento}
                    isOpen={openStandsTracking}
                    onClose={() => setOpenStandsTracking(false)}
                />
            </>);    
}