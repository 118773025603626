import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { getEmpresasOperadorasByEvento } from '../../core/apiEmpresaOperadora';
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import axios from 'axios';
import { createReglaComisionProveedor, updateReglaComisionProveedor } from '../../core/apiReglaComisionProveedor';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioReglaComision ({open, close, mode, data, refreshTable, Dept, eventos})
{
    const classes = useStyles();
    /* Snackbar */
    const [empresasOperadoras, setEmpresasOperadoras] = useState([]);
    const [eventoSelected, setEventoSelected] = useState("");
    const [snackbarProps, setSnackbarProps] = useState();
    const [swicthComision, setSwicthComision] = useState(false);
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    const loadDataEmpresaOperadora = async (idEvento) => {
        showLoader();
        const res = await getEmpresasOperadorasByEvento(idEvento)
    
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEmpresasOperadoras([]);
        }else{
            setEmpresasOperadoras(res.data);
        }
    
        hideLoader();
    }

    useEffect(() => {
        if(eventoSelected){
            console.log(eventoSelected);
            loadDataEmpresaOperadora(eventoSelected)
        }
    }, [eventoSelected]);

    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        
        //Se adiciona el campo empresa al formulario de registro
        var user = isAuthenticated().user;
        //Existe por lo menos un archivo cargado
        if(parseInt(values.valorMinimo) < parseInt(values.valorMaximo)){
            let res = {};
            values.tipoComision = !swicthComision ? 'PORCENTAJE' : 'VALOR'
            values.usuario = `${user.name} ${user.lastname} - ${user.role == 2 ? 'Admistrador' : 'Gerente'}`
            if (mode === "crear"){
                res = await createReglaComisionProveedor({...values, proveedorPagos: Dept.rest._id})
            }else if (mode === "editar"){
                values.id = data._id
                res = await updateReglaComisionProveedor(values)
            }

            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                setSnackbarProps("s" + res.data.message);
                //borrar campos
                refreshTable();
                resetForm();
                //reiniciar tabla de vehiculos
            }
        }else{
            setSnackbarProps('e' + 'El valor mínimo no puede superar el valor máximo')
        }
        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            valorMinimo: 0,
            valorMaximo: 0,
            valorComision: 0,
            evento: '',
            empresaOperadora: ''
        },
        validationSchema: yup.object().shape({
            evento: yup.string().required("Debes llenar este campo"),
            empresaOperadora: yup.string().required("Debes llenar este campo"),
            valorMinimo:yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            valorMaximo:yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            valorComision: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const eventoProps = getFieldProps("evento");
    const valorMinimoProps = getFieldProps("valorMinimo");
    const empresaOperadoraProps = getFieldProps("empresaOperadora");
    const valorMaximoProps = getFieldProps("valorMaximo");
    const valorComisionProps = getFieldProps("valorComision");
    
    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        setSwicthComision(false)
        async function init(){
            console.log(mode)
            if (mode === "crear"){
                formik.handleReset();
                formik.setFieldValue('evento', eventoSelected)
                setFormTitle(`Registrar nueva regla de comisión de ${Dept?.name}`);
            }else if (mode === "editar"){
                setFormTitle(`Actualizar datos de la regla de comisión de  ${Dept?.name}`);
                formik.setFieldValue("evento", data.evento._id);
                formik.setFieldValue("valorComision", data.valorComision);
                formik.setFieldValue("valorMinimo", data.valorMinimo);
                formik.setFieldValue("valorMaximo", data.valorMaximo);
                formik.setFieldValue("empresaOperadora", data?.empresaOperadora?._id || '');
            }
        }
        init();
    }, [open]);

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 55,
        height: 34,
        padding: 5,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(28px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 6,
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 20,
          height: 20,
          borderRadius: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 32 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
    }));
    
    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos de la regla de comisión</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            value={eventoSelected}
                            select
                            variant="outlined" 
                            name="evento" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.evento ? errors.evento : ""}
                            error={touched.evento && Boolean(errors.evento)}
                            onChange={(e)=>{
                                setEventoSelected(e.target.value);
                                formik.setFieldValue("evento", e.target.value);
                            }}>   
                            {
                                eventos.map((e, i) => (
                                    <MenuItem key={i+1000} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>   
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="empresaOperadora" 
                            label="Empresa operadora"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.empresaOperadora ? errors.empresaOperadora : ""}
                            error={touched.empresaOperadora && Boolean(errors.empresaOperadora)}
                            {...empresaOperadoraProps}
                        >   
                            {
                                empresasOperadoras.map((e, i) => (
                                    <MenuItem key={i+2000} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>   
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="valorMinimo" 
                            label="Valor Mínimo"
                            fullWidth
                            disabled={mode == 'editar'}
                            required
                            inputProps={{ 
                                maxLength: 200, 
                            }}
                            helperText={touched.valorMinimo ? errors.valorMinimo : ""}
                            error={touched.valorMinimo && Boolean(errors.valorMinimo)}
                            {...valorMinimoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="valorMaximo" 
                            label="Valor Máximo"
                            fullWidth
                            disabled={mode == 'editar'}
                            required
                            inputProps={{ 
                                maxLength: 200, 
                            }}
                            helperText={touched.valorMaximo ? errors.valorMaximo : ""}
                            error={touched.valorMaximo && Boolean(errors.valorMaximo)}
                            {...valorMaximoProps}
                        />
                    </Grid>
                    <Grid item xs={12} ms={12} className="smallPadding centerContent">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">Tipo de comisión</div>
                            <Stack 
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center"
                            >
                                <Typography>Porcentaje (%)</Typography>
                                <AntSwitch 
                                    checked = {swicthComision}
                                    onChange = {() => setSwicthComision(!swicthComision)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    
                                />
                                <Typography>Valor</Typography>
                            </Stack>
                        </Box>                        
                    </Grid>   
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined" 
                            name="valorComision" 
                            label={`${swicthComision ? 'Valor' : 'Porcentaje'} de comisión`}
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 45,
                            }}
                            helperText={touched.valorComision ? errors.valorComision : ""}
                            error={touched.valorComision && Boolean(errors.valorComision)}
                            {...valorComisionProps}
                        />
                    </Grid>     
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}