import React, { useState, useEffect, useLayoutEffect } from 'react';

import moment from 'moment'
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet

// Mis Componentes
import PageContent from '../../../components/shared/PageContent';
import DisableDayModal from './DisableDayModal';
import EnableDayModal from './EnableDayModal';
import DisabledDaysList from './DisabledDaysList';

// MUI Components
import { 
    Button,
    Paper,
    Box,
    Typography,
    Divider,
    Grid
} from '@material-ui/core'

// Custom components
import CustomAlert from '../../shared/CustomAlert';
import Loader from '../../shared/Loader';

// Servicios
import { getDisabledDays } from "../../../core/settingsAPI";

/**
 * Gracias a esto, se muestra  el contenido de LandingNoWorkingDays, pero, 
 * se evita escribir la estructura establecida en el componente 'Page Content'
 */
export default function NoWorkingDays ()
{
    return (
        <PageContent view="no-working-days"/>
    )
}
/**
 * Landing Page de configuración
 */
export const LandingNoWorkingDays = () =>
{
    /* Determina si se debe mostar el loader */
    const [showLoader, setShowLoader] = useState(true)

    /* Alerta que muestra mensajes de éxito o error */
    const [alertProps, setAlertProps] = useState({});

    // Botón para deshabilitar días
    const [showDisableButton, setShowDisableButton] = useState(true)

    // Almacena la fecha seleccionada en el calendario
    const [selectedDate, setSelectedDate] = useState(false)
    // Almacena los días deshabilitados que se muestran en el listado de días deshabilitados
    const [disabledDays, setDisabledDays] = useState([]);
    // Almacena los días deshabilitados que se muestran en el calendario
    const [daysOnCalendar, setDaysOnCalendar] = useState([])

    // Muestran/ocultan los modales para habilitar/deshabilitar días
    const [showDisableDayModal, setShowDisableDayModal] = useState(false)
    const [showEnableDayModal, setShowEnableDayModal] = useState(false)

    // Define si el día seleccionado se puede deshabilitar o reactivar
    const [diaNoApto, setDiaNoApto] = useState(false);

    // Cambia el valor de 'selectedDate' cuando se selecciona un día en el calendario
    const handleCalendarClick = (date) =>
    {
        setShowDisableButton(true);
        setSelectedDate(moment(date).format("DD-MM-YYYY"));

        // Define si el día es apto o no para habilitar o reactivar
        const hoy = moment();
        if (moment(date).diff(hoy, "days") <= 0)
        {
            setDiaNoApto(true)
        }
        else
        {
            setDiaNoApto(false);
        }
    }
    
    // Se ejecuta cuando se carga la página
    useEffect(() =>
    {
        async function getDays ()
        {
            const res = await getDisabledDays();

            if (res.error)
            {
                setAlertProps({
                    open: true,
                    severity: "error",
                    message: res.error
                })
            }
            else
            {
                setDisabledDays(res.data)
            }
            setShowLoader(false)
        }
        getDays();

    }, [])

    useEffect(() =>
    {
        let data = [];

        // Recorre JSON de días deshabilitados y filtra solo los días, para guardarlos en un array unidimensional
        disabledDays.map((e) =>
        {
            e.months.map((date) =>
            {
                data = data.concat(date.dates)
            })
        })

        // Recorre el array y convierte cada elemento a tipo Date para poder mostrarlos en el calendario
        for (let i in data)
        {
            // El mes se toma como un array, es decir 11 es diciembre y 12 es enero, por eso se resta 1
            let month   = data[i].substr(3, 2) - 1
            let year    = data[i].substr(6, 4)
            let day     = data[i].substr(0, 2)

            data[i] = new Date(year, month, day)
        }
        setDaysOnCalendar(data)
    }, [disabledDays])

    return (
        <>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={1} className="paper-card">
                            <Typography variant="body1" color="textSecondary">
                                Aquí puedes especificar los días en los que no se realizarán ventas a crédito ni pago de cuotas.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={8}>
                        <Paper elevation={1} className="paper-card">
                            <InfiniteCalendar            
                                width="100%"
                                displayOptions={{
                                    layout: 'portrait'
                                }}
                                height={window.innerHeight - 350}
                                disabledDates={daysOnCalendar}
                                locale={{
                                    locale: require('date-fns/locale/es'),
                                    headerFormat: 'dddd, D MMM',
                                    weekdays: ["Dom","Lun","Mar","Mié","Jue","Vie","Sáb"],
                                    weekStartsOn: 1,
                                    blank: 'Sin fecha seleccionada',
                                    todayLabel: {
                                        long: 'Hoy',
                                        short: 'Hoy'
                                    }
                                }}
                                onSelect={handleCalendarClick}
                                theme={{
                                    selectionColor: () => {
                                        return diaNoApto ? '#EC6150' : '#559FFF';
                                    }
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <Paper elevation={1} className="paper-card">
                            <Box display="flex" 
                                 justifyContent="space-between" 
                                 alignItems="center" 
                                 pb={1}>
                                <Typography variant="h6" color="textSecondary">
                                    <strong>Días deshabilitados</strong>
                                </Typography>
                                <React.Fragment>
                                    {selectedDate && showDisableButton && ! diaNoApto && (
                                        <Button 
                                            variant="contained" 
                                            color="secondary" 
                                            onClick={() => setShowDisableDayModal(true)}
                                        >
                                            Deshabilitar día
                                        </Button>
                                    )}
                                    {! selectedDate && (
                                        <Typography varian="body2" color="textSecondary">
                                            Selecciona un día
                                        </Typography>
                                    )}
                                </React.Fragment>
                            </Box>
                            <Divider />
                            <DisabledDaysList 
                                disabledDays={disabledDays}
                                setSelectedDate={setSelectedDate}
                                abrirModalReactivacion={() => {
                                    setShowDisableButton(false)
                                    setShowEnableDayModal(true)
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid> 
            </Box>

            {/* Modal deshabilitar día */}
            <DisableDayModal
                date={selectedDate}
                setDisabledDays={setDisabledDays}
                cleanCalendar={() => setSelectedDate(false)}
                open={showDisableDayModal}
                close={() => setShowDisableDayModal(false) }
                setShowLoader={setShowLoader}
            />
            <EnableDayModal
                date={selectedDate}
                setDisabledDays={setDisabledDays}
                open={showEnableDayModal}
                close={() => {
                    setShowDisableButton(true)
                    setShowEnableDayModal(false)
                    setSelectedDate(false)
                }}
                setShowLoader={setShowLoader}
            />

            <CustomAlert alertProps={alertProps} />
            <Loader show={showLoader} />
        </>
    );
}