import React from "react";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { EventProvider } from "../../contexts/eventContext";

const NavContainer = ({nameEvento=""}) =>
{
    const [abrir, setAbrir] = React.useState(false);

    const toggleSidebar = () => {
        setAbrir(!abrir);
    }

    const cerrarSidebar = () => {
        console.log("CERRAR MENU");
        setAbrir(false);
    }

    return (
        <EventProvider>
            <Navbar accionAbrir={toggleSidebar} cerrarSidebar={cerrarSidebar} nameEvento={nameEvento}/>
            <Sidebar 
                variant="temporary"
                open={abrir}
                onClose={toggleSidebar}
            />
        </EventProvider>
    )
}
export default NavContainer;