import { pagoSupervisorAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getPagoSupervisores = async (idSupervisor) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getPagoSupervisores`,{idSupervisor}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const verifyChangeRoleAllowed = async (idSupervisor) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.get(`${API}/verify-change-role-allowed/${idSupervisor}`, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getPagoSupervisorByEventoFirmaMasiva = async (evento, listaSupervisores) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getPagoSupervisorByEventoFirmaMasiva`,{evento, listaSupervisores}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const signatureRequestMasivo = async (data) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/signature-request-masivo`, {data}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const createPagoSupervisor = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createPagoSupervisor`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updatePagoSupervisor = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updatePagoSupervisor`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const pagoSupervisorPagoAdelantadoAll = async (id) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/pagoSupervisorPagoAdelantadoAll`, {id}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const pagoSupervisorPagoAdelantadoOne = async (id, proveedorPagos, evento, empresaOperadora) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/pagoSupervisorPagoAdelantadoOne`, {id, proveedorPagos, evento, empresaOperadora}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const pagoSupervisorPagoUnicoAll = async (id, proveedorPagos, evento, empresaOperadora) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/pagoSupervisorPagoUnicoAll`, {id, proveedorPagos, evento, empresaOperadora}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const cambiarEstadoPago = async (id, estado) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/cambiarEstadoPago`, {id, estado}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const cambiarEstadoBySupervisor = async (supervisor, estado) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/cambiarEstadoBySupervisor`, {supervisor, estado}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const cambiarEstadoBySupervisores = async (supervisores, estado) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/cambiarEstadoBySupervisores`, {supervisores, estado}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const validarReportePDF = async (evento, consecutivo, supervisor, rol, pago) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/validarReportePDF`, {evento, consecutivo, supervisor, rol, pago}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const exportarDocumentosEquivalentes = async (supervisores, evento) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/exportarDocumentosEquivalentes`, {supervisores, evento}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const pagarTodo = async (supervisores, evento) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/pagarTodo`, {supervisores, evento}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const confirmarDescargaDocumentoById = async (id) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/confirmarDescargaDocumentoById`, {id}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const resetDownloadDocsEquivalentes = async (supervisores) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/resetDownloadDocsEquivalentes`, {supervisores}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const changeProveedorPagos = async (supervisores, proveedorPagos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/changeProveedorPagos`, {supervisores, proveedorPagos}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const createTxtByProveedor = async (evento, proveedorPagos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createTxtByProveedor`, {evento, proveedorPagos}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const signatureRequest = async (evento, supervisor, rol, idConcepto, consecutivoFacturacion) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/signature-request`, {evento, supervisor, rol, idConcepto, consecutivoFacturacion}, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getPagoSupervisorById = async id =>
{
    const res = await axios.post(`${API}/pago-by-id`, {id});
    return res;
}

export const validarReportePDFWithoutToken = async (evento, consecutivo, supervisor, rol, pago) =>
{
    const res = await axios.post(`${API}/validarReportePDF`, {evento, consecutivo, supervisor, rol, pago});
    return res;
}

export const signPaymentConfirmation = async (supervisor, idConcepto, signatureUrl, pagoUrl, signatureDate, consecutivoFacturacion) =>
{
    const res = await axios.post(`${API}/sign-payment`, {supervisor, idConcepto, signatureUrl, pagoUrl, signatureDate, consecutivoFacturacion});
    return res;
}