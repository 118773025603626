import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import { handleResponse, isAuthenticated } from "../../core/sessionAPI";

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery,
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import { createEvento, updateEvento } from '../../core/apiEventos';
import UploadFiles from '../controls/UploadFiles';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Formulario ({open, close, mode, data, refreshTable})
{
    const classes = useStyles();
    const theme = useTheme();
    const [imgs, setImgs] = useState([]);

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    

        //Se adiciona el campo empresa al formulario de registro
        var user = isAuthenticated().user;
        values.empresa = user.empresa;
        
        //Existe por lo menos un archivo cargado
        if(imgs.length > 0){
            values.urlImg = imgs[0].url;
        }else{
            values.urlImg = "";
        }

        let res = {};
        if (mode === "crear"){
            res = await createEvento(values);
        }else if (mode === "editar"){
            values.id = data._id;
            res = await updateEvento(values);
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            name: "",
            descripcion: "",
            CAD: false
        },
        validationSchema: yup.object().shape({
            name: yup.string().required("Debes llenar este campo"),
            descripcion: yup.string().required("Debes llenar este campo")
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const descripcionProps = getFieldProps("descripcion"); 
    const CADProps = getFieldProps("CAD");
    

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){

            var user = isAuthenticated().user;
            console.log(user);

            if (mode === "crear"){
                formik.handleReset();
                setFormTitle("Registrar nuevo Evento");
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos del Evento");
                setImgs([{id:99, url:data.urlImg}]);
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("descripcion", data.descripcion);
                formik.setFieldValue("CAD", data.CAD);
            }
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos basicos de Eventos</h3>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 200 }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="descripcion" 
                            label="Descripcion del evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 200 }}
                            helperText={touched.descripcion ? errors.descripcion : ""}
                            error={touched.descripcion && Boolean(errors.descripcion)}
                            {...descripcionProps}
                        />
                    </Grid> 
                    <Grid item xs={12} sm={12}>
                        <h3>
                            Indica si el evento cuenta con el proceso de <i>Entrega CAD</i>
                        </h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel
                            label="Entrega CAD"
                            control={
                                <Checkbox 
                                    name='CAD'
                                    color='primary' 
                                    {...CADProps}
                                />
                            } 
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <UploadFiles
                            title="Imagen asociada al evento"
                            label="Adjuntar imagen"
                            setSnackbarProps={setSnackbarProps}
                            documents={imgs}
                            setDocuments={setImgs}
                            disableDelete={false}
                            maxNumberFiles={1}
                        />                            
                    </Grid> 
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}