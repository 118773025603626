/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from 'react';
// Componentes
import {
    Grid, 
    Box, 
    Paper, 
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
    makeStyles,
    Slide,
    useTheme,
    Dialog,
    AppBar,
    IconButton,
    Typography,
    DialogContent,
    Button,
    Toolbar,
    Tooltip} from '@material-ui/core';
import ReloadWithFilter from "../shared/ReloadWithFilter";
import AddIcon from '@material-ui/icons/Add';
import {getMunicipiosEstado} from "../../core/apiEventos";
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";
import CustomTableHead from "../shared/Tablas/CustomTableHead";

// Validaciones
import CloseIcon from '@material-ui/icons/Close';
import Paginacion from "../shared/Tablas/Paginacion";

import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

import DialogMunicipioConfirmar from './DialogMunicipioConfirmacion';
import DialogPuestosMarcacion from '../puestos/DialogPuestosMarcacion';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogMunicipiosMarcacion({open, close, departamento, producto, estado, evento, tipo}){
    const classes = useStyles();

    const [ciudades, setCiudades] = useState([]);  
    const [ciudadesTable, setCiudadesTable] = useState([]);  

    const [municipio, setMunicipio]=useState();

    const [openMarcacionMunicipio, setOpenMarcacionMunicipio] = useState(false);
    const [tipoMarcacion, setTipoMarcacion] = useState();

    const [openPuestos, setOpenPuestos] = useState(false);

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    const [reloadFilter, setReloadFilter] = useState(false);
    
    const loadDataCiudades = async () => {        
        showLoader();        
        
        const res = await getMunicipiosEstado(tipo, departamento._id, producto, estado, evento);

        if (res.error){
            setSnackbarProps("e" + res.error);
            setCiudades([]);
            setCiudadesTable([]);
        }else{
            setCiudades(res.data);
            setCiudadesTable(res.data);
        }
        setReloadFilter(!reloadFilter);

        hideLoader();
    }

    const loadCiudadesFilter = async () => {
        showLoader();
        const res = await getMunicipiosEstado(tipo, departamento._id, producto, estado, evento);
        if (res.error) {
            setSnackbarProps("e" + res.error);
            setCiudades([]);
            setCiudadesTable([]);
        } else {
            const filterCiudades = res.data.filter(item2 => ciudadesTable.some(item1 => item1._id === item2._id));
             setCiudades(res.data);
             setCiudadesTable(filterCiudades);     
        }
        hideLoader();
    }

    const actualizarValores = async () => {
        console.log(ciudadesTable.length);
        if(ciudades.length===ciudadesTable.length || ciudadesTable.length ===0){
            setReloadFilter(!reloadFilter);
            await loadDataCiudades();
        }else{
            await loadCiudadesFilter();
            setReloadFilter(!reloadFilter);
        } 
    }

    useEffect(() => {
        async function inicializar() {
            if(departamento){
                await actualizarValores()
            }
        }
        if(open){
            inicializar();
        }
    }, [open]);

    useEffect(() => {
        return () => {
            setReloadFilter(v => !v);
            setCiudades([]);
            setCiudadesTable([]);
        }
    }, [])

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            MUNICIPIOS DE { departamento?.name || '' } + {tipo} + {estado.toUpperCase()}
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box p={1}>
                                <Paper elevation={1}>
                                    <Box p={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={10} md={10}>
                                                <ReloadWithFilter
                                                    source={ciudades}
                                                    searchBy="ciudades"
                                                    setTableData={setCiudadesTable}
                                                    tableReload={reloadFilter}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2}  md={2}>   
                                                    <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={actualizarValores}
                                                    >ACTUALIZAR
                                                    </Button>       
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>   
                            <Box p={1}>
                                <Paper elevation={1}>
                                    <TablaMunicipios
                                        confirmarMunicipio={(municipio) => {
                                            setMunicipio(municipio);
                                            setTipoMarcacion("marcar");
                                            setOpenMarcacionMunicipio(true);
                                        }}
                                        cancelarMunicipio={(municipio) => {
                                            setMunicipio(municipio);
                                            setTipoMarcacion("desmarcar");
                                            setOpenMarcacionMunicipio(true);
                                        }}
                                        onSelectMunicipio={(municipio) => {
                                            setMunicipio(municipio);
                                            setOpenPuestos(true);
                                        }}
                                        municipios={ciudadesTable}                                        
                                    />
                                </Paper>
                            </Box> 
                        </Grid>
                    </Grid>
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>               
            </Dialog>

            <DialogPuestosMarcacion 
                open={openPuestos}
                close={()=>{
                    setOpenPuestos(false);
                    actualizarValores();
                }}
                departamento={departamento}
                municipio={municipio}
                producto={producto}
                estado={estado}
                evento={evento}
                tipo={tipo}
            />

            <DialogMunicipioConfirmar
                open={openMarcacionMunicipio}
                close={()=>{
                    setOpenMarcacionMunicipio(false)
                }}
                departamento={departamento}
                municipio={municipio}
                producto={producto}
                estado={estado}
                evento={evento}
                tipo={tipo}
                setSnackbarProps={setSnackbarProps}
                tipoMarcacion={tipoMarcacion}
                reloadAll={()=>{
                    actualizarValores()
                }}
            />
        </>
    );
}


export function TablaMunicipios ({municipios, confirmarMunicipio, cancelarMunicipio, onSelectMunicipio})
{
    const headCells = 
    [
        { id: 'options', label: 'Opciones', align: 'left', sortable: false },
        { id: 'item', align: "left", label: '#', sortable: true },
        { id: 'codigoMunicipio', label: 'Código Municipio', align: 'left', sortable: true },
        { id: 'name', label: 'Municipio', align: 'left', sortable: true },
        { id: 'estado', label: 'Estado', align: 'left', sortable: true },
        { id: 'estadoPorcentual', label: 'Estado Porcentual (%)', align: 'left', sortable: true },
        { id: 'estadoMesas', label: 'Estado Mesas', align: 'left', sortable: true },
        { id: 'estadoPorcentualMesas', label: 'Estado Porcentual Mesas (%)', align: 'left', sortable: true },        
        { id: 'tipo', label: 'Tipo', align: 'left', sortable: true }
    ];

    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 50;
	const rowsPerPageOptionsDefault = [50, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() => {
        setRows(Array.from(municipios, (municipio, index) =>{
            return {
                item: index + 1,
                municipio: municipio.ciudad,
                codigo: municipio.ciudad.codigo,
                name: municipio.ciudad.name,
				estado: municipio.valor + " / " + municipio.meta + " Puestos",
                estadoPorcentual: parseFloat((municipio.valor/municipio.meta) * 100).toFixed(2),
                estadoMesas: municipio.valorMesas + " / " + municipio.metaMesas + " Mesas",
                estadoPorcentualMesas: parseFloat((municipio.valorMesas/municipio.metaMesas) * 100).toFixed(2),
                tipo: municipio.tipo
            };
        }));
    }, [municipios]);

    return (
		<>
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}			
					/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron registros
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className={tableClasses.options}>
									<Box display="flex" justifyContent="left" alignItems="left">
                                    <Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                confirmarMunicipio(row.municipio)
                                            }}>
											<Tooltip title="Marcar todos los puestos relacionados al municipio">
												<LibraryAddCheckIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>
										<Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                cancelarMunicipio(row.municipio)
                                            }}>
											<Tooltip title="Desmarcar todos los puestos relacionados al municipio">
												<CancelPresentationIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>                                        	
										<Avatar
											className={`${tableClasses.icons} ${tableClasses.blueGrey}`}
											variant="rounded"
											onClick={() => {
                                                onSelectMunicipio(row.municipio)
                                            }}>
											<Tooltip title="Listar puestos">
												<PlaylistAddCheckIcon fontSize="inherit"/>
											</Tooltip>
										</Avatar>	                                        										
									</Box>
								</TableCell>
								<TableCell align="left">{row.item}</TableCell>
								<TableCell align="left">{row.codigo}</TableCell>								
								<TableCell align="left"><b>{row.name}</b></TableCell>
								<TableCell align="left">{row.estado}</TableCell>
                                <TableCell align="left">
                                    <div  className={"blockBase " + (row.estadoPorcentual < 50 ? "blockRed":row.estadoPorcentual < 99 ? "blockYellow":"blockGreen")}>
                                        {row.estadoPorcentual}
                                    </div>
                                </TableCell>  
								<TableCell align="left">{row.estadoMesas}</TableCell>
                                <TableCell align="left">
                                    <div  className={"blockBase " + (row.estadoPorcentualMesas < 50 ? "blockRed":row.estadoPorcentualMesas < 99 ? "blockYellow":"blockGreen")}>
                                        {row.estadoPorcentualMesas}
                                    </div>
                                </TableCell>
                                <TableCell align="left">{row.tipo}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
    	</>
	);
}