import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField, MenuItem } from "@material-ui/core"

export const DialogValidarAllPagos = ({open, handleClose, validarPago, title, content}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>VALIDAR</b>: Se valida el pago y habilita la opción de <b>PAGAR</b> para todos los conceptosValidar todos los conceptos de .
                </DialogContentText>
            </DialogContent>  
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={validarPago} color="primary">
                    VALIDAR
                </Button>
            </DialogActions>
        </Dialog>
    )
}
