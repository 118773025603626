import { Document, Page, Text, View, Image, StyleSheet, pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import React from 'react';
import logo from '../../assets/imgs/logoProsegur.png'
import anulado from '../../assets/imgs/ANULADO.png'
import { NumeroALetras } from '../NumeroALetras';
import { hideLoader, showLoader } from '../../components/shared/Loader';
import {confirmarDescargaDocumentoById, validarReportePDF} from '../../core/apiPagoSupervisor'
import { tableStyles } from "../../assets/js/GeneralStyles";
import { Avatar, Tooltip } from "@material-ui/core";
import { PictureAsPdf } from "@mui/icons-material";
import moment from "moment";

export const GeneratePDFPagos = ({values = {}, setSnackbarProps}) => {
	const tableClasses = tableStyles();

    const generate = async () => {
        showLoader()

        const validar = await validarReportePDF(
            values?.restSupervisor?.evento,
            values.consecutivo,
            values?.restSupervisor,
            values?.rol,
            values
        )

        if(!validar.error){
            const auxValues = {
                ...values,
                ...validar.data
            }
            const blob = await pdf((
                <PDFModel values={auxValues} />
            )).toBlob()
            const changeFlagDescargado = await confirmarDescargaDocumentoById(auxValues._id)
            if(!changeFlagDescargado)
                setSnackbarProps('eHa ocurrido un error en la descarga');
            else{
                saveAs(blob, auxValues?.nameDoc + ".pdf")
                setSnackbarProps('sPDF generado correctamente')
            }            
        }else{
            setSnackbarProps('e' + validar.error)
        }
        hideLoader()
    }

    return (
        <Avatar
            className={`
                ${tableClasses.icons} ${tableClasses.blue}
            `}
            variant="rounded"
            onClick={async () => await generate()}>
            <Tooltip title="Descargar documento equivalente">
                <PictureAsPdf fontSize="inherit"/>
            </Tooltip>
        </Avatar>
    )
    
}

export const PDFModel = ({values}) => {

    console.log(values);
    if(!values.signatureUrl){
        values.signatureUrl = null;
    }

    return (
        <Document>
            <Page style={styles.page} wrap size="A4" orientation='landscape' >
                {   
                    values?.estado === 'ANULADO' ?
                    <View style={{zIndex: -1, position: 'absolute'}}>
                        <Image
                            src={anulado}
                            alt="ANULADO"
                            style={{ maxWidth: "850px", maxHeight: "850px", opacity: 0.6}}
                        /> 
                    </View> : <View />
                }
                { values?.signatureUrl &&
                    <View style={{zIndex: -1, position: 'absolute', paddingLeft:70,  bottom: 190, left: 35, maxHeight: 70, maxWidth: 250}}>
                        <View style={{ maxHeight: 70}}>
                            <Image
                                src={values?.signatureUrl }
                                alt="signature"
                                style={{ maxWidth: "120px", minHeight: "70px",  alignSelf: 'center'}}
                            />
                        </View>
                    </View>
                }
                <View style={{border: '2px',borderColor: 'black', height: 575, zIndex: 0}}>
                    <Text style={{fontSize: "9px", textAlign: 'left'}}>
                        {values?.codigoEquivalente}
                    </Text>
                    <View >
                        
                        <Image
                            src={logo}
                            alt="random image"
                            style={{ maxWidth: "120px", maxHeight: "120px",  alignSelf: 'center', marginTop: '10px', objectFit: 'contain' }}
                        />
                        <Text style={{fontSize: "10px", textAlign: 'center' }}>
                            {'\n'}{values?.restSupervisor?.empresaOperadora?.name.toString().toUpperCase()}{'\n'}
                            NIT: {values?.restSupervisor?.empresaOperadora?.nit} {'\n'}{'\n'}
                        </Text>
                        <Text style={{fontSize: "9px", textAlign: 'center'}}>
                            Documento Soporte En Adquisiciones Efectuadas A No Obligados A Facturar {'\n'}{'\n'}{'\n'}
                        </Text>
                    </View>
                    <View style={{fontStyle: "Helvetica-Bold", lineHeight: 1.6, height: 50}}>
                        <View style={[styles.row, {fontSize: 11}]}>
                            {/* <View style={styles.left}>
                                <View style={{borderColor: 'black', borderWidth: '1px', borderRadius: '15px', width: '250px'}}>
                                    <Text style={styles.date}>FECHA: {new Date(values?.fechaPagado || values?.fechaPagadoAdelantado).toLocaleString('es-co')} {'\n'}</Text>
                                </View>
                            </View> */}
                            <View style={styles.left}>
                                <Text style={{fontSize: "10px", marginLeft: '8px'}}>
                                    No. {String(values?.consecutivoFacturacion).padStart(6, "0")}
                                </Text>
                                {/* <Text style={{fontSize: "10px"}}>
                                    Proveedor de Pagos:  {values?.proveedorPagos?.name}
                                </Text> */}
                                <Text style={{fontSize: "10px", marginLeft: '8px'}}>
                                    Ciudad:  {values?.restSupervisor?.ciudadFacturacion?.name || ''}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{fontStyle: "Helvetica-Bold", lineHeight: 1.6, height: 90}}>
                        <View style={styles.row}>
                            <View style={styles.leftThree}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px' }}>
                                    <Text style={styles.cell}>
                                    {` `}
                                    </Text>
                                </View>
                                <View style={{ borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                        ADQUIRIENTE DEL BIEN O SERVICIO:
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px' , borderLeftColor: 'black', borderLeft: '1px'}}>
                                    <Text style={styles.cell}>
                                        BENEFICIARIO DEL PAGO:
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                        DIRECCIÓN:
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.middleThree}>
                                <View style={{
                                    borderBottom: '1px',
                                    borderBottomColor: 'black',
                                }}>
                                    <Text style={styles.cell}>
                                        {` `}
                                    </Text>
                                </View>
                                <View style={{
                                    borderRightColor: 'black',
                                    borderRight: '1px',
                                    borderColor: 'black',
                                    borderLeft: '1px',
                                    borderLeftColor: 'black',
                                }}>
                                    <Text style={{...styles.cell, fontSize: '9px'}}>
                                    {values?.restSupervisor?.empresaOperadora?.name}
                                    </Text>
                                </View>
                                <View style={{
                                    borderRightColor: 'black',
                                    borderRight: '1px',
                                    borderColor: 'black',
                                    borderLeft: '1px',
                                    borderLeftColor: 'black',
                                    borderBottom: '1px',
                                    borderBottomColor: 'black',
                                }}>
                                    <Text style={{...styles.cell, fontSize: '9px'}}>
                                    </Text>
                                </View>
                                <View style={{
                                    borderRightColor: 'black',
                                    borderRight: '1px',
                                    borderColor: 'black',
                                    borderLeft: '1px',
                                    borderLeftColor: 'black',
                                    borderBottom: '1px',
                                    borderBottomColor: 'black'
                                }}>
                                    <Text style={styles.cell}>
                                        {`${String(values?.restSupervisor?.firstName || '').toUpperCase()} ${String(values?.restSupervisor?.secondName || '').toUpperCase()} ${String(values?.restSupervisor?.firstLastname || '').toUpperCase()} ${String(values?.restSupervisor?.secondLastname || '').toUpperCase()}`}
                                    </Text>
                                </View>
                                <View style={{
                                    borderRightColor: 'black',
                                    borderRight: '1px',
                                    borderColor: 'black',
                                    borderBottom: '1px',
                                    borderBottomColor: 'black',
                                }}>
                                    <Text style={{...styles.cellLeft, fontSize: 9}}>
                                        {String(values?.restSupervisor?.direccion || 'X').toUpperCase()}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.rightThree}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px'}}>
                                    <Text style={styles.cell}>
                                        {` `}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'  }}>
                                    <Text style={styles.cell}>
                                        NIT: {values?.restSupervisor?.empresaOperadora?.nit}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'  }}>
                                    <Text style={styles.cell}>
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'  }}>
                                    <Text style={styles.cell}>
                                        {`C.C: ${values?.restSupervisor?.documento}`}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'  }}>
                                    <Text style={styles.cell}>
                                        {`TELÉFONO: ${values?.restSupervisor?.telefono}`}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{display: 'flex', border: '1px solid black', flexDirection: 'row', marginLeft: 15, marginRight: 15, borderTop: 0}}>
                            <View style={{width: 220, fontSize: 9, textAlign: 'center',  alignContent: 'center'}}>
                                <Text>CONCEPTO DE LA TRANSACCIÓN:</Text>
                            </View>
                            <Text style={{fontSize: 9,  alignContent: 'center'}}>{String(values?.rol?.name || 'X').toUpperCase()} - PAGO REALIZADO EN EL MES DE {MONTHS[new Date(values?.createdAt).getMonth()]}</Text>
                        </View>
                    </View>
                    <View style={{fontStyle: "Helvetica-Bold", lineHeight: 1.6, height: 30, marginTop: 15}}>
                        <View style={[styles.row]}>
                            <View style={styles.left}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderLeftColor: 'black', borderLeft: '1px', borderTop: '1px solid black' }}>
                                    <Text style={styles.cell}>VALOR EN LETRAS: </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px' }}>
                                    <Text style={styles.cell}>{' '} </Text>
                                </View>
                            </View>
                            <View style={styles.right}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px', borderTop: '1px solid black' }}>
                                    <Text style={styles.cellLeft}>
                                        {String(NumeroALetras(values?.total || values?.valorTotalPago || 0 ).toUpperCase())}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px' }}>
                                    <Text style={styles.cellLeft}>
                                        {' '}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{fontStyle: "Helvetica-Bold", lineHeight: 1.6, height: 100}}>
                        <View style={[styles.row]}>
                            <View style={styles.oneSix}>
                                <View style={{borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px' }}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px'}}>
                                    <Text style={styles.cell}>
                                        Firma
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px', borderLeftColor: 'black', borderLeft: '1px'}}>
                                    <Text style={styles.cellLeft}>
                                        {values?.signatureUrl ? `${String(values?.restSupervisor?.firstName || '').toUpperCase()} ${String(values?.restSupervisor?.secondName || '').toUpperCase()} ${String(values?.restSupervisor?.firstLastname || '').toUpperCase()} ${String(values?.restSupervisor?.secondLastname || '').toUpperCase()}` : null}
                                    </Text>
                                    <Text style={styles.cellLeft}>
                                         {values?.signatureUrl ? `C.C: ${values?.restSupervisor?.documento}`: null}
                                    </Text>
                                    <Text style={styles.cellLeft}>
                                        {values?.signatureUrl ? `${moment(values?.signatureDate).format('DD/MM/YYYY HH:mm:ss')}` : null}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.twoSix}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        ELABORADO
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.threeSix}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        REVISADO
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.fourSix}>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        APROBADO
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.fiveSix}>
                                <View style={{borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        VALOR DEL SERVICIO
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{ borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        RETENCIONES
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        TOTAL NETO A PAGAR
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px',}}>
                                    <Text style={styles.cell}>
                                        {' '}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.sixSix}>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {`$ ${parseInt((values?.total || values?.valorTotalPago || 0) + (((values?.total || values?.valorTotalPago || 0) * (values.retencion / 100)) + ((values?.total || values?.valorTotalPago || 0) * (values.reteica / 100))))}`}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {` `}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {`$ ${parseInt(((values?.total || values?.valorTotalPago || 0) * (values.retencion / 100)) + ((values?.total || values?.valorTotalPago || 0) * (values.reteica / 100)) )}`}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {` `}
                                    </Text>
                                </View>
                                <View style={{borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {`$ ${values?.total || values?.valorTotalPago || 0 }`}
                                    </Text>
                                </View>
                                <View style={{borderBottomColor: 'black', borderBottom: '1px', borderRightColor: 'black', borderRight: '1px'}}>
                                    <Text style={styles.cell}>
                                        {` `}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{fontStyle: "Helvetica-Bold", lineHeight: 1.6, height: 30}}>
                        <View style={[styles.row]}>
                            <View style={styles.left}>
                                <View>
                                    <Text style={styles.cell}>{' '}</Text>
                                </View>
                                <View>
                                    <Text style={styles.cell}>RETENCIONES EN LA FUENTE: $ {parseInt((values?.total || values?.valorTotalPago || 0) * (values.retencion / 100)) } ({values.retencion}%)</Text>
                                </View>
                            </View>
                            <View style={styles.right}>
                                <View>
                                    <Text style={styles.cell}>{' '}</Text>
                                </View>
                                <View>
                                    <Text style={styles.cellLeft}>
                                        RETEICA: $ {parseInt((values?.total || values?.valorTotalPago || 0) * (values.reteica / 100)) } ({values.reteica}%)
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    
                    <View style={{alignContent: 'center', fontStyle: "Helvetica-Bold", lineHeight: 1.6, fontSize: 10, textAlign: 'center'}}>
                        <Text>
                            {'\n'} {'\n'} {'\n'} {'\n'} {'\n'} {'\n'} {'\n'} {'\n'}
                            {values.descripcionFacturacion}
                        </Text>
                    </View> 
                </View>
            </Page>
        </Document>
    )
}

const styles = StyleSheet.create({
    cellLeft:{
        fontSize: 8,
        margin: 2,
        textAlign: 'left',
        alignContent: 'center'
    },
    cell:{
        fontSize: 9,
        margin: 2,
        textAlign: 'center',
        alignContent: 'center'
    },
    date:{
        fontSize: 10,
        margin: 10,
        textAlign: 'left',
        alignContent: 'center'
    },  
    page: {
        padding: 10,
    },
    text: {
        margin: 10,
        fontFamily: 'Oswald',
        textAlign: 'justify',
    },
    h2: {
        display: 'block',
        fontSize: 30,
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 0,
        marginRight: 0,
        padding: 10,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
    },
    left: {
        width: 410,
        marginLeft: 15,
        //width: '50%',//<- working alternative
        flexGrow: 0,
        flexShrink: 1,
    },
    right: {
        //width: '50%', //<- working alternative
        flexShrink: 1,
        marginRight: 15,
        flexGrow: 2,
    },
    leftThree: {
        width: 220,
        marginLeft: 15,
        //width: '50%',//<- working alternative
        flexGrow: 0,
        flexShrink: 1,
    },
    middleThree: {
        width: 400,
    },
    rightThree: {
        //width: '50%', //<- working alternative
        flexShrink: 1,
        marginRight: 15,
        flexGrow: 2,
    },
    oneSix: {
        width: 270,
        marginLeft: 15,
        //width: '50%',//<- working alternative
        flexGrow: 0,
        flexShrink: 1,
    },
    twoSix: {
        width: 90,
    },
    threeSix: {
        width: 90,
    },
    fourSix: {
        width: 90,
    },
    fiveSix: {
        width: 110,
    },
    sixSix: {
        //width: '50%', //<- working alternative
        flexShrink: 1,
        marginRight: 15,
        flexGrow: 2,
    },
});

const MONTHS = [
    'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
    'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
]