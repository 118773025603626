import { proveedorPagosAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getProveedoresPagos = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getProveedoresPagos`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createProveedorPagos = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createProveedorPagos`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const updateProveedorPagos = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateProveedorPagos`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}