import { empresasOperadorasAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getEmpresasOperadoras = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getEmpresasOperadoras`,{delete: false}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getEmpresasOperadorasByEvento = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getEmpresasOperadorasByEvento`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getEmpresasOperadorasByEventoRegistro = async (evento) =>
{
        const res = await axios.post(`${API}/getEmpresasOperadorasByEvento`,{evento});

        return handleResponse(res);
}

export const createEmpresaOperadora = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createEmpresaOperadora`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updateEmpresaOperadora = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateEmpresaOperadora`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}