import moment from "moment";
import { refreshToken, signout } from "../core/sessionAPI";

// A medida que el usuario navega por la página, es true
let userNavigating = false;
let tokenRefrescado = true;
// Almacena el setInterval(), así se puede hacer uso de la función clearInterval()
let tokenInterval;
// Almacena la fecha-hora máxima para tener la sesión inactiva
let endDate = moment().add(1440, "minutes").format();

// Inicializa la ejecución de la función 'tokenTime()' cada 60 segundos y resetea el tiempo límite de inactividad
export const setTokenInterval = () =>
{
    // Ejecuta la función especificada cada 60 segundos
    tokenInterval = setInterval(tokenTime, 60000)
}

// Se ejecuta cada 30 segundos y valida si se superó el tiempo límite de inactividad
const tokenTime = () =>
{
    // console.log(moment().format("HH:mm:ss"), moment(endDate).format("HH:mm:ss"));
    if (moment().format() > endDate)
    {
        signout();
        return false;
    }

    // No se ha refrescado el token
    if (! tokenRefrescado) return false;

    // El token ya se ha refrescado y el usuario navegó por la página
    if (userNavigating)
    {
        // Actualizar variables
        tokenRefrescado = false;
        userNavigating = false;
        endDate = moment().add(1440, "minutes").format();
        
        refreshToken(() =>
        {
            tokenRefrescado = true;
        });
    }
}

// Se ejecuta cada vez que el usuario dispara los eventos onMouseMove; onClick; onKeyPress y refresca el token de sesión
export const onUserNavigate = () =>
{
    userNavigating = true;
    clearInterval(tokenTime);
}