import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Button,
    Dialog,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import "../../../src/App.css";
import { FilterEvento } from '../empresasOperadoras/FilterEvento';
import { getProductosByEventos} from '../../core/apiProductosEvento';
import FormularioProductos from './FormularioProductos';
import TablaProductos from './TablaProductos';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogProducto({open, close, eventos = []}){
    const classes = useStyles();
    const theme = useTheme();

    const [productos, setProductos] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [productoSeleccionado, setProductoSeleccionado] = useState(null)
    const [myEventos, setMyEventos] = useState(eventos)
    const [dataFilter, setDataFilter] = useState([]);
    const [eventoSelected, setEventoSelected] = useState('')

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }

    const loadDataProductos = async () => {
        showLoader();
        const res = await getProductosByEventos(eventos)
        if (res.error){
            setSnackbarProps("e" + res.error);
            setProductos([]);
            setDataFilter([])
        }else{
            setProductos(res.data);
            setDataFilter(res.data)
            console.log('result', res.data)
        }
        hideLoader();
    }

    useEffect(() => {
        async function inicializar() {
            await loadDataProductos()
        }
        inicializar();
    }, [eventos]);

    const handleEditarCrearProducto= (tipo, deptSeleccionado) => {
        setProductoSeleccionado(deptSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = () => {
        setOpenForm(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar productos
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={6}>
                                        <Filter
                                            source={productos}
                                            searchBy="rol"
                                            setTableData={setDataFilter}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <FilterEvento
                                            defaultID={eventos[0]?._id}
                                            data={productos}
                                            eventos={eventos}
                                            setData={setDataFilter}
                                            setEvento={setEventoSelected}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => handleEditarCrearProducto("crear", null)}>
                                                Agregar producto
                                            </Button>    
                                        </Grid>   
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <TablaProductos
                            registros= {dataFilter}
                            handleEditClick = {handleEditarCrearProducto}
                            reloadTable = {loadDataProductos}
                            setSnackbarProps ={setSnackbarProps}
                        />
                    </Box>                  
                </Box>               
            </Dialog>

            <FormularioProductos
                open={openForm}
                close={closeForm}
                mode={modo}
                data={productoSeleccionado}
                refreshTable={loadDataProductos}
                eventos={eventos}
                eventoDefault={eventoSelected}
            />
        </>
    );
}