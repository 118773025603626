import { reportesAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";
import fileDownload from 'react-file-download';

var monitoreoGeneracion  = 0;
export const downloadFile = async (showLoader, hideLoader, setSnackbarProps, nameFile) => {
    showLoader();
    setTimeout(async function(){
        const resStateFile = await existFile(nameFile);
        if(resStateFile.error){
            if(monitoreoGeneracion < 240){
                monitoreoGeneracion++;
                downloadFile(showLoader, hideLoader, setSnackbarProps, nameFile);
            }else{
                monitoreoGeneracion = 0;
                setSnackbarProps("e" + "No se pudo generar el archivo para su descarga.");
                hideLoader();
            }
        }else{
            //Se descarga el archivo
            const resFile = await getFile(nameFile);

            if (resFile.error){
                setSnackbarProps("e" + resFile.error);
                hideLoader();
            }else{
                hideLoader();
                fileDownload(resFile.data, nameFile)
                setSnackbarProps('s' + 'Archivo descargado con éxito')
            }
        }
    },5000);
}

export const existFile = async (nameFile) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/existFile`,{nameFile}, {
            headers: {
                token: isAuthenticated().token                
            },
        });

        return handleResponse(res);
    }
}

export const getFile = async (nameFile) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getFile`,{nameFile}, {
            headers: {
                token: isAuthenticated().token                
            },
            responseType: 'blob'
        });

        return handleResponse(res);
    }
}

export const supervisoresByGerente = async (ciudades, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/supervisoresByGerente`,{ciudades, evento}, {
            headers: {
                token: isAuthenticated().token                
            },
            responseType: 'blob'
        });

        return handleResponse(res);
    }
}

export const supervisoresAll= async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/supervisoresAll`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            },
        });

        return handleResponse(res);
    }
}

export const createTxtByProveedor= async (evento, proveedorPagos, nameProveedorPagos) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/createTxtByProveedor`,{evento, proveedorPagos, nameProveedorPagos}, {
            headers: {
                token: isAuthenticated().token                
            },
        });

        return handleResponse(res);
    }
}

export const createTxtByProveedorDate= async (
    date, 
    evento, 
    proveedorPagos, 
    nameProveedorPagos,
    sucursal = null,
    municipio = null,
    puesto = null,
) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/createTxtByProveedorDate`,{date, evento, proveedorPagos, nameProveedorPagos, sucursal, municipio, puesto}, {
            headers: {
                token: isAuthenticated().token                
            },
        });

        return handleResponse(res);
    }
}

export const reportConceptoByState= async (evento, stateConcepto) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/reportConceptoByState`,{evento, stateConcepto}, {
            headers: {
                token: isAuthenticated().token                
            },
        });

        return handleResponse(res);
    }
}

export const conceptosSupervisores= async (listaSupervisores, evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/conceptosSupervisores`,{listaSupervisores, evento}, {
            headers: {
                token: isAuthenticated().token                
            },
            responseType: 'blob'
        });

        return handleResponse(res);
    }
}

export const reteicaByDepartamento= async (evento, departamento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/reteicaByDepartamento`,{evento, departamento}, {
            headers: {
                token: isAuthenticated().token                
            },
            responseType: 'blob'
        });

        return handleResponse(res);
    }
}

export const reteicaAll= async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/reteicaAll`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            },
            responseType: 'blob'
        });

        return handleResponse(res);
    }
}

export const conceptosProcesadosSupervisores= async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/conceptosProcesadosSupervisores`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            },
            responseType: 'blob'
        });

        return handleResponse(res);
    }
}