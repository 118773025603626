import React, { useState, useEffect } from 'react';

// Componentes 
import { 
    Paper,
    Box,
    Avatar,
    Tooltip,
	Button
} from '@material-ui/core';

// Tabla
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';

// Estilos
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";

// Custom components
import { stableSort, getComparator } from "../shared/Tablas/CustomSortTable";
import CustomTableHead from "../shared/Tablas/CustomTableHead";
import Paginacion from "../shared/Tablas/Paginacion";
import DeleteControl from '../controls/DeleteControl';
import moment from 'moment'

import "../../App.css"

const headCells = 
[
	{ id: 'options', label: 'Opciones', align: 'left', sortable: false },
    { id: 'item', align: "left", label: '#', sortable: true },
	{ id: 'evento', label: 'Evento', align: 'left', sortable: true },
	{ id: 'empresaOperadora', label: 'Empresa operadora', align: 'left', sortable: true },
    { id: 'min', label: 'Valor mínimo', align: 'left', sortable: true },
    { id: 'max', label: 'Valor máximo', align: 'left', sortable: true },
    { id: 'tipo', label: 'Tipo de comisión', align: 'left', sortable: true },
    { id: 'procentajecomision', label: 'Valor', align: 'left', sortable: true },
    { id: 'creador', label: 'Creador/Editor', align: 'left', sortable: true },
    { id: 'creacion', label: 'Fecha de creación', align: 'left', sortable: true },
    { id: 'edicion', label: 'Fecha de edición', align: 'left', sortable: true },
];

export default function TablaReglaComision ({municipios, handleEdit, reloadTable, setSnackbarProps})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 30;
	const rowsPerPageOptionsDefault = [30, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */

    useEffect(() => {
        setRows(Array.from(municipios, (mun, index) =>{
            return {
                item: index + 1,
				evento: mun.evento.name,
				min: mun.valorMinimo,
				max: mun.valorMaximo,
				valorComision: mun.valorComision,
				usuario: mun.usuario,
				empresaOperadora: mun?.empresaOperadora?.name || 'Sin asignar',
				tipoComision: mun.tipoComision,
				creacion: new Date(mun.createdAt).toLocaleString('es-co'),
				edicion: new Date(mun.updatedAt).toLocaleString('es-co'),
				rest: mun
            };
        }));
    }, [municipios]);
    
    return (
		<>
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}			
					/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron reglas de comisión o no se han registrado.
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={row.item}>
								<TableCell className={tableClasses.options}>
									<Box display="flex" justifyContent="left" alignItems="left">
										{/* Editar */}
										<Avatar
											className={`
												${tableClasses.icons} ${tableClasses.yellow}
											`}
											variant="rounded"
											onClick={() => handleEdit("editar", row.rest)}
										>
											<Tooltip title="Editar">
												<Icons.Edit fontSize="inherit"/>
											</Tooltip>
										</Avatar>
										<DeleteControl
											entidad="ReglaComisionProveedor"
											idEntidad={row.rest._id}
											setSnackbarProps={setSnackbarProps}
											nameShowDelete='esta regla de comisión'
											refreshEvent={reloadTable}/>											
									</Box>
								</TableCell>
								<TableCell align="left">{row.item}</TableCell>
								<TableCell align="left">{row.evento}</TableCell>								
								<TableCell align="left">{row.empresaOperadora}</TableCell>								
								<TableCell align="left">{row.min}</TableCell>
								<TableCell align="left">{row.max}</TableCell>
								<TableCell align="left">{row.tipoComision}</TableCell>
								<TableCell align="left">{row.valorComision}</TableCell>
								<TableCell align="left">{row.usuario}</TableCell>
								<TableCell align="left">{row.creacion}</TableCell>
								<TableCell align="left">{row.edicion}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Paginación */}
			<Paginacion 
				count={rows.length}
				rowsPerPageDefault={rowsPerPageDefault}
				rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
				setCalculoPaginacion={setCalculoPaginacion}
			/>
    	</>
	);
}