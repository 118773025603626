import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { 
    Button
} from '@material-ui/core';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { esES } from '@material-ui/core/locale';

// Aquí se pueden modificar valores que tiene el framework MUI por defecto
// Ver documentación relacionada a ThemeProvider 'https://material-ui.com/'
const theme = createMuiTheme({}, esES);

const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider 
            ref={notistackRef}
            maxSnack={2}
            action={(key) => (
                <Button 
                    onClick={onClickDismiss(key)}
                    style={{color:"#fff"}}>
                    Cerrar
                </Button>
            )}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <App />
        </SnackbarProvider>
    </ThemeProvider>,
    document.getElementById('root')
);