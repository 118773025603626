import React, { useState, useLayoutEffect } from 'react';

// Componentes 
import { 
    Paper,
    Box,
    Typography,
    Button,
    Hidden,
    Fab
} from '@material-ui/core';

// Iconos
import AddIcon from '@material-ui/icons/Add';

// Servicios
import { getEmpresasAdmin } from '../../../core/superAdminServices/empresaAPI';
import { getAdminUsers } from '../../../core/superAdminServices/userAPI';

// Custom components
import { showLoader, hideLoader } from '../../shared/Loader';
import Formulario from './Formulario';
import Tabla from './Tabla';
import ShowAlert from '../../shared/Snackbar';

export default function MainUsuarios ()
{
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /**
     * formMode: Define si el formulario es para crear o editar administradores
     * editData: Almacena los datos de un administrador si formMode está en modo de edición
     * openForm: Define si el formulario está abierto o no
     */
     const [formMode, setFormMode] = useState();
     const [editData, setEditData] = useState();
     const [openForm, setOpenForm] = useState(false);
    
    /* Listado de empresas y administradores */
    const [empresas, setEmpresas] = useState([]);
    const [admins, setAdmins] = useState([]);

    /**
     * Al dar click en el botón 'Nuevo administrador' se abre el formulario de administradores en modo crear
     * Si se da click en la opción 'Editar' de la tabla, se abre en modo editar
     */

    const loadUsuarios = async () =>{
        const res = await getEmpresasAdmin();
    
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEmpresas([]);
        }else{
            setEmpresas(res.data);
        }
        
        setOpenForm(true);

        hideLoader();
    }

    const handleOpenForm = (mode, data = "") => e =>
    {
        // Cargar listado de empresas si el formulario se abre en modo crear
        if (mode === "crear"){
            showLoader();
            loadUsuarios();
        }else if(mode === "editar"){
            setEmpresas([{
                _id: data.empresa._id,
                name: data.empresa.name
            }])
        }

        setFormMode(mode);
        setEditData(data);
        setOpenForm(true);
    }

    /* Cierra el formulario de Creación/Edición de empresas */
    const closeForm = () => 
    {
        setOpenForm(false);
    }

    /* Carga el listado de administradores */
    const loadAdmins = async () =>
    {
        showLoader();
        const res = await getAdminUsers();

        if (res.error)
        {
            setSnackbarProps("e" + res.error);
            setAdmins([]);
        }
        else
        {
            setAdmins(res.data);
        }
        hideLoader();
    }

    /* Carga el listado de las administradores una vez cargue la página */
    useLayoutEffect(() =>
    {
        async function init()
        {
            loadAdmins();
        }

        init();
    }, []);

    return (
        <>
            <Box p={2}>
                <Paper elevation={2}>
                    <Box display="flex" p={2} justifyContent="space-between">
                        <Typography variant="h6" align="left">
                            Administradores
                        </Typography>

                        {/* Botón crear para pantallas normales */}
                        <Hidden xsDown>
                            <Button 
                                variant="contained" 
                                color="primary"
                                startIcon={<AddIcon/>}
                                size="small"
                                onClick={handleOpenForm("crear")}>
                                Nuevo Administrador
                            </Button>
                        </Hidden>
                        {/* Botón crear para pantallas pequeñas */}
                        <Hidden smUp>
                            <Fab
                                color="primary"
                                aria-label="add"
                                size="small"
                                title="Nuevo Administrador"
                                onClick={handleOpenForm("crear")}>
                                <AddIcon />
                            </Fab>
                        </Hidden>
                    </Box>
                </Paper>
            </Box>

            <Tabla
                admins={admins}
                handleEditClick={handleOpenForm}
                reloadTable={loadAdmins}
                setSnackbarProps={setSnackbarProps}
            />

            {/* Modal con el formulario para crear administradores */}
            <Formulario 
                open={openForm}
                close={closeForm} 
                mode={formMode}
                data={editData}
                empresas={empresas}
                refreshTable={loadAdmins}
            />

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}