import React from 'react';
import { Redirect, Route } from 'react-router-dom';
// Servicios
import { isAuthenticated } from "../core/sessionAPI";

const PrivateRoute = ({ component: Component, ...rest }) =>
{
    const isLoggedIn = isAuthenticated();

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
                    )
            }
        />
    )
}

export default PrivateRoute