import React, { useState, useEffect, useRef} from 'react';
import * as yup from "yup";
import { regex } from '../../../src/helpers/Validator';
import { procesarArchivoSupervisores } from '../../core/apiDivipol';

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Link,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    Paper,
    LinearProgress
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";
import { getUploadFileStatus } from '../../core/apiControls';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogCargueSupervisores ({open, close, idEvento, eventoName, reloadTarjetas})
{
    const classes = useStyles();
    const inputFileRef = useRef();

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [progressUpload, setProgressUpload] = useState(0);
    const [fileSelect, setFileSelect] = useState(null);
    
    /* Textos del título/botón del formulario */

    const procesarFileSupervisores = async () => {
        if(fileSelect){
            showLoader();
            const res = await procesarArchivoSupervisores(fileSelect, idEvento, setProgressUpload);
            setSnackbarProps("s" + res.data.message);   

            const intervalId =  setInterval(async function(){
                const fileStatus = await getUploadFileStatus(res.data.fileId);
                if (fileStatus.data.status === "ERROR"){
                    setSnackbarProps("e" + fileStatus.data.message);   
                    inputFileRef.current.value = null;
                    setFileSelect(null);
                    setProgressUpload(0);             
                    hideLoader();
                    clearInterval(intervalId);
                }else if(fileStatus.data.status === 'SUCCESS'){      
                    setSnackbarProps("s" + fileStatus.data.message);
                    inputFileRef.current.value = null;
                    setFileSelect(null);
                    setProgressUpload(0);
                    
                    /* if(res.data.usuariosOmitidos){
                        //Si los supervisores ya se encuentran registrados son omitidos
                        setSnackbarProps("s" + res.data.usuariosOmitidos);                      
                    } */
    
                    hideLoader();
                    reloadTarjetas();
                    close();
                    clearInterval(intervalId);
                }             
            },5000);
        }else{
            setSnackbarProps("e" + "Debe adjuntar un documento formato excel, con la información de los supervisores, siguiendo el formato anexo.");
        }
    }

    const closeClear = () => {
        setFileSelect(null);
        setProgressUpload(0);
        close();
    }

    useEffect(() => {
    }, []);

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={closeClear} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Registro de supervisores, evento: {eventoName}
                        </Typography>
                        <Hidden xsDown>
                            <Button 
                                color="inherit" 
                                endIcon={<SaveIcon />}
                                onClick={() => {
                                    procesarFileSupervisores();
                                }}>
                                Procesar cargue supervisores
                            </Button>
                        </Hidden>                            
                        <Hidden smUp>
                            <IconButton color="inherit">
                                <SaveIcon />
                            </IconButton>
                        </Hidden>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box p={2}>
                                <Paper elevation={2}>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Subida masiva de información</Typography>
                                    </Box>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Instrucciones</Typography>  
                                    </Box>
                                    <Box display="flex" p={2}>
                                        <div>
                                        <Typography>1. Descargar la plantilla que ha sido generada con base a la configuración realizada.</Typography>
                                        <Typography>2. Diligenciarla con la información requerida.</Typography>
                                        <Typography>3. Carga el archivo dando click en el botón "Seleccionar archivo"</Typography>
                                        <Typography>Nota: Guiarse estrictamente del formato de la plantilla para evitar errores en la lectura de la informacón.</Typography>
                                        </div>
                                    </Box>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Descargar plantilla <Link href="./plantillas/supervisoresBulkInsert.xlsx">aquí</Link></Typography>  
                                    </Box>
                                </Paper>
                            </Box>  
                        </Grid>                
                        <Grid item xs={12}>
                            <Box p={2}>
                                <Paper elevation={2}>
                                    <Grid item xs={12}>
                                        <Box className="panelUploadFiles">    
                                            <LinearProgress 
                                                className="sizeProgressBar"
                                                variant="determinate" 
                                                value={progressUpload} />
                                            <Box p={1}>
                                                <h3>Subida de archivo supervisores</h3>
                                            </Box>  
                                            <Box p={1}>
                                                <h4>{fileSelect? fileSelect.name:""}</h4>
                                            </Box>                   
                                            <Box p={1}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    color="primary"
                                                    startIcon={<CloudUploadIcon />}
                                                    onClick={()=> {
                                                        //Call triger in input file
                                                        inputFileRef.current.click();
                                                    }}>
                                                    Seleccione el archivo que contiene los registros de supervisores.
                                                </Button>

                                                <input  type="file" 
                                                        name="file" 
                                                        ref={inputFileRef} 
                                                        onChange={(e)=>{
                                                            setFileSelect(e.target.files[0])
                                                        }} 
                                                        className="hidenPanel"/>                         
                                            </Box>      
                                        </Box>                      
                                    </Grid>
                                </Paper>
                            </Box>    
                        </Grid>
                    </Grid>                    
                </DialogContent>               
            </Dialog>

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}