import React, { useState, useRef, useEffect} from 'react';
import Webcam from 'react-webcam';
// Componentes
import { 
    Button,
    Dialog,
    Grid, 
    Slide,
    Box,
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";
import {validarCedulaFrontal, validarCedulaFrontalNueva, validarCedulaPosteriorNueva, validarCedulaPosterior, validarFotoPersona} from "../../core/apiRegistro";
import { uploadFileUrl } from '../../core/apiControls';
import FormularioRegistroSupervisores from "../eventos/FormularioRegistroSupervisores";
import { showLoader, hideLoader } from '../shared/Loader';
import useResponsive from '../../hooks/useResponsive'

const useStyles = makeStyles((theme) => ({
  appBar: {
      position: 'relative',
  },
  title: {
      marginLeft: theme.spacing(2),
      flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function mesEnNumero(nombreMes) {
  const meses = {
    'ENE': 1, 'FEB': 2, 'MAR': 3, 'ABR': 4, 'MAY': 5, 'JUN': 6,
    'JUL': 7, 'AGO': 8, 'SEP': 9, 'OCT': 10, 'NOV': 11, 'DIC': 12
  };
  return meses[nombreMes.toUpperCase()] || -1;
}

export default function ValidacionDocumentoFoto ({onCancel, open, cedulaDigital, close, idEvento , eventoName, modo, tiposPago, datos, closeFormulario })
{
  //Formulario de Supervisiores
  const [openFormProducto, setOpenFormProducto] = useState(false);

  const classes = useStyles();

  const [snackbarProps, setSnackbarProps] = useState();
  const [imagen, setImagen] = useState(false);
  const [urlImagen, setUrlImagen] = useState("");
  const [infoImagen, setInfoImagen] = useState("/img/iconoCedula.PNG");
  
  const [datosFormulario, setDatosFormulario] =useState({}); 
  const [datosFrontal, setDatosFrontal] = useState({});
  const [datosPosterior, setDatosPosterior] = useState({});

  //Guardar Fotos de Cdula y selfies
  const [imgCedulaFrontal, setImgCedulaFrontal] = useState("");
  const [imgCedulaPosterior, setImgCedulaPosterior] = useState("");
  const [posisicion, setPosisicion] = useState(true);

  const [informacion, setInformacion] = useState("Coloca la parte frontal de tu cédula en el recuadro para validar.");
  const [informacionBoton, setInformacionBoton] = useState("Capturar Cédula Parte Frontal");
  const [verificacion, setVerificacion] = useState(0);

  const [modeCamara, setModeCamara] = useState('user');
  const {isMobile} = useResponsive();
  
  const webcamRef = useRef(null);

  const toggleFacingMode = async () => {
    if(modeCamara==="user"){
      setPosisicion(false);
      setModeCamara("environment");
    }else if(modeCamara==="environment"){
      setPosisicion(true);
      setModeCamara("user");
    }
  }

  const videoConstraints = {
    facingMode: "user"
  };

  const videoConstraintsAtras = {
    facingMode: { exact: "environment" }
  };

  //No Tocar
  useEffect(() => {
    //Carga los tipos de pagos
    if(cedulaDigital===true){
      setInfoImagen("/img/ccdigital.png")
    }else if(cedulaDigital===false){
      setInfoImagen("/img/ccamarilla.jpeg")
    } 
  }, [cedulaDigital]);


  //No tocar
  const captureScreen = async () => {
    setPosisicion(false);
    showLoader();
    setTimeout(captureScreenFoto, 1)
  }

  const captureScreenFoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();    
    const urlIm = imageSrc;
    setUrlImagen(urlIm);
    setPosisicion(true);
    if(urlIm){
      setImagen(true);
      if(verificacion===0){
        if(cedulaDigital===true){
          const resPersona= await validarFotoPersona(urlIm);
          if(resPersona===false){
            setSnackbarProps("e" + "Debes intentarlo de nuevo");
            setImagen(false);
          }else{
            const res= await validarCedulaFrontalNueva(urlIm);

            if(res===false){
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
            }else{
                //Cedula Frontal Validada 
                const resUrl = await uploadFileUrl(urlIm);
                if(resUrl!==false){
                  setImgCedulaFrontal(resUrl);
                  setSnackbarProps("s" + "Validación exitosa. Continua con la parte posterior");
                  setInformacion("Coloca la parte posterior de tu cédula en el recuadro para validar.");
                  setInformacionBoton("Capturar Cédula Parte Posterior");
                  setVerificacion(1);
                  setInfoImagen("/img/backccdigital.png");
                  setImagen(false);
                  setDatosFrontal(res);
                  setImgCedulaFrontal(resUrl);
                }else{
                  setSnackbarProps("e" + "Debes intentarlo de nuevo");
                  setImagen(false);
                }
              } 
          }
        }else{
          const resPersona= await validarFotoPersona(urlIm);
          if(resPersona===false){
            setSnackbarProps("e" + "Debes intentarlo de nuevo");
            setImagen(false);
          }else{
            const res= await validarCedulaFrontal(urlIm);
          
            if(res===false){
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
            }else{
              //Cedula Frontal Validada 
              const resUrl = await uploadFileUrl(urlIm);
              if(resUrl!==false){
                setImgCedulaFrontal(resUrl);
                setSnackbarProps("s" + "Validación exitosa. Continua con la parte posterior");
                setInformacion("Coloca la parte posterior de tu cédula en el recuadro para validar.");
                setInformacionBoton("Capturar Cédula Parte Posterior");
                setVerificacion(1);
                setInfoImagen("/img/backccamarilla.png");
                setImagen(false);
                setDatosFrontal(res);
                setImgCedulaFrontal(resUrl);
              }else{
                setSnackbarProps("e" + "Debes intentarlo de nuevo");
                setImagen(false);
              }
            } 
          }
        }
      } else if(verificacion===1){
        if(cedulaDigital===true){
          const res= await validarCedulaPosteriorNueva(urlIm);
          if(res===false){
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
          }else{
              const resUrl = await uploadFileUrl(urlIm);
            if(resUrl!==false){
              setImgCedulaPosterior(resUrl);
              //setDatosPosterior(res);
              setSnackbarProps("s" + "Validación exitosa. Continua con tu rostro");
              setInformacion("Coloca tu rostro en recuadro para capturar la imagen.");
              setInformacionBoton("Capturar Selfie");
              setVerificacion(2);
              setInfoImagen("/img/SELFIE.jpg");
              //Debemos guardar el link de la imagen
              //const urlFileRut = await uploadFile(imageSrc);
              setImagen(false); 
            }else{
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
            }
          }

        } else{
          const res= await validarCedulaPosterior(urlIm);

          if(res===false){
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
          }else{
              const resUrl = await uploadFileUrl(urlIm);
            if(resUrl!==false){
              setImgCedulaPosterior(resUrl);
              setDatosPosterior(res);
              setSnackbarProps("s" + "Validación exitosa. Continua con tu rostro");
              setInformacion("Coloca tu rostro en recuadro para capturar la imagen.");
              setInformacionBoton("Capturar Selfie");
              setVerificacion(2);
              setInfoImagen("/img/SELFIE.jpg")
              //Debemos guardar el link de la imagen
              //const urlFileRut = await uploadFile(imageSrc);
              setImagen(false); 
            }else{
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
            }
          }
        }
      }else if(verificacion===2){
          const res= await validarFotoPersona(urlIm);
          if(res===false){
              setSnackbarProps("e" + "Debes intentarlo de nuevo");
              setImagen(false);
          }else{
              const resUrl = await uploadFileUrl(urlIm);
              if(resUrl!==false){
                  let values={};
                  values.document=datosFrontal.cedula.replace(/\./g, '');
                  const indicePrimerEspacio = datosFrontal.nombres.indexOf(" ");
                  const indicePrimerEspacioApellido = datosFrontal.apellidos.indexOf(" ");
                  if(indicePrimerEspacio===-1){
                      values.firstName= datosFrontal.nombres.substring(indicePrimerEspacio + 1);
                      values.secondName="";
                  }else{
                      values.firstName= datosFrontal.nombres.substring(0, indicePrimerEspacio);
                      values.secondName= datosFrontal.nombres.substring(indicePrimerEspacio + 1);
                  }

                  if(indicePrimerEspacioApellido===-1){
                      values.firstLastname= datosFrontal.apellidos.substring(indicePrimerEspacioApellido + 1);
                      values.secondLastname="";
                  }else{
                      values.firstLastname= datosFrontal.apellidos.substring(0, indicePrimerEspacioApellido);
                      values.secondLastname= datosFrontal.apellidos.substring(indicePrimerEspacioApellido + 1);
                  }
                  
                  if(cedulaDigital===true){
                    const fechaNueva = datosFrontal.nacimiento.replace(/\s/g, '-')

                    const partesFecha = fechaNueva.split('-');
                    const nombreMes = partesFecha[1];
                    const numeroMes = mesEnNumero(nombreMes);

                    const dia = partesFecha[0];
                    const mes = numeroMes.toString().padStart(2, '0');
                    const anio = partesFecha[2];

                    values.nacimiento= dia + mes + anio;
    
                    values.sexo=datosFrontal.sexo;
                    values.sangre=datosFrontal.sangre;
                    values.email= datos.email;
                    values.urlCedulaFrontal = imgCedulaFrontal;
                    values.urlCedulaPosterior = imgCedulaPosterior;
                    values.urlSelfie = resUrl;
    
                    setDatosFormulario(values);
    
                    setOpenFormProducto(true);
                  }else{
                    const fechaNueva = datosPosterior.nacimiento;

                    const partesFecha = fechaNueva.split('-');
                    const nombreMes = partesFecha[1];
                    const numeroMes = mesEnNumero(nombreMes);

                    const dia = partesFecha[0];
                    const mes = numeroMes.toString().padStart(2, '0');
                    const anio = partesFecha[2];

                    values.nacimiento= dia + mes + anio;
    
    
                    values.sexo=datosPosterior.sexo;
                    values.sangre=datosPosterior.sangre;
                    values.email= datos.email;
                    values.urlCedulaFrontal = imgCedulaFrontal;
                    values.urlCedulaPosterior = imgCedulaPosterior;
                    values.urlSelfie = resUrl;
    
                    setDatosFormulario(values);
    
                    setOpenFormProducto(true);
                  }

                  // setSnackbarProps("s" + "Validación exitosa. Continua con tu rostro");
                  // setInformacion("Coloca tu rostro en recuadro para capturar la imagen.");
                  // setInformacionBoton("Capturar Selfie");
                  // setVerificacion(2);
                  // setInfoImagen("/img/persona.PNG")
                  // //Debemos guardar el link de la imagen
                  // //const urlFileRut = await uploadFile(imageSrc);
                  // setImagen(false);
              }else{
                setSnackbarProps("e" + "Debes intentarlo de nuevo");
                setImagen(false);
              }
          }
      }
    }else{
      setImagen(false);
      setSnackbarProps("e" + "Verifica los permisos de acceso a la cámara");
    }
    
    hideLoader();
  }

  const registerButton = () =>(
    <Grid container spacing={2} justify="center" alignItems="center">
      <Grid item xs={12} md={5} style={{ padding: 10 }}>
        <Paper style={{ padding: '5px',  height: '415px' }}>
            <img className="imageLogo" src={infoImagen} style={{ width: '100%', height: '350px',  objectFit: "contain" }}/>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h4 style={{padding:8, paddingTop: 20, textAlign: 'center'}}>{informacion}</h4>
            </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={5} style={{ padding: 10 }}>
        <Paper style={{ padding: '5px', height: '415px', }}>
          <>
            {
              imagen ? <img style={{ border: '3px dashed #BDBDBD', width: '100%', height: '350px',  objectFit: "contain" }} src={urlImagen}/> :
              <>
                <Webcam
                  style={{ border: '3px dashed #BDBDBD', width: '98.5%', height: '335px'}}
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={modeCamara==="user"?videoConstraints: videoConstraintsAtras}
                  screenshotFormat="image/jpeg"
                  mirrored= {isMobile ? modeCamara === 'user' : true}
                />
                {isMobile? 
                  <Grid container justify="center" alignItems="center">
                    <Button fullWidth disabled={imagen} variant="contained" color="secondary" onClick={toggleFacingMode}>
                      Cambiar Camara a {modeCamara==="user"? "Trasera": "Frontal"}
                    </Button> 
                  </Grid> : null
                }
              </>             
            }
            <div 
            style={{padding:2}}
            >
              <Button fullWidth disabled={imagen} variant="contained" color="primary" onClick={() => captureScreen()}>
                {informacionBoton}
              </Button>
            </div>
          </>
        </Paper>
        {
          openFormProducto?
            <FormularioRegistroSupervisores
              open = {openFormProducto}
              close = {closeFormulario}
              idEvento={idEvento}
              eventoName={eventoName}
              modo={"crear"}
              tiposPago={tiposPago}
              datos={datosFormulario}
            />:null
        }
      </Grid>
    </Grid>
  )

  return (
      <>
          <Dialog 
              open={open} 
              fullScreen
              aria-labelledby="form-dialog-title"
              TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                      <Toolbar>
                          <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close">
                              <CloseIcon />
                          </IconButton>
                          <Typography variant="h6" className={classes.title}>
                            Validación de Identidad: {informacionBoton}
                          </Typography>
                      </Toolbar>
                  </AppBar>
                    <Box className="form-containerRegistro">
                      <Grid container>
                                  {registerButton()}
                      </Grid>
                  </Box>     
          </Dialog>
          {ShowAlert(snackbarProps, setSnackbarProps)}
      </>
  );
}