import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField, MenuItem } from "@material-ui/core"
import { useFormik } from 'formik';

import ShowAlert from '../shared/Snackbar';

import { updateSupervisor } from '../../core/apiSupervisores';

export const DialogValidarCambiarCedula = ({open, handleClose, modificarCedula, title, content, values, newCedula}) => {
    const [snackbarProps, setSnackbarProps] = useState();

    const onSubmit = async(values1) =>{
            values.documento = values1.cedulaNueva;
            values.id = values._id;
                            
            const res = await updateSupervisor(values)

            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                setSnackbarProps("s" + res.data.message);
                newCedula(values1.cedulaNueva);
                handleClose();
            }

         
          console.log('Valores del formulario:', values);
      }
    
    const formik = useFormik({
        initialValues: {
          cedulaNueva: '',
          cedulaNuevaConfirmar: '',
        },
        validate: (values) => {
          const errors = {};
          if (!values.cedulaNueva) {
            errors.cedulaNueva = 'Campo requerido';
          }
          if (!values.cedulaNuevaConfirmar) {
            errors.cedulaNuevaConfirmar = 'Campo requerido';
          }
          if (values.cedulaNuevaConfirmar !== values.cedulaNueva) {
            errors.cedulaNuevaConfirmar = 'Los campos deben ser iguales';
          }
    
          return errors;
        },
         onSubmit
      });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Modificar cédula <b>{values?.documento}</b> de <b>{values?.firstName} {values?.firstLastname}</b> por:</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <Grid >
                        <TextField
                        size="small"
                        variant="outlined"
                        required
                        label="Número Cedula Nueva"
                        name="cedulaNueva"
                        value={formik.values.cedulaNueva}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        error={formik.touched.cedulaNueva && Boolean(formik.errors.cedulaNueva)}
                        helperText={formik.touched.cedulaNueva && formik.errors.cedulaNueva}
                        />  
                    </Grid>
                    <Grid style={{paddingTop:15}}>
                        <TextField
                        size="small"
                        variant="outlined"
                        required
                        label="Confirmar Cedula Nueva"
                        name="cedulaNuevaConfirmar"
                        value={formik.values.cedulaNuevaConfirmar}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        error={formik.touched.cedulaNuevaConfirmar && Boolean(formik.errors.cedulaNuevaConfirmar)}
                        helperText={formik.touched.cedulaNuevaConfirmar && formik.errors.cedulaNuevaConfirmar}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                    <b>Cancelar</b>
                    </Button>
                    <Button type="submit" color="primary">
                    <b>Aceptar</b>
                    </Button>
                </DialogActions>
            </form>
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </Dialog>
    )
}
