import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

import axios from 'axios';
import TablaMunicipios from './TablaMunicipios';
import FormularioMunicipio from './FormularioMunicipio';
import { getProveedoresPagos } from '../../core/apiProveedorPagos';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogMunicipios({open, close, Dept}){
    const classes = useStyles();
    const theme = useTheme();

    const [ciudades, setCiudades] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState([]);
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState(null)

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataProveedoresPagos = async () => {
        showLoader();
        const res = await getProveedoresPagos()
        if (res.error){
            setSnackbarProps("e" + res.error);
            setProveedores([]);
        }else{
            setProveedores(res.data);
        }

        hideLoader();
    }

    const loadDataCiudades = async () => {
        showLoader();
        const res = await axios.post(API + "/getDataFromEntity", {
            entity: 'Ciudad',
            condition: {departamento:Dept._id, delete:false}
        })

        if (res.error){
            setSnackbarProps("e" + res.error);
            setCiudades([]);
            setFiltering([])
        }else{
            setCiudades(res.data);
            setFiltering(res.data)
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar() {
            if(Dept){
                await loadDataProveedoresPagos()
                await loadDataCiudades()
            }
        }
        inicializar();
    }, [Dept]);

    const handleCrearEditarMunicipios= (tipo, deptSeleccionado) => {
        setCiudadSeleccionada(deptSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = () => {
        setOpenForm(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar municipios de { Dept?.name || '' }
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <Filter
                                            source={ciudades}
                                            searchBy="municipio"
                                            setTableData={setFiltering}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                            startIcon={<AddIcon />}
                                            onClick={() => handleCrearEditarMunicipios("crear", null)}>
                                            Agregar municipio
                                        </Button>                        
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>
                            <TablaMunicipios
                                municipios={filtering}
                                handleEdit={handleCrearEditarMunicipios}
                                reloadTable={loadDataCiudades}
                                setSnackbarProps={setSnackbarProps}
                                proveedores={proveedores}
                            />
                        </Paper>
                    </Box> 
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>               
            </Dialog>

            <FormularioMunicipio
                open={openForm}
                close={closeForm}
                proveedores={proveedores}
                mode={modo}
                Dept={Dept}
                refreshTable={loadDataCiudades}
                data={ciudadSeleccionada}
            />
        </>
    );
}