import React, {useEffect, useState} from 'react';
import { Popover, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { getVideos } from "../../core/apiControls";
import ShowAlert from '../shared/Snackbar';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const VideoSelector = ({ anchorEl, onClose, onVideoSelect }) => {

  const [videos, setVideos] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState();

  const videosConsulta = async () =>{
    const res = await getVideos();

    if(res.error){
      //setSnackbarProps("e" + "Debes intentarlo de nuevo");
    }else{
      let arregloVideos= [];
      for(let i = 0; i<res.data.length; i++){
        let value = {};
        value.id = i+1;
        value.title = res.data[i].name;
        value.url = res.data[i].url;
        value.description = res.data[i].description;
        arregloVideos.push(value);
      }
      setVideos(arregloVideos);
    }
  }

  useEffect(() => {
    videosConsulta();
  }, []);

  return (
    <>
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <List>
        {videos.map((video) => (
          <ListItem key={video.id} button onClick={() => onVideoSelect(video.url)}>
            <ListItemAvatar>
              <Avatar>
                <PlayArrowIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={video.title} secondary={video.description} />
          </ListItem>
        ))}
      </List>
     </Popover>
    {ShowAlert(snackbarProps, setSnackbarProps)}
    </>
  );
};

export default VideoSelector;