import React, {useState, useEffect} from 'react';

// Import de componentes necesarios 
import { 
    Paper,
    Box,
    TextField,
    Grid,
    Typography,
    Button,
    Dialog,
    Slide,
    Toolbar,
    AppBar,
    IconButton,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Hidden
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import ShowAlert from './shared/Snackbar';
import { showLoader, hideLoader } from './shared/Loader';
import { getTerminosCondicionesUsers, acceptTerminosCondicionesUser } from '../core/settingsAPI' 
import ReactHtmlParser from 'react-html-parser'; 

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TerminosCondiciones(){

    const [open, setOpenTerminos] = useState(false);

    const handleClose = () =>{
        setOpenTerminos(false);
    }

    const [terminosCondiciones, setTerminosCondiciones] = useState("");
    const [versionTC, setVersionTC] = useState("");
    const [snackbarProps, setSnackbarProps] = useState();

    const handleAceptarTerminos = async () => {
        //Si el usuario acepta, quiere decir que esta aceptando la versión actual de los terminos y condicione
        showLoader();
        
        var res = await acceptTerminosCondicionesUser(versionTC);

        if (res.error){
            setSnackbarProps("e" + res.error)
        }else{
            handleClose();        
        }

        hideLoader();
    }

    //Servicio de consulta de empresas
    const loadAll = async () =>
    {
        showLoader();
        
        var res = await getTerminosCondicionesUsers();

        if (res.error){
            setSnackbarProps("e" + res.error)
            setTerminosCondiciones("Error de conexión");
        }else{
            if(res.data.contenido == ""){
                //En caso de que no se encuetren terminos y condiciones pendientes de aceptar el modal no se presenta                    
                handleClose();
            }else{
                setTerminosCondiciones(res.data.contenido);
                setVersionTC(res.data.version);
                setOpenTerminos(true);
            }            
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar(){
            loadAll();
        }
        
        inicializar();
    },[]);    

    return(
        <div>
            <Dialog 
                fullScreen 
                open={open}
                PaperProps={{style: { backgroundColor: "#ECEFF1"}}}
                TransitionComponent={Transition}>
                <AppBar position="static">
                    <Toolbar>                      
                        <Typography variant="h6" className="titleDialog" style={{ flex: 1 }}>
                            Terminos y condiciones
                        </Typography>
                        <div>
                            <IconButton onClick={handleAceptarTerminos} color="inherit">
                                Aceptar
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Box p={3}>
                    <Paper elevation={3}>
                        <Box p={3}>
                            <h2>
                                Versión actual terminos y condiciones: {versionTC}
                            </h2>
                            <br></br>
                            { ReactHtmlParser(terminosCondiciones) }
                        </Box>
                    </Paper>                    
                </Box>
            </Dialog>

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </div>
    )
}