import { rolesAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getRolesByEventos = async (eventos) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getRolesByEventos`,{eventos}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getRolesEvento = async (evento) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getRolesEvento`,{evento}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getRolesEventoRegistro = async (evento) =>
{
        const res = await axios.post(`${API}/getRolesEvento`,{evento});

        return handleResponse(res); 
}

export const createRolesEvento = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createRolesEvento`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const updateRolesEvento = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateRolesEvento`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const getPuestosEventoRegistro = async (evento, rol) =>
{
        let values = {};
        values.evento= evento;
        values.rol = rol;
        const res = await axios.post(`${API}/getPuestosEventoRegistro`,{values});

        return handleResponse(res); 
}

export const getPuesto = async (puesto) =>
{
        const res = await axios.post(`${API}/getPuesto`,{puesto});

        return handleResponse(res); 
}