/**
 * El componente 'PageContent' tiene el fin de evitar escribir la estructura necesaria 
 * cuando se importa el componente 'NavContainer'
 */
import React, { useEffect } from 'react'
import { LandingDashboard } from '../Dashboard';

import NavContainer from './NavContainer';
import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken } from "../../core/sessionAPI";

/**
 * Cuando se importa el componente 'PageContent', es necesario asignarle la propiedad 'view', con
 * la que se determina la Landing Page a mostrar. Dicha Landing Page está definida en el componente
 * desde donde se importó a 'PageContent'
 */
const View = (props) =>
{
    const { view } = props;

    useEffect(() =>
    {
        async function init ()
        {
            // Realizar el servicio que refresca el token
            await refreshToken();
            // Reiniciar timer de inactividad
            setTokenInterval();
        }
        init();
    }, [])

    if (view === "dashboard"){
        return <LandingDashboard />
    }

    return <h1>Esta vista no existe</h1>
}

export default function PageContent (props)
{
    const wrapper = useWrapperStyles();

    return (
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />

                <div className={wrapper.offset}></div>
                
                {/* IMPORTANTE: Ver definición del componente View */}
                <View view={props.view}/>
            </div>
        </div>
    )
}