import { usersAPI as API } from "../apiUrls";
import { handleResponse, isAuthenticated } from "../sessionAPI";
import axios from "axios";

// Crear usuario administrador
export const createAdmin = async (user) => 
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createAdmin`, user, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

// Crear usuario administrador
export const updateAdmin = async (user) => 
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateAdmin`, user, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

// Devuele un listado con solo los usuarios administradores
export const getAdminUsers = async () =>
{
    if (isAuthenticated())
    {
        const res = await axios.get(`${API}/getAdmins`, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}