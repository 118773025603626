import { useState, useEffect } from 'react';

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Ajusta el valor según tus necesidades
    };

    handleResize(); // Llamada inicial para establecer el estado inicial

    window.addEventListener('resize', handleResize); // Agregar el evento resize

    return () => {
      window.removeEventListener('resize', handleResize); // Eliminar el evento resize al desmontar el componente
    };

  }, []);

  return {isMobile};
};

export default useResponsive;