import React, {useState} from 'react';
import { Paper, Box, Grid, IconButton, Collapse, Divider, Button } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutocompleteFromEntity from '../controls/AutocompleteFromEntity';

import '../../App.css';

export default function FiltrosMapa({polyline, setPolyline , evento, supervisores, setSupervisores, departamentos, setDepartamentos, ciudades, setCiudades, puestos, setPuestos, usuariosDisponibles}){
    const [reloadCities, setReloadCities] = useState(0);
    const [reloadStation, setReloadStation] = useState(0);
    const [reloadUsuarios, setReloadUsuarios] = useState(0);

    const [collapse, setCollapse] = useState(true);

    return(
        <Paper elevation={1} className="floatPanelOverMap">
            <Grid container>
                <Grid container item xs={10}>
                    <Box p={1} style={{paddingTop:"15px", fontWeight:"bold"}}>
                        Filtros de búsqueda
                    </Box>                    
                </Grid>
                <Grid container item xs={2} justify="flex-end">
                    <IconButton 
                        justify="flex-end"
                        color="primary" 
                        aria-label="upload picture"
                        onClick={()=>{
                            setCollapse(!collapse)  
                        }}>                        
                        {collapse ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
                    </IconButton>                    
                </Grid>
            </Grid>
            <Collapse in={collapse}>
                <Divider />
                <Box p={1}>
                    Filtrado por asignación
                </Box>
                <Box p={1}>
                    <AutocompleteFromEntity
                        size="small"
                        value={departamentos}
                        enableCreation={false}
                        label="Departamentos"
                        placeholder="Seleccione los departamentos asignados"
                        entity="Departamento"
                        field="name"
                        setObject={(objs) => {
                            setDepartamentos(objs);
                            setReloadCities(objs.length);
                            setCiudades([]);
                            setPuestos([])
                            setSupervisores([]);
                            setReloadUsuarios(Math.random());
                        }}
                        condition={{delete: false}}
                        multiple={true}
                        selectAll={false}
                        showOtherField={{field: 'codigo', show: true}}
                    />
                </Box>
                <Box p={1}>
                    <AutocompleteFromEntity
                        size="small"
                        value={ciudades}
                        enableCreation={false}
                        label="Ciudades"
                        placeholder="Seleccione las ciudades asignadas"
                        entity="Ciudad"
                        field="name"
                        setObject={(objs) => {
                            setCiudades(objs);
                            setReloadStation(objs.length);
                            setReloadUsuarios(Math.random());
                            setPuestos([])
                            setSupervisores([]);
                        }}
                        condition={
                            {departamento:{$in:departamentos}, delete:false}
                        }
                        multiple={true}
                        selectAll={false}
                        reloadFlag={reloadCities}      
                        showOtherField={{field: 'codigo', show: true}}                              
                    />
                </Box>
                <Box p={1}>
                    <AutocompleteFromEntity
                        size="small"
                        value={puestos}
                        enableCreation={false}
                        label="Puestos"
                        placeholder="Seleccione los puestos asignados"
                        entity="DivipolRegistros"
                        field="nombreDelPuesto"
                        setObject={(objs) => {
                            setPuestos(objs);
                            setReloadUsuarios(Math.random());
                            setSupervisores([]);
                        }}
                        condition={{municipio:{$in:ciudades}, evento:evento, delete:false}}
                        reloadFlag={reloadStation}
                        multiple={true}
                        selectAll={false}
                        showOtherField={{field: 'codigoDivipol', show: true}}                            
                    />
                </Box>
                <Box p={1} pb={2}>
                    <Button
                        variant='contained'
                        fullWidth
                        color='primary'
                        onClick={() => {
                            setDepartamentos([]);
                            setCiudades([]);
                            setPuestos([]);
                            setSupervisores([]);
                            setReloadCities(0);
                            setReloadStation(0);
                            setReloadUsuarios(Math.random());
                        }}
                    >
                        Limpiar filtro por asignación
                    </Button>
                </Box>
                {usuariosDisponibles && usuariosDisponibles.length > 0 &&
                    <>
                        <Divider />
                        <Box p={1}>
                            Filtrado por personas
                        </Box>
                        <Box p={1}>
                            <AutocompleteFromEntity
                                size="small"
                                value={supervisores}
                                enableCreation={false}
                                label="Supervisores evento"
                                placeholder="Seleccione los supervisores que desea visualizar"
                                entity="Supervisor"
                                field="firstLastname"
                                fields={["telefono","-","documento", "firstName", "secondtName", "firstLastname", "secondLastname"]}
                                setObject={(objs) => {
                                    setSupervisores(objs);
                                }}
                                condition={{
                                    departamentos:(departamentos.length > 0 ? {$in: departamentos}:{$exists: true}),
                                    ciudades:(ciudades.length > 0 ? {$in: ciudades}:{$exists: true}),
                                    puestos:(puestos.length > 0 ? {$in: puestos}:{$exists: true}),
                                    _id: {$in: usuariosDisponibles},
                                    evento:evento, 
                                    delete:false,
                                    entrega: true,
                                    recolecta: true
                                }}
                                reloadFlag={reloadUsuarios + usuariosDisponibles.length}
                                multiple={true}
                                selectAll={false}
                                showOtherField={{field: 'firstName', show: true}}
                            />
                        </Box>  
                    </>
                }             
                { polyline.length > 0 &&
                    <>
                        <Divider />
                        <Box p={1}>
                            { polyline.length } Rutas mostradas
                        </Box>
                        <Box p={1} pb={2}>
                            <Button  
                                variant='contained'
                                fullWidth
                                color='primary'
                                onClick={() => {
                                    setPolyline([]);
                                }}
                            >
                                Ocultar rutas
                            </Button>
                        </Box>
                    </>
                } 
            </Collapse>
        </Paper>
    )
}