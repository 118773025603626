import { Box, Button } from '@material-ui/core'
import React from 'react'

export const SuccessSignedPage = () => {
  return (
    <Box display='flex' flexDirection='column' style={{gap: '2rem'}} alignItems='center'>
      <Box  
        width='100%' 
        height={120} 
        bgcolor='green' 
        textAlign='center'
        style={{opacity: 0.7}}
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderRadius={15}
      >
        <h1 style={{color: 'white', opacity: 1}}>Este documento ya fue firmado</h1>
      </Box>
      {/* <Button variant='contained' color='primary' href='/' style={{maxWidth: 'fit-content'}} size='large'>
        Volver al inicio
      </Button> */}
    </Box>
  )
}
