import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import "../../../src/App.css";

import axios from 'axios';
import FormularioConcepto from './FormularioConcepto';
import TablaConcepto from './TablaConcepto';
import { getConceptos } from '../../core/apiConcepto';
import ShowAlert from '../shared/Snackbar';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogConcepto({open, close}){
    const classes = useStyles();
    const theme = useTheme();

    const [conceptos, setConceptos] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState([]);
    const [conceptoSeleccionado, setConceptoseleccionado] = useState(null)

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadConceptos = async () => {
        showLoader();
        const res = await getConceptos()

        if (res.error){
            setSnackbarProps("e" + res.error);
            setConceptos([]);
            setFiltering([])
        }else{
            setConceptos(res.data);
            setFiltering(res.data)
            console.log(res.data)
        }
        
        hideLoader();;
    }

    useEffect(() => {
        async function inicializar() {
            await loadConceptos()
        }
        inicializar();
    }, []);

    const handleCrearEditarConcepto = (tipo, deptSeleccionado) => {
        setConceptoseleccionado(deptSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = async() => {
        await loadConceptos()
        setOpenForm(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar conceptos presupuestales
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={9}>
                                        <Filter
                                            source={conceptos}
                                            searchBy="concepto"
                                            setTableData={setFiltering}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => handleCrearEditarConcepto("crear", null)}>
                                                Agregar concepto
                                            </Button>    
                                        </Grid>   
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>   
                            <TablaConcepto
                                departamentos = {filtering}
                                handleEdit = {handleCrearEditarConcepto}
                                reloadTable = {loadConceptos}
                                setSnackbarProps ={setSnackbarProps}
                            />
                        </Paper>
                    </Box>                   
                </Box>               
            </Dialog>

            <FormularioConcepto
                open = {openForm}
                close = {closeForm}
                mode={modo}
                data={conceptoSeleccionado}
            />

			{ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}