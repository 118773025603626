//Import react
import React, { useEffect, useState } from 'react';

//Import estructura de navegación, y renderizado de la landing page
import { useWrapperStyles } from '../../../assets/js/wrapperStyles';
import NavContainer from '../../shared/NavContainer'
import { onUserNavigate, setTokenInterval } from "../../../helpers/CheckSession";
import { refreshToken, isAuthenticated } from "../../../core/sessionAPI";

//Control paginacion
import CustomTableHead from "../../shared/Tablas/CustomTableHead";
import { stableSort, getComparator } from "../../shared/Tablas/CustomSortTable";
import Paginacion from "../../shared/Tablas/Paginacion";

//Controles generales
import { showLoader, hideLoader } from '../../shared/Loader';
import ShowAlert from '../../shared/Snackbar';

//Formulario y Tabla
import Formulario from './Formulario';
import Tabla from './Tablas';

//Consumo de servicios
import { getGerentes } from '../../../core/apiGerentes';

import ImportExportIcon from '@material-ui/icons/ImportExport';
import PaymentIcon from '@material-ui/icons/Payment';
import AddIcon from '@material-ui/icons/Add';

// Import de componentes necesarios 
import {
    Paper,
    Box,
    Grid,
    Button
} from '@material-ui/core';

import Filter from "../../shared/Filters";

const View = () => {
    useEffect(() => {
        async function init() {
            // Realizar el servicio que refresca el token
            await refreshToken();
            // Reiniciar timer de inactividad
            setTokenInterval();
        }
        init();
    }, [])

    return <LandingPage />
}

export default function Gerentes() {
    const wrapper = useWrapperStyles();

    return (
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />
                <div className={wrapper.offset}></div>

                {/* IMPORTANTE: Ver definición del componente View */}
                <View />
            </div>
        </div>
    )
}

//Estructura relacionada con la tabla de datos
// Columnas de la tabla, el valor 'id' debe ser equivalente al JSON que inserta las filas para que funcione el ordenamiento
export function LandingPage() {
    //Control de mensajes de error    
    const [formMode, setFormMode] = useState("crear")
    const [editData, setEditData] = useState(null)
    const [openForm, setOpenForm] = useState(false)

    const [snackbarProps, setSnackbarProps] = useState();

    //Array con entiedades
    const [gerentes, setGerentes] = useState([]);
    const [tableGerentes, setTableGerentes] = useState([]);
       
    const [filtering, setFiltering] = useState(false);

    //
    const [gerenteSeleccionado, setGerenteSeleccionado] = useState(null);
    const [modo, setModo] = useState("crear");


    //Servicio de consulta de empresas
    /* Carga el listado de empresas */
    const loadAll = async () => {
        showLoader();

        var user = isAuthenticated().user;
        var res = await getGerentes(user.empresa);

        if (res.error) {
            setSnackbarProps("e" + res.error)
            setGerentes([]);
            setTableGerentes(res.data);
        } else {
            setGerentes(res.data);
            setTableGerentes(res.data);
        }

        hideLoader();
    }

    const closeHandler = () => {
        setOpenForm(false)
    }

    useEffect(() => {
        async function inicializar() {
            loadAll();
        }

        inicializar();
    }, []);

    const handleCrearEditar = (tipo, gerenteSeleccionado) => {
        console.log(tipo);
        setGerenteSeleccionado(gerenteSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    return (
        <>
            <Box p={1}>
                <Paper elevation={1}>
                    <Grid container>
                        <Grid item xs={12} sm={8} md={10}>
                            <Box p={1}>
                                <Filter
                                    source={gerentes}
                                    searchBy="gerentes"
                                    setTableData={setTableGerentes}
                                    setFiltering={setFiltering}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Box p={1}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        startIcon={<AddIcon />}
                                        onClick={() => handleCrearEditar("crear", null)}>
                                        Nuevo gerente
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            <Tabla
                registros= {tableGerentes}
                handleEditClick= {handleCrearEditar} 
                reloadTable= {loadAll}
                setSnackbarProps= {setSnackbarProps}
            />
            
            <Formulario 
                open= {openForm}
                close= {closeHandler}
                mode= {modo}
                data= {gerenteSeleccionado}
                refreshTable= {loadAll}
            />

            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}