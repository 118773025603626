import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,
    Container,
    Card,
    CardContent,
    CardMedia,
    Divider,

} from '@material-ui/core';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

import { getServicios } from '../../core/apiServicio';
import { getPagoSupervisores } from '../../core/apiPagoSupervisor';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogInfoSupervisor({edicionEvento, open, close, supervisor, roles = []}){
    //console.log(supervisor);
    const classes = useStyles();

    const fechaNacimiento = moment(supervisor?.nacimiento,"DDMMYYYY").format("DD-MMM-YYYY")

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Información personal de {`${supervisor?.firstName || ''} ${supervisor?.firstLastname}`}
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box p={6}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Card>
                                        <CardMedia
                                        component="img"
                                        alt="Selfie"
                                        src={supervisor?.urlSelfie? supervisor?.urlSelfie : "/img/sinFoto.png"}
                                        style={{ objectFit: 'cover', maxHeight: 450 }}
                                        />
                                        <CardContent>
                                            <h4 variant="subtitle2">Foto de Supervisor</h4>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button 
                                        fullWidth 
                                        variant='contained' 
                                        color='primary'
                                        onClick={() => window.open(supervisor?.rutUrl, '_blank')}
                                    >
                                        Descargar RUT
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={2}> 
                                <Grid item xs={12} sm={12}>
                                    <h2>Información general</h2>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <h4 variant="subtitle1">Identificación:</h4>
                                    <Typography variant="body1">{supervisor?.documento}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <h4 variant="subtitle1">Nombres:</h4>
                                    <Typography variant="body1">{supervisor?.firstName} {supervisor?.secondName}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <h4 variant="subtitle1">Apellidos:</h4>
                                    <Typography variant="body1">{supervisor?.firstLastname} {supervisor?.secondLastname}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <h4 variant="subtitle1">Sexo:</h4>
                                    <Typography variant="body1">{supervisor?.sexo}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <h4 variant="subtitle1">Fecha de Nacimiento:</h4>
                                    <Typography variant="body1">{fechaNacimiento}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <h4 variant="subtitle1">Tipo de Sangre:</h4>
                                    <Typography variant="body1">{supervisor?.tipoSangre}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <h2>Fotos del documento</h2>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box width='100%' display='flex' justifyContent='end'>
                                        <Card style={{width: 'fit-content'}}>
                                            <CardMedia
                                                component="img"
                                                alt="Cédula Delantera"
                                                src={supervisor?.urlCedulaFrontal ? supervisor?.urlCedulaFrontal: "/img/sinFoto.png"}
                                                style={{ width: 'auto',  objectFit: 'cover', maxHeight: 250 }}
                                            />
                                            <CardContent>
                                            <h4 variant="subtitle2">Cédula Delantera</h4>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box width='100%' display='flex' justifyContent='start'>
                                        <Card style={{width: 'fit-content'}}>
                                            <CardMedia
                                                component="img"
                                                alt="Cédula Frontal"
                                                src={supervisor?.urlCedulaPosterior ? supervisor?.urlCedulaPosterior : "/img/sinFoto.png"}
                                                style={{ width: 'auto',  objectFit: 'cover', maxHeight: 250 }}
                                            />
                                            <CardContent>
                                            <h4 variant="subtitle2">Cédula Posterior</h4>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>               
                {ShowAlert(snackbarProps, setSnackbarProps)}
            </Dialog>
        </>
    );
}