import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

import axios from 'axios';
import TablaProyectos from './TablaProyectos';
import FormularioProyecto from './FormularioProyecto';
import { getProyectos } from '../../core/apiProyecto';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogProyectos({open, close, Empresa}){
    const classes = useStyles();
    const theme = useTheme();

    const [Proyectos, setProyectos] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState(false);
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState(null)

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataProyectoss = async () => {
        showLoader();
        const res = await getProyectos(Empresa._id)

        if (res.error){
            setSnackbarProps("e" + res.error);
            setProyectos([]);
        }else{
            setProyectos(res.data);
            console.log(res.data)
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar() {
            if(Empresa){
                await loadDataProyectoss()
            }
        }
        inicializar();
    }, [Empresa]);

    const handleCrearEditarMunicipios= (tipo, EmpresaSeleccionado) => {
        setCiudadSeleccionada(EmpresaSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = async() => {
        await loadDataProyectoss()
        setOpenForm(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar proyectos de { Empresa?.name || '' }
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <Filter
                                            source={Proyectos}
                                            searchBy="name"
                                            setTableData={setProyectos}
                                            setFiltering={setFiltering}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                            startIcon={<AddIcon />}
                                            onClick={() => handleCrearEditarMunicipios("crear", null)}>
                                            Agregar proyecto
                                        </Button>                        
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>
                            <TablaProyectos
                                proyectos={Proyectos}
                                handleEdit={handleCrearEditarMunicipios}
                                reloadTable={loadDataProyectoss}
                                setSnackbarProps={setSnackbarProps}
                            />
                        </Paper>
                    </Box> 
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>               
            </Dialog>

            <FormularioProyecto
                open={openForm}
                close={closeForm}
                mode={modo}
                Empresa={Empresa}
                data={ciudadSeleccionada}
            />
        </>
    );
}