import { TextField } from "@material-ui/core"
import { FastField, getIn } from "formik"
import React from "react"

export function MyInput (props) {
  const { label, name, ...rest } = props
  return (
    <FastField name={name}>
      {({ field, form }) => (
        <TextField
          error={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}
          helperText={getIn(form.errors, name)}
          label={label}
          {...rest}
          {...field}
        />
      )}
    </FastField>
  )
}