import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from '../../../src/helpers/Validator';
import { handleResponse, isAuthenticated } from "../../../src/core/sessionAPI";

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    MenuItem,
    Collapse
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

// Validaciones
import { 
    letrasEspacios,
    corregirEspaciado
} from "../../../src/helpers/Validator";
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from "../../../src/components/shared/Loader";
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

// Servicios
import { createRolesEvento, updateRolesEvento } from '../../../src/core/apiRolesEvento';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioRoles ({open,recargarEventos, close, mode, data, refreshTable, eventos = [], eventoDefault})
{
    const classes = useStyles();
    const theme = useTheme();

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    const [switchEntrega, setSwitchEntrega] = useState(false);
    const [switchRecoleccion, setSwitchRecoleccion] = useState(false);
    const [switchEntregaCAD, setSwitchEntregaCAD] = useState(false);
    const [switchDiaAdicional, setSwitchDiaAdicional] = useState(false);
    const [switchViaticos, setSwitchViaticos] = useState(false);
    const [switchSoloMunZonificado, setSwitchSoloMunZonificado] = useState(false);
    const [isCustomValue, setIsCustomValue] = useState(false);

    const [puestoObligatorio, setPuestoObligatorio] = useState(true);
    const [unPuesto, setUnPuesto] = useState(true);

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        // Corregir espaciado de algunos campos
        values.name = corregirEspaciado(values.name);       

        //Se adiciona el campo empresa al formulario de registro
        //Registro de funcionarios de la plataforma
        values.entrega = switchEntrega;
        values.entregaCAD = switchEntregaCAD;
        values.recoleccion = switchRecoleccion;
        values.aplicaValorDiaAdicional = switchDiaAdicional;
        values.aplicaViaticos = switchViaticos;
        values.aplicaSoloMunicipioZonificado = switchSoloMunZonificado;
        values.isCustomValue = isCustomValue;
        values.puestoObligatorio = puestoObligatorio;
        values.unPuesto = unPuesto;

        if(isCustomValue){
            values.valorDia = 0;
            values.valorDiaAdicional = 0;
            values.valorDiaViaticos = 0;
            values.aplicaValorDiaAdicional = false
            values.aplicaSoloMunicipioZonificado = false
        }

        let res = {};
        if (mode === "crear"){
            res = await createRolesEvento(values);
        }else if (mode === "editar"){
            values.id = data._id;
            res = await updateRolesEvento(values);
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
           
            recargarEventos();
            resetForm();
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */
    const formik = useFormik({
        initialValues:{
            name: "",
            entrega: "",
            recoleccion: "",
            valorDia: '',
            valorDiaAdicional: '',
            aplicaValorDiaAdicional: '',
            valorDiaViaticos: '',
            aplicaViaticos: '',
            isCustomValue:'',
            evento: '',
            tipoRol: ''
        },
        validationSchema: yup.object().shape({
            name: yup.string().required("Debes llenar este campo"),
            entrega: yup.string(),
            recoleccion: yup.string(),
            aplicaValorDiaAdicional: yup.string(),
            aplicaSoloMunicipioZonificado: yup.string(),
            aplicaViaticos: yup.string(),    
            evento: yup.string().required("Debes llenar este campo"),
            tipoRol: yup.string().required("Debes llenar este campo"),
            valorDia: yup.number().typeError('Debe ser un valor numérico'),
            valorDiaAdicional: yup.number().typeError('Debe ser un valor numérico'),
            valorDiaViaticos: yup.number().typeError('Debe ser un valor numérico')
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const entregaProps = getFieldProps("entrega");
    const recoleccionProps = getFieldProps("recoleccion");
    const valorDiaProps = getFieldProps("valorDia");
    const valorDiaAdicionalProps = getFieldProps("valorDiaAdicional");    
    const aplicaValorDiaAdicionalProps = getFieldProps('aplicaValorDiaAdicional');
    const aplicaValorViaticosProps = getFieldProps('aplicaViaticos');
    const valorDiaViaticosProps = getFieldProps("valorDiaViaticos");
    const aplicaSoloMunicipioZonificadoProps = getFieldProps('aplicaSoloMunicipioZonificado');
    const isCustomValueProps = getFieldProps('isCustomValue');
    const eventoProps = getFieldProps('evento');
    const tipoRolProps = getFieldProps('tipoRol');

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                setSwitchEntrega(false);
                setSwitchEntregaCAD(false);
                setSwitchRecoleccion(false);
                setSwitchDiaAdicional(false);
                setSwitchSoloMunZonificado(false);
                setIsCustomValue(false);

                formik.setFieldValue("evento", eventoDefault);

                setFormTitle("Registrar nuevo Rol");
                setPuestoObligatorio(true);
                setUnPuesto(true);
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos del Rol");
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("entrega", data.entrega);
                formik.setFieldValue("recoleccion", data.recoleccion);
                formik.setFieldValue("valorDia", data?.valorDia || 0);
                formik.setFieldValue("valorDiaAdicional", data?.valorDiaAdicional || 0);
                formik.setFieldValue("valorDiaViaticos", data?.valorDiaViaticos || 0);
                formik.setFieldValue("evento", data.evento?._id || '');
                formik.setFieldValue("tipoRol", data?.tipoRol || '');

                setSwitchEntrega(data.entrega)
                setSwitchEntregaCAD(data.entregaCAD ? data.entregaCAD:false);
                setSwitchRecoleccion(data.recoleccion)
                setSwitchDiaAdicional(data?.aplicaValorDiaAdicional)
                setSwitchSoloMunZonificado(data?.aplicaSoloMunicipioZonificado)
                setIsCustomValue(data.isCustomValue ? data.isCustomValue:false);
                setSwitchViaticos(data?.aplicaViaticos ? data?.aplicaViaticos :false)
                setPuestoObligatorio(data.puestoObligatorio ? data.puestoObligatorio:false);
                setUnPuesto(data.unPuesto ? data.unPuesto:false);
            }
        }

        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    //Switch
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 55,
        height: 34,
        padding: 5,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(28px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 6,
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 20,
          height: 20,
          borderRadius: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 32 / 2,
          opacity: 1,
          backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));

    const formFields = () => (
        <>
            <Box p={4}>
                <Grid container spacing={8}>
                    <Grid item xs={12} className="smallPadding">
                        <TextField
                            select
                            variant="outlined" 
                            name="evento" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.evento ? errors.evento : ""}
                            error={touched.evento && Boolean(errors.evento)}
                            {...eventoProps}
                        >   
                            {
                                eventos.map((e, i) => (
                                    <MenuItem key={i} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>    
                    <Grid item xs={12} className="smallPadding">
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombre"
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={12} className="smallPadding">
                        <TextField
                            select
                            variant="outlined" 
                            name="tipoRol" 
                            label="Tipo de Rol"
                            fullWidth
                            required
                            helperText={touched.tipoRol ? errors.tipoRol : ""}
                            error={touched.tipoRol && Boolean(errors.tipoRol)}
                            {...tipoRolProps}
                        >   
                            <MenuItem key='Serv' value='Serv'>
                                Servicio
                            </MenuItem>
                            <MenuItem key='Trans' value='Trans'>
                                Transporte
                            </MenuItem>
                        </TextField>
                    </Grid>  

                    <Grid item xs={12} ms={12} className="smallPadding">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">El registro de puesto es obligatorio</div>
                            <Stack  
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center">
                                <Typography>No</Typography>
                                <AntSwitch 
                                    checked = {puestoObligatorio}
                                    onChange = {() => setPuestoObligatorio(!puestoObligatorio)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                                <Typography>Si</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} ms={12} className="smallPadding">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">Solo permitir asignar un puesto</div>
                            <Stack  
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center">
                                <Typography>No</Typography>
                                <AntSwitch 
                                    checked = {unPuesto}
                                    onChange = {() => setUnPuesto(!unPuesto)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                                <Typography>Si</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} style={{padding:"10px"}}>
                        <Box>
                            <p className='smallTextDescription' style={{padding:"10px"}}>
                                Habilitar rol, para reporte de los diferentes estados de los productos
                            </p>
                        </Box>
                        <Grid container>
                            <Grid item xs={12} className="smallPadding">
                                <Box p={1} className="boxContentInput">
                                    <div className="leftContentTitle">Entrega</div>
                                    <Stack  
                                        className="bolleanButtonCenter"
                                        direction="row" 
                                        spacing={1} 
                                        alignItems="center"
                                        name="entrega"
                                        {...entregaProps}>
                                        <Typography>No</Typography>
                                        <AntSwitch 
                                            checked = {switchEntrega}
                                            onChange = {() => setSwitchEntrega(!switchEntrega)}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                        <Typography>Si</Typography>
                                    </Stack>
                                </Box>
                            </Grid>   
                            <Grid item xs={12} className="smallPadding">
                                <Box p={1} className="boxContentInput">
                                    <div className="leftContentTitle">Recolección</div>
                                    <Stack 
                                        className="bolleanButtonCenter"
                                        direction="row" spacing={1} alignItems="center"  
                                        {...recoleccionProps}  
                                        name="recoleccion"     
                                        >
                                        <Typography>No</Typography>
                                        <AntSwitch 
                                            checked= {switchRecoleccion}
                                            onChange= {() => setSwitchRecoleccion(!switchRecoleccion)}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                        <Typography>Si</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className="smallPadding">
                                <Box p={1} className="boxContentInput">
                                    <div className="leftContentTitle">Entrega CAD</div>
                                    <Stack 
                                        className="bolleanButtonCenter"
                                        direction="row" spacing={1} alignItems="center"  
                                        {...recoleccionProps}  
                                        name="entregaCAD"     
                                        >
                                        <Typography>No</Typography>
                                        <AntSwitch 
                                            checked= {switchEntregaCAD}
                                            onChange= {() => setSwitchEntregaCAD(!switchEntregaCAD)}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                        />
                                        <Typography>Si</Typography>
                                    </Stack>
                                </Box>
                            </Grid>                            
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="smallPadding">
                        <p className='smallTextDescription'>
                            Todo rol por defecto tiene un valor día, en caso de activar el valor personalizado, se debe definir al momento de asignar el rol al usuario.
                        </p>
                    </Grid>

                    <Grid item xs={12} className="smallPadding">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">Valor personalizado en la asignación del rol al usuario</div>
                            <Stack  
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center"
                                name="isCustomValue"
                                {...isCustomValueProps}>
                                <Typography>No</Typography>
                                <AntSwitch 
                                    checked = {isCustomValue}
                                    onChange = {() => setIsCustomValue(!isCustomValue)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                                <Typography>Si, valor personalizado</Typography>
                            </Stack>
                        </Box>                    
                    </Grid>
                    
                    <Grid item xs={12} style={{padding:"10px"}}>
                        <Collapse in={!isCustomValue}>
                            <Box>
                                <p className='smallTextDescription' style={{padding:"10px"}}>
                                    Configuración valida, para pagos no persinalizados
                                </p>
                            </Box>
                            <Grid container>                                
                                <Grid item xs={12} className="smallPadding">
                                    <TextField
                                        variant="outlined" 
                                        name="valorDia" 
                                        label="Valor diario"
                                        required={!isCustomValue}
                                        fullWidth  
                                        numeric                                      
                                        inputProps={{ maxLength: 30 }}
                                        helperText={touched.valorDia ? errors.valorDia : ""}
                                        error={touched.valorDia && Boolean(errors.valorDia)}
                                        {...valorDiaProps}
                                    />
                                </Grid>
                                <Grid item xs={12} className="smallPadding">
                                    <Box p={1} className="boxContentInput">
                                        <div className="leftContentTitle">Aplica día adicional</div>
                                        <Stack 
                                            className="bolleanButtonCenter"
                                            direction="row" 
                                            spacing={1} 
                                            alignItems="center"                                              
                                            name="aplicaValorDiaAdicional"     
                                            {...aplicaValorDiaAdicionalProps}  
                                            >
                                            <Typography>No</Typography>
                                            <AntSwitch 
                                                checked= {switchDiaAdicional}
                                                onChange= {() => setSwitchDiaAdicional(!switchDiaAdicional)}
                                                inputProps={{ 'aria-label': 'ant design' }}
                                            />
                                            <Typography>Si</Typography>
                                        </Stack>
                                    </Box>
                                </Grid>                                             
                                <Grid item xs={12} className="smallPadding">
                                    <Collapse in={switchDiaAdicional}>
                                        <TextField
                                            variant="outlined" 
                                            name="valorDiaAdicional" 
                                            label="Valor del día adicional"
                                            fullWidth
                                            disabled={!switchDiaAdicional}
                                            required={switchDiaAdicional}
                                            inputProps={{ maxLength: 30 }}
                                            helperText={touched.valorDiaAdicional ? errors.valorDiaAdicional : ""}
                                            error={touched.valorDiaAdicional && Boolean(errors.valorDiaAdicional)}
                                            {...valorDiaAdicionalProps}
                                        />
                                    </Collapse>
                                </Grid>
                                <Grid item xs={12} className="smallPadding">
                                    <Box p={1} className="boxContentInput">
                                        <div className="leftContentTitle">Aplica día viáticos</div>
                                        <Stack 
                                            className="bolleanButtonCenter"
                                            direction="row" 
                                            spacing={1} 
                                            alignItems="center"                                              
                                            name="aplicaViaticos"     
                                            {...aplicaValorViaticosProps}  
                                            >
                                            <Typography>No</Typography>
                                            <AntSwitch 
                                                checked= {switchViaticos}
                                                onChange= {() => setSwitchViaticos(!switchViaticos)}
                                                inputProps={{ 'aria-label': 'ant design' }}
                                            />
                                            <Typography>Si</Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} className="smallPadding">
                                    <Collapse in={switchViaticos}>
                                        <TextField
                                            variant="outlined" 
                                            name="valorDiaViaticos" 
                                            label="Valor del día viáticos"
                                            fullWidth
                                            disabled={!switchViaticos}
                                            required={switchViaticos}
                                            inputProps={{ maxLength: 30 }}
                                            helperText={touched.valorDiaViaticos ? errors.valorDiaViaticos : ""}
                                            error={touched.valorDiaViaticos && Boolean(errors.valorDiaViaticos)}
                                            {...valorDiaViaticosProps}
                                        />
                                    </Collapse>
                                </Grid>
                                <Grid item xs={12} className="smallPadding">
                                    <Box p={1} className="boxContentInput">
                                        <div className="leftContentTitle">Aplica en Municipios Zonificados</div>
                                        <Stack 
                                            className="bolleanButtonCenter"
                                            direction="row" spacing={1} alignItems="center"  
                                            {...aplicaSoloMunicipioZonificadoProps}  
                                            name="aplicaSoloMunicipioZonificado"     
                                            >
                                            <Typography>No</Typography>
                                            <AntSwitch 
                                                checked= {switchSoloMunZonificado}
                                                onChange= {() => setSwitchSoloMunZonificado(!switchSoloMunZonificado)}
                                                inputProps={{ 'aria-label': 'ant design' }}
                                            />
                                            <Typography>Si</Typography>
                                        </Stack>
                                    </Box>
                                </Grid>  
                            </Grid>
                        </Collapse> 
                    </Grid>
                </Grid>                                                 
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}