import { sedesAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getSedes = async (idEmpresa) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getSedes`,{empresa:idEmpresa}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const crearSede = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/create`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const actualizarSede = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/update`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}