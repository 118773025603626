import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';
import { isAuthenticated } from "../../core/sessionAPI";

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import "../../../src/App.css";
import ShowAlert from '../shared/Snackbar';


import axios from 'axios';
import TablaConceptoCiudad from './TablaConceptoCiudad';
import FormularioConceptoCiudad from './FormularioConceptoCiudad';
import { getConceptoCiudad } from '../../core/apiConceptoCiudad';
import DialogConcepto from './DialogConcepto';
import { getroles } from '../../core/apiConcepto';
import { getRolesByEventos } from '../../core/apiRolesEvento';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogConceptoCiudad({open, close, municipio, evento}){
    const classes = useStyles();
    const theme = useTheme();

    const [conceptoCiudades, setConceptoCiudad] = useState([]);
    const [filtering, setFiltering] = useState([]);

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataConceptoCiudad = async () => {
        showLoader();
        const res = await getConceptoCiudad(
            municipio?._id,
            municipio?.ciudad?.isZonificado,
            evento,
            municipio?.departamento?._id
        )
        if (res.error){
            setSnackbarProps("e" + res.error);
            setFiltering([])
            setConceptoCiudad([]);
        }else{
            setConceptoCiudad(res.data);
            setFiltering(res.data)
            console.log(res.data)
        }
        hideLoader();
    }


    useEffect(() => {
        async function inicializar() {
            if(municipio)
                await loadDataConceptoCiudad()
        }
        inicializar();
    }, [municipio, evento]);

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar presupuesto por roles del municipio de <b>{municipio?.ciudad?.name}</b>
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <Filter
                                            source={conceptoCiudades}
                                            searchBy="DEPART"
                                            setTableData={setFiltering}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => handleCrearEditarDepartamento("crear", null)}>
                                                Añadir concepto al municipio
                                            </Button>    
                                        </Grid>   
                                    </Grid> */}
                                    {/* {user.role == 2 &&
                                    <Grid item xs={12} sm={3} md={4}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => openDialogConcepto()}>
                                                Administrar concepto presupuestal
                                            </Button>    
                                        </Grid>   
                                    </Grid>} */}
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>   
                            <TablaConceptoCiudad
                                departamentos = {filtering}
                                close={close}
                                municipio = {municipio}
                                reloadTable = {loadDataConceptoCiudad}
                                setSnackbarProps ={setSnackbarProps}
                            />
                        </Paper>
                    </Box>                 
                </Box>               
            </Dialog>

            {/* <DialogConcepto
                open={showConcepto}
                close={closeDialogConcepto}
            /> */}

            {/* <FormularioConceptoCiudad
                open = {openForm}
                close = {closeForm}
                mode={modo}
                roles={roles}
                municipio={municipio}
                data={concpetoCiudadSeleccionado}
            /> */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}