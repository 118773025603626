import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    InputAdornment
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import axios from 'axios';
import { createProyecto, updateProyecto } from '../../core/apiProyecto';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioProyecto ({open, close, mode, data, refreshTable, Empresa})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    

        values.empresaOperadora = Empresa._id
        let res = {};
        if (mode === "crear"){
            res = await createProyecto(values)
        }else if (mode === "editar"){
            values.id = data._id;
            res = await updateProyecto(values)
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            codigo: "",
            descripcion: "",
            name: "",
        },
        validationSchema: yup.object().shape({
            codigo: yup.string().required("Debes llenar este campo"),
            name: yup.string().required("Debes llenar este campo"),
            descripcion: yup.string().required("Debes llenar este campo")
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const codigoProps = getFieldProps("codigo");
    const nameProps = getFieldProps("name");
    const descripcionProps = getFieldProps("descripcion");
    

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){

            var user = isAuthenticated().user;
            console.log(mode)
            if (mode === "crear"){
                formik.handleReset();
                setFormTitle(`Registrar proyecto de ${Empresa?.name}`);
            }else if (mode === "editar"){
                setFormTitle(`Actualizar proyecto de ${Empresa?.name}`);
                formik.setFieldValue("codigo", data.codigo);
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("descripcion", data.descripcion);
            }
        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);


    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos del proyecto</h3>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="codigo" 
                            label="Coódigo"
                            fullWidth
                            required
                            inputProps={{ 
                                maxLength: 10, 
                            }}
                            helperText={touched.codigo ? errors.codigo : ""}
                            error={touched.codigo && Boolean(errors.codigo)}
                            {...codigoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombre"
                            fullWidth
                            required
                            inputProps={{ 
                                maxLength: 200, 
                            }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined" 
                            name="descripcion" 
                            label="Descripcion del proyecto"
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 45,
                            }}
                            helperText={touched.descripcion ? errors.descripcion : ""}
                            error={touched.descripcion && Boolean(errors.descripcion)}
                            {...descripcionProps}
                        />
                    </Grid>     
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}