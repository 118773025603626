import { supervisorsAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";


const validarDatosCedulaFrontal = async (data) =>
{
    try {
        const texto = data;
        const numeroRegex = /[0-9]+(?:\.[0-9]{3})*(?:,[0-9]+)?/g;
        let values = {};
 
        const arreglo = texto.split('\n');

        const numerosEncontrados = texto.match(numeroRegex);
        const cedu=numerosEncontrados[0];
        values.cedula= cedu;
        const regex = /^[A-ZÁÉÍÓÚÑ][A-ZÁÉÍÓÚÑ\s]*[A-ZÁÉÍÓÚÑ\s]$/;
        const regex1= /\s/;

        if(numerosEncontrados){
            for (let index = 0; index < arreglo.length; index++) {
                //Encontro Cedula
                if(arreglo[index].includes(numerosEncontrados[0])){
                    //Va encontrar Nombre
                    for (let i = index+1; i < arreglo.length; i++) {
                       
                        
                       if(regex.test(arreglo[i]) && regex1.test(arreglo[i]) ){
                            values.apellidos=arreglo[i];
                            //Va encontrar Apellido
                            for (let j = i+1; j < arreglo.length; j++) {
                                if(regex.test(arreglo[j]) && regex1.test(arreglo[j]) ){
                                     values.nombres=arreglo[j];
                                     j=arreglo.length;
                                     return values;
                                } 
                                //Encontrar Nombre
                                else if(regex.test(arreglo[j]) && !arreglo[j].includes("PELL") && !arreglo[j].includes("PELUD") && !arreglo[i].includes("MBRE")){
                                    values.nombres=arreglo[j];
                                    j=arreglo.length;
                                    return values;
                                }
                            }
                            i=arreglo.length;
                       }

                      //Encontrar solo nombre, solo apellido o "nombre y apellidos" o "nombre y apellidos" 
                      else if(regex.test(arreglo[i]) && !arreglo[i].includes("MBRE") && !arreglo[i].includes("UME")  ){
                        values.apellidos= arreglo[i];
                        //Va encontrar Apellido
                        for (let j = i+1; j < arreglo.length; j++) {
                            if(regex.test(arreglo[j]) && regex1.test(arreglo[j]) ){
                                 values.nombres=arreglo[j]; 
                                 j=arreglo.length;
                                 return values;
                            } 
                            //Encontrar Nombre
                            else if(regex.test(arreglo[j]) && !arreglo[j].includes("ELL") && !arreglo[i].includes("MBRE") ){
                                values.nombres=arreglo[j]
                                j=arreglo.length;
                                return values;
                            }
                        }
                        i=arreglo.length;
                    }  
                      
                    }     
                    index=arreglo.length;
                }
            }
        }else{
            return values;
        }
    } catch (error) {
        return 0;
    }
}

const validarDatosCedulaFrontalNueva = async (data) =>
{
    try {
        const texto = data;
        const numeroRegex = /[0-9]+(?:\.[0-9]{3})*(?:,[0-9]+)?/g;
        let values = {};
 
        const arreglo = texto.split('\n');

        const numerosEncontrados = texto.match(numeroRegex);
        const cedu=numerosEncontrados[0];
        values.cedula= cedu;
        const regex = /^[A-ZÁÉÍÓÚÑ][A-ZÁÉÍÓÚÑ\s]*[A-ZÁÉÍÓÚÑ\s]$/;
        const regex1= /\s/;

        const numeroRegexNacimiento = /(\d+)\s+([A-Za-z]+)\s+(\d+)/;
        const sang = /(O|A|B|AB)[+-]/g;

        if(numerosEncontrados){
            for (let index = 0; index < arreglo.length; index++) {
                //Encontro Cedula
                if(arreglo[index].includes(numerosEncontrados[0])){
                    //Va encontrar Nombre
                    for (let i = index+1; i < arreglo.length; i++) {
                       
                        
                       if(regex.test(arreglo[i]) && regex1.test(arreglo[i]) ){
                            values.apellidos=arreglo[i];
                            //Va encontrar Apellido
                            for (let j = i+1; j < arreglo.length; j++) {
                                if(regex.test(arreglo[j]) && regex1.test(arreglo[j]) ){
                                     values.nombres=arreglo[j];
                                     j=arreglo.length;
                                   //  return values;
                                } 
                                //Encontrar Nombre
                                else if(regex.test(arreglo[j]) && !arreglo[j].includes("ELL") && !arreglo[i].includes("MBRE")){
                                    values.nombres=arreglo[j];
                                    j=arreglo.length;
                                  //  return values;
                                }
                            }
                            i=arreglo.length;
                       }

                      //Encontrar solo nombre, solo apellido o "nombre y apellidos" o "nombre y apellidos" 
                      else if(regex.test(arreglo[i]) && !arreglo[i].includes("MBRE") && !arreglo[i].includes("UME")  ){
                        values.apellidos= arreglo[i];
                        //Va encontrar Apellido
                        for (let j = i+1; j < arreglo.length; j++) {
                            if(regex.test(arreglo[j]) && regex1.test(arreglo[j]) ){
                                 values.nombres=arreglo[j]; 
                                 j=arreglo.length;
                                // return values;
                            } 
                            //Encontrar Nombre
                            else if(regex.test(arreglo[j]) && !arreglo[j].includes("ELL") && !arreglo[i].includes("MBRE") ){
                                values.nombres=arreglo[j]
                                j=arreglo.length;
                               // return values;
                            }
                        }
                        i=arreglo.length;
                    }  
                      
                    }     
                    index=arreglo.length;
                }
            }

            const numerosEncontradosNacimiento = texto.match(numeroRegexNacimiento);
            const numerosEncontradosSangre= texto.match(sang);

            if(numerosEncontradosNacimiento && numerosEncontradosSangre){
              //  console.log(numerosEncontradosNacimiento);
                const naci=numerosEncontradosNacimiento[0];
                values.nacimiento=naci;
                values.sangre= numerosEncontradosSangre[0];
                for (let index = 0; index < arreglo.length; index++) {
                    if(arreglo[index]=="M" || arreglo[index]=="F"){
                        values.sexo= arreglo[index];
                    }
                }
            }

            return values;

        }else{
            return values;
        }
    } catch (error) {
        return 0;
    }
}


const validarDatosCedulaPosterior = async (data) =>
{
        try {
            const texto = data;
            const numeroRegex = /(\d+)-([A-Za-z]+)-(\d+)/;
            const sang = /(O|A|B|AB)[+-]/g;
            let values = {};

            const arreglo = texto.split('\n');

            const numerosEncontrados = texto.match(numeroRegex);
            const numerosEncontradosSangre= texto.match(sang); 
            if(numerosEncontrados && numerosEncontradosSangre){
                const naci=numerosEncontrados[0];
                values.nacimiento= naci;
                values.sangre= numerosEncontradosSangre[0];
                for (let index = 0; index < arreglo.length; index++) {
                    if(arreglo[index]=="M" || arreglo[index]=="F"){
                        values.sexo = arreglo[index];
                        index= arreglo.length;
                        return values;
                    }
                }
            }else{
                return values;
            }
        } catch (error) {
            return 0;
        }
}


export const validarCedulaFrontal = async (datos) =>
{
    try {
        let values= {};
        values.urlFoto = datos;
        const res = await axios.post(`${API}/cedulaFrontal`, values);

        if(res.data.text.description){
            console.log(res.data);
            const texto = res.data.text.description;
            if((texto.includes("REPÚBLICA") || texto.includes("REPUBLICA"))){ 
                if(texto.includes("IDENTIFICACIÓN") || texto.includes("IDENTIFICACION")){
                    if(texto.includes("CÉDULA") || texto.includes("CEDULA")){
                        if(texto.includes("CIUDADANÍA") || texto.includes("CIUDADANIA")){
                            if(texto.includes("COLOMBIA") && texto.includes("PERSONAL")){  
                              const response= await validarDatosCedulaFrontal(texto);
                              if(response.nombres && response.apellidos && response.cedula){
                                return response;
                              }else{
                                const values = false;
                                return values;
                              }  
                            }else{
                                const values = false;
                                return values;
                            }
                        }else{
                            const values = false;
                            return values;
                        }
                    }else{
                        const values = false;
                        return values;
                    }
                }else{
                    const values = false;
                    return values;
                }
            }else{
                const values = false;
                return values;
            }
        }else{
            const values = false;
            return values;
        }
    } catch (error) {
        const values = false;
        return values;
    }
}

export const validarCedulaFrontalNueva = async (datos) =>
{
    try {
        let values= {};
        values.urlFoto = datos;
        const res = await axios.post(`${API}/cedulaFrontal`, values);

        if(res.data.text.description){
            const texto = res.data.text.description;

            if((texto.includes("REPÚBLICA") || texto.includes("REPUBLICA"))){ 
                    // if(texto.includes("CÉDULA") || texto.includes("CEDULA")){
                        //if(texto.includes("CIUDADANÍA") || texto.includes("CIUDADANIA")){
                            if(texto.includes("COLOMBIA")){  
                              const response= await validarDatosCedulaFrontalNueva(texto);
                              if(response.nombres && response.nacimiento && response.sangre && response.apellidos && response.cedula){
                                return response;
                              }else{
                                const values = false;
                                return values;
                              }  
                            }else{
                                const values = false;
                                return values;
                            }
                        // }else{
                        //     const values = false;
                        //     return values;
                        // }
                    // }else{
                    //     const values = false;
                    //     return values;
                    // }
            }else{
                const values = false;
                return values;
            }
        }else{
            const values = false;
            return values;
        }
    } catch (error) {
        const values = false;
        return values;
    }
}

export const validarCedulaPosterior = async (datos) =>
{
    try {
        let values= {};
        values.urlFoto = datos;
        const res = await axios.post(`${API}/cedulaFrontal`, values);

        if(res.data.text.description){
            const texto = res.data.text.description;
            // if(texto.includes("ÍNDICE") ||texto.includes("INDICE")){
                // if(texto.includes("DERECHO")){
                    const response= await validarDatosCedulaPosterior(texto);
                    if(response.sexo && response.nacimiento && response.sangre){
                        return response;
                    }else{
                        const values = false;
                        return values;  
                    }
                // }else{
                //     const values = false;
                //     return values;
                // }
            // }else{
            //     const values = false;
            //     return values;
            // }
        }else{
            const values = false;
            return values;
        }
    } catch (error) {
        const values = false;
        return values;
    }
}

export const validarCedulaPosteriorNueva = async (datos) =>
{
    try {
        let values= {};
        values.urlFoto = datos;
        const res = await axios.post(`${API}/cedulaFrontal`, values);

        if(res.data.text.description){
            const texto = res.data.text.description;
                if(texto.includes("ICCOL")){
                        const values = true;
                        return values;  
                }else{
                    const values = false;
                    return values;
                }
        }else{
            const values = false;
            return values;
        }
    } catch (error) {
        const values = false;
        return values;
    }
}

export const validarFotoPersona = async (datos) =>
{
    try {
        let values= {};
        values.urlFoto = datos;
        const res = await axios.post(`${API}/validarFotoPersona`, values);
        if(res.data.respuesta == true){
            const values = true;
            return values;
        }else{
            const values = false;
            return values;  
        }
    } catch (error) {
        const values = false;
        return values;
    }
}