import React, {useState} from "react";
import {
    Paper,
    Box,    
    Grid,
    Button,
    Hidden,
    IconButton,
    Select,
    MenuItem
} from '@material-ui/core';

import "../../../App.css";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ServerPagination({numberTotalRows, page, perPage, setPage, setPerPage, rowsPerPageOptions, className}){
    return(
        <>
            <Box p={1} className={className}>
                <Grid container spacing={1} className="borderBlock">
                    <Grid item xs={4}>
                        <div className="littleTextPagination">
                            Filas por pagina
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <Box>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={perPage}
                                className='smallClassSelect'
                                label="Filas por pagina"
                                fullWidth
                                variant="outlined"
                                onChange={(e)=>{
                                    setPerPage(e.target.value)
                                }}>
                                {rowsPerPageOptions.map((obj, index)=>(
                                    <MenuItem value={obj} key={index}>{obj}</MenuItem>
                                ))}                            
                            </Select>
                        </Box>
                    </Grid>                        
                    <Grid item xs={3}>
                        <div className="littleTextPagination">
                            {(perPage * (page-1)) + 1}-{perPage * page} de {numberTotalRows}
                        </div>
                    </Grid>          
                    <Grid item xs={3} className="paddingButtonsPaginate">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <IconButton 
                                    size="small"
                                    color="primary" 
                                    aria-label="upload picture" 
                                    component="span"
                                    onClick={()=>{
                                        //Decrementar número de pagina
                                        if((page - 1) <= 0){
                                            setPage(1);
                                        }else{
                                            setPage(page - 1);
                                        }
                                    }}>
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6}>
                                <IconButton 
                                    size="small"
                                    color="primary" 
                                    aria-label="upload picture" 
                                    component="span"
                                    onClick={()=>{
                                        //Incrementar número de pagina
                                        //Se calcula el número de paginas posibles
                                        var maxPages = Math.ceil(numberTotalRows / perPage);
                                        if((page + 1) > maxPages){
                                            setPage(maxPages);
                                        }else{
                                            setPage(page + 1);
                                        }
                                    }}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Grid>                                
                        </Grid>
                    </Grid>                                                
                </Grid>
            </Box>            
        </>
    );
}