import React, { useState, useEffect } from "react";

import { 
    Box, 
    Button,
    TextField,
    Typography,
    useTheme,
    useMediaQuery,
    Grid,
    Collapse,
    Hidden 
} from "@material-ui/core";

// Validaciones
import * as yup from "yup";
import "../../App.css"

// Valores iniciales de los campos
const initialValues = 
{
    username: "",
    password: ""
}

// Validaciones de los campos
const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim()
        .required("Ingresa tu correo"),
    password: yup
        .string()
        .trim()
        .required("Ingresa tu contraseña"),
})


const Login = () =>
{
    /**
     * MediaQuery para que el formulario ocupe toda la pantalla en 'sm'
     */
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [restorePass, setRestorePass] = useState(false);
    const [mailToRestore, setMailToRestore] = useState("");

    const [snackbarProps, setSnackbarProps] = useState();


    return(
        <Box>
            <Box p={5}>
                <h3>
                    <b>Versión actual terminos y condiciones: 1.1 APP Móvil 10 y superiores</b>
                </h3>
                <br/>
                <h1>
                    <b>Términos del servicio, politicas de privacidad (privacy policy)</b>
                </h1>
                <br/>
                <h2>
                    <b>Condiciones generales, app  DISPROEL(com.indesap.disproelrn)</b>
                </h2>
                <br/>
                <p>
                    El ingreso y uso de la herramienta Sistema DISPROEL  disponible en el sitio disproel.app  implica la aceptación de estas condiciones, sin perjuicio de las condiciones particulares que pudieran aplicarse en algunos de los servicios concretos del sitio disproel.app El usuario ingresa y hace uso del sitio disproel.app y sus contenidos bajo su responsabilidad. El acceso al sitio Web no tiene ningún costo, sin embargo, nos reservamos nuestro derecho de entregar ciertos contenidos solo a usuarios registrados. Los diseños, contenidos, servicios y condiciones del sitio pueden ser modificados libremente por Indesap S.A.S en el momento que se considere necesario, ya sea por comodidad o porque las leyes competentes así lo requieren. Por razones técnicas o de seguridad el acceso al sitio disproel.app podrá verse interrumpido por pequeños periodos de tiempo sin dar lugar alguna explicación o indemnizaciones, el usuario debe ser responsable de tener sus copias de seguridad las cuales están disponibles para ser descargadas desde la plataforma.
                </p> 
                <br/>       
                <p>
                    <h2>
                        <b>Propiedad intelectual</b>
                    </h2>
                </p>
                <br/>        
                <p>
                    Indesap S.A.S tiene la propiedad intelectual de disproel.app y sus servicios derivados y complementarios. El usuario no debe considerar el acceso a disproel.app, incluso si es un usuario que paga por el servicio, como un activo o licencia sobre el sitio. Los usuarios de disproel.app únicamente podrán realizar un uso privado, personal o profesional de los contenidos de este. Cualquier tipo de actividad ilícita realizada a través del sitio está totalmente prohibida. Está prohibida la reproducción o copia ilegal de los contenidos, archivos, plantillas y modelos de la plataforma disproel.app
                </p> 
                <br/>       
                <p>
                    <h2>
                        <b>De las partes</b>
                    </h2>
                </p>
                <br/>
                <p>
                    Los términos expresados en este contrato constituyen las condiciones de uso y privacidad para todos los servicios prestados en este momento y los añadidos en el futuro, por Indesap S.A.S propietario de la solución disproel.app en su sitio web disproel.app y sus aplicaciones.
                </p>
                <p>
                    El usuario del sitio disproel.app acepta, al momento de empezar a utilizar el servicio, respetar las condiciones impuestas por este contrato.
                </p>
                <br/>
                <p>
                    <h2>
                        <b>Términos Usados</b>
                    </h2>
                </p>
                <br/>
                <p>
                    <h2>
                        <b>Las definiciones a continuación tendrán el siguiente significado en este contrato:</b>
                    </h2>
                </p>
                <br/>
                <p>
                    <h2>
                        <b>Geolocalización</b>
                    </h2>
                </p>
                <br/>
                <p>
                    La geolocalización de los usuarios es con fines informativos y no tiene la finalidad de estudiar ni análizar el comportamiento de los usuarios, por ello, toda información recopilada en la geolocalización es eliminada, posterior a la visualización y monitoreo de los usuario durante los periodos de entrega y recolección de productos en los diferentes eventos.
                </p>
                <br/>
                <p>
                    El término "contrato" o "acuerdo" hace referencia a este contrato y a sus Términos y Condiciones.
                    El término "leyes aplicables" hace referencia a las leyes que se aplican en Colombia para este tipo de contratos.
                    El término "sitio" ó "sitio web" hace referencia al sitio donde se prestan todos los servicios que Indesap S.A.S ofrece a través de su solución disproel.app, sin tener en cuenta posibles terceros relacionados con Indesap S.A.S.
                    El término "servicio" o “servicios” hace referencia a la aplicación que ofrece Indesap S.A.S en su sitio web y que opera desde los dominios disproel.app. También hace referencia a el uso de la página web de Indesap S.A.S y su centro de soporte.
                    El término "Sistema" hace referencia a la plataforma web usada por Indesap S.A.S para prestar su servicio, incluido el software que usa la plataforma y todos sus contenidos.
                    El término "Aplicación" hace referencia al software que ofrece Indesap S.A.S como servicio a en su sitio disproel.app
                    El término "titular de la cuenta" hace referencia a la persona a nombre de quien está la tarjeta de crédito, débito o cuentas bancarias que se usan para el pago y registrar de la cuenta en disproel.app
                    El término "usuario" hace referencia a la persona que visita y usa el sitio web disproel.app, sea o no "titular de cuenta".
                    El término "Plan" hace referencia a las condiciones de uso que tienen un usuario según el pago que haya efectuado.
                </p>
                <br/>
                <p>
                    <h2>
                        <b>Términos y condiciones</b>
                    </h2>
                </p>
            </Box>
        </Box>
    )
}

export default Login;