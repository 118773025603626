import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    MenuItem,
    Collapse
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";
import { createPagoSupervisor, updatePagoSupervisor } from '../../core/apiPagoSupervisor';

// Servicios

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioPagoSupervisor({open, close, mode, data, refreshTable, Empresa, roles = [], pagosAsignados = []})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [isAdmin, setIsAdmin] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    const [rolActual, setRolActual] = useState({});
    const [isCustomValue, setIsCustomValue] = useState(false);

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        const nameRol = String(rolActual?.name) || ''
        if(
            nameRol === 'COTEROS' && 
            values.diasAdicionales > 16
        ) return setSnackbarProps('El rol COTEROS solo permite como máximo 10 días adicionales')
        if(
            nameRol === 'SUPERVISOR DE ENTREGA' && 
            (values?.diasAdicionales > 10 || values?.diasViaticos > 4)
        ) return setSnackbarProps('El rol SUPERVISOR DE ENTREGA solo permite como máximo 10 días adicionales y 4 días de viáticos')
        if(
            nameRol === 'JEFE DE BODEGA' &&
            values?.diasAdicionales > 4
        ) return setSnackbarProps('El rol JEFE DE BODEGA solo permite como máximo 4 días adicionales')
        
        showLoader();    
        const {user} = isAuthenticated()
        if(user?.role == 2)
            values.creador = 'administrador'
        else if(user?.role == 3)
            values.creador = 'gerente'

        values.totalDia = parseInt(values.dias) * parseInt(rolActual?.valorDia)

        if(rolActual?.aplicaValorDiaAdicional){
            values.totalDiasAdicionales = parseInt(values.diasAdicionales || 0) * parseInt(rolActual?.valorDiaAdicional || 0)            
        }else{
            values.diasAdicionales = 0;
            values.totalDiasAdicionales = 0;
        }

        if(rolActual?.aplicaViaticos){
            values.totalViaticos = parseInt(values.diasViaticos || 0) * parseInt(rolActual?.valorDiaViaticos || 0)
        }else{
            values.diasViaticos = 0;
            values.totalViaticos = 0;
        }

        values.total = values.totalDia + values.totalDiasAdicionales + values.totalViaticos
        values.supervisor = Empresa._id
        values.evento = Empresa?.evento

        /*
        if(rolActual?.aplicaValorDiaAdicional && values.diasAdicionales <= 0){
            hideLoader();   
            setSnackbarProps("eEl rol requiere días adicionales");
            return 
        }
        */

        let res = {};
        if (mode === "crear"){
            res = await createPagoSupervisor(values)
        }else if (mode === "editar"){
            values.id = data._id;
            values.consecutivo = data.consecutivo
            res = await updatePagoSupervisor(values)
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            dias: "1",
            rol: '',
            diasAdicionales: '',
            diasViaticos: '',
            valorTotalPago: ''
        },
        validationSchema: yup.object().shape({
            dias: isAdmin ? yup.string().matches(regex.tenNumbers, "Este campo solo permite 10 dias").required('Campo requerido'):yup.string().matches(regex.oneNumber, "Este campo solo admite numeros, valor permitido 1").required('Campo requerido'),
            rol: yup.string().required("Debes llenar este campo"),
            diasAdicionales: yup.string(),
            diasViaticos: yup.string()
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const diasProps = getFieldProps("dias");
    const rolProps = getFieldProps("rol");
    const diasAdicionalesProps = getFieldProps("diasAdicionales");
    const diasViaticosProps = getFieldProps("diasViaticos");
    const valorTotalPagoProps = getFieldProps("valorTotalPago");    

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                setFormTitle(`Registrar concepto de pago de supervisor`);
            }else if (mode === "editar"){
                setFormTitle(`Actualizar concepto de pago de supervisor`);
                formik.setFieldValue("total", data?.total || 0);
                formik.setFieldValue("rol", data?.rol?._id || '');
                formik.setFieldValue("dias", data?.dias || 0);
                formik.setFieldValue("diasAdicionales", data?.diasAdicionales || 0);
                formik.setFieldValue("diasViaticos", data?.diasViaticos || 0);
                formik.setFieldValue("valorTotalPago", data?.valorTotalPago || 0);
                setIsCustomValue(data?.rol?.isCustomValue)
                setRolActual(data?.rol)
            }
        }
        const {user} = isAuthenticated()
        if(user?.role == 2){
            setIsAdmin(true);
        }else{
            setIsAdmin(false);
        }

        init();

    // eslint-disable-next-line
    }, [open]);


    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos del concepto</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="rol" 
                            label="Concepto presupuestal"
                            fullWidth
                            required                            
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.rol ? errors.rol : ""}
                            error={touched.rol && Boolean(errors.rol)}
                            disabled={!isAdmin && !(mode === "crear")}
                            {...rolProps}>
                            {
                                roles.length > 0 ?
                                roles.map((e, i) => {
                                    var exist = false;
                                    for(const pago of pagosAsignados){
                                        if(pago.rol?._id == e?._id && pago.rol?._id != rolProps.value){
                                            exist = true;
                                        }
                                    }

                                    if(!exist){
                                        return (<MenuItem key={i} value={e._id} 
                                            onClick={() => {
                                                setIsCustomValue(e.isCustomValue);
                                                setRolActual(e);
                                            }}>
                                            {e.name}
                                        </MenuItem>)
                                    }
                                }) : <></>
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Collapse in={!isCustomValue}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type='number'
                                        variant="outlined" 
                                        name="dias" 
                                        label="Días"
                                        fullWidth
                                        disabled={!((isAdmin && rolProps.value != '') || ((mode === "crear") && !isAdmin && rolProps.value != ''))}
                                        helperText={touched.dias ? errors.dias : ""}
                                        error={touched.dias && Boolean(errors.dias)}
                                        {...diasProps}
                                    />
                                </Grid>  
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined" 
                                        disabled
                                        label='Valor total por días'
                                        fullWidth
                                        value={`$ ${parseInt(diasProps.value || 0) * parseInt(rolActual?.valorDia || 0)}`}
                                    />
                                </Grid>                                   
                                    <Grid item xs={12} sm={6}>
                                        <Collapse in={rolActual?.aplicaValorDiaAdicional}>
                                            <TextField
                                                type='number'
                                                variant="outlined" 
                                                name="diasAdicionales" 
                                                label="Días adicionales"
                                                fullWidth
                                                disabled={!rolActual?.aplicaValorDiaAdicional}
                                                helperText={touched.diasAdicionales ? errors.diasAdicionales : ""}
                                                error={touched.diasAdicionales && Boolean(errors.diasAdicionales)}
                                                {...diasAdicionalesProps}
                                            />
                                        </Collapse>
                                    </Grid>  
                                    <Grid item xs={12} sm={6}>
                                        <Collapse in={rolActual?.aplicaValorDiaAdicional}>
                                            <TextField
                                                variant="outlined" 
                                                disabled
                                                label='Valor total por días adicionales'
                                                fullWidth
                                                value={`$ ${parseInt(diasAdicionalesProps.value || 0) * parseInt(rolActual?.valorDiaAdicional || 0)}`}
                                            />
                                        </Collapse>
                                    </Grid>                                
                                
                                    <Grid item xs={12} sm={6}>
                                        <Collapse in={rolActual?.aplicaViaticos}>
                                            <TextField
                                                type='number'
                                                variant="outlined" 
                                                name="diasViaticos" 
                                                label="Días viáticos"
                                                fullWidth
                                                disabled={!rolActual?.aplicaViaticos}
                                                helperText={touched.diasViaticos ? errors.diasViaticos : ""}
                                                error={touched.diasViaticos && Boolean(errors.diasViaticos)}
                                                {...diasViaticosProps}
                                            />
                                        </Collapse>
                                    </Grid>  
                                    <Grid item xs={12} sm={6}>
                                        <Collapse in={rolActual?.aplicaViaticos}>
                                            <TextField
                                                variant="outlined" 
                                                disabled
                                                label='Valor total por días adicionales'
                                                fullWidth
                                                value={`$ ${parseInt(diasViaticosProps.value || 0) * parseInt(rolActual?.valorDiaViaticos|| 0)}`}
                                            />
                                        </Collapse>
                                    </Grid>                                                                
                            </Grid>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Collapse in={isCustomValue}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined" 
                                    name="valorTotalPago" 
                                    label="Valor total a pagar"
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    helperText={touched.valorTotalPago ? errors.valorTotalPago : ""}
                                    error={touched.valorTotalPago && Boolean(errors.valorTotalPago)}
                                    {...valorTotalPagoProps}
                                />
                            </Grid> 
                        </Collapse>
                    </Grid>
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}