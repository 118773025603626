import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
// MUI Components
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Collapse
} from '@material-ui/core';

// Icons
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PieChartIcon from '@material-ui/icons/PieChart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import BuildIcon from '@material-ui/icons/Build';
import SubjectIcon from '@mui/icons-material/Subject';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MapIcon from '@mui/icons-material/Map';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import PublicIcon from '@mui/icons-material/Public';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

// Servicios
import { isAuthenticated } from "../../core/sessionAPI";
import { useEventContext } from '../../contexts/eventContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 300,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

export default function NavbarOptions()
{
    const { currentEvent: evento } = useEventContext()

    const path = window.location.pathname;
    const [isWebView,setIsWebView] = useState(false);
    const [openGlobal, setOpenGlobal] = React.useState(false || path.indexOf("global") != -1);   
    const [openZonificado, setOpenZonificado] = React.useState(false || path.indexOf("zonificado") != -1);   
    const [openNoZonificado, setOpenNoZonificado] = React.useState(false || path.indexOf("nozonificado") != -1);   
    const [openExterior, setOpenExterior] = React.useState(false || path.indexOf("exterior") != -1);   
    const [openManual, setOpenManual] = React.useState(false || path.indexOf("confirmacion-manual") != -1);   

    const classes = useStyles();
    const auth = isAuthenticated();

    useEffect(() => {
        //Validar si el origen es un webview, para remover el accceso a la interfaz de eventos y marcación
        var variables = window.location.href.split("/");
        if(variables.length == 8){
            //Contiene variable de webviwe
            if(variables[7] == "webView"){
                //Esconder acceso a controles
                setIsWebView(true);
            }
        };
    }, []); 

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={`${classes.root} bgItemsSidebar`}>
            {auth.user.role === 1 && (
                <>
                    <ListItem button component={Link} to="/empresasAdmin" selected={window.location.pathname.indexOf("empresasAdmin") != -1}>
                        <ListItemIcon>
                            <SupervisedUserCircleIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Administrador empresas, usuarios" />
                    </ListItem>
                    <ListItem button component={Link} to="/adminTerminosCondiciones" selected={window.location.pathname.indexOf("adminTerminosCondiciones") != -1}>
                        <ListItemIcon>
                            <ReceiptIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Administrador terminos y condiciones"/>
                    </ListItem>                    
                </>
            )}
            {auth.user.role === 2 && !evento && (
                <>
                    <ListItem button component={Link} to="/eventos" selected={window.location.pathname.indexOf("/eventos") != -1}>
                        <ListItemIcon>
                            <EventIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Eventos" />
                    </ListItem>                           
                    <ListItem button component={Link} to="/gerentes" selected={window.location.pathname.indexOf("/gerentes") != -1}>
                        <ListItemIcon>
                            <PeopleIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Gerentes y asistentes de gerencía" />
                    </ListItem> 
                    <ListItem button component={Link} to="/masters" selected={window.location.pathname.indexOf("/masters") != -1}>
                        <ListItemIcon>
                            <BuildIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Configuración de eventos" />
                    </ListItem>                                                                              
                </>
            )}
            {(auth.user.role === 2 || auth.user.role === 3 || auth.user.role === 4) && evento && (
                <>
                    {!isWebView &&
                    <ListItem button component={Link} to="/eventos">
                        <ListItemIcon>
                            <EventIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Eventos" />
                    </ListItem>
                    }
                    <ListItem button onClick={() => {setOpenGlobal(!openGlobal); setOpenZonificado(false); setOpenNoZonificado(false); setOpenExterior(false); setOpenManual(false)}}>
                        <ListItemIcon>
                            <PublicIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Global" />
                        {openGlobal ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>   
                    <Collapse in={openGlobal}>
                        <List component="div">
                            { evento?.entrega &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/global/entrega/" + evento?._id + "/webView":"/eventos/global/entrega/" + evento?._id} selected={window.location.pathname.indexOf("/global/entrega") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega" />
                                </ListItem>
                            }
                            <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/global/recoleccion/" + evento?._id + "/webView":"/eventos/global/recoleccion/" + evento?._id} selected={window.location.pathname.indexOf("/global/recoleccion") != -1}>
                                <ListItemIcon>
                                    <SubjectIcon htmlColor="white"/>
                                </ListItemIcon>
                                <ListItemText primary="Recolección" />
                            </ListItem>
                            { evento?.CAD &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/global/CAD/" + evento?._id + "/webView":"/eventos/global/CAD/" + evento?._id} selected={window.location.pathname.indexOf("/global/CAD") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega CAD" />
                                </ListItem>
                            }
                        </List>
                    </Collapse>  
                    <ListItem button onClick={() => {setOpenGlobal(false); setOpenZonificado(!openZonificado); setOpenNoZonificado(false); setOpenExterior(false); setOpenManual(false)}}>
                        <ListItemIcon>
                            <MapIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Zonificados" />
                        {openZonificado ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>   
                    <Collapse in={openZonificado}>
                        <List component="div">
                            { evento?.entrega &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/zonificado/entrega/" + evento?._id + "/webView":"/eventos/zonificado/entrega/" + evento?._id} selected={window.location.pathname.indexOf("/zonificado/entrega") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega" />
                                </ListItem>
                            }
                            { evento?.recoleccion &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/zonificado/recoleccion/" + evento?._id + "/webView":"/eventos/zonificado/recoleccion/" + evento?._id} selected={window.location.pathname.indexOf("/zonificado/recoleccion") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Recolección" />
                                </ListItem>
                            }
                            { evento?.CAD &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/zonificado/CAD/" + evento?._id + "/webView":"/eventos/zonificado/CAD/" + evento?._id} selected={window.location.pathname.indexOf("/zonificado/CAD") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega CAD" />
                                </ListItem>
                            }
                        </List>
                    </Collapse>  
                    <ListItem button onClick={() => {setOpenGlobal(false); setOpenZonificado(false); setOpenNoZonificado(!openNoZonificado); setOpenExterior(false); setOpenManual(false)}}>
                        <ListItemIcon>
                            <NotListedLocationIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="No Zonificados" />
                        {openNoZonificado ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>   
                    <Collapse in={openNoZonificado}>
                        <List component="div">
                            { evento?.entrega &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/nozonificado/entrega/" + evento?._id + "/webView":"/eventos/nozonificado/entrega/" + evento?._id} selected={window.location.pathname.indexOf("/nozonificado/entrega") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega" />
                                </ListItem>
                            }
                            { evento?.recoleccion &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/nozonificado/recoleccion/" + evento?._id + "/webView":"/eventos/nozonificado/recoleccion/" + evento?._id} selected={window.location.pathname.indexOf("/nozonificado/recoleccion") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Recolección" />
                                </ListItem>
                            }
                            { evento?.CAD &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/nozonificado/CAD/" + evento?._id + "/webView":"/eventos/nozonificado/CAD/" + evento?._id} selected={window.location.pathname.indexOf("/nozonificado/CAD") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega CAD" />
                                </ListItem>
                            }
                        </List>
                    </Collapse>  
                    <ListItem button onClick={() => {setOpenGlobal(false); setOpenZonificado(false); setOpenNoZonificado(false); setOpenExterior(!openExterior); setOpenManual(false)}}>
                        <ListItemIcon>
                            <PublicIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Exterior" />
                        {openExterior ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>   
                    <Collapse in={openExterior}>
                        <List component="div">
                            { evento?.entrega &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/exterior/entrega/" + evento?._id + "/webView":"/eventos/exterior/entrega/" + evento?._id} selected={window.location.pathname.indexOf("/exterior/entrega") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega" />
                                </ListItem>
                            }
                            { evento?.recoleccion &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/exterior/recoleccion/" + evento?._id + "/webView":"/eventos/exterior/recoleccion/" + evento?._id} selected={window.location.pathname.indexOf("/exterior/recoleccion") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Recolección" />
                                </ListItem>
                            }
                            { evento?.CAD &&
                                <ListItem button className={classes.nested} component={Link} to={isWebView ? "/eventos/exterior/CAD/" + evento?._id + "/webView":"/eventos/exterior/CAD/" + evento?._id} selected={window.location.pathname.indexOf("/exterior/CAD") != -1}>
                                    <ListItemIcon>
                                        <SubjectIcon htmlColor="white"/>
                                    </ListItemIcon>
                                    <ListItemText primary="Entrega CAD" />
                                </ListItem>
                            }
                        </List>
                    </Collapse> 
                    {!isWebView &&
                        <>
                            <ListItem button onClick={() => {setOpenGlobal(false); setOpenZonificado(false); setOpenNoZonificado(false); setOpenExterior(false); setOpenManual(!openManual)}}>
                                <ListItemIcon>
                                    <EditNotificationsIcon htmlColor="white"/>
                                </ListItemIcon>
                                <ListItemText primary="Confirmación Manual" />
                                {openManual ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>   
                            <Collapse in={openManual}>
                                <List component="div">                            
                                    { evento?.entrega &&
                                        <ListItem button className={classes.nested} component={Link} to={"/eventos/confirmacion-manual/entrega/" + evento?._id} selected={window.location.pathname.indexOf("/confirmacion-manual/entrega") != -1}>
                                            <ListItemIcon>
                                                <SubjectIcon htmlColor="white"/>
                                            </ListItemIcon>
                                            <ListItemText primary="Entrega" />
                                        </ListItem>
                                    }
                                    { evento?.recoleccion &&
                                        <ListItem button className={classes.nested} component={Link} to={"/eventos/confirmacion-manual/recoleccion/" + evento?._id} selected={window.location.pathname.indexOf("/confirmacion-manual/recoleccion") != -1}>
                                            <ListItemIcon>
                                                <SubjectIcon htmlColor="white"/>
                                            </ListItemIcon>
                                            <ListItemText primary="Recolección" />
                                        </ListItem>
                                    }
                                    { evento?.CAD &&
                                        <ListItem button className={classes.nested} component={Link} to={"/eventos/confirmacion-manual/CAD/" + evento?._id} selected={window.location.pathname.indexOf("/confirmacion-manual/CAD") != -1}>
                                            <ListItemIcon>
                                                <SubjectIcon htmlColor="white"/>
                                            </ListItemIcon>
                                            <ListItemText primary="Entrega CAD" />
                                        </ListItem>
                                    }
                                </List>
                            </Collapse>  
                        </>
                    }       
                    {!isWebView &&
                        <ListItem button component={Link} to={"/mapa/" + evento?._id} selected={window.location.pathname.indexOf("/mapa") != -1}>
                            <ListItemIcon>
                                <MapIcon htmlColor="white"/>
                            </ListItemIcon>
                            <ListItemText primary="Ubicación de supervisores" />
                        </ListItem>  
                    }                                                                                                                
                </>
            )}
            {auth.user.role === 3 && !evento &&(
                <>     
                    <ListItem button component={Link} to="/eventos" selected={window.location.pathname.indexOf("/eventos") != -1}>
                        <ListItemIcon>
                            <EventIcon htmlColor="white"/>
                        </ListItemIcon>
                        <ListItemText primary="Eventos" />
                    </ListItem>                               
                </>
            )}
        </List>
    );
}