import React, { useState, useEffect } from "react";
import Loader from "../components/shared/Loader";

import ShowAlert from './shared/Snackbar';

import { 
    Box, 
    Button,
    TextField,
    Typography,
    useTheme,
    useMediaQuery,
    Grid,
    Collapse,
    Hidden 
} from "@material-ui/core";

import { signin, authenticate, isAuthenticated } from "../core/sessionAPI";
import { Redirect } from "react-router-dom";

// Validaciones
import * as yup from "yup";
import { useFormik } from "formik";

import { restorePassMail } from '../core/sessionAPI';
import { showLoader, hideLoader } from './shared/Loader';
import "../App.css"

// Valores iniciales de los campos
const initialValues = 
{
    username: "",
    password: ""
}

// Validaciones de los campos
const validationSchema = yup.object().shape({
    username: yup
        .string()
        .trim()
        .required("Ingresa tu correo"),
    password: yup
        .string()
        .trim()
        .required("Ingresa tu contraseña"),
})


const Login = () =>
{
    /**
     * MediaQuery para que el formulario ocupe toda la pantalla en 'sm'
     */
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [restorePass, setRestorePass] = useState(false);
    const [mailToRestore, setMailToRestore] = useState("");

    const [snackbarProps, setSnackbarProps] = useState();

    /**
     * Llamar al servicio para hacer login
     */
    const onSubmit = async (values) =>
    {
        const response = await signin(values);
        
        if (response.error)
        {
            setSnackbarProps("e" + response.error);
        }
        else
        {
            authenticate(response.data)
        }
    }

    /**
     * Formik
     */
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });
    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;

    const usernameProps = getFieldProps("username");
    const passwordProps = getFieldProps("password");

    /**
     * Redirige usuario a dashboard si ya está autenticado
     */
    const redirectUser = () => {
        //Independiente del perfíl el usuario es redirigido al dashboard de inicio
        if (isAuthenticated()){
            return <Redirect to="/eventos" />
        }
    }

    /**
     * Se ejecuta cada vez que se entra a la página
     */
    useEffect(() => {
        // Redirige al usuario si ya está autenticado
        redirectUser();
    }, []);

    const handleRestaurarContrasena = async () => {
        showLoader();

        var res = await restorePassMail(mailToRestore);

        if (res.error){
            setSnackbarProps("e" + "Correo invalido.");
        }else{
            setSnackbarProps("s" + res.data.message);
            setRestorePass(!restorePass);
            setMailToRestore("");
        }             

        hideLoader();
    }

    const loginForm = () =>(
        <Box className="form-container">
            <Grid container> 
                <Hidden xsDown >          
                    <Grid item sm={2} md={4}> 
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={8} md={4}> 
                    <Collapse 
                        in={!restorePass}>
                        <Box 
                            className="form"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column">                                
                            <img className="imageLogo" src="/img/disproelLogo.png"/>
                            <form onSubmit={handleSubmit}>
                                <Box className="fullWidth">
                                    <TextField 
                                        name="username"
                                        label="Correo / documento"
                                        margin="normal"
                                        fullWidth
                                        inputProps={{ maxLength: 30 }}
                                        variant="outlined"
                                        size="small"
                                        helperText={touched.username ? errors.username : ""}
                                        error={touched.username && Boolean(errors.username)}
                                        {...usernameProps}
                                    />
                                    <TextField
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        fullWidth
                                        inputProps={{ maxLength: 30 }}
                                        variant="outlined"
                                        size="small"
                                        helperText={touched.password ? errors.password : ""}
                                        error={touched.password && Boolean(errors.password)}
                                        {...passwordProps}
                                    />
                                    <div style={{height: 20}}></div>
                                    <Button 
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isSubmitting}>
                                        Iniciar Sesión
                                    </Button>
                                    <div style={{height: 20}}></div>
                                    <Button 
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setRestorePass(!restorePass);
                                        }}>
                                        Recuperar contraseña
                                    </Button>                                    
                                </Box>
                            </form>
                        </Box>
                    </Collapse>
                    <Collapse 
                        in={restorePass}>
                        <Box 
                            className={"form " + (smallScreen ? "full-width" : "")}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column">
                            <h3>Recuperar contraseña de acceso</h3>
                            <Box className="fullWidth">
                                <TextField 
                                    name="email"
                                    label="Correo ó número de teléfono"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{ maxLength: 30 }}
                                    variant="outlined"
                                    size="small"
                                    value={mailToRestore}
                                    onChange={(e)=>{
                                        setMailToRestore(e.target.value);
                                    }}
                                />
                                <div style={{height: 20}}></div>
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={()=>{
                                        handleRestaurarContrasena();
                                    }}>
                                    Recuperar contraseña
                                </Button>
                                <div style={{height: 20}}></div>
                                <Button 
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setRestorePass(!restorePass);
                                    }}>
                                    Cancelar
                                </Button> 
                            </Box>
                        </Box>
                    </Collapse>
                </Grid>
                <Hidden xsDown >  
                    <Grid item sm={2} md={4}> 
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    )
    
    return (
        <>
            {loginForm()}
            {redirectUser()}
            
            {ShowAlert(snackbarProps, setSnackbarProps)}
            <Loader show={isSubmitting} />
        </>
    )
}

export default Login;