import { registroRetencionAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getRegistroRetenciones = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getRegistroRetenciones`,{}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const createRegistroRetencion = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createRegistroRetencion`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });
        console.log(res)
        return handleResponse(res);
    }
}

export const updateRegistroRetenciones = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateRegistroRetenciones`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}