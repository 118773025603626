import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';

export default function Paginacion (props)
{
    const { count, rowsPerPageDefault, rowsPerPageOptionsDefault, setCalculoPaginacion } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);

    // Maneja el evento onChangePage que se encarga de cambiar la página
    const handleChangePage = (event, newPage) =>
    {
        setPage(newPage);
    };

    // Maneja el evento onChangeRowsPerPage que se encarga de mostrar las filas hasta el máximo permitido
    const handleChangeRowsPerPage = (event) =>
    {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Detecta los cambios de página o de 'Filas por página' y setea el cálculo necesario para filtrar la tabla
    useEffect(() =>
    {
        setCalculoPaginacion({inicio: page * rowsPerPage, fin: page * rowsPerPage + rowsPerPage})
    }, [page, rowsPerPage])

    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptionsDefault}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    )
}

// Propiedades requeridas para el correcto funcionamiento del paginador
Paginacion.propTypes = {
    count: PropTypes.number.isRequired, // Entero
    rowsPerPageDefault: PropTypes.number.isRequired, // Entero
    rowsPerPageOptionsDefault: PropTypes.array.isRequired, // Array
    setCalculoPaginacion: PropTypes.func.isRequired, // Función
};