import React, {memo, useEffect, useState} from "react";
import { Marker, InfoWindow, OverlayView } from "@react-google-maps/api";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { getUserPath } from "../../core/apiLocation";
import { getRandomColor } from "../../helpers/getRandomColor";

const MarkerBase = memo(({marker, draggable=false, setPolyline, currentModalMarker, setCurrentModalMarker}) =>
{
    const [showPath, setShowPath] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [loadingPath, setLoadingPath] = useState(false);

    const handleToggleOpen = () => {
        setCurrentModalMarker(marker.id)
        setIsOpen(true);            
    }

    const handleToggleClose = () => {
        setIsOpen(false);
        setCurrentModalMarker('');
    }

    const handleChangePath = (event, newValue) => {
        setShowPath(newValue);
    };
    
    useEffect(() => {
        const loadPath = async () => {
            setLoadingPath(true)
            const res = await getUserPath(marker.id, showPath);
            if(res?.data){
                const {path} = res.data
                setPolyline(prev => {
                    const resetUserPath =  [...prev].filter(p => p.user !== marker.id)
                    return [...resetUserPath, {user: marker.id, path, color: getRandomColor()}]
                });
            }
            setLoadingPath(false)
        }
        if(showPath > 0){
            loadPath()
        }else {
            setPolyline(prev => prev.filter(p => p.user !== marker.id));
        }
    }, [marker.id, setPolyline, showPath])

    useEffect(() => {
        if(currentModalMarker !== marker.id){
            setIsOpen(false);
        }
    }, [currentModalMarker, marker.id])


    useEffect(() => {
        return () => {
            setShowPath(0)
            setPolyline(prev => prev.filter(p => p.user !== marker.id));
        }
    }, [marker.id, setPolyline])

    return (
        <>
            <Marker 
                noRedraw={true}
                key={marker.id}
                animation={2}   
                opacity={1}      
                draggable={draggable}                  
                position={{ lat: marker.lat, lng: marker.lon }} 
                icon={{
                    url: require('../../assets/imgs/locationIcon.png'),
                    scaledSize: new window.google.maps.Size(60, 60),
                    anchor: new window.google.maps.Point(30, 50)
                }}        
                onClick={(e) => handleToggleOpen(e)}
                title="Menú marker">
                    {marker.obj && isOpen &&  
                        <InfoWindow onCloseClick={handleToggleClose}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 8
                                }}
                            >
                                <p><strong>{marker.obj.conentPopup1}</strong></p>
                                <p>{marker.obj.conentPopup2}</p>
                                <p><strong>{marker.obj.conentPopup3}</strong></p>
                                <p>{marker.obj.conentPopup4}</p>
                                <p><strong>Mostrar ruta</strong></p>
                                <ToggleButtonGroup
                                    size="small"
                                    color="primary"
                                    value={showPath}
                                    exclusive
                                    onChange={handleChangePath}
                                    aria-label="Ruta"
                                >
                                    <ToggleButton value={2} disabled={loadingPath}>
                                        {loadingPath ? 'Cargando...' : 'Últimas 2h'}
                                    </ToggleButton>
                                    <ToggleButton value={0} disabled={loadingPath}>
                                        {loadingPath ? 'Cargando...' : 'No mostrar'}
                                    </ToggleButton>
                                    <ToggleButton value={24} disabled={loadingPath}>
                                        {loadingPath ? 'Cargando...' : 'Últimas 24h'}
                                    </ToggleButton>
                                    <ToggleButton value={24 * 4} disabled={loadingPath}>
                                        {loadingPath ? 'Cargando...' : 'Últimos 4 días'}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </InfoWindow>
                    }
            </Marker>
            {marker.obj && 
                <OverlayView
                    position={{ lat: marker.lat, lng: marker.lon }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    <div style={{ position: `absolute`,
                                    marginTop: `10px`,
                                    marginLeft: `-50px`,
                                    background: `#3f51b5d7`, 
                                    textAlign: `left`,
                                    border: `0px`, 
                                    borderRadius: `5px`,
                                    padding: 5,
                                    color:'#fff' }}>
                        <div style={{width:`100px`, fontSize: '10px'}}>{marker.obj.texto1}</div>
                        <div style={{width:`100px`, fontSize: '10px', color:"#"}}>{marker.obj.conentPopup2}</div>
                        <div style={{width:`100px`, fontSize: '10px'}}>{marker.obj.texto2}</div>
                    </div>
                </OverlayView>
            }
        </>
    )
})

export default MarkerBase;