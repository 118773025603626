import React, {useState, useEffect, useRef} from 'react';
import {
        Grid, 
        Box, 
        Paper,  
        FormControl,
        Select,
        Button,
        InputLabel,
        Hidden,
        MenuItem} from '@material-ui/core';

import Loader from '../shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import "../../App.css"
import NavContainer from '../shared/NavContainer';
import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken } from "../../core/sessionAPI";
import { getProductosEventosClear, getDepartamentosEstado, getEstadoEvento, getMunicipiosEstado } from '../../core/apiEventos';

import { render } from 'react-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import DialogPuestosMarcacion from '../puestos/DialogPuestosMarcacion';

export default function MainReporteDepartamentos(props) {
    const wrapper = useWrapperStyles();
    const View = () => {
        useEffect(() => {
            async function init() {
                // Realizar el servicio que refresca el token
                await refreshToken();
                // Reiniciar timer de inactividad
                setTokenInterval();
            }
            init();
        }, []);
        
        return (
            <>
                <LandingPage tipo={props.match.params.tipo} estado={props.match.params.estado} evento={props.match.params.evento}/>
            </>)
    }

    var namePage = "";
    if(props.match.params){
        if(props.match.params.tipo == "global"){
            namePage += "Global ";
        }else if(props.match.params.tipo == "zonificado"){
            namePage += "Zonificado ";            
        }else if(props.match.params.tipo == "nozonificado"){
            namePage += "No Zonificado ";    
        }else if(props.match.params.tipo == "exterior"){
            namePage += "Exterior ";
        }

        if(props.match.params.estado == "entrega"){
            namePage += "Entrega ";
        }else if(props.match.params.estado == "recoleccion"){
            namePage += "Recolección ";
        }else if(props.match.params.estado == "CAD"){
            namePage += "Entrega CAD ";
        }
    }

    return (
        <>
            <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
                <div className={wrapper.content}>
                    <NavContainer nameEvento={namePage}/>
                    <div className={wrapper.offset}></div>

                    {/* IMPORTANTE: Ver definición del componente View */}
                    <View/>
                </div>
            </div>
        </>
    )
}

export function LandingPage({estado, evento, tipo}){
    const referenciaBarras = useRef(null);
    
    const [isWebView, setIsWebView] = useState(false);

    const [snackbarProps, setSnackbarProps] = useState();
    const [showLoader, setShowLoader] = useState(false);

    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState();

    const [categoriaBars, setCategoriaBars] = useState([]);
    const [categoriaIDs, setCategoriaIDs] = useState([]);
    const [dataBars, setDataBars] = useState([]);
    
    const [tipoState, setTipoState] = useState("");
    const [titleBars, setTitleBars] = useState("");

    const [titlePie, setTitlePie] = useState("");
    const [labelPie, setLabelPie] = useState("");

    const [totalValor, setTotalValor] = useState(0);
    const [totalPendiente, setTotalPendiente] = useState(0);

    const [heightChart, setHeightChart] = useState(0);    
    const [heightChartBars, setHeightChartBars] = useState(0); 

    const [indicadorMesasEntrega , setIndicadorMesasEntrega] = useState(0);
    const [indicadorMesasRecoleccion , setIndicadorMesasRecoleccion] = useState(0);
    const [indicadorMesasCAD , setIndicadorMesasCAD] = useState(0);

    const [indicadorPuestosEntrega , setIndicadorPuestosEntrega] = useState(0);
    const [indicadorPuestosRecoleccion , setIndicadorPuestosRecoleccion] = useState(0);
    const [indicadorPuestosCAD , setIndicadorPuestosCAD] = useState(0);
    
    const [valorEntregaPuestos , setValorEnetregaPuestos] = useState(0);
    const [valorRecoleccionPuestos , setValorRecoleccionPuestos] = useState(0);
    const [valorCADPuestos , setValorCADPuestos] = useState(0);

    const [valorEntregaMesas , setValorEnetregaMesas] = useState(0);
    const [valorRecoleccionMesas , setValorRecoleccionMesas] = useState(0);
    const [valorCADMesas , setValorCADMesas] = useState(0);

    const [totalPuestos , setTotalPuestos] = useState(0);
    const [totalMesas , setTotalmesas] = useState(0);

    const [deptoSeleccionadoName, setDeptoSeleccionadoName] = useState("");
    const [deptoSeleccionado, setDeptoSeleccionado] = useState(null);
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState(null);

    const [openPuestos, setOpenPuestos] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [selectMesas, setSelectMesas] = useState("PUESTOS");

    const [departamentoTitleGraphs, setDepartamentoTitleGraphs] = useState(null);
    const [titleCity, setTitleCity] = useState(null);

    const [titleDepartamento, setTitleDepartamento] = useState(null); 

    const loadDepartamentos = async () => {
        setTitleGraphs(tipo, estado, producto);        

        var tipoString = "";

        if(tipo == "global"){
            tipoString = "ALL";
        }else if(tipo == "zonificado"){
            tipoString = "ZO";
        }else if(tipo == "nozonificado"){
            tipoString = "NZ";
        }else if(tipo == "exterior"){
            tipoString = "EXT";
        }

        var res = [];        
        if(deptoSeleccionado == null){
            //Consulta todos los departamentos
            res = await getDepartamentosEstado(tipoString, producto._id, estado, evento);
        }else{
            //Consulta las ciudades del departamento seleccioando
            //console.log(tipoString, deptoSeleccionado,  producto._id, estado, evento);
            res = await getMunicipiosEstado(tipoString, deptoSeleccionado,  producto._id, estado, evento);
        }        

        if (res.error) {
            setSnackbarProps("e" + res.error);
            setCategoriaBars([]);
            setDataBars([]);
        } else {
            var categorias = [];
            var idsCategorias = [];
            var data = [];

            //Para el calculo de totales se suman todos los valores y todas las metas
            var totalValores = 0;
            var totalMetas = 0;            
            for(var i = 0; i < res.data.length; i++){
                if(deptoSeleccionado == null){
                    categorias.push(res.data[i].departamento.name);
                    idsCategorias.push(res.data[i].departamento._id);
                }else{
                    categorias.push(res.data[i].ciudad.name);
                    idsCategorias.push(res.data[i].ciudad._id);
                }
                
                var valor = 0;
                var meta = 0;
                if(selectMesas == "PUESTOS"){
                    valor = parseFloat(res.data[i].valor);
                    totalValores += valor;                

                    meta = parseFloat(res.data[i].meta);
                    totalMetas += meta;
                }else{
                    valor = parseFloat(res.data[i].valorMesas);
                    totalValores += valor;                

                    meta = parseFloat(res.data[i].metaMesas);
                    totalMetas += meta;
                }

                var indicador = valor/meta * 100;                
                indicador = indicador.toFixed(2);

                if(indicador > 100){
                    console.log(indicador);
                    indicador = 100;
                }

                //color by value
                var color = "#ff4652";
                if(parseFloat(indicador) < 50){
                    color = "#ff4652";
                }else if(parseFloat(indicador) < 100){
                    color = "#ddd780";
                }else if(parseFloat(indicador) == 100){
                    color = "#39af0a";
                }

                data.push({
                            y: parseFloat(indicador),
                            color: color
                        });
            }

            setCategoriaIDs(idsCategorias);
            setCategoriaBars(categorias);
            setDataBars(data);

            //Ahora se calcula el total en %
            setTotalValor(totalValores);
            setTotalPendiente(totalMetas - totalValores);
        }

        //Ahora hace la consulta del global
        const resEstadoGeneral = await getEstadoEvento(producto._id, evento);
        if (resEstadoGeneral.error) {
            setSnackbarProps("e" + resEstadoGeneral.error);

            setIndicadorMesasEntrega(0);
            setIndicadorMesasRecoleccion(0);
            setIndicadorPuestosEntrega(0);
            setIndicadorPuestosRecoleccion(0);
        
            setValorEnetregaPuestos(0);
            setValorRecoleccionPuestos(0);
            setValorEnetregaMesas(0);
            setValorRecoleccionMesas(0);
            setValorCADPuestos(0);
            setValorCADMesas(0);
        
            setTotalPuestos(0);
            setTotalmesas(0);

        } else {
            for(var i = 0; i < resEstadoGeneral.data.length; i++){
                var valor = parseInt(resEstadoGeneral.data[i].valor);
                var meta = parseInt(resEstadoGeneral.data[i].meta);

                var valorMesas = parseInt(resEstadoGeneral.data[i].valorMesas);
                var metaMesas = parseInt(resEstadoGeneral.data[i].metaMesas);

                var indicador = valor / meta * 100;
                indicador = indicador.toFixed(2);

                if(indicador > 100){
                    console.log(indicador);
                    indicador = 100;
                }

                var indicadorMesas = valorMesas / metaMesas * 100;
                indicadorMesas = indicadorMesas.toFixed(2);

                if(indicadorMesas > 100){
                    console.log(indicadorMesas);
                    indicadorMesas = 100;
                }

                //console.log(resEstadoGeneral);
                if(resEstadoGeneral.data[i].estado == "entrega"){
                    setTotalPuestos(resEstadoGeneral.data[i].meta);
                    setTotalmesas(resEstadoGeneral.data[i].metaMesas);
                    setValorEnetregaPuestos(resEstadoGeneral.data[i].valor>resEstadoGeneral.data[i].meta?resEstadoGeneral.data[i].meta:resEstadoGeneral.data[i].valor);
                    setValorEnetregaMesas(resEstadoGeneral.data[i].valorMesas>resEstadoGeneral.data[i].metaMesas?resEstadoGeneral.data[i].metaMesas:resEstadoGeneral.data[i].valorMesas);

                    setIndicadorMesasEntrega(indicadorMesas);                    
                    setIndicadorPuestosEntrega(indicador);                    
                }
                
                if(resEstadoGeneral.data[i].estado == "recoleccion"){
                    setTotalPuestos(resEstadoGeneral.data[i].meta);
                    setTotalmesas(resEstadoGeneral.data[i].metaMesas);
                    setValorRecoleccionPuestos(resEstadoGeneral.data[i].valor>resEstadoGeneral.data[i].meta?resEstadoGeneral.data[i].meta:resEstadoGeneral.data[i].valor);
                    setValorRecoleccionMesas(resEstadoGeneral.data[i].valorMesas>resEstadoGeneral.data[i].metaMesas?resEstadoGeneral.data[i].metaMesas:resEstadoGeneral.data[i].valorMesas);   
                    
                    setIndicadorMesasRecoleccion(indicadorMesas);
                    setIndicadorPuestosRecoleccion(indicador);
                }

                if(resEstadoGeneral.data[i].estado == "CAD"){
                    setTotalPuestos(resEstadoGeneral.data[i].meta);
                    setTotalmesas(resEstadoGeneral.data[i].metaMesas);
                    setValorCADPuestos(resEstadoGeneral.data[i].valor>resEstadoGeneral.data[i].meta?resEstadoGeneral.data[i].meta:resEstadoGeneral.data[i].valor);
                    setValorCADMesas(resEstadoGeneral.data[i].valorMesas>resEstadoGeneral.data[i].metaMesas?resEstadoGeneral.data[i].metaMesas:resEstadoGeneral.data[i].valorMesas);   
                    
                    setIndicadorMesasCAD(indicadorMesas);
                    setIndicadorPuestosCAD(indicador);
                }
            }
        }

        if(window.myInterval != undefined && window.myInterval != 'undefined'){
            window.clearInterval(window.myInterval);
        }

        window.myInterval = setInterval(async function() {
                setDisableButton(true);                       
                await loadDepartamentos();   
                setDisableButton(false);
        }, 5000);
        handleResize()
    }
    
    const loadProductos = async () => {
        setShowLoader(true);

        const res = await getProductosEventosClear(evento);
        if (res.error) {
            setSnackbarProps("e" + res.error);
            setProductos([]);
        } else {
            setProductos(res.data);    
            setProducto(res.data[0]);     
        }

        setShowLoader(false);
    }    

    useEffect(() => {
        async function effect() {    
            if(producto){
                //Primer llamado
                setDisableButton(true);
                await loadDepartamentos();      
                setDisableButton(false);
            }            
        } 

        effect();
        //eslint-disable-next-line
    }, [producto, deptoSeleccionado, selectMesas]);    

    useEffect(() => {
        async function effect() {                                     
            await loadProductos();           
        }
            
        effect();
        window.addEventListener('resize', handleResize)
        return () => {
            clearInterval(window.myInterval)
        }
        //eslint-disable-next-line
    }, [tipoState, evento]);   

    useEffect(() => {
        //Validar si el origen es un webview, para remover el accceso a la interfaz de eventos y marcación
        var variables = window.location.href.split("/");
        if(variables.length == 8){
            //Contiene variable de webviwe
            if(variables[7] == "webView"){
                //Esconder acceso a controles
                setIsWebView(true);
            }
        };
        //eslint-disable-next-line
    }, []);   

    function handleResize() {
        if(window.innerWidth < 960){
            setHeightChart(600);
            setHeightChartBars(900);
        }else{
            setHeightChart(window.innerHeight);
            setHeightChartBars(window.innerHeight);
        }        
    }

    function setTitleGraphs(tipoVar, estadoVar, producto, departamentoString){
        var tipoConsulta = "";
        var estadoString = "";
        if(estadoVar == "entrega"){
            estadoString = "ENTREGA ";            
        }else if(estadoVar == "recoleccion"){
            estadoString = "RECOLECCIÓN ";
        }else if(estadoVar == "CAD"){
            estadoString = "ENTREGA CAD ";
        }

        setLabelPie(estadoString);

        var stringDepartamento = "";
        if(deptoSeleccionadoName != ""){
            stringDepartamento = deptoSeleccionadoName + " - ";
        }

        if(tipoVar == "global"){
            setTitleBars(stringDepartamento + selectMesas + " GLOBAL "+estadoString + " " + producto.name.toUpperCase());
            setTitlePie(stringDepartamento + selectMesas + " GLOBAL "+estadoString + " " + producto.name.toUpperCase());
        }else if(tipoVar == "zonificado"){
            setTitleBars(stringDepartamento + selectMesas + " ZONIFICADO "+estadoString + " " + producto.name.toUpperCase());
            setTitlePie(stringDepartamento + selectMesas + " ZONIFICADO "+estadoString + " " + producto.name.toUpperCase());
        }else if(tipoVar == "nozonificado"){
            setTitleBars(stringDepartamento + selectMesas + " NO ZONIFICADO "+estadoString + " " + producto.name.toUpperCase());
            setTitlePie(stringDepartamento + selectMesas + " NO ZONIFICADO "+estadoString + " " + producto.name.toUpperCase());
        }else if(tipoVar == "exterior"){
            setTitleBars(stringDepartamento + selectMesas + " EXTERIOR "+estadoString + " " + producto.name.toUpperCase());
            setTitlePie(stringDepartamento + selectMesas + " EXTERIOR "+estadoString + " " + producto.name.toUpperCase());
        }
    }
    
    return (
        <>
            <Box p={1} className={productos.length > 1 ? "":"hiddenClass"}>
                <Paper elevation={1}> 
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box p={1}>
                                <FormControl fullWidth variant="standard">
                                    <Select
                                        fullWidth
                                        value={producto}
                                        key={producto}
                                        defaultValue={producto}
                                        onChange={async (e)=>{
                                            //Se cancela el intervalo de consulta
                                            if(window.myInterval != undefined && window.myInterval != 'undefined'){
                                                window.clearInterval(window.myInterval);
                                            }

                                            setProducto(e.target.value);
                                        }}
                                        label="Age">
                                        {productos.map((producto, index)=>{
                                            if(index == 0){
                                                return(<MenuItem value={producto} key={index} selected>{producto.name}</MenuItem>)                                                
                                            }else{
                                                return(<MenuItem value={producto} key={index}>{producto.name}</MenuItem>)
                                            }                                            
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>                                          
                    </Grid>
                </Paper>
            </Box>
            <Box p={1}>
                <Paper elevation={1}>
                    <Grid container>
                        <Hidden smDown>
                            <Grid item xs={12} sm={12} md={9}>
                                <Box p={1}>
                                    <div className="ContainerGraph">
                                        {deptoSeleccionado && 
                                        <Button 
                                            disabled={disableButton}
                                            className="floatButton" 
                                            variant="outlined" 
                                            startIcon={<ArrowBackIosIcon />}
                                            onClick={()=>{

                                                if(window.myInterval != undefined && window.myInterval != 'undefined'){
                                                    window.clearInterval(window.myInterval);
                                                }

                                                setDeptoSeleccionado(null)
                                                setDeptoSeleccionadoName("")
                                            }}>
                                                Átras
                                        </Button>}

                                        <Select
                                            fullWidth
                                            className="floatSelect"
                                            key={selectMesas}
                                            variant="outlined"
                                            defaultValue={selectMesas}
                                            onChange={async (e)=>{
                                                setSelectMesas(e.target.value);
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}>
                                            <MenuItem value="PUESTOS" selected>PUESTOS </MenuItem>
                                            <MenuItem value="MESAS" selected>MESAS </MenuItem>                                            
                                        </Select>                                     

                                        <HighchartsReact 
                                            highcharts={Highcharts} 
                                            options={{
                                                chart: {
                                                    type: 'bar',
                                                    height: heightChart-100
                                                },
                                                title: {
                                                    text: titleBars,
                                                    align: "left",
                                                    x:10,
                                                    style: {
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        textOverflow: 'ellipsis'
                                                    }
                                                },
                                                subtitle: {
                                                    text: 'DISPROEL',
                                                    align: "left",
                                                    x:10
                                                },
                                                xAxis: {
                                                    categories: categoriaBars,
                                                    title: {
                                                        text: null
                                                    },
                                                    labels: {
                                                        step: 1
                                                    },
                                                    style: {
                                                        fontSize: '12px'
                                                    }
                                                },
                                                yAxis: {
                                                    min: 0,
                                                    title: {
                                                        text: '',
                                                        align: 'high',
                                                        skew3d: true
                                                    },
                                                    labels: {
                                                        overflow: 'justify'
                                                    }
                                                },
                                                tooltip: {
                                                    valueSuffix: '%'
                                                },
                                                plotOptions: {
                                                    bar: {
                                                        dataLabels: {
                                                            enabled: true
                                                        },
                                                        events:{
                                                            click: (e) => {
                                                                if(window.myInterval != undefined && window.myInterval != 'undefined'){
                                                                    window.clearInterval(window.myInterval);
                                                                }
                                                                
                                                                if(!disableButton){
                                                                    if(deptoSeleccionado == null){
                                                                        //console.log("SELECT DEPARTAMENTO: " + categoriaIDs[e.point.index]);
                                                                        setDeptoSeleccionadoName(categoriaBars[e.point.index]);
                                                                        setDeptoSeleccionado(categoriaIDs[e.point.index]);
                                                                    }else if(ciudadSeleccionada == null){
                                                                        //console.log("SELECT CIUDAD: " + categoriaIDs[e.point.index]);
                                                                        if(!isWebView){
                                                                            setCiudadSeleccionada(categoriaIDs[e.point.index]);                                                                        
                                                                            setOpenPuestos(true);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                },
                                                credits: {
                                                    enabled: false
                                                },
                                                series: [{
                                                    name: labelPie,
                                                    data: dataBars
                                                }]
                                            }} />
                                    </div>
                                </Box>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={12} md={3}>
                            <Grid container>
                                {/** Indicadores númericos */}

                                <Grid item xs={12} sm={12} md={12}>
                                    {estado == "entrega" && <Box p={1} className={indicadorMesasEntrega < 50 ? "blockRed":indicadorMesasEntrega < 99 ? "blockYellow":"blockGreen"}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                Mesas Entrega
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h1 className="leftText">
                                                    {indicadorMesasEntrega} %
                                                </h1>     
                                                <div className="smallTextIndicador">
                                                    {valorEntregaMesas} de {totalMesas}
                                                </div>                               
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                    {estado == "recoleccion" && <Box p={1} className={indicadorMesasRecoleccion < 50 ? "blockRed":indicadorMesasRecoleccion < 99 ? "blockYellow":"blockGreen"}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                Mesas Recolección
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h1 className="leftText">
                                                    {indicadorMesasRecoleccion} %
                                                </h1>    
                                                <div className="smallTextIndicador">
                                                    {valorRecoleccionMesas} de {totalMesas}
                                                </div>                
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                    {estado == "CAD" && <Box p={1} className={indicadorMesasCAD < 50 ? "blockRed":indicadorMesasCAD < 99 ? "blockYellow":"blockGreen"}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                Mesas Entrega CAD
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h1 className="leftText">
                                                    {indicadorMesasCAD} %
                                                </h1>    
                                                <div className="smallTextIndicador">
                                                    {valorCADMesas} de {totalMesas}
                                                </div>                
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                    {estado == "entrega" && <Box p={1} className={indicadorPuestosEntrega < 50 ? "blockRed":indicadorPuestosEntrega < 99 ? "blockYellow":"blockGreen"}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                Puestos Entrega
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h1 className="leftText">
                                                    {indicadorPuestosEntrega} %
                                                </h1>
                                                <div className="smallTextIndicador">
                                                    {valorEntregaPuestos} de {totalPuestos}
                                                </div>   
                                            </Grid>
                                        </Grid>
                                    </Box>}
                                    {estado == "recoleccion" && <Box p={1} className={indicadorPuestosRecoleccion< 50 ? "blockRed":indicadorPuestosRecoleccion < 99 ? "blockYellow":"blockGreen"}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                Puestos Recolección
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h1 className="leftText">
                                                    {indicadorPuestosRecoleccion} %
                                                </h1>
                                                <div className="smallTextIndicador">
                                                    {valorRecoleccionPuestos} de {totalPuestos}
                                                </div>   
                                            </Grid>
                                        </Grid>
                                    </Box>}       
                                    {estado == "CAD" && <Box p={1} className={indicadorPuestosCAD< 50 ? "blockRed":indicadorPuestosCAD < 99 ? "blockYellow":"blockGreen"}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                Puestos Entrega CAD
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h1 className="leftText">
                                                    {indicadorPuestosCAD} %
                                                </h1>
                                                <div className="smallTextIndicador">
                                                    {valorCADPuestos} de {totalPuestos}
                                                </div>   
                                            </Grid>
                                        </Grid>
                                    </Box>}                          
                                </Grid>

                                {/** Control de tipo de visualización mesa/puesto */}
                                <Hidden mdUp>
                                    <Grid item xs={12} sm={12}>       
                                        <Grid container fullWidth className="paddingMobileDevice">                                                
                                            {deptoSeleccionado && 
                                            <Grid item xs={4} sm={4}>  
                                                <Button 
                                                    fullWidth
                                                    disabled={disableButton}
                                                    className="heightControl"
                                                    variant="outlined" 
                                                    startIcon={<ArrowBackIosIcon />}
                                                    onClick={()=>{

                                                        if(window.myInterval != undefined && window.myInterval != 'undefined'){
                                                            window.clearInterval(window.myInterval);
                                                        }

                                                        setDeptoSeleccionado(null)
                                                        setDeptoSeleccionadoName("")
                                                    }}>
                                                        Átras
                                                </Button>
                                            </Grid>
                                            }      
                                            <Grid item xs={deptoSeleccionado ? 8:12} sm={deptoSeleccionado ? 8:12}>                                                           
                                                <Select
                                                    className="heightControl"
                                                    fullWidth                                                
                                                    key={selectMesas}
                                                    variant="outlined"
                                                    defaultValue={selectMesas}
                                                    onChange={async (e)=>{
                                                        setSelectMesas(e.target.value);
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                    <MenuItem value="PUESTOS" selected>PUESTOS </MenuItem>
                                                    <MenuItem value="MESAS" selected>MESAS </MenuItem>                                            
                                                </Select>
                                            </Grid> 
                                        </Grid> 
                                    </Grid>
                                </Hidden>

                                {/** Grafico de torta móvil y WEB */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box>
                                        <div>
                                            <HighchartsReact
                                                ref={referenciaBarras} 
                                                highcharts={Highcharts} 
                                                options={{
                                                    chart: {
                                                        plotBackgroundColor: null,
                                                        plotBorderWidth: null,
                                                        plotShadow: false,
                                                        type: 'pie',
                                                        height: heightChart-250
                                                    },
                                                    title: {
                                                        text: titlePie,
                                                        style: {
                                                            width: '100%',
                                                            fontSize: '14px',
                                                            textOverflow: 'ellipsis'
                                                        }
                                                    },
                                                    tooltip: {
                                                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                                    },
                                                    accessibility: {
                                                        point: {
                                                            valueSuffix: '%'
                                                        }
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            allowPointSelect: true,
                                                            cursor: 'pointer',
                                                            dataLabels: {
                                                                enabled: true,
                                                                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                                                                distance: -30
                                                            },
                                                            colors:['#39af0a', '#0d233a']
                                                        }
                                                    },
                                                    credits: {
                                                        enabled: false
                                                    },
                                                    series: [{
                                                        name: '',
                                                        colorByPoint: true,
                                                        data: [{
                                                            name: labelPie,
                                                            y: totalValor,
                                                            sliced: true,
                                                            selected: true
                                                        },
                                                        {
                                                            name: "PENDIENTE",
                                                            y: totalPendiente,
                                                            sliced: true,
                                                            selected: true
                                                        }]
                                                    }]
                                                }} />
                                        </div>
                                    </Box>
                                </Grid>

                                {/**  Grafico de barras móvil*/}
                                <Hidden mdUp>
                                    <Grid item xs={12} sm={12}>                                            
                                        <Box className="paddingMobileDevice">
                                            <HighchartsReact 
                                                highcharts={Highcharts} 
                                                options={{
                                                    chart: {
                                                        type: 'bar',
                                                        height: heightChartBars
                                                    },
                                                    title: {
                                                        text: titleBars,
                                                        style: {
                                                            width: '100%',
                                                            fontSize: '14px',
                                                            textOverflow: 'ellipsis'
                                                        }
                                                    },
                                                    subtitle: {
                                                        text: 'DISPROEL'
                                                    },
                                                    xAxis: {
                                                        categories: categoriaBars,
                                                        title: {
                                                            text: null
                                                        },
                                                        skew3d: true,
                                                        style: {
                                                            fontSize: '16px'
                                                        }
                                                    },
                                                    yAxis: {
                                                        min: 0,
                                                        title: {
                                                            text: '',
                                                            align: 'high',
                                                            skew3d: true
                                                        },
                                                        labels: {
                                                            overflow: 'justify'
                                                        }
                                                    },
                                                    tooltip: {
                                                        valueSuffix: '%'
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            dataLabels: {
                                                                enabled: true
                                                            },
                                                            events:{
                                                                click: (e) => {
                                                                    console.log("CLICK: " + disableButton);
                                                                    if(window.myInterval != undefined && window.myInterval != 'undefined'){
                                                                        window.clearInterval(window.myInterval);
                                                                    }
                                                                    
                                                                    if(!disableButton){
                                                                        if(deptoSeleccionado == null){
                                                                            //console.log("SELECT DEPARTAMENTO: " + categoriaIDs[e.point.index]);
                                                                            setDeptoSeleccionadoName(categoriaBars[e.point.index]);
                                                                            setDeptoSeleccionado(categoriaIDs[e.point.index]);
                                                                        }else if(ciudadSeleccionada == null){
                                                                            //console.log("SELECT CIUDAD: " + categoriaIDs[e.point.index]);
                                                                            setCiudadSeleccionada(categoriaIDs[e.point.index]);
                                                                            setOpenPuestos(true);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    credits: {
                                                        enabled: false
                                                    },
                                                    series: [{
                                                        name: labelPie,
                                                        data: dataBars
                                                    }]
                                                }} 
                                            />
                                        </Box>                         
                                    </Grid>
                                </Hidden>                                                            
                            </Grid> 
                        </Grid>                        
                    </Grid>
                </Paper>
            </Box>

            <DialogPuestosMarcacion 
                open={openPuestos && tipo != "global"}
                close={()=>{
                    setCiudadSeleccionada(null);
                    setOpenPuestos(false);
                    loadDepartamentos();
                }}
                departamento={deptoSeleccionado}
                municipio={ciudadSeleccionada}
                producto={producto?._id}
                estado={estado}
                evento={evento}
                titleCity={(tipo=="global" ? " - GLOBAL":tipo=="zonificado" ? " - ZONIFICADO":tipo==" - NO ZONIFICADO" ? "NZ":"EXT") + " - " + titleDepartamento + " / " + titleCity + (estado == "entrega" ? " - ENTREGA" : estado == 'CAD' ? 'CAD' : " - RECOLECCIÓN")}
                tipo={tipo=="global" ? "ALL":tipo=="zonificado" ? "ZO":tipo=="nozonificado" ? "NZ":"EXT"}
            />

            <Loader show={showLoader} />
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}