import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../../helpers/Validator";

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box
} from '@material-ui/core';

// Validaciones
import { 
    letrasEspacios,
    letrasNumerosCaracteres,
    corregirEspaciado
} from "../../../helpers/Validator";
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../shared/Loader';
import ShowAlert from '../../shared/Snackbar';
import "../../../App.css";

// Servicios
import { createAdmin, updateAdmin } from '../../../core/superAdminServices/userAPI';
import { getSedes } from '../../../core/apiSedes';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Formulario ({open, close, mode, data, empresas, refreshTable})
{
    const classes = useStyles();

    /* State para saber si la pantalla esta en tamaño small */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");

    //1 - Super admin
    //2 - Administrador
    //3 - Funcionario
    //4 - Cliente
    const [roles, setRoles] = useState([
                                            {id: 1, name: "Super administrador"},
                                            {id: 2, name: "Administrador empresa"},
                                            {id: 3, name: "Funcionario"},
                                            {id: 4, name: "Cliente"}
                                        ]);

    const [sedes, setSedes] = useState([]);

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>
    {
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) =>
    {
        showLoader();
        let res = {};

        // Corregir espaciado de algunos campos
        values.name = corregirEspaciado(values.name);
        values.lastname = corregirEspaciado(values.lastname);        

        if (mode === "crear"){
            res = await createAdmin(values);
        }else if (mode === "editar"){
            values.id = data._id;
            res = await updateAdmin(values);
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            resetForm();
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */
    const formik = useFormik({
        initialValues:{
            name: "",
            lastname: "",
            email: "",
            password: "",
            confirmPassword: "",
            empresa: "",
            role: "",
            sede:"",
            telefono: ""
        },
        validationSchema:yup.object().shape({
            name: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            lastname: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            email: yup.string().email("Ingresa un email válido").required("Debes llenar este campo"),
            password: yup.string().min(8, "La contraseña debe contener al menos 8 caracteres").matches(regex.letrasNumerosCaracteres, "La contraseña se puede conformar por números, letras y caracteres como - _ * + . # $ % & @ ¡ ! ¿ ?").required("Debes llenar este campo"),
            confirmPassword: yup.string().required("Debes llenar este campo").oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
            empresa: yup.string().required("Selecciona una empresa"),
            role: yup.string().required("Selecciona un rol para el usuario"),
            sede: yup.string().required("Selecciona una sede para el usuario"),
            telefono: yup.number().typeError('Campo solo númerico').test('len', 'El teléfono debe ser de minimo 10 digitos', val => !val || (val && val.toString().length === 10))
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const lastnameProps = getFieldProps("lastname");
    const emailProps = getFieldProps("email");
    const passwordProps = getFieldProps("password");
    const confirmPasswordProps = getFieldProps("confirmPassword");
    const empresaProps = getFieldProps("empresa");
    const roleProps = getFieldProps("role");
    const sedesProps = getFieldProps("sede");
    const telefonoProps = getFieldProps("telefono");

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    useEffect(() =>{
        async function init ()
        {
            if (mode === "crear")
            {
                formik.handleReset();
                setFormTitle("Registrar nuevo administrador");
            }
            else if (mode === "editar")
            {
                setFormTitle("Actualizar datos del administrador");

                formik.setFieldValue("name", data.name);
                formik.setFieldValue("lastname", data.lastname);
                formik.setFieldValue("email", data.email);
                formik.setFieldValue("empresa", data.empresa._id);
                formik.setFieldValue("telefono", data.telefono);

                // Se pone cualquier valor para que formik los tome como válidos
                formik.setFieldValue("password", "xxxxxxxxxxxxxxx");
                formik.setFieldValue("confirmPassword", "xxxxxxxxxxxxxxx");
            }
        }
        init();
    }, [open]);

    const handleEmpresaChange = async (id) => {
        if(id){
            const res = await getSedes(id);
            console.log(res);

            if (res.error){
                setSnackbarProps("e" + res.error)
                setSedes([]);
            }else{
                setSedes(res.data);
            }
        }else{
            setSedes([]);
        }
    }

    /**
     * Campos del formulario
     */
    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos basicos</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombres"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            onKeyPress={letrasEspacios(30)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="lastname" 
                            label="Apellidos"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.lastname ? errors.lastname : ""}
                            error={touched.lastname && Boolean(errors.lastname)}
                            onKeyPress={letrasEspacios(30)}
                            {...lastnameProps}
                        />
                    </Grid>            
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="email" 
                            label="Correo electrónico"
                            fullWidth
                            required
                            inputProps={{ maxLength: 50 }}
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            {...emailProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="telefono" 
                            label="Número de contacto"
                            fullWidth
                            required
                            inputProps={{ maxLength: 50 }}
                            helperText={touched.telefono ? errors.telefono : ""}
                            error={touched.telefono && Boolean(errors.telefono)}
                            {...telefonoProps}
                        />
                    </Grid>      
                </Grid>                      
                    {mode === "crear" && (
                    <>
                        <Grid container spacing={2}>        
                            <Grid item xs={12}>
                                <h3>Datos de acceso</h3>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined" 
                                    name="password" 
                                    label="Contraseña" 
                                    type="password"
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 30 }}
                                    helperText={touched.password ? errors.password : ""}
                                    error={touched.password && Boolean(errors.password)}
                                    onKeyPress={letrasNumerosCaracteres(30)}
                                    {...passwordProps}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined" 
                                    name="confirmPassword" 
                                    label="Confirmar contraseña" 
                                    type="password"
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 30 }}
                                    helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                    onPasteCapture={(e) => e.preventDefault()}
                                    {...confirmPasswordProps}
                                />
                            </Grid>
                        </Grid>    
                    </> 
                    )}                
            </Box>  
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Tipo de perfil</h3>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            select
                            variant="outlined" 
                            name="role" 
                            label="Selecciona rol del usuario"
                            disabled={mode === "editar"}
                            fullWidth
                            required                          
                            helperText={touched.role ? errors.role : ""}
                            error={touched.role && Boolean(errors.role)}
                            {...roleProps}>
                            {roles.map((e, i) => (
                                <MenuItem key={i} value={e.id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>            
                    <Grid item xs={4}>
                        <TextField
                            select
                            variant="outlined" 
                            name="empresa" 
                            label="Selecciona una empresa"
                            disabled={mode === "editar"}
                            fullWidth
                            required                            
                            helperText={touched.empresa ? errors.empresa : ""}
                            error={touched.empresa && Boolean(errors.empresa)}
                            {...empresaProps}
                            onBlur={(e) => handleEmpresaChange(e.target.value)}>
                            {empresas.map((e, i) => (
                                <MenuItem key={i} value={e._id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid> 
                    <Grid item xs={4}>
                        <TextField
                            select
                            variant="outlined" 
                            name="sede" 
                            label="Selecciona una sede dentro de la empresa"
                            fullWidth
                            required
                            helperText={touched.sedes ? errors.sedes : ""}
                            error={touched.sedes && Boolean(errors.sedes)}
                            {...sedesProps}>
                            {sedes.map((e, i) => (
                                <MenuItem key={i} value={e._id}>
                                    {e.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>                                
                </Grid>
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                            <Hidden xsDown>
                                <Button type="submit" color="inherit" disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </Hidden>                            
                            <Hidden smUp>
                                <IconButton type="submit" color="inherit" disabled={isSubmitting}>
                                    <SaveIcon />
                                </IconButton>
                            </Hidden>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}