import { gerentesAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const updatePassword = async (gerente, pass) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/updatePassword`,{gerente,pass}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getGerentes = async (idEmpresa) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getGerentes`,{empresa:idEmpresa}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getDepartamentosMunicipiosGerente = async (id) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getDepartamentosMunicipiosGerente`,{id}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getDepartamentosMunicipiosGerenteRegistro = async () =>
{
        const res = await axios.post(`${API}/getDepartamentosMunicipiosGerenteRegistro`, {});

        return handleResponse(res);
}

export const createGerente = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/createGerente`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const updateGerente = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/updateGerente`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

//Traer Gerente para ciudad
export const getGerenteCiudad = async (evento, ciudad) =>
{
    let datos = {};
    datos.evento = evento;
    datos.ciudad = ciudad;

    const res = await axios.post(`${API}/getGerenteCiudad`, datos);
    return handleResponse(res);
}