import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import { regex } from "../../../helpers/Validator";

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery, 
    useTheme,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Slide,
    Box
} from '@material-ui/core';

// Validaciones
import { letrasNumerosCaracteresEspacios,corregirEspaciado } from "../../../helpers/Validator";
import { useFormik } from "formik";
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Servicios
import { crearSede, actualizarSede } from '../../../core/apiSedes';

// Custom components
import { showLoader, hideLoader } from '../../shared/Loader';
import ShowAlert from '../../shared/Snackbar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioSede ({open, close, mode, data=null, empresaSeleccionada, refreshTable})
{
    const classes = useStyles();
    const theme = useTheme();
    
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () => {
        formik.handleReset();
        close();
    }

    /* Manejador evento submit del formulario */
    const onSubmit = async (values) => {
        showLoader();

        let res = {};
        values.name = corregirEspaciado(values.name);

        console.log(values);
        console.log(mode);

        if (mode === "crear"){        
            values.empresa = empresaSeleccionada._id;    
            res = await crearSede(values);
        }else if (mode === "editar"){
            values.idEmpresa = data._id;
            res = await actualizarSede(values);
        }        

        // Validar respuesta del servicio
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            resetForm();
            refreshTable();
        }

        hideLoader();
    }

    /* Formik */
    const formik = useFormik({
        initialValues: {
            name: "",
            derscr: ""
        },
        validationSchema: yup.object().shape({
            name: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            descripcion: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo")
        }),
        onSubmit
    });
    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;

    const nameProps = getFieldProps("name");
    const descripcionProps = getFieldProps("descripcion");

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    useEffect(() =>
    {
        async function init ()
        {
            if (mode === "crear"){
                formik.handleReset();
            }else if (mode === "editar"){                
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("descripcion", data.descripcion);
            }
        }
        init();
    }, [open]);

    /* Campos del formulario */
    const fields = () => (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined" 
                        label="Nombre"
                        name="name"
                        color="primary"
                        fullWidth
                        required
                        inputProps={{ maxLength: 100 }}
                        helperText={touched.name ? errors.name : ""}
                        error={touched.name && Boolean(errors.name)}
                        onKeyPress={letrasNumerosCaracteresEspacios(100)}
                        {...nameProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined" 
                        label="Descripción"
                        name="descripcion"
                        fullWidth
                        required
                        inputProps={{ maxLength: 100 }}
                        helperText={touched.descripcion ? errors.descripcion : ""}
                        error={touched.descripcion && Boolean(errors.descripcion)}
                        onKeyPress={letrasNumerosCaracteresEspacios(100)}
                        {...descripcionProps}
                    />
                </Grid>                
            </Grid>
        </Box>  
    )

    return (
        <>
            <Dialog                 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Sede
                            </Typography>
                            <Hidden xsDown>
                                <Button type="submit" color="inherit" disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </Hidden>                            
                            <Hidden smUp>
                                <IconButton type="submit" color="inherit" disabled={isSubmitting}>
                                    <SaveIcon />
                                </IconButton>
                            </Hidden>
                        </Toolbar>
                    </AppBar>

                    <DialogContent>
                        {fields()}
                    </DialogContent>                    
                </form>
            </Dialog>

            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}