import { controlsAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const uploadFile = async (file) => {
    const fileData = new FormData() 
    fileData.append('file', file);

    const uploadedFile = await axios.post(API + "/uploadFile", fileData)
    var url = null;
    if(uploadedFile && uploadedFile.data && uploadedFile.data.file){
        url = uploadedFile.data.file.url
    }

    return url
}  

export const uploadFileUrl = async (file) => {
    let values= {};
    values.urlFoto = file;

    const uploadedFile = await axios.post(API + "/uploadFileUrl", values)
    const url = uploadedFile.data.respuesta;
    if(url===false){
        return false;
    }else{
        return url
    }  
}  

export const deleteEntity = async (entity, idEntity) => {
    var token = null;
    if (isAuthenticated()) {
        token = isAuthenticated().token;
        const res = await axios.post(`${API}/deleteEntity`, { entity, idEntity }, {
            headers: {
                token: token
            }
        });
        return handleResponse(res);
    }
}

export const getUploadFileStatus = async (fileId) => {
    var token = null;
    if (isAuthenticated()) {
        token = isAuthenticated().token;
        const res = await axios.post(`${API}/getFileState`, { fileId }, {
            headers: {
                token: token
            }
        });
        return handleResponse(res);
    }
}

export const getVideos = async () => {
    var token = null;
    if (isAuthenticated()) {
        token = isAuthenticated().token;
        const res = await axios.post(`${API}/getVideos`, {
            headers: {
                token: token
            }
        });
        return handleResponse(res);
    }
}