import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'

export const ButtonSelect = React.memo(({defaultValue, list = [], handleSelect}) => {
    return (
        <>
            <TextField
                select
                size='small'
                variant="outlined" 
                name="textValue" 
                label='Filtro por conceptos'
                value={defaultValue}      
                fullWidth
            >   
                {
                    list.map((e, index) => 
                        <MenuItem key={index} value={e.value} onClick={() => {
                            handleSelect(e.value)
                        }}>
                            {e.name}
                        </MenuItem>
                    )
                }
            </TextField>
        </>
    )
})
