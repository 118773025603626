import React, { useState, useEffect } from 'react';

// Componentes 
import { 
    Paper,
    Box,
    Avatar,
    Tooltip,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Button
} from '@material-ui/core';

// Estilos
import { tableStyles, Icons } from "../../../assets/js/GeneralStyles";
import { showLoader, hideLoader } from '../../shared/Loader';

// Custom components
import { stableSort, getComparator } from "../../shared/Tablas/CustomSortTable";
import CustomTableHead from "../../shared/Tablas/CustomTableHead";
import Paginacion from "../../shared/Tablas/Paginacion";
import DeleteControl from "../../controls/DeleteControl";
import PasswordIcon from '@mui/icons-material/Password';
import { updatePassword } from '../../../core/apiGerentes';

import "../../../App.css"

const headCells = 
[
	{ id: 'options', label: 'Opciones', align: 'left', sortable: false },
    { id: 'item', align: "left", label: '#', sortable: true },
	{ id: 'fullname', label: 'Nombre completo', align: 'left', sortable: true },
	{ id: 'documento', label: 'Cédula', align: 'left', sortable: true },
	{ id: 'telefono', label: 'Celular', align: 'left', sortable: true }, 
	{ id: 'email', label: 'Correo', align: 'left', sortable: true }
	
];

export default function Tabla ({registros, handleEditClick, reloadTable, setSnackbarProps})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();
	const [openUpdatePass, setOpenUpdatePass] = useState(false);
	const [user, setUser] = useState(null);
	const [newPass, setNewPass] = useState("");

	const handleCloseUpdatePass = () => {
		setOpenUpdatePass(false);
	}

	const updatePasswordMethod = async () => {
		if(newPass != "" && user != null){
			showLoader();

			var res = await updatePassword(user._id, newPass);
			if (res.error){
				setSnackbarProps("e" + res.error);
			}else{
				setSnackbarProps("s" + res.data.message);	
				setNewPass("");
				setOpenUpdatePass(false);
			}
	
			hideLoader();			
		}else{
			setSnackbarProps("e" + "Debe diligenciar una nueva contraseña");
		}
	}

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 30;
	const rowsPerPageOptionsDefault = [30, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() =>
    {
        setRows(Array.from(registros, (reg, index) =>
        {
            return {
                item: index + 1,
                fullname: `${reg.name} ${reg.firstLastname} ${reg.secondLastname}`,
                documento: reg.documento,
                telefono: reg.telefono,
                email: reg.email,
				rest: reg
            };
        }));
    }, [registros]);

    return (
		<>
			<Box p={1}>
				<Paper>
					<TableContainer className="borderTable">
						<Table
							stickyHeader
							aria-labelledby="tableTitle"
							aria-label="sticky table"	>
							<CustomTableHead
								order={order}
								setOrder={setOrder}
								orderBy={orderBy}
								setOrderBy={setOrderBy}
								headCells={headCells}/>
							<TableBody>
								{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
								{rows.length === 0 && (
									<TableRow>
										<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
											No se encontraron gerentes o no se han registrado.
										</TableCell>
									</TableRow>
								)}
								{/* Sí hay filas para mostrar */}
								{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
									<TableRow
										hover
										tabIndex={-1}
										key={row.item}>
										<TableCell className="col20">
											<Box display="flex" justifyContent="left" alignItems="left">
												{/* Editar */}
												<Avatar
													className={`
														${tableClasses.icons}
														${tableClasses.yellow}
													`}
													variant="rounded"
													onClick={() => handleEditClick("editar", row.rest)}>
													<Tooltip title="Editar">
														<Icons.Edit fontSize="inherit"/>
													</Tooltip>
												</Avatar>
												<Avatar
													className={`
														${tableClasses.icons}
														${tableClasses.yellow}
													`}
													variant="rounded"
													onClick={() => {
														setUser(row.rest);
														setOpenUpdatePass(true);
													}}>
													<Tooltip title="Actualizar contraseña">
														<PasswordIcon fontSize="inherit"/>
													</Tooltip>
												</Avatar>
												<DeleteControl
													entidad="Gerente"
													idEntidad={row.rest._id}
													setSnackbarProps={setSnackbarProps}
													nameShowDelete={"Usuario, tenga en cuenta que se eliminará este gerente"}
													refreshEvent={reloadTable}/>											
											</Box>
										</TableCell>
										<TableCell className="col20" align="left">{row.item}</TableCell>
										<TableCell className="col200" align="left">{row.fullname}</TableCell>									
										<TableCell className="col150" align="left">{row.documento}</TableCell>
										<TableCell className="col50" align="left">{row.telefono}</TableCell>
										<TableCell className="col100" align="left">{row.email}</TableCell>

									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{/* Paginación */}
					<Paginacion 
						count={rows.length}
						rowsPerPageDefault={rowsPerPageDefault}
						rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
						setCalculoPaginacion={setCalculoPaginacion}
					/>
				</Paper>
			</Box>
			<Dialog 
				open={openUpdatePass} 
				onClose={handleCloseUpdatePass}>
				<DialogTitle>Actualización de contraseña</DialogTitle>
				<DialogContent>
				<DialogContentText>	
					Diligencie la nueva contraseña de acceso del usuario.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="password"
					label=" Nueva contraseña"
					type="text"
					fullWidth
					variant="standard"
					value={newPass}
					onChange={(e) => {
						setNewPass(e.target.value);
					}}
				/>
				</DialogContent>
				<DialogActions>
				<Button 
					color="secondary"
					onClick={()=>{
					handleCloseUpdatePass();
					}}>
						Cancelar
				</Button>
				<Button 
					color="primary"
					onClick={()=>{
						updatePasswordMethod()
					}}>
						Actualizar contraseña</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}