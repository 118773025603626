import React from "react";
import PropTypes from 'prop-types';
import {
    makeStyles,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    // Oculta el texto de accesibilidad al ordenar la tabla
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

/* Header de la tabla */
export default function CustomTableHead(props)
{
    const classes = useStyles();
    const { order, setOrder, orderBy, setOrderBy, headCells } = props;
    
    const onRequestSort = (event, property) =>
    {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className="table-head">
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.sortable)
                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'ordenado descendentemente' : 'ordenado ascendentemente'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    else
                        return (
                            <TableCell 
                                key={headCell.id}
                                align={headCell.align}
                            >
                                {headCell.label}
                            </TableCell>
                        )
                })}
            </TableRow>
        </TableHead>
    );
}

CustomTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    setOrder: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    setOrderBy: PropTypes.func.isRequired,
};