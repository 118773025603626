import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { customStyles } from "../../assets/js/themeConfig";
import {
    Drawer, Box, Typography, Divider,Button
} from '@material-ui/core';
import SidebarOptions from './SidebarOptions';
import { isAuthenticated } from "../../core/sessionAPI";
import "../../App.css"
import TerminosCondicionesPublic from '../TerminosCondicionesPublic';

const drawerWidth = 340;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        maxWidth: drawerWidth
    },
    toolbar: theme.mixins.toolbar,
    bg: customStyles.sidebarBackground
}));

export default function Sidebar(props) {
    const classes = useStyles();
    const auth = isAuthenticated();
    const [openTerminos,setOpenTerminos] = useState(false);

    console.log(auth.user);

    const handleCloseTerminos = () =>{
        setOpenTerminos(false);
    }

    return (
        <>
            <Drawer
                className={`${classes.drawer}`}
                classes={{
                    paper: `${classes.drawerPaper} ${classes.bg}`
                }}
                anchor="left"
                variant={props.variant}
                open={props.open}
                onClose={props.onClose ? props.onClose : null}>
                <Box 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent="center" 
                    alignItems="center" 
                    className={classes.toolbar}>
                    <img className="imageLogo" src="/img/disproelLogoWhite.png"/>
                </Box>
                <Box 
                    p={1}
                    flexDirection="row" 
                    justifyContent="left" 
                    alignItems="center" 
                    className={classes.toolbar}>
                    <Typography style={{color: "white", width:"100%"}} component="p">                        
                        {auth.user.name} {auth.user.lastname}
                    </Typography>     
                    <Typography style={{color: "white", width:"100%", fontSize:10}} component="p">                        
                        {auth.user.role == 2 ? "Administrador":""}
                        {auth.user.role == 3 ? "Gerente":""}
                    </Typography>                    
                </Box>               
                <Divider />
                <SidebarOptions/>
                <Divider />
                <Box p={1} className="bottomButton" fullWidth>
                    <Button 
                        variant="outlined"  
                        color="secondary" 
                        fullWidth
                        className="whiteButton"
                        onClick={() => {
                            console.log("Ver");
                            setOpenTerminos(true);
                        }}>
                        Ver terminos y condiciones
                    </Button>
                </Box>
            </Drawer>     

            <TerminosCondicionesPublic open={openTerminos} handleClose={handleCloseTerminos}/>
        </>
    );
}