import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    InputAdornment,
    Switch,
    styled,
    MenuItem
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";
import Stack from '@mui/material/Stack';


// Servicios
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioMunicipio ({open, close, mode, data, refreshTable, Dept, proveedores = []})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    const [switchZonificado, setSwitchZonificado] = useState(false)

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        
        //Se adiciona el campo empresa al formulario de registro
        //Existe por lo menos un archivo cargado

        let res = {};
        values.isZonificado = switchZonificado
        if (mode === "crear"){
            values.codigo = String(Dept.codigo) + String(values.codigo)
            values.isZonificado = switchZonificado
            res = await axios.post(API + "/createEntity", {
                entity: 'Ciudad', values: {...values, delete:false, departamento: Dept._id}
            })
        }else if (mode === "editar"){
            values.id = data._id;
            res = await axios.post(API + "/updateEntity", {
                id: values.id, entity: 'Ciudad', values: {...values}
            })
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            codigo: "",
            name: "",
            isZonificado: '',
            proveedorPagos: ''
        },
        validationSchema: yup.object().shape({
            name: yup.string().required("Debes llenar este campo"),
            codigo: yup.string().required("Debes llenar este campo"),
            isZonificado: yup.string(),
            proveedorPagos: yup.string().required("Debes llenar este campo"),
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const codigoProps = getFieldProps("codigo");
    const isZonificadoProps = getFieldProps("isZonificado");
    const proveedorPagosProps = getFieldProps("proveedorPagos");
    
    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                setSwitchZonificado(false)
                formik.handleReset();
                if(proveedores.length)
                    formik.setFieldValue("proveedorPagos", proveedores[0]._id);
                setFormTitle(`Registrar nuevo municio de ${Dept?.name}`);
            }else if (mode === "editar"){
                setFormTitle(`Actualizar datos del departemento ${Dept?.name}`);
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("codigo", data.codigo);
                formik.setFieldValue("proveedorPagos", data?.proveedorPagos || '');
                setSwitchZonificado(data?.isZonificado)
            }
        }
        init();
    }, [open]);

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 55,
        height: 34,
        padding: 5,
        display: 'flex',
        '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(28px)',
        },
        },
        '& .MuiSwitch-switchBase': {
        padding: 6,
        '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
        },
        '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        borderRadius: 12,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
        },
        '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        },
    }));

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos del municipio</h3>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombre"
                            fullWidth
                            required
                            inputProps={{ 
                                maxLength: 200, 
                            }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="codigo" 
                            label="Código del municipio"
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 45,
                            }}
                            helperText={touched.codigo ? errors.codigo : ""}
                            error={touched.codigo && Boolean(errors.codigo)}
                            {...codigoProps}
                            InputProps={{
                                startAdornment: mode === 'crear' ? 
                                    <InputAdornment position="start" disabled={true}>{ Dept?.codigo }</InputAdornment>
                                    : <></>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className="smallPadding centerContent">
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">¿Es zonificado?</div>
                            <Stack 
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center"
                                name="entrega"
                                {...isZonificadoProps}>
                                <Typography>No</Typography>
                                <AntSwitch 
                                    checked = {switchZonificado}
                                    onChange = {() => setSwitchZonificado(!switchZonificado)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                    
                                />
                                <Typography>Si</Typography>
                            </Stack>
                        </Box>                        
                    </Grid> 
                    <Grid item xs={12} className="smallPadding">
                        <TextField
                            select
                            variant="outlined" 
                            name="proveedorPagos" 
                            label="Proveedor de pagos"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.proveedorPagos ? errors.proveedorPagos : ""}
                            error={touched.proveedorPagos && Boolean(errors.proveedorPagos)}
                            {...proveedorPagosProps}
                        >   
                            {
                                proveedores.map((e, i) => (
                                    <MenuItem key={i} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>        
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}