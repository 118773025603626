import React, { useCallback, useEffect, useRef, useState} from 'react';
import { 
    Dialog,
    Typography,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Box,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";
import { hideLoader, showLoader } from '../shared/Loader';
import { downloadStandsTrackingReport, getStandsAndSupervisores } from '../../core/apiEventos';
import AutocompleteFromEntity from '../controls/AutocompleteFromEntity';
import { StandsTrackingTable } from './StandsTrackingTable';
import { Autocomplete } from '@mui/material';
import { getCiudadSucursales } from '../../core/apiCiudadSucursal';
import SupervisorsByStands from './SupervisorsByStands';
import { isAuthenticated } from '../../core/sessionAPI';
import { MySelect } from '../shared/MySelect';
import { getUploadFileStatus } from '../../core/apiControls';
import { saveAs } from 'file-saver'

const INITIAL_DATA_STATE = {count: 0, stands: []}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogStandsTracking ({idEvento, isOpen, onClose, eventName})
{
    const { user } = isAuthenticated()
    const [snackbarProps, setSnackbarProps] = useState();
    const classes = useStyles();

    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [sucursal, setSucursal] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [standStatus, setStandStatus] = useState('ALL') // 'ALL', 'EMPTY', 'FULL'
    const [availableMunicipios, setAvailableMunicipios] = useState([]);
    const [data, setData] = useState(INITIAL_DATA_STATE);
    const [rowPerPage, setRowPerPage] = useState(100);
    const [page, setPage] = useState(1);
    const [openStand, setOpenStand] = useState(false);
    const [stand, setStand] = useState(null);
    const isMounted = useRef(false)

    const handleClose = () => {
        onClose();
        setSearch('');
        setSearchText('');
        setSucursal('');
        setMunicipio('');
        setAvailableMunicipios([]);
        setData(INITIAL_DATA_STATE);
        setRowPerPage(100);
        setPage(1);
    }

    const handlerOpenStand = stand => {
        if(!stand.hasSupervisores) return void setSnackbarProps("eEl puesto no tiene personal asignado")
        setOpenStand(true);
        setStand(stand);
    }

    const closeStand = () => {
        setOpenStand(false);
        setStand(null);
    }

    const downloadReport = async () => {
        showLoader()
        const res = await downloadStandsTrackingReport({
            evento: idEvento, 
            search,
            sucursal,
            municipio,
            standStatus
        });
        const intervalId =  setInterval(async function(){
            const fileStatus = await getUploadFileStatus(res.data.fileId);
            if (fileStatus.data.status === "ERROR"){
                setSnackbarProps("eError al generar el archivo, contacte un administrador");              
                hideLoader();
                clearInterval(intervalId);
            }else if(fileStatus.data.status === 'SUCCESS'){      
                const timestamp = new Date().getTime();
                const fileUrl = fileStatus.data.url
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', `reporte-puestos-${timestamp}.xlsx`); 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSnackbarProps("s¡Archivo generado con éxito!");
                hideLoader();
                clearInterval(intervalId);
            }             
        },5000);
    }

    const loadData = useCallback( async () => {
        if(isMounted.current) return
        showLoader()
        const response = await getStandsAndSupervisores({
            evento: idEvento, 
            search,
            sucursal,
            municipio,
            standStatus,
            page,
            limit: rowPerPage
        })
        if(response?.data) setData(response.data)
        hideLoader()
    }, [idEvento, search, sucursal, municipio, page, rowPerPage, standStatus])

    useEffect(() => {
        const loadMunicipiosSucursal = async () => {
            const res = await getCiudadSucursales(sucursal)

            if (res.error){
                setSnackbarProps("e" + res.error);
                setAvailableMunicipios([]);
                setMunicipio('');
            }else{
                setAvailableMunicipios(res.data.map(item => item.ciudad));
            }
        }
        Boolean(sucursal) && void loadMunicipiosSucursal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucursal])
    
    useEffect(() => {
        isOpen && void loadData()
    }, [sucursal, municipio, page, rowPerPage, search, loadData, isOpen])

    useEffect(() => {
        user?.role === 3 && setSucursal(user?.sucursal)
    }, [isOpen, user.role, user.sucursal])

    return (
        <>
            <SupervisorsByStands 
                isOpen={openStand}
                onClose={closeStand}
                stand={stand}
            />        
            <Dialog 
                open={isOpen} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Seguimiento de registro de puestos - {eventName}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box p={1}>
                    <Grid item xs={12}>
                        <Paper elevation={1} style={{padding: 8, background: "#fafafa"}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={2}>
                                    <TextField 
                                        size="small"
                                        value={searchText}
                                        variant="outlined"                                                 
                                        placeholder="Nombre del puesto o personal"
                                        label="Buscar"
                                        fullWidth
                                        onChange={(e)=>{
                                            setPage(1);
                                            setSearchText(e.target.value);
                                        }}
                                        onKeyPress={event => {
                                            setPage(1)
                                            if (event.key === 'Enter') {
                                                setSearch(searchText);
                                            }
                                        }}
                                        onKeyUp={event => {       
                                            setPage(1)
                                            if (event.key === 'Backspace' && search === "") {
                                                setSearch(searchText);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <AutocompleteFromEntity
                                        value={sucursal}
                                        enableCreation={false}
                                        label="Sucursal"
                                        placeholder="Sucursal"
                                        entity="Sucursal"
                                        disabled={user?.role === 3}
                                        size='small'
                                        field="name"
                                        setObject={(obj) => {
                                            setPage(1)
                                            if(obj){
                                                setSucursal(obj._id);
                                                //loadMunicipiosSucursal(obj._id)
                                            }else{
                                                setSucursal(null);
                                                setMunicipio(null);
                                                //loadMunicipiosSucursal(null);                                                        
                                            }
                                        }}
                                        condition={{delete:false}}
                                        multiple={false}
                                        selectAll={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Autocomplete
                                        key={sucursal}
                                        fullWidth         
                                        size="small"    
                                        options={availableMunicipios}      
                                        getOptionLabel={(option) => {
                                            return option.codigo + " - " + option.name;
                                        }} 
                                        onChange={(e, value)=>{
                                            setPage(1)
                                            if(value){
                                                setMunicipio(value._id);   
                                            }else{
                                                setMunicipio(null);
                                            }
                                        }}             
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}                                                    
                                                variant="outlined"
                                                label="Municipio"
                                                placeholder="Municipio"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <MySelect 
                                        size="small"
                                        value={standStatus}
                                        setValue={setStandStatus}
                                        label="Estado del puesto"
                                        options={[
                                            {label: 'Todos', value: 'ALL'},
                                            {label: 'Sin asignación', value: 'EMPTY'},
                                            {label: 'Con asignación', value: 'FULL'}
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={downloadReport}
                                    >
                                        Descargar reporte
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{marginTop: 8}}>
                        <Paper elevation={1} style={{padding: 8, background: "#fafafa"}}>
                            <StandsTrackingTable 
                                page={page}
                                perPage={rowPerPage}
                                setPage={setPage}
                                setPerPage={setRowPerPage}
                                data={data} 
                                openStand={handlerOpenStand}
                            />
                        </Paper>
                    </Grid>
                </Box>              
            </Dialog>
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}