import { tipoPagoAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getTiposPagos = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getTiposPagos`,{}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getTiposPagosRegistro = async () =>
{    
        const res = await axios.post(`${API}/getTiposPagos`,{},);
        return handleResponse(res);
}
