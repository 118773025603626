import React, { useState, useEffect } from 'react';

// Componentes 
import { 
    Paper,
    Box,
    Avatar,
    Tooltip
} from '@material-ui/core';

// Tabla
import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';

// Estilos
import { tableStyles, Icons } from "../../../assets/js/GeneralStyles";

// Custom components
import { stableSort, getComparator } from "../../shared/Tablas/CustomSortTable";
import CustomTableHead from "../../shared/Tablas/CustomTableHead";
import Paginacion from "../../shared/Tablas/Paginacion";
import DeleteControl from '../DeleteControl';

const headCells = 
[
	{ id: 'options', label: 'Opciones', align: 'left', sortable: false },
    { id: 'item', align: "left", label: '#', sortable: true },
    { id: 'fullname', label: 'Nombre completo', align: 'left', sortable: true },
    { id: 'email', label: 'E-mail', align: 'left', sortable: true },
    { id: 'empresa', label: 'Empresa', align: 'left', sortable: true }  
];

export default function Tabla ({admins, handleEditClick, reloadTable, setSnackbarProps})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();

    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');

	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
	const rowsPerPageDefault = 30;
	const rowsPerPageOptionsDefault = [30, 70, 100];
	const [calculoPaginacion, setCalculoPaginacion] = useState({inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1});

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() =>
    {
        setRows(Array.from(admins, (admin, index) =>
        {
            return {
                item: index + 1,
                fullname: `${admin.name} ${admin.lastname}`,
                email: admin.email,
                empresa: admin.empresa.name,
				rest: admin
            };
        }));
    }, [admins]);

    return (
		<div className={tableClasses.root}>
			<Paper>
				<TableContainer className="borderTable">
					<Table
                        stickyHeader
						aria-labelledby="tableTitle"
						aria-label="sticky table"	>
						<CustomTableHead
							order={order}
							setOrder={setOrder}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
							headCells={headCells}			
						/>
						<TableBody>
							{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
							{rows.length === 0 && (
								<TableRow>
									<TableCell className={tableClasses.emptyTable} colSpan={6}>
										No se encontraron usuarios administradores o no se han registrado.
									</TableCell>
								</TableRow>
							)}
							{/* Sí hay filas para mostrar */}
							{stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
								<TableRow
									hover
									tabIndex={-1}
									key={row.item}>
									<TableCell className={tableClasses.options}>
										<Box display="flex" justifyContent="left" alignItems="left">
											{/* Editar */}
											<Avatar
												className={`
													${tableClasses.icons}
													${tableClasses.yellow}
												`}
												variant="rounded"
												onClick={handleEditClick("editar", row.rest)}
											>
												<Tooltip title="Editar">
													<Icons.Edit fontSize="inherit"/>
												</Tooltip>
											</Avatar>
											<DeleteControl
   	                                        	entidad="User"
												idEntidad={row.rest._id}
												setSnackbarProps={setSnackbarProps}
												nameShowDelete={"Usuario, tenga en cuenta que al eliminar este usuario, este perdera acceso al sistema."}
												refreshEvent={reloadTable}/>											
										</Box>
									</TableCell>
									<TableCell align="left">{row.item}</TableCell>
									<TableCell align="left">{row.fullname}</TableCell>
									<TableCell align="left">{row.email}</TableCell>
									<TableCell align="left">{row.empresa}</TableCell>									
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				{/* Paginación */}
				<Paginacion 
					count={rows.length}
					rowsPerPageDefault={rowsPerPageDefault}
					rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
					setCalculoPaginacion={setCalculoPaginacion}
				/>
      		</Paper>
    	</div>
	);
}