export function getRandomColor() {
  const minBrightness = 50; // Brillo mínimo aceptable
  const maxBrightness = 200; // Brillo máximo aceptable

  const getRandomValueInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  let red, green, blue;
  
  // Generar colores hasta que se encuentre uno legible
  do {
    red = getRandomValueInRange(0, 150);
    green = getRandomValueInRange(0, 150);
    blue = getRandomValueInRange(0, 150);
  } while (
    (red + green + blue) / 3 < minBrightness || // Verificar el brillo promedio
    (red + green + blue) / 3 > maxBrightness    // Verificar el brillo promedio
  );

  return `rgb(${red},${green},${blue})`;
}

