import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";

// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import "../../../src/App.css";

import { getConceptoDepartamentoTotal } from '../../core/apiConceptoCiudad';
import TablaPresupuestoDepartamento from './TablaPrespuestoDepartamentos';
import { SelectorEvento } from './SelectorEvento';
import DialogConcepto from './DialogConcepto';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPresupuestoDepartamento({open, close, eventos}){
    const classes = useStyles();
    const theme = useTheme();

    const [departamentos, setDepartamentos] = useState([]);
    const [filtering, setFiltering] = useState(false);
    const [eventoSeleccionado, setEventoSeleccionado] = useState(eventos[0])
    const [showConcepto, setShowConcepto] = useState(false)
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    const loadDataSucursales = async () => {
        showLoader();
        const res = await getConceptoDepartamentoTotal(eventoSeleccionado)

        if (res.error){
            setSnackbarProps("e" + res.error);
            setDepartamentos([]);
        }else{
            setDepartamentos(res.data);
            setFiltering(res.data)
            console.log(res.data)
        }
        
        hideLoader();;
    }

    const resetForm = () =>{
        close();
    }

    useEffect(() => {        
        async function inicializar() {
            if(eventoSeleccionado){
                console.log(eventoSeleccionado);
                await loadDataSucursales()
            }
        }

        inicializar();
    }, [eventoSeleccionado]);

    useEffect(() => {
        setEventoSeleccionado(eventos[0]?._id)
    }, [eventos])

    const closeDialogConcepto = async () => {
        setShowConcepto(false)
    }
    
    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar presupuesto por sucursales
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={6}>
                                        <Filter
                                            source={departamentos}
                                            searchBy="sucursal"
                                            setTableData={setFiltering}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={6}>
                                        <SelectorEvento
                                            defaultId={eventos[0]?._id}
                                            setEventos={setEventoSeleccionado}
                                            eventos={eventos}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4} md={4}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => openDialogConcepto()}>
                                                Administrar concepto presupuestal
                                            </Button>    
                                        </Grid>   
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>   
                            <TablaPresupuestoDepartamento
                                departamentos = {filtering}
                                reloadTable = {loadDataSucursales}
                                setSnackbarProps ={setSnackbarProps}
                                eventos={eventos}
                                evento={eventoSeleccionado}
                            />
                        </Paper>
                    </Box>                  
                </Box>               
            </Dialog>

            <DialogConcepto
                open={showConcepto}
                close={closeDialogConcepto}
            />
        </>
    );
}