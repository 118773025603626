import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    InputAdornment
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import AutocompleteFromEntity from '../controls/AutocompleteFromEntity';
import { createCiudadSucursal } from '../../core/apiCiudadSucursal';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioCiudadSucursal ({open, close, mode, data, refreshTable, sucursal})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [departamentos, setDepartamentos] = useState([])
    const [ciudades, setCiudades] = useState([])
    const [reloadCities, setReloadCities] = useState(0)
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        let res = {};
        
        if(mode === 'crear'){        
            res = await createCiudadSucursal({
                departamento: departamentos,
                ciudad: ciudades,
                sucursal: sucursal._id
            })
        }
            
        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }
        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            codigo: "",
            name: "",
        },
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const codigoProps = getFieldProps("codigo");

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            setCiudades([])
            setDepartamentos([])
            setReloadCities(0)
            if (mode === "crear"){
                formik.handleReset();
                setFormTitle(`Asignar nuevas ciudades ${sucursal?.name}`);
            }else if (mode === "editar"){
                setFormTitle(`Actualizar ciudades ${sucursal?.name}`);
                setDepartamentos([data.departamento])
                setCiudades([data._id])
            }
        }
        init();
    }, [open]);


    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Asingación de ciudades</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                            <AutocompleteFromEntity
                                value={departamentos}
                                enableCreation={false}
                                label="Departamentos"
                                placeholder="Seleccione los departamentos asignados"
                                entity="Departamento"
                                field="name"
                                setObject={(objs) => {
                                    console.log("Departamentos seleccionados");
                                    console.log(objs);
                                    setDepartamentos(objs);
                                    setReloadCities(objs)
                                }} 
                                condition={{delete:false}}
                                multiple={false}
                                selectAll={false}
                                showOtherField={{field: 'codigo', show: true}}
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                            <AutocompleteFromEntity
                                value={ciudades}
                                enableCreation={false}
                                label="Ciudades"
                                placeholder="Seleccione las ciudades asignadas"
                                entity="Ciudad"
                                field="name"
                                reloadFlag={reloadCities}
                                setObject={(objs) => {
                                    console.log("Ciudades seleccionadas");
                                    console.log(objs)
                                    setCiudades(objs);
                                }}
                                condition={{departamento:{$in:departamentos}, delete:false}}
                                multiple={true}
                                selectAll={true}
                                showOtherField={{field: 'codigo', show: true}}
                            />
                    </Grid>     
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}