import React,{useState, useEffect} from "react";
import { GoogleMap, LoadScript, Polyline } from "@react-google-maps/api";
import Loader from "../shared/Loader";
import MarkerBase from "./MarkerBase";
import { getLocationsEvento } from "../../core/apiLocation";
import ShowAlert from '../../../src/components/shared/Snackbar';
import FiltrosMapa from "./FiltrosMapa";
import moment, { isDate } from "moment";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const MAP_CENTER = {lat: 4.551048871429184, lng:  -75.64116318325173};
const mapStyles = {        
    height: "calc(100vh - 65px)",
    width: "100%"
}

const MapContainer = ({evento}) =>
{
    const [snackbarProps, setSnackbarProps] = useState();
    const [arrayMarkers, setArrayMarkers] = useState([]);
    const [usuariosDisponibles, setUsuariosDisponibles] = useState([]);
    const [showLoader, setShowLoader] = useState(true)
    const [currentModalMarker, setCurrentModalMarker] = useState('');
    
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [puestos, setPuestos] = useState([]);
    const [supervisores, setSupervisores] = useState([]);

    const [polyline, setPolyline] = useState([]);

    const fetchData = async () => {
        var res = await getLocationsEvento(evento, supervisores, departamentos, ciudades, puestos);
        if (res.error) {
            setSnackbarProps("e" + res.error)
            setArrayMarkers([]);
        } else {

            var arrayTempUsuarios = []
            if(res.data.length > 0){
                for(var i = 0; i < res.data.length; i++){
                    arrayTempUsuarios.push(res.data[i]._id);
                }
            }

            setUsuariosDisponibles(arrayTempUsuarios)
            setArrayMarkers([...res.data]);            

            if(window.myInterval !== undefined && window.myInterval !== 'undefined')
                window.clearInterval(window.myInterval);
    
            window.myInterval = setInterval(async function() {
                await fetchData();   
            }, 10000);
        }                
    }

    useEffect(() => {
        async function effect() {                           
            setShowLoader(true);
            await fetchData();  
            setShowLoader(false);         
        }

        effect();
        return () => window.clearInterval(window.myInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evento, supervisores, departamentos, ciudades, puestos]);

    return (
        <>
            <LoadScript
                googleMapsApiKey={API_KEY}
                onLoad={() => setShowLoader(false)}>
                <FiltrosMapa 
                    evento={evento}
                    supervisores={supervisores}
                    setSupervisores={(val) => setSupervisores(val)}
                    departamentos={departamentos}
                    setDepartamentos={(val) => setDepartamentos(val)}
                    ciudades={ciudades}
                    setCiudades={(val) => setCiudades(val)}
                    puestos={puestos}
                    setPuestos={(val) => setPuestos(val)}
                    usuariosDisponibles={usuariosDisponibles}
                    setPolyline={setPolyline}
                    polyline={polyline}
                /> 
                <GoogleMap
                    // className={estilo.mapStyles}
                    clickableIcons={false}
                    mapContainerStyle={mapStyles}
                    zoom={6}
                    center={MAP_CENTER}
                    options={{ 
                        streetViewControl: false, 
                        mapTypeControl: false, 
                        fullscreenControl: false
                    }}>
                    {arrayMarkers.map((marker, index)=>{
                        return(
                            <MarkerBase
                                key={marker._id}
                                setPolyline={setPolyline}
                                currentModalMarker={currentModalMarker}
                                setCurrentModalMarker={setCurrentModalMarker}
                                marker={{
                                    id: marker._id,
                                    lat: marker.lat,
                                    lon: marker.lon,
                                    obj:{
                                        texto1: (marker ? marker.firstName + " " + marker.secondName + " " + marker.firstLastname + " " + marker.secondLastname:"--"),
                                        texto2: (marker.localDate  ? moment(marker.localDate).format('DD/MM/YY hh:mm:ss A') :"--"),                                        
                                        conentPopup1: "Contacto",
                                        conentPopup2: (marker ? marker.telefono:"--"),
                                        conentPopup3: "Gerente",
                                        conentPopup4:  (marker ? marker.gerente.name + " " + marker.gerente.firstLastname + " " + marker.gerente.secondLastname:"--"),
                                    }
                                }}
                            />
                        )
                    })}
                    {
                        polyline.map( ({path, user, color}) => (
                            <Polyline
                                key={user}
                                path={path}
                                geodesic={true}
                                options={{
                                    strokeColor: color,
                                    strokeOpacity: 0.5,
                                    strokeWeight: 4,
                                    icons: [
                                        {
                                            icon: {
                                                path: 1,
                                                strokeOpacity: 1,
                                                scale: 3,
                                            },
                                            offset: '0',
                                            repeat: '100px'
                                        }
                                    ]
                                }}
                                
                            />
                        ))
                    }
                </GoogleMap>
            </LoadScript>
            <Loader show={showLoader} />
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
  )
}



export default MapContainer;