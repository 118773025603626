import React, {useState} from 'react';

// Custom components
import DashboardPage from "./dashboards/DashboardPage";
import PageContent from "./shared/PageContent";
import TerminosCondiciones from './TerminosCondiciones';

// Servicios
import { isAuthenticated } from "../core/sessionAPI";


export default function Dashboard ()
{
    return (
        <PageContent view="dashboard"/>
    )
}

export const LandingDashboard = () =>{
    const setDashboard = () =>
    {
        if (isAuthenticated())
        {
            const userData = isAuthenticated();
            console.log(userData)
            if (userData.user.role === 1)
            {
                //Super admin todas las empresas
                return (
                    <>
                        <DashboardPage/>
                        <TerminosCondiciones/>
                    </>
                )
            }else if (userData.user.role === 2){
                //Admin empresa
                return (
                    <>
                        <DashboardPage/>
                        <TerminosCondiciones/>
                    </>
                )
            }else if (userData.user.role === 3){
                //Dasboard usuarios empleados de la organización
                return (
                    <>
                        <DashboardPage/>
                        <TerminosCondiciones/>
                    </>
                )
            }else if (userData.user.isGerente){
                //Dasboard usuarios empleados de la organización
                return (
                    <>
                        <DashboardPage/>
                        <TerminosCondiciones/>
                    </>
                )
            }
        }
    }

    return (
        setDashboard()
    )
}