import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, TextField, MenuItem } from "@material-ui/core"

export const DialogValidarPago = ({open, handleClose, validarPago}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Validar Pagos</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>RECHAZAR</b>: Se rechaza el concepto, y no suma a la liquidación total del usuario, esta operación implica que el gerente debe registrar nuevamente el concepto.
                    <br/>
                    <br/>
                    <b>VALIDAR</b>: Se valida el pago y habilita la opción de <b>PAGAR</b> para el concepto seleccionado.
                </DialogContentText>
            </DialogContent>                
            <DialogActions style={{textAlign: 'right'}}>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={() => validarPago('RECHAZADO')} color="secondary">
                    RECHAZAR
                </Button>
                <Button onClick={() => validarPago('VALIDADO')} color="primary">
                    VALIDAR
                </Button>
            </DialogActions>
        </Dialog>
    )
}
