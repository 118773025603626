import React, { useState, useEffect} from 'react';
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,

} from '@material-ui/core';
import Filter from "../shared/Filters";
import AddIcon from '@material-ui/icons/Add';

// Validaciones
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";

import axios from 'axios';
import TablaPresupuestoMunicipios from './TablaPresupuestoMunicipios';
import { getConceptoCiudadesTotal } from '../../core/apiConceptoCiudad';
import { SelectorEvento } from './SelectorEvento';
import DialogConcepto from './DialogConcepto';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPrespuestoMunicipios({open, close, Dept, eventos, evento}){
    const classes = useStyles();
    const theme = useTheme();

    const [ciudades, setCiudades] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [modo, setModo] = useState("crear");
    const [filtering, setFiltering] = useState([]);
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState(null)
    const [showConcepto, setShowConcepto] = useState(false)
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        close();
    }
    
    const loadDataCiudades = async () => {
        showLoader();
        const { _id} = Dept
        const res = await getConceptoCiudadesTotal(_id)
        if (res.error){
            setSnackbarProps("e" + res.error);
            setFiltering([])
            setCiudades([]);
        }else{
            setCiudades(res.data);
            setFiltering(res.data)
            console.log(res.data)
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar() {
            if(Dept){
                await loadDataCiudades()
            }
        }
        inicializar();
    }, [Dept]);

    const handleCrearEditarMunicipios= (tipo, deptSeleccionado) => {
        setCiudadSeleccionada(deptSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }

    const closeForm = async() => {
        await loadDataCiudades()
        setOpenForm(false)
    }

    const openDialogConcepto = () => {
        setShowConcepto(true)
    }

    const closeDialogConcepto = () => {
        setShowConcepto(false)
    }

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Administrar presupuesto de municipios de { Dept?.name || '' }
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8} md={12}>
                                        <Filter
                                            source={ciudades}
                                            searchBy="departamento"
                                            setTableData={setFiltering}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4} md={4}>
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            fullWidth
                                            startIcon={<AddIcon />}
                                            onClick={() => openDialogConcepto()}>
                                            Administrar concepto presupuestal
                                        </Button>                        
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>     
                    <Box p={1}>
                        <Paper elevation={1}>
                            <TablaPresupuestoMunicipios
                                Dept={Dept}
                                municipios={filtering}
                                reloadTable={loadDataCiudades}
                                eventos={eventos}
                                evento={evento}
                            />
                        </Paper>
                    </Box> 
                    {ShowAlert(snackbarProps, setSnackbarProps)}
                </Box>               
            </Dialog>

            <DialogConcepto
                open={showConcepto}
                close={closeDialogConcepto}
            />
        </>
    );
}