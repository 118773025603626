import React, { useEffect, useState } from 'react'
import { useSignatureRequest } from '../../hooks/useSignatureRequest'
import ShowAlert from '../shared/Snackbar'
import { ElectronicSignatureContainer } from './ElectronicSignatureContainer'
import { useStyles } from '../shared/Tablas/CustomTableHead'
import { AppBar, Box, Toolbar, Typography } from '@material-ui/core'
import Loader from '../shared/Loader'
import { SuccessSignedPage } from './SuccessSignedPage'
import useResponsive from '../../hooks/useResponsive'

export const ElectronicSignaturePage = (props) => {
  const [snackbarProps, setSnackbarProps] = useState()
  const [concept, setConcept] = useState()
  const {getPagoById} = useSignatureRequest()
	const classes = useStyles();
  const {isMobile} = useResponsive();

  const loadData = async () => {
    const id = props.match.params.concepto
    const response = await getPagoById(id)
    if(response) setConcept(response)
    else setSnackbarProps('eError al cargar el concepto de pago')
  }

  useEffect(() => {
    async function init () {
      await loadData()
    }
    void init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPagoById, props.match.params.concepto])

  return (
    <Box minHeight='calc(100vh - 64px)'>
      <AppBar className={`${classes.appBar} ${classes.bgColor} ${classes.bgShadow}`}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
					{isMobile? null: `Evento ${concept?.evento?.name} - `}Firma de documento equivalente #{concept?.consecutivoFacturacion}
					</Typography>
        </Toolbar>
			</AppBar>
      <Box mt='64px' p={2}  minHeight='calc(100vh - 64px - 32px)'>
        { concept?.signatureStatus === 'REQUESTED' ?
          <ElectronicSignatureContainer concept={concept} reloadPage={loadData} />
          : concept?.signatureStatus === 'SIGNED' ?
            <SuccessSignedPage />
          : null
        }
      </Box>
      {ShowAlert(snackbarProps, setSnackbarProps)}
      <Loader />
    </Box>
  )
}
