//Import react
import React, { useEffect, useState } from 'react';

//Controles generales
import { showLoader, hideLoader } from './shared/Loader';
import ShowAlert from './shared/Snackbar';

import { restaurarPassword } from '../core/sessionAPI';

// Import de componentes necesarios 
import { 
    Paper,
    Box,
    TextField,
    Grid,
    Button,
    Hidden
} from '@material-ui/core';

import '../App.css';

export default function RestorePass(){
    const [passValue, setPassValue] = useState("");
    const [snackbarProps, setSnackbarProps] = useState();

    const handleChangeCodeValue = (e) => {                
        setPassValue(e.target.value);
    }

    const handleRestaroePass = async () => {
        var codeRestauracion = window.location.pathname.split("/")[2];
        if(passValue.length > 5){
            if(codeRestauracion && codeRestauracion != null && passValue != ""){
                var res = await restaurarPassword(codeRestauracion, passValue);

                if (res.error){
                    setSnackbarProps("e" + "Error en la restauración de contraseña, código invalido.");
                }else{
                    setSnackbarProps("s" + "Contraseña restaurada, será redireccionado a la interfaz de inicio.");
                    setTimeout(function(){
                        window.location.href = '/'; 
                    },5000);
                }
            }else{
                setSnackbarProps("e" + "Código de restauración invalido");
            }
        }else{
            setSnackbarProps("e" + "Longitud minima, 6 caracteres");
        }
    }

    return (
        <>
            <Box className="form-container">
                <Grid container>           
                    <Grid item sm={2} md={4}> 
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}> 
                        <Box 
                            className="form"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column">                                
                            <img className="imageLogo" src="/img/disproelLogo.png"/>                       

                            <Box className="fullWidth">
                                <TextField
                                    label="Nueva contraseña"
                                    variant="outlined"
                                    name="password"
                                    color="primary"
                                    required
                                    fullWidth
                                    value={passValue}
                                    onChange={handleChangeCodeValue}
                                    inputProps={{ maxLength: 8, style: {fontSize: 18}}}
                                />
                                <div style={{height: 20}}></div>
                                <Button                                     
                                    variant="contained" 
                                    color="primary"
                                    fullWidth
                                    onClick={handleRestaroePass}>
                                    Actualizar contraseña
                                </Button>                                                                     
                            </Box>
                        </Box>                     
                    </Grid>
                    <Grid item xs={1} sm={2} md={2}> 
                    </Grid>
                </Grid>
            </Box>
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>            
    )
}