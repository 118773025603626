import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { numeros, regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    MenuItem
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

import { createRegistroRetencion, updateRegistroRetenciones } from '../../core/apiRegistroRetencion';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioRegistroRetencion ({roles = [], eventos = [], open, close, mode, data, refreshTable})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    

        //Se adiciona el campo empresa al formulario de registro
        //Existe por lo menos un archivo cargado

        if(values.tarifa <= 100 && values.tarifa >= 0){
            let res = {};
            if (mode === "crear"){
                res = await createRegistroRetencion(values)
            }else if (mode === "editar"){
                values.id = data._id;
                res = await updateRegistroRetenciones(values)
            }

            if (res.error){
                setSnackbarProps("e" + res.error);
            }else{
                setSnackbarProps("s" + res.data.message);
                
                //borrar campos
                resetForm();
                //reiniciar tabla de vehiculos
                refreshTable();
        }}else{
            setSnackbarProps('e' + 'La tarifa debe ser mayor a 0 y menor a 100')
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            evento: '',
            rol: '',
            tarifa: 0,
            regimen: ''
        },
        validationSchema: yup.object().shape({
            evento: yup.string().required("Debes llenar este campo"),
            rol: yup.string().required("Debes llenar este campo"),
            regimen: yup.string().required("Debes llenar este campo"),
            tarifa: yup.string().matches(regex.numeros, "Este campo solo admite numeros y dicimales separados por (.)").required("Debes llenar este campo"),
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const eventoProps = getFieldProps("evento");
    const rolProps = getFieldProps("rol");
    const tarifaProps = getFieldProps("tarifa");
    const regimenProps = getFieldProps("regimen");
    

    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                formik.setFieldValue("evento", eventos[0]?._id);
                setFormTitle("Registrar retencion");
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos de la retención");
                formik.setFieldValue("evento", data.evento._id);
                formik.setFieldValue("rol", data.rol._id);
                formik.setFieldValue("tarifa", data.tarifa);
                formik.setFieldValue("regimen", data.regimen);
            }
        }

        init();
    }, [open]);

    console.log(eventoProps)

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos de la retención</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="evento" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.evento ? errors.evento : ""}
                            error={touched.evento && Boolean(errors.evento)}
                            {...eventoProps}
                        >   
                            {
                                eventos.map((e, i) => (
                                    <MenuItem key={200+i} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            variant="outlined" 
                            name="regimen" 
                            label="Regimen"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.regimen ? errors.regimen : ""}
                            error={touched.regimen && Boolean(errors.regimen)}
                            {...regimenProps}>  
                            <MenuItem key={10999} value='Natural'>
                                Natural
                            </MenuItem>
                            <MenuItem key={20999} value='Juridica'>
                                Juridica
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="tarifa" 
                            label="Tarifa"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.tarifa ? errors.tarifa : ""}
                            error={touched.tarifa && Boolean(errors.tarifa)}
                            {...tarifaProps}
                        />  
                    </Grid>   
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="rol" 
                            label="Rol"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.rol ? errors.rol : ""}
                            error={touched.rol && Boolean(errors.rol)}
                            {...rolProps}
                        >   
                            {
                                roles.map((e, i) => 
                                    eventoProps.value == e.evento?._id ?
                                    <MenuItem key={100 + i} value={e._id}>
                                        {e.name}
                                    </MenuItem> : <></>
                                )
                            }
                        </TextField>
                    </Grid>
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}