import React, { useLayoutEffect, useState, useEffect } from 'react';
import Loader from "../../../components/shared/Loader";
import ShowAlert from '../../shared/Snackbar';

import FormularioSede from './FormularioSede';

import { getSedes } from '../../../core/apiSedes';
import moment from "moment";

// Componentes
import { 
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    MenuItem, 
    useMediaQuery, 
    useTheme,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Slide,
    Box,
    Paper,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Menu,
    Hidden
} from '@material-ui/core';

import CustomTableHead from '../../shared/Tablas/CustomTableHead';
import { stableSort, getComparator } from "../../shared/Tablas/CustomSortTable";
import Paginacion from "../../shared/Tablas/Paginacion";
import AddIcon from '@material-ui/icons/Add';
import Filter from '../../shared/Filters';
import CloseIcon from '@material-ui/icons/Close';
import DeleteControl from '../DeleteControl';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TablaSedes ({open, close, empresaSeleccionada})
{
    const classes = useStyles();
    const [sedes, setSedes] = useState([]);
    const [tableSedes, setTableSedes] = useState([]);
    const [tipoFormulario, setTipoFormulario] = useState("crear");

    const [snackbarProps, setSnackbarProps] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [filtering, setFiltering] = useState(false);

    const [openForm, setOpenForm] = useState(false);

    const closeForm = () => {
        setOpenForm(false);
    }

    const handleCrearSede = (modo) => {
        setTipoFormulario(modo);
        setOpenForm(true);
    }

    const loadSedes = async () => {        
        setShowLoader(true);
        //Consulta de sedes disponibles
        //empresaSeleccionada._id
        const res = await getSedes(empresaSeleccionada._id);
        console.log(res);

        if (res.error){
            setSnackbarProps("e" + res.error)
            setSedes([]);
            setTableSedes([]);
        }else{
            setSedes(res.data);
            setTableSedes(res.data);
        }

        setShowLoader(false);
    }    

    useEffect(() => {
        async function init (){
            if(empresaSeleccionada){
                await loadSedes();
            }
        }

        init();
    }, [open]);

    return (
        <>
            <Dialog                 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Sedes {empresaSeleccionada ? empresaSeleccionada.name:""}
                        </Typography>                           
                    </Toolbar>
                </AppBar>
                <DialogContent className="tableModalNoPadding">
                    <Box p={1}>
                        <Paper elevation={1}>
                            <Grid container>
                                <Grid item xs={12} sm={8} md={10}>
                                    <Box p={1}>
                                        <Filter
                                            source={sedes}
                                            searchBy="sedes"
                                            setTableData={setTableSedes}
                                            setFiltering={setFiltering}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <Box p={1}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                startIcon={<AddIcon />}
                                                onClick={() => handleCrearSede("crear")}>
                                                Nueva sede
                                            </Button>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                    <Box p={1}>
                        <Paper elevation={1}>
                            <TablaData
                                registros={tableSedes}
                                setSnackbarProps={setSnackbarProps}
                                reloadTable={loadSedes}>
                            </TablaData>
                        </Paper>
                    </Box>
                </DialogContent>                    
            </Dialog>

            <Loader show={showLoader} />

            <FormularioSede 
                open={openForm}
                close={closeForm}
                mode={tipoFormulario}
                empresaSeleccionada={empresaSeleccionada}
                refreshTable={loadSedes}
            />

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}

export function TablaData({ registros, setSnackbarProps, reloadTable}) {
    /* Datos necesarios para ordernar las tablas */
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('item');

    /* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
    const [rows, setRows] = useState([]);

    /* Datos necesarios para la paginación de la tabla */
    const rowsPerPageDefault = 10;
    const rowsPerPageOptionsDefault = [10, 25, 50, 100];
    const [calculoPaginacion, setCalculoPaginacion] = useState({ inicio: rowsPerPageDefault, fin: rowsPerPageDefault + 1 });
        
    var headCells = [
            { id: 'opciones', align: "left", label: 'Opciones', sortable: true },    
            { id: 'item', align: "left", label: '#', sortable: true },
            { id: 'name', align: "left", label: 'Nombre', sortable: true },
            { id: 'descripcion', align: "left", label: 'Descripción', sortable: true },        
            { id: 'createAt', align: "left", label: 'Fecha de creación', sortable: true }     
    ];

    /* Detecta los cambios en el listado de clientes y ajusta su estructura para ponerlos en la tabla */
    useEffect(() => {
        setRows(Array.from(registros, (reg, index) => {                    
            return {
                item: index + 1,
                _id: reg._id,
                name: reg.name,
                descripcion: reg.descripcion,
                rest: reg
            };
        }));
    }, [registros]);

    return (
        <>
            <Paper>
                <TableContainer className="borderTable">
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table">
                        <CustomTableHead
                            order={order}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            headCells={headCells}
                        />
                        <TableBody>
                            {/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
                            {rows.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={22} className="col100">
                                        No se encontraron registros.
                                    </TableCell>
                                </TableRow>
                            )}
                            {/* Sí hay filas para mostrar */}
                            {stableSort(rows, getComparator(order, orderBy)).slice(calculoPaginacion.inicio, calculoPaginacion.fin).map((row, index) => (
                                <TableRow hover tabIndex={-1} key={row.rest._id}>
                                    <TableCell className="col100">
                                        <DeleteControl
                                            entidad="Sede"
                                            idEntidad={row.rest._id}
                                            setSnackbarProps={setSnackbarProps}
                                            nameShowDelete={"Sede, tenga en cuenta que se desvincularán todos los usuarios"}
                                            refreshEvent={reloadTable}/>
                                    </TableCell>
                                    <TableCell align="left">{row.item}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.descripcion}</TableCell>                            
                                    <TableCell align="left">{moment(row.createdAt).format("DD/MM/YYYY")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Paginación */}
                <Paginacion
                    count={rows.length}
                    rowsPerPageDefault={rowsPerPageDefault}
                    rowsPerPageOptionsDefault={rowsPerPageOptionsDefault}
                    setCalculoPaginacion={setCalculoPaginacion}
                />
            </Paper>
        </>
    );
}