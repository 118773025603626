//Import react
import React, { useEffect, useState } from 'react';

//Import estructura de navegación, y renderizado de la landing page
import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import NavContainer from '../shared/NavContainer'
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken, isAuthenticated } from "../../core/sessionAPI";

//Controles generales
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';

//Formulario, tarjetas y tablas
import Formulario from './Formularios';
import Tarjetas from './Tarjetas';

//Consumo de servicios
import { getEventos } from '../../core/apiEventos';

import AddIcon from '@material-ui/icons/Add';

import '../../App.css';

// Import de componentes necesarios 
import {
    Paper,
    Box,
    Grid,
    Button
} from '@material-ui/core';

import Filter from "../shared/Filters";

const View = () => {
    useEffect(() => {
        async function init() {
            // Realizar el servicio que refresca el token
            await refreshToken();
            // Reiniciar timer de inactividad
            setTokenInterval();
        }
        init();
    }, [])

    return <LandingPage />
}


export default function Eventos() {
    const wrapper = useWrapperStyles();

    return (
        <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
            <div className={wrapper.content}>
                <NavContainer />
                <div className={wrapper.offset}></div>

                {/* IMPORTANTE: Ver definición del componente View */}
                <View />
            </div>
        </div>
    )
}

//Estructura relacionada con la tabla de datos
// Columnas de la tabla, el valor 'id' debe ser equivalente al JSON que inserta las filas para que funcione el ordenamiento
export function LandingPage() {
    const { user } = isAuthenticated()
    //Control de mensajes de error    
    const [formMode, setFormMode] = useState("crear")
    const [editData, setEditData] = useState(null)
    const [openForm, setOpenForm] = useState(false)

    const [snackbarProps, setSnackbarProps] = useState();

    //Array con entiedades
    const [eventosTarjetas, setEventosTarjetas] = useState([]);
    const [eventosTarjetasSearch, setEventosTarjetasSearch] = useState([]);
    const [filtering, setFiltering] = useState(false);

    //
    const [eventoSeleccionado, setEventoSeleccionado] = useState(null);
    const [modo, setModo] = useState("crear");


    //Servicio de consulta de empresas
    /* Carga el listado de empresas */
    const loadAll = async () => {
        showLoader();

        //var user = isAuthenticated().user;
        var res = await getEventos();

        if (res.error) {
            setSnackbarProps("e" + res.error)
            setEventosTarjetas([]);
            setEventosTarjetasSearch([]);
        } else {
            setEventosTarjetas(res.data);
            setEventosTarjetasSearch(res.data);
        }

        hideLoader();
    }

    const closeHandler = () => {
        setOpenForm(false)
    }

    useEffect(() => {
        async function inicializar() {
            loadAll();
        }

        inicializar();
    }, []);

    const handleCrearEditar = (tipo, eventoSeleccionado) => {
        setEventoSeleccionado(eventoSeleccionado);
        setModo(tipo);
        setOpenForm(true);        
    }
    
    return (
        <>
     
            <Box p={1}>
                <Paper elevation={1}>
                    <Grid container>
                        <Grid item xs={12} sm={user?.role == 2 ? 8:12} md={user?.role == 2 ? 10:12}>
                            <Box p={1}>
                                <Filter
                                    source={eventosTarjetas}
                                    searchBy="eventos"
                                    setTableData={setEventosTarjetasSearch}
                                />
                            </Box>

                        </Grid>
                        {user?.role == 2 && <Grid item xs={12} sm={4} md={2}>
                            <Box p={1}>
                                <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                startIcon={<AddIcon />}
                                onClick={() => handleCrearEditar("crear", null)}>
                                Nuevo evento 
                                </Button> 
                            </Box>
                        </Grid>}
                    </Grid>
                </Paper>
            </Box>
            <Box>
                <Tarjetas
                    registros={eventosTarjetasSearch}
                    reloadTarjetas={loadAll}
                    setSnackbarProps={setSnackbarProps}
                />
            </Box>          
            <Formulario 
                open= {openForm}
                close= {closeHandler}
                mode= {modo}
                data= {eventoSeleccionado}
                refreshTable= {loadAll}
            />

            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}