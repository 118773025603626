import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';

// Componentes 
import { 
    Box,
    Avatar,
    Tooltip,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
	Grid,
	Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isAuthenticated } from '../../core/sessionAPI';

// Estilos
import { tableStyles, Icons } from "../../assets/js/GeneralStyles";
// Custom components
import CustomTableHead from "../shared/Tablas/CustomTableHead";
import ServerPagination from "../shared/Tablas/ServerPagination";

import "../../../src/App.css";
import DialogPagoSupervisor from '../pagoSupervisor/DialogPagoSupervisor';
import DialogInfoSupervisor from '../pagoSupervisor/DialogInfoSupervisor';
import DeleteSupervisorControl from './DeleteSupervisorControl';
import { ResendEmailVerificationModal } from '../supervisor/resendEmailVerification/ResendEmailVerificationModal';

const headCells = 
[
	{ id: 'options', label: 'Opciones', align: 'left', sortable: false },
	{ id: 'estadoActivacion', label: 'Correo', align: 'left', sortable: true },
	{ id: 'documento', label: 'Documento', sortable: true},
	{ id: 'name', label: 'Nombre', align: 'left', sortable: true },
	{ id: 'apellido', label: 'Apellido', align: 'left', sortable: true },
	{ id: 'rol', label: 'Rol', align: 'left', sortable: true },
	{ id: 'Gerente', label: 'Gerente', align: 'left', sortable: true },
	{ id: 'Departamento', label: 'Departamento Facturación', align: 'left', sortable: true },
	{ id: 'Ciudad', label: 'Ciudad Facturación', align: 'left', sortable: true },
	{ id: 'proveedor', label: 'Proveedor de Pagos', align: 'left', sortable: true },
	{ id: 'celular', label: 'Celular', sortable: true},
	{ id: 'numeroPagos', label: 'Número de pagos', sortable: true},
	{ id: 'totalpagar', label: 'Total a pagar', sortable: true},
	{ id: 'updatedAt', label: 'Última modificación', sortable: true},
];

const useStyles = makeStyles((theme) => ({
	verificado: {
	  backgroundColor: theme.palette.success.main,
	  color: theme.palette.error.contrastText,
	},
	noVerificado: {
	  backgroundColor: theme.palette.error.main,
	  color: theme.palette.error.contrastText,
		cursor: 'pointer'
	},
  }));

export default function TablaSupervisores ({edicionEvento, registros, handleEditClick, roles=[], reloadTable, setSnackbarProps, perPage, setPerPage, page, setPage, numberTotalRows, totalPago})
{
    /* Estilos personalizados para las tablas de la aplicación */
	const tableClasses = tableStyles();
	const {user} = isAuthenticated()
    /* Datos necesarios para ordernar las tablas */
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('item');
	/* Datos a mostrar en la tabla, los valores del objeto JSON deben ser equivalentes al valor 'id' del JSON headCells */
	const [rows, setRows] = useState([]);
	const [openPagoSupervisor, setOpenPagoSupervisor] = useState(false);
	const [openInfoSupervisor, setOpenInfoSupervisor] = useState(false)
	const [openResendEmailVerification, setOpenResendEmailVerification] = useState(false)
	const [supervisor, setSupervisor] = useState(null)
    
	/* Datos necesarios para la paginación de la tabla */
	const rowsPerPageOptions = [50, 100, 200, 500];	
	const classes = useStyles();

    /* Detecta los cambios en el listado de administradores y ajusta su estructura para ponerlos en la tabla */
    useEffect(() =>
    {
        setRows(Array.from(registros, (reg) =>
        {
            return {
				name: reg.firstName + ' ' + (reg?.secondName || ''),
				estadoActivacion:((reg?.estadoActivacion==="VERIFICADO" && reg?.estadoActivacion) ?"VERIFICADO":'NO VERIFICADO'),
				apellido: reg.firstLastname + ' ' + reg.secondLastname,
				rol: reg?.rol?.name || 'Sin asignar',
				allDocsDescargados: reg?.allDocsDescargados, 
				completeNameGerente: reg.completeNameGerente,
				tipo_servicio: reg.tipo_servicio,
				documento: reg.documento,
				puestos: reg.puestos,
				telefono: reg.telefono,	
				pago: reg?.totalPagar || 0,
				numeroPagos: reg?.numeroPagos || 0,
				proveedorPagos: reg?.proveedorPagos?.name || 'No asignado',
				ciudadFacturacion: reg.ciudadFacturacion ? "(" + reg.ciudadFacturacion.codigo + ") " + reg.ciudadFacturacion.name:"--",
				departamentoFacturacion: reg.departamentoFacturacion ? "(" + reg.departamentoFacturacion.codigo + ") " + reg.departamentoFacturacion.name:"--",
				rest: reg			
            };
        }));

    }, [registros]);

	const openDialogPagoSupervisor = (sv) => {
		setSupervisor(sv)
		setOpenPagoSupervisor(true)
	}

	const closeDialogPagoSupervisor = async () => {
		await reloadTable()
		setOpenPagoSupervisor(false)
	}

	const openDialogDatosImagenes = (sv) => {
		console.log(sv)
		setSupervisor(sv)
		setOpenInfoSupervisor(true)
	}

	const closeDialogDatosImagenes = async () => {
		//await reloadTable()
		setOpenInfoSupervisor(false)
	}

	const handleOpenResendVerificationEmailModal = supervisor => {
		setSupervisor(supervisor)	
		setOpenResendEmailVerification(true)
	}

	const handleCloseResendVerificationEmailModal = () => {
		setOpenResendEmailVerification(false)
		setSupervisor(null)
	}

    return (
		<>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Box p={1} className="greyBackground bigPanelMoney">
						<h3 style={{textAlign: 'left', margin: 0}}>
							<CurrencyFormat value={totalPago} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>PAGO TOTAL USUARIOS LISTADO ACTUAL: {value}</div>}/>
						</h3>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ServerPagination 
						numberTotalRows={numberTotalRows}
						page={page}
						perPage={perPage}
						setPage={setPage}
						setPerPage={setPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						className="greyBackground rightAlign"/>			
				</Grid>
			</Grid>			
				
			<TableContainer className="borderTable">
				<Table
					stickyHeader
					aria-labelledby="tableTitle"
					aria-label="sticky table"	>
					<CustomTableHead
						order={order}
						setOrder={setOrder}
						orderBy={orderBy}
						setOrderBy={setOrderBy}
						headCells={headCells}/>
					<TableBody>
						{/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
						{rows.length === 0 && (
							<TableRow>
								<TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
									No se encontraron Supervisores o no se han registrado.
								</TableCell>
							</TableRow>
						)}
						{/* Sí hay filas para mostrar */}
						{rows.map((row, index) => (
							<TableRow
								hover
								tabIndex={-1}
								key={index}
								style={
									row.allDocsDescargados ?
									{background: '#ded'} : {}
								}>
								<TableCell className="col50">
									<Box display="flex" justifyContent="left" alignItems="left">
										{/* Editar */}
										{(edicionEvento || user?.role === 2)&&
											<Avatar
												className={`
													${tableClasses.icons}
													${tableClasses.yellow}
												`}
												variant="rounded"
												onClick={() => handleEditClick("editar", row.rest)}>
												<Tooltip title="Editar">
													<Icons.Edit fontSize="inherit"/>
												</Tooltip>
											</Avatar>
										}
										<Avatar
											className={`
												${tableClasses.icons}
												${tableClasses.blue}
											`}
											variant="rounded"
											onClick={() => openDialogDatosImagenes(row.rest)}>
											<Tooltip title="Información de Supervisor">
												<Icons.Persona fontSize="inherit"/>
											</Tooltip>
										</Avatar>
										<Avatar
											className={`
												${tableClasses.icons}
												${tableClasses.green}
											`}
											variant="rounded"
											onClick={() => openDialogPagoSupervisor(row.rest)}>
											<Tooltip title="Administrar conceptos de pago">
												<Icons.List fontSize="inherit"/>
											</Tooltip>
										</Avatar>
										{(edicionEvento || user?.role === 2) &&
											<DeleteSupervisorControl
												id={row.rest._id}
												setSnackbarProps={setSnackbarProps}
												nameShowDelete={row.documento}
												refreshEvent={reloadTable}
											/>	
										}										
									</Box>
								</TableCell>
								<TableCell className="col100" align="left">
									{ row?.estadoActivacion === "VERIFICADO" ?
										<Chip 
											label='Verificado'
											className={classes.verificado}
										/>
										: 
										<Chip 
											onClick={() => handleOpenResendVerificationEmailModal(row.rest)}
											label='No Verificado'
											className={classes.noVerificado}
										/>
									}
								</TableCell>
								<TableCell className="col50" align="left">{row.documento}</TableCell>
								<TableCell className="col150" align="left">{row.name}</TableCell>
								<TableCell className="col150" align="left">{row.apellido}</TableCell>
								<TableCell className="col200" align="left">{row.rol}</TableCell>
								<TableCell className="col150" align="left">{row.completeNameGerente}</TableCell>
								<TableCell className="col150" align="left">{row.departamentoFacturacion}</TableCell>
								<TableCell className="col150" align="left">{row.ciudadFacturacion}</TableCell>								
								<TableCell className="col50" align="left">{row.proveedorPagos}</TableCell>								
								<TableCell className="col150" align="left">{row.telefono}</TableCell>
								<TableCell className="col20" align="left">
									<div className="blockBase blockGreen">
										{row.numeroPagos}
									</div>
								</TableCell>
								<TableCell className="col50" align="left">
									<div className="blockBase blockGreen">
										<CurrencyFormat 
											value={row.pago} 
											displayType={'text'} 
											thousandSeparator={true} 
											prefix={'$'} 
											renderText={
												value => <div>{value}</div>
											}
										/>
									</div>
								</TableCell>
								<TableCell className="col150" align="left">
									{
										user?.role === 2 ?
										new Date(row.rest.updatedAt).toLocaleString('es-co')
										: 'Solo para admin'
									}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>		
			<ResendEmailVerificationModal
				supervisor={supervisor}
				open={openResendEmailVerification}
				onClose={handleCloseResendVerificationEmailModal}
			/>
			<DialogPagoSupervisor
				open={openPagoSupervisor}
				close={closeDialogPagoSupervisor}
				Empresa={supervisor}
				roles={roles}
				edicionEvento={edicionEvento}
			/>
			{openInfoSupervisor?
				<DialogInfoSupervisor
				open={openInfoSupervisor}
				close={closeDialogDatosImagenes}
				supervisor={supervisor}
				roles={roles}
				edicionEvento={edicionEvento}
				/>
			:<></>
			}	
    	</>
	);
}