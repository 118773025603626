import { locationAPI as API } from "./apiUrls";
import { handleResponse, isAuthenticated } from "./sessionAPI";
import axios from "axios";

export const getLocationsEvento = async (evento, supervisores, departamentos, ciudades, puestos) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/getLocationsEvento`,{evento, supervisores, departamentos, ciudades, puestos}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const getUserPath = async (id, time) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.get(`${API}/${id}?time=${time}`, {} , {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}