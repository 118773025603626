import React, { useCallback, useEffect, useState} from 'react';
import { 
    Dialog,
    Typography,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Grid,
    makeStyles,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    Box,
    Avatar,
    Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShowAlert from '../../../src/components/shared/Snackbar';
import "../../../src/App.css";
import { hideLoader, showLoader } from '../shared/Loader';
import { getSupervisorsByStand } from '../../core/apiEventos';
import { TableCell, Tooltip } from '@mui/material';
import CustomTableHead from '../shared/Tablas/CustomTableHead';
import { Icons, tableStyles } from '../../assets/js/GeneralStyles';
import DialogInfoSupervisor from '../pagoSupervisor/DialogInfoSupervisor';

const headCells = [
	{ id: 'options', label: 'Detalles', align: 'left', sortable: false },
  { id: '#', label: '#', align: 'left', sortable: true },
  { id: 'documento', label: 'Documento', align: 'left', sortable: false },
  { id: 'name', label: 'Nombre completo', align: 'left', sortable: false },
  { id: 'rol', label: 'Rol', align: 'left', sortable: false },
];

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SupervisorsByStands ({stand, onClose, isOpen})
{
    const tableClasses = tableStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('item');
    const [snackbarProps, setSnackbarProps] = useState();
    const [data, setData] = useState([]);
    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [supervisor, setSupervisor] = useState(null);
    const classes = useStyles();

    const closeDetails = () => {
      setIsOpenDetails(false);
      setSupervisor(null);
    }

    const openDetails = supervisor => {
      setSupervisor(supervisor);
      setIsOpenDetails(true);
    }

    const loadData = useCallback( async () => {
      showLoader()
      const response = await getSupervisorsByStand(stand?._id)
      if(response.data) setData(response.data)
      hideLoader()
    }, [stand])

    useEffect(() => {
      void loadData()
    }, [loadData])

    return (
        <>
          <DialogInfoSupervisor 
            close={closeDetails}
            open={isOpenDetails}
            supervisor={supervisor}
          />
          <Dialog 
              open={isOpen} 
              fullScreen
              aria-labelledby="form-dialog-title"
              TransitionComponent={Transition}
          >
              <AppBar className={classes.appBar}>
                  <Toolbar>
                      <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                          <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className={classes.title}>
                          Personal de {stand?.nombreDelPuesto}  ({stand?.departamento?.name} - {stand?.municipio?.name})
                      </Typography>
                  </Toolbar>
              </AppBar>
              <Box p={1}>
                  <Grid item xs={12}>
                    <Paper elevation={1}>
                      <TableContainer className="borderTable">
                        <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          aria-label="sticky table"	
                        >
                          <CustomTableHead
                            order={order}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            headCells={headCells}
                          />
                          <TableBody>
                            {/* No hay filas para mostrar (tampoco se está filtrando la tabla) */}
                            {data.length === 0 && (
                              <TableRow>
                                <TableCell className={tableClasses.emptyTable} colSpan={headCells.length}>
                                  No se encontró personal o no se han registrado.
                                </TableCell>
                              </TableRow>
                            )}
                            {/* Sí hay filas para mostrar */}
                            {data.map((row, index) => (
                              <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell className="col20">
                                  <Box display="flex" justifyContent="left" alignItems="left">
                                    <Avatar
                                      className={`
                                        ${tableClasses.icons}
                                        ${tableClasses.blue}
                                      `}
                                      variant="rounded"
                                      onClick={() => openDetails(row)}
                                    >
                                      <Tooltip title="Ver detalles">
                                        <Icons.Info fontSize="inherit"/>
                                      </Tooltip>
                                    </Avatar>
                                  </Box>
                                </TableCell>
                                <TableCell className="col20" align="left">{index + 1}</TableCell>
                                <TableCell className="col50" align="left">{row?.documento}</TableCell>
                                <TableCell className="col100" align="left">{row?.firstName} {row?.secondName} {row?.firstLastname} {row?.secondLastname}</TableCell>
                                <TableCell className="col100" align="left">{row?.rol?.name}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>	
                    </Paper>
                  </Grid>
              </Box>               
          </Dialog>
          {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}