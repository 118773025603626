import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
/*
  label: string
  options: array of objects with label and value
  value: string
  setValue: function
*/
export const MySelect = ({ label, options = [], value, setValue, ...props}) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        {...props}
        value={value}
        fullWidth
        label={label}
        onChange={handleChange}
      >
        { options.map(({label, value}) => 
          <MenuItem key={value} value={value}>{label}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
