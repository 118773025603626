import React, { useState, useEffect } from "react";
import "../App.css"

import { showLoader, hideLoader } from './shared/Loader';
import Loader from "../components/shared/Loader";

import { 
    Box
} from "@material-ui/core";

import { signinWebView, authenticate, isAuthenticated } from "../core/sessionAPI";

const Login = () =>{
    const [messageUser, setMessageUser] = useState("Iniciando sesión...");

    useEffect(() => {
        // Redirige al usuario si ya está autenticado
        async function init(){
            showLoader();

            var variables = window.location.href.split("/");
            var evento = variables[4];
            var idUsuario = variables[5];

            if(evento.length == 24 && idUsuario.length == 24){
                try{
                    const response = await signinWebView(evento,idUsuario);
                    hideLoader();
                        
                    if (response.error){
                        setMessageUser("Usuario no identificado. para la visualización de reportes acceda a https://disproel.app desde cualquier navegador WEB");
                    }else{
                        authenticate(response.data);
                        window.location.href = "/eventos/zonificado/entrega/" + evento +"/webView";
                    }
                }catch(ex){
                    hideLoader();
                    setMessageUser("Usuario no identificado. para la visualización de reportes acceda a https://disproel.app desde cualquier navegador WEB");
                }
            }else{
                hideLoader();
                window.location.href = "/";
            }            
        }
        init();
    }, []);
    
    return (
        <>
            <Box p={1} fullWidth className="loadingText">
                {messageUser}
            </Box>
            <Loader />
        </>        
    )
}

export default Login;