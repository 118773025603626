import { empresasAPI as API } from "../apiUrls";
import { handleResponse, isAuthenticated } from "../sessionAPI";
import axios from "axios";

export const getEmpresasAdmin = async () =>
{
    if (isAuthenticated())
    { 
        const res = await axios.get(`${API}/getEmpresasAdmin`, {
            headers: {
                token: isAuthenticated().token
            } 
        });

        return handleResponse(res);
    }
}

export const getEmpresasAdminRegistro = async () =>
{
        const res = await axios.get(`${API}/getEmpresasAdmin`);

        return handleResponse(res);
}

export const crearEmpresa = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/create`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}

export const actualizarEmpresa = async (datos) =>
{
    if (isAuthenticated())
    {
        const res = await axios.post(`${API}/update`, datos, {
            headers: {
                token: isAuthenticated().token
            }
        });

        return handleResponse(res);
    }
}