import { sessionAPI as API } from "./apiUrls";
import { setTokenInterval } from "../helpers/CheckSession";
import axios from "axios";

import moment from "moment-timezone";

// Valida si el usuario está registrado en la base de datos
export const signinWebView = async (evento, idUser) =>
{
    const res = await axios.post(`${API}/signinWebView`, {idUser, evento});
    
    return handleResponse(res);
}

// Reorganiza la respuesta obtenida al lanzar un servicio
export const handleResponse = (response) =>
{
    // Devuelve el mensaje de error o cierra la sesión dependiendo del caso
    if (response.data.error)
    {
        // El parámetro type está definido, validar qué tiene almacenado
        if (response.data.error.type)
        {
            // El error es relacionado al token, cerrar la sesión
            if (response.data.error.type === "token")
            {
                signout();
            }
        }
        return { error: response.data.error }
    }
    return { data: response.data }
}

// Valida si el usuario está registrado en la base de datos
export const signin = async (user) =>
{
    const res = await axios.post(`${API}/login`, user);
    
    return handleResponse(res);
}

export const signinMovil = async (idUser, evento) =>
{
    const res = await axios.post(`${API}/loginMovil`, {idUser, evento});
    
    return handleResponse(res);
}

// Si el usuario está registrado, crea una sesión con los datos devueltos por 'signin'
export const authenticate = (data) =>
{
    if (typeof window !== "undefined"){
        //console.log("CREADA");
        localStorage.setItem("jwt", JSON.stringify(data));
        setTokenInterval();
    }
}

// Retorna los datos de la sesión si el usuario está autenticado
export const isAuthenticated = () =>
{
    if (typeof window === "undefined")
    {
        return false;
    }

    //console.log("VALIDADA");
    //console.log(localStorage.getItem("jwt"));

    if (localStorage.getItem("jwt")){
        return JSON.parse(localStorage.getItem("jwt"));
    }else{
        console.log("Usuario no autenticado");
        return false;
    }
}

// Eliminar sesión del usuario en el navegador y enviar de nuevo a login
export const signout = async () =>
{
    if (typeof window !== "undefined")
    {
        localStorage.removeItem("jwt");
        window.location.href = "/"
    }
}

// Refresca el token de sesión
export const refreshToken = async () =>
{
    if (isAuthenticated())
    {
        const res = await axios.get(`${API}/refreshSession`, {
            headers: {
                token: isAuthenticated().token
            }
        });
        
        if (res.data.error){
            signout()
        }else{
            //localStorage.removeItem("jwt");
            //console.log("RENOVAR TOKEN");
            //authenticate(res.data)
            setTokenInterval();
        }
    }else{
        signout();
    }
}

export const refreshDataSession = (user) =>
{
    const myUser = isAuthenticated()
    if (myUser){
        localStorage.removeItem("jwt");
        console.log("RENOVAR TOKEN");
        console.log({...myUser, user})
        authenticate({...myUser, user})
    }else{
        signout();
    }
}

// Devuelve la hora con la zona horaria de la empresa
export const time = (horasMinutosSegundos = true) =>
{
    if (isAuthenticated())
    {
        const token = isAuthenticated().user;
        let fechaHoy = moment().tz(token.timezone).format("YYYY-MM-DD HH:mm:ss");

        if (! horasMinutosSegundos)
        {
            fechaHoy = moment(fechaHoy).format("YYYY-MM-DD");
            fechaHoy = moment(fechaHoy).format();
        }

        return fechaHoy;
    }
}

export const restorePassMail = async (email) =>
{
    var token = null;
    if (isAuthenticated()){
        token = isAuthenticated().token;        
    }

    const res = await axios.post(`${API}/restorePassMail`, {email:email}, {
        headers: {
            token: token
        }
    });

    return handleResponse(res);
}

// Obtiene el listado de días deshabilitados
export const restaurarPassword = async(codigoRestauracion, password) =>
{    
    const res = await axios.post(`${API}/restaurarPassword`, {codigoRestauracion:codigoRestauracion, password:password}, {
        headers: {
            "token": null
        }
    });

    return handleResponse(res);
}

export const restaurarPasswordSeller = async (user, password) =>
{
    var token = null;
    if (isAuthenticated()){
        token = isAuthenticated().token;        

        const res = await axios.post(`${API}/restaurarPasswordSeller`, {user:user, password:password}, {
            headers: {
                token: token
            }
        });

        return handleResponse(res);
    }
}

export const disableSeller = async (user) =>
{
    var token = null;
    if (isAuthenticated()){
        token = isAuthenticated().token;        

        const res = await axios.post(`${API}/disableSeller`, {user:user}, {
            headers: {
                token: token
            }
        });

        return handleResponse(res);
    }
}

export const enableSeller = async (user) =>
{
    var token = null;
    if (isAuthenticated()){
        token = isAuthenticated().token;        

        const res = await axios.post(`${API}/enableSeller`, {user:user}, {
            headers: {
                token: token
            }
        });

        return handleResponse(res);
    }
}

export const deleteSeller = async (user) =>
{
    var token = null;
    if (isAuthenticated()){
        token = isAuthenticated().token;        

        const res = await axios.post(`${API}/deleteSeller`, {user:user}, {
            headers: {
                token: token
            }
        });

        return handleResponse(res);
    }
}

export const changeTipoDigitacionState = async (isDigitacionManual) =>
{
    if (isAuthenticated())
    { 
        const res = await axios.post(`${API}/changeTipoDigitacionState`,{isDigitacionManual}, {
            headers: {
                token: isAuthenticated().token                
            } 
        });

        return handleResponse(res);
    }
}

export const validarCodigoSms = async(id) =>
{    
    const res = await axios.post(`${API}/validarCodigoSms`, {codigo:id},);

    return handleResponse(res);
}