import React, { useState, useEffect} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import {  isAuthenticated } from "../../core/sessionAPI";
import { controlsAPI as API } from "../../core/apiUrls";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    MenuItem
} from '@material-ui/core';

// Validaciones
import { useFormik } from "formik";

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";

// Servicios
import axios from 'axios';
import { createEmpresaOperadora, updateEmpresaOperadora } from '../../core/apiEmpresaOperadora';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioEmpresaOperadora ({open, close, mode, data, refreshTable, eventos = [], eventoDefault})
{
    const classes = useStyles();
    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    
    /* Textos del título/botón del formulario */
    const [formTitle, setFormTitle] = useState("");
    

    /* Resetea el formulario y lo cierra */
    const resetForm = () =>{
        formik.handleReset();
        close();
    }
    
    /**
     * Manejador evento submit del formulario
     */
    const onSubmit = async (values) => {
        showLoader();    
        //Existe por lo menos un archivo cargado

        let res = {};
        if (mode === "crear"){
            res = await createEmpresaOperadora(values)
        }else if (mode === "editar"){
            values._id = data._id;
            res = await updateEmpresaOperadora(values)
        }

        if (res.error){
            setSnackbarProps("e" + res.error);
        }else{
            setSnackbarProps("s" + res.data.message);
            
            //borrar campos
            resetForm();
            //reiniciar tabla de vehiculos
            refreshTable();
        }

        hideLoader();   
    }

    /* Formik */ //validacion de entrada de datos en formulario
    const formik = useFormik({
        initialValues:{
            codigo: "",
            codigo2: "",
            name: "",
            evento: '',
            nit: ''
        },
        validationSchema: yup.object().shape({
            name: yup.string().required("Debes llenar este campo"),
            codigo: yup.string().required("Debes llenar este campo"),
            codigo2: yup.string().required("Debes llenar este campo"),
            evento: yup.string().required("Debes llenar este campo"),
            nit: yup.string().required("Debes llenar este campo")
        }),
        onSubmit
    });

    const { handleSubmit, isSubmitting, touched, errors, getFieldProps } = formik;
    const nameProps = getFieldProps("name");
    const codigoProps = getFieldProps("codigo");
    const codigo2Props = getFieldProps("codigo2");
    const nitProps = getFieldProps("nit");
    const eventoProps = getFieldProps("evento");
    
    /* Modificar formulario, ya que puede estar en modo "crear" o "editar" */
    //init llama funcion de forma asincrona con await 
    useEffect(() =>{
        async function init(){
            if (mode === "crear"){
                formik.handleReset();
                formik.setFieldValue("evento", eventoDefault);
                setFormTitle("Registrar nueva empresa operadora");
            }else if (mode === "editar"){
                setFormTitle("Actualizar datos de la empresa operadora");
                formik.setFieldValue("name", data.name);
                formik.setFieldValue("codigo", data.codigo);
                formik.setFieldValue("codigo2", data.codigo2);
                formik.setFieldValue("nit", data.nit);
                formik.setFieldValue("evento", data.evento._id);
            }
        }

        init();
    }, [open]);

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>Datos de la empresa operadora</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant="outlined" 
                            name="nit" 
                            label="NIT"
                            fullWidth
                            required
                            inputProps={{ maxLength: 200 }}
                            helperText={touched.nit ? errors.nit : ""}
                            error={touched.nit && Boolean(errors.nit)}
                            {...nitProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="codigo" 
                            label="Código Pagafacil"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.codigo ? errors.codigo : ""}
                            error={touched.codigo && Boolean(errors.codigo)}
                            {...codigoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="codigo2" 
                            label="Código Efecty"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.codigo2 ? errors.codigo2 : ""}
                            error={touched.codigo2 && Boolean(errors.codigo2)}
                            {...codigo2Props}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined" 
                            name="name" 
                            label="Nombre"
                            fullWidth
                            required
                            inputProps={{ maxLength: 200 }}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            {...nameProps}
                        />
                    </Grid>    
                    <Grid item xs={12} sm={12}>
                        <TextField
                            select
                            variant="outlined" 
                            name="evento" 
                            label="Evento"
                            fullWidth
                            required
                            inputProps={{ maxLength: 45 }}
                            helperText={touched.evento ? errors.evento : ""}
                            error={touched.evento && Boolean(errors.evento)}
                            {...eventoProps}
                        >   
                            {
                                eventos.map((e, i) => (
                                    <MenuItem key={i} value={e._id}>
                                        {e.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>    
                </Grid>    
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off">

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={resetForm} aria-label="close" disabled={isSubmitting}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {formTitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()}
                        <Box p={1}>
                            <Button 
                                fullWidth
                                type="submit" 
                                color="primary" 
                                variant='contained'
                                disabled={isSubmitting}
                                startIcon={<SaveIcon />}>
                                Guardar
                            </Button> 
                        </Box>
                    </DialogContent>   
                    
                </form>
            </Dialog>
        
            {/* Snackbar */}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}