import React, {useState, useEffect} from 'react';

// Import de componentes necesarios 
import { 
    Paper,
    Box,
    Typography,
    Dialog,
    Slide,
    Toolbar,
    AppBar,
    IconButton,
} from '@material-ui/core';

import ShowAlert from './shared/Snackbar';
import { showLoader, hideLoader } from './shared/Loader';
import { getTerminosPublicCondicionesUsers } from '../core/settingsAPI' 
import ReactHtmlParser from 'react-html-parser'; 

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TerminosCondicionesPublic({open, handleClose}){

    const [terminosCondiciones, setTerminosCondiciones] = useState("");
    const [versionTC, setVersionTC] = useState("");
    const [snackbarProps, setSnackbarProps] = useState();

    //Servicio de consulta de empresas
    const loadAll = async () =>
    {
        showLoader();
        
        var res = await getTerminosPublicCondicionesUsers();

        if (res.error){
            setSnackbarProps("e" + res.error)
            setTerminosCondiciones("Error de conexión");
        }else{
            if(res.data.contenido === ""){
                //En caso de que no se encuetren tgerminos y condiciones pendientes de aceptar el modal no se presenta                    
                handleClose();
            }else{
                setTerminosCondiciones(res.data.contenido);
                setVersionTC(res.data.version);
            }            
        }

        hideLoader();
    }

    useEffect(() => {
        async function inicializar(){
            loadAll();
        }
        
        if(open){
            console.log("Iniciar terminos");
            inicializar();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open]);    

    return(
        <div>
            <Dialog 
                fullScreen 
                open={open}
                PaperProps={{style: { backgroundColor: "#ECEFF1"}}}
                TransitionComponent={Transition}>
                <AppBar position="static">
                    <Toolbar>                      
                        <Typography variant="h6" className="titleDialog" style={{ flex: 1 }}>
                            Terminos y condiciones
                        </Typography>
                        <div>
                            <IconButton onClick={handleClose} color="inherit">
                                Cerrar
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <Box p={3}>
                    <Paper elevation={3}>
                        <Box p={3}>
                            <h2>
                                Versión actual terminos y condiciones: {versionTC}
                            </h2>
                            <br></br>
                            { ReactHtmlParser(terminosCondiciones) }
                        </Box>
                    </Paper>                    
                </Box>
            </Dialog>

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </div>
    )
}