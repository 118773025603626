import React, { useState, useEffect} from 'react';
// Componentes
import { 
    Button,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    Paper,
    DialogActions
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {confirmarMarcacionMunicipioWS, cancelarMarcacionMunicipioWS} from "../../core/apiEventos";

// Custom components
import { showLoader, hideLoader } from '../../../src/components/shared/Loader';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogMunicipioConfirmar({tipoMarcacion, setSnackbarProps, open, close, tipo, estado, departamento, municipio, evento, producto, reloadAll}){
    const classes = useStyles();
    
    useEffect(() => {
        async function inicializar() {
        }

        inicializar();
    }, []);

    const confirmarMarcacionMunicipio = async () => {
        showLoader();
        
        var res = {};
        if(tipoMarcacion == "marcar"){
            res = await confirmarMarcacionMunicipioWS(tipo, estado, departamento, municipio, producto, evento);
        }else if(tipoMarcacion == "desmarcar"){
            res = await cancelarMarcacionMunicipioWS(tipo, estado, departamento, municipio, producto, evento);
        }
        if (res.error){
            setSnackbarProps("e" + res.error);
            hideLoader();
        }else{
            setSnackbarProps("s" + res.data.message);            
            hideLoader();

            reloadAll();
            close();            
        }            
    }

    return (
        <>
            <Dialog 
                fullWidth
                maxWidth="sm"
                open={open} 
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {tipoMarcacion == "marcar" ? "Confirmar Marcación Municipio " + municipio?.name : "Confirmar Demarcación Municipio " + municipio?.name}
                        </Typography>                            
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {tipoMarcacion == "marcar" ? "Marcación del municipio completo, si continua, se marcarán todos los puestos asociados a este municipio.":"Demarcación del municipio completo, si continua, se demarcarán todos los puestos asociados a este municipio."}
                </DialogContent>    
                <DialogActions>
                    <Button onClick={close}><b>Cancelar</b></Button>
                    <Button color="secondary" onClick={()=>{
                                                    confirmarMarcacionMunicipio();
                                                }} autoFocus>
                        {tipoMarcacion == "marcar" ? "¿Confirmar Marcación Municipio " + municipio?.name + "?":"¿Confirmar Demarcación Municipio " + municipio?.name + "?"}
                    </Button>
                </DialogActions>           
            </Dialog>
        </>
    );
}