import React from 'react'
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useState } from 'react';
import "../../App.css"

export const SelectorEvento = ({ eventos = [], setEventos, defaultId}) => {
    const [value, setValue] = useState(defaultId)

    const handleChange = (e) => {
        const valor = e.target.value
        setValue(valor)
        setEventos(valor)
    }

    return (
        <FormControl fullWidth variant="outlined">
            <Select
                variant="outlined" 
                fullWidth
                className='smallClassSelect'
                defaultValue={defaultId}                
                value={value}
                onChange={handleChange}
            >   
                {
                    eventos.map((e, i) => (
                        <MenuItem key={i} value={e._id}>
                            {e.name}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}
