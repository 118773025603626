/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ShowAlert from '../shared/Snackbar';
import { Box, Grid, Collapse, Hidden } from "@material-ui/core";
import "../../App.css";
import { activarCuenta } from '../../core/apiSupervisores';
import { hideLoader, showLoader } from "../shared/Loader";

const ActivarCuenta = (props) =>
{  
    const [validado, setValidado]= useState(false);
   
    const [snackbarProps, setSnackbarProps] = useState();

    const verificacion= async ()=> {
        try {
            const id = props.match.params.code;
            showLoader()
            const res = await activarCuenta(id);
            hideLoader()
            setValidado(res.data.exist === true)
        } catch (error) {
            setValidado(false);
        }
        
    }
    /**
     * Se ejecuta cada vez que se entra a la página
     */
    useEffect(() => {
        async function init() {
           await verificacion();
        }
        init();
    }, []);

    const registerButton = () =>(
            <Box className="form-container">
            <Grid container> 
                <Hidden xsDown >          
                    <Grid item sm={2} md={4}> 
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={8} md={4}> 
                    <Collapse in={true}>
                        <Box 
                            className="form"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column">                                
                            <img className="imageLogo" src="/img/disproelLogo.png" alt='logo'/>  
                            <h4>{validado?<>¡Cuenta Verificada! Comunícate con el gerente de operación para continuar con el proceso.</> :<>¡El Vinculo del Correo Electrónico no es valido!</> }</h4>
                        </Box>
                    </Collapse>
                    
                </Grid>
                <Hidden xsDown >  
                    <Grid item sm={2} md={4}> 
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    )
    
    return (
        <>
            {registerButton()}
            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    )
}

export default ActivarCuenta;