import React, {useState, useRef, useEffect} from 'react';
import {Grid, Box, Paper, MenuItem, TextField} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../App.css"

import NavContainer from '../shared/NavContainer';
import { useWrapperStyles } from '../../assets/js/wrapperStyles';
import { onUserNavigate, setTokenInterval } from "../../helpers/CheckSession";
import { refreshToken } from "../../core/sessionAPI";

export default function DashboardPage(props) {
    const [ nameEvento, setNameEvento ] = useState("...");
    const wrapper = useWrapperStyles();

    const View = () => {
        useEffect(() => {
            async function init() {
                // Realizar el servicio que refresca el token
                await refreshToken();
                // Reiniciar timer de inactividad
                setTokenInterval();
            }
            init();
        }, []);
        
        return (
            <>
                <LandingPage setNameEvento={setNameEvento}/>
            </>)
    }

    return (
        <>
            <div className={wrapper.root} onMouseMove={onUserNavigate} onClick={onUserNavigate} onKeyPress={onUserNavigate}>
                <div className={wrapper.content}>
                    <NavContainer nameEvento={nameEvento} />
                    <div className={wrapper.offset}></div>

                    {/* IMPORTANTE: Ver definición del componente View */}
                    <View/>
                </div>
            </div>
        </>
    )
}

export function LandingPage ({setNameEvento}){

    const options = {
        credits:{
            enabled:false,
        },
        chart: {
            type: 'column'
        },
        title: {
            text: '--'
        },
        subtitle: {
            text: '--'
        },
        xAxis: {
            categories: [
                '--',
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: '--'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: '--',
            data: [0]
    
        }]
    }; 

    const refEntradasGrafico = useRef(null);
    const refSalidasGrafico = useRef(null);
    const refGastosGrafico = useRef(null);
    const [showLoader, setShowLoader] = useState(false);

    const callbackGenerationChart = () => {
        console.log("Grafico entradas generado correctamente");
    }

    const loadIndicadores = async () => {
        //Seleccion de vendedor             
        refEntradasGrafico.current.chart.update({
            title:{
                text:"Entradas"
            },
            subtitle:{
                text: "Movimientos de ingreso de dinero, total día"
            },
            series: {name:"", data:[10, 15, 22, 55, 56, 44, 88, 99, 10, 100, 90, 80]},
            yAxis:{
                title:{
                    text: "Valor del movimiento"
                }
            },
            xAxis:{
                categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                crosshair: true
            }
        }, true, true); 
        
        refSalidasGrafico.current.chart.update({
            title:{
                text:"Salidas"
            },
            subtitle:{
                text: "Movimientos de salida de dinero, total día"
            },
            series: {name:"", data:[10, 15, 22, 55, 56, 44, 88, 99, 10, 100, 90, 80]},
            yAxis:{
                title:{
                    text: "Valor del movimiento"
                }
            },
            xAxis:{
                categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                crosshair: true
            }
        }, true, true); 
        
        refGastosGrafico.current.chart.update({
            title:{
                text:"Gastos"
            },
            subtitle:{
                text: "Gastos realizados, total día"
            },
            series: {name:"", data:[10, 15, 22, 55, 56, 44, 88, 99, 10, 100, 90, 80]},
            yAxis:{
                title:{
                    text: "Valor del movimiento"
                }
            },
            xAxis:{
                categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                crosshair: true
            }
        }, true, true);             
    }

    async function loadDataEvento(){
        setNameEvento("Dasboard / Nombre del evento");  
        //Consulta de información del evento

    }

    useEffect(() => {
        async function effect() {      
            var evento = window.location.pathname.split("/")[2];
            //await loadIndicadores();               
        }
        
        loadDataEvento();         
        effect();
    }, []);

    return (
        <>
            <Box p={2}>
                <Paper elevation={1}>                          
                    <Grid container justify="center">                        
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Box p={2} className="smallTextDashboard">
                                Valor recaudado del día
                            </Box>                            
                            <Box p={2} className="bigText">
                                $ 0
                            </Box>                            
                        </Grid> 
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <Box p={2} className="smallTextDashboard">
                                Meta recaudo día
                            </Box>                            
                            <Box p={2} className="bigText">
                                $ 0
                            </Box>                            
                        </Grid> 
                        <Grid item xs={12} sm={4} md={2} lg={2}>
                            <Box p={2} className="smallTextDashboard">
                                Clientes en mora
                            </Box>                            
                            <Box p={2} className="bigText">
                                0
                            </Box>                            
                        </Grid> 
                        <Grid item xs={12} sm={4} md={2} lg={2}>
                            <Box p={2} className="smallTextDashboard">
                                Valor total en mora
                            </Box>                            
                            <Box p={2} className="bigText">
                                $ 0
                            </Box>                            
                        </Grid>                                                                                   
                    </Grid>
                    <Grid container justify="center">
                        <Grid item xs={12} md={12} lg={11}>
                            <HighchartsReact 
                                ref={refEntradasGrafico}
                                highcharts={Highcharts} 
                                options={options} 
                                allowChartUpdate = { true }
                                callback={callbackGenerationChart}/>
                        </Grid>           
                    </Grid>
                    <Grid container justify="center">
                        <Grid item xs={12} md={12} lg={11}>
                            <HighchartsReact 
                                ref={refSalidasGrafico}
                                highcharts={Highcharts}                             
                                options={options} 
                                allowChartUpdate = { true }
                                callback={callbackGenerationChart}/>
                        </Grid>           
                    </Grid>
                    <Grid container justify="center">
                        <Grid item xs={12} md={12} lg={11}>
                            <HighchartsReact 
                                ref={refGastosGrafico}
                                highcharts={Highcharts}                             
                                options={options} 
                                allowChartUpdate = { true }
                                callback={callbackGenerationChart}/>
                        </Grid>           
                    </Grid>                                
                </Paper>
            </Box>

            <Loader show={showLoader} />
        </>
    )
}